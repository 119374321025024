import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
// import {dataStore} from './module'
// import BigNumber from "bignumber.js";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: ["id", "ticket", "walletAddress", "isEnableKaikas", "balanceList"],
        }),
    ],
    state: {
        configCategory: [],
        configFront: null,
        configNetwork: [],
        configNftInfo: [],
        configTokenInfo: [],
        
        categoryList: [],

        id: "",
        ticket: "",

        //UserInfo
        aboutMe: '',
        banner: null,
        createDate: "",
        collectionCount: 0,
        email: "",
        erc20Address: "",
        flagAcceptOffer: 0,
        flagBid: 0,
        flagEmail: 0,
        flagExpiredSell: 0,
        flagItemSell: 0,
        flagMinOffer: 0,
        flagOverOffer: 0,
        userName: "",
        memberIdx: 0,
        minOfferPriceAtt: 0,
        minOfferPriceKlay: 0,
        profile: null,

        //Wallet
        walletAddress: '',
        balanceList: [
            {name:'KLAY', balance: 0, usd: 0, iconImg: require('@/assets/images/icon_klaytn.svg')},
            {name:'ATT', balance: 0, usd: 0, iconImg: require('@/assets/images/att.svg')}
        ],
        networkId: 0,
        isEnableKaikas: false,

        //Floating Popup
        wsAlram: null,  //알림 웹소켓
        intervalCheckWebSocketState: null,
        wsCheckState: 0,    //0 연결 안함, 1: 연결 확인 요청 중, 2: 연결 확인 됨
        isShowFloating: false,
        floatingData: null,
    },
    mutations: {
        updateId: function (state, id) {
            state.id = id;
        },
        updateTicket: function (state, ticket) {
            state.ticket = ticket;
        },
        setUserInfo: async function (state, host) {
            var param = {
                id: state.id,
                ticketId: state.ticket,
            };
            axios
                .post(`${host}/user/info`, param, {
                    headers: {
                        Pragma: "no-cache",
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        "Contcurrent-type": "application/json",
                    },
                })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        state.aboutMe = res.data.data.aboutMe;
                        state.banner = res.data.data.banner;
                        state.createDate = res.data.data.createDate;
                        state.collectionCount = res.data.data.collectionCount;
                        state.email = res.data.data.email;
                        state.erc20Address = res.data.data.erc20Address;
                        state.userName = res.data.data.id;
                        state.memberIdx = res.data.data.memberIdx;
                        state.profile = res.data.data.profile;

                        state.flagAcceptOffer = res.data.data.flagAcceptOffer;
                        state.flagBid = res.data.data.flagBid;
                        state.flagEmail = res.data.data.flagEmail;
                        state.flagExpiredSell = res.data.data.flagExpiredSell;
                        state.flagItemSell = res.data.data.flagItemSell;
                        state.flagMinOffer = res.data.data.flagMinOffer;
                        state.flagOverOffer = res.data.data.flagOverOffer;
                        state.minOfferPriceAtt = res.data.data.minOfferPriceAtt;
                        state.minOfferPriceKlay = res.data.data.minOfferPriceKlay;

                        return true;
                    }
                });
        },
        setConfigFront: function (state, host) {
            var param = {};
            axios
                .post(`${host}/config/front`, param, {
                    headers: {
                        Pragma: "no-cache",
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        "Contcurrent-type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.returnCode === 0) {
                        state.configFront = res.data.data;
                    }
                    // console.log('-----config front-----');
                    // console.log(state.configFront);
                });
        },
        setConfigNetwork: function (state, host) {
            var param = {};
            axios
                .post(`${host}/config/network`, param, {
                    headers: {
                        Pragma: "no-cache",
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        "Contcurrent-type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.returnCode === 0) {
                        res.data.list.forEach((item) => {
                            var pItem = {
                                // idx: item.idx,
                                // state: item.state,
                                name: item.name,
                                displayName: item.displayName,
                                network: item.network,
                                chainInt: item.chainInt,
                                data: JSON.parse(item.data),
                            };
                            state.configNetwork.push(pItem);
                        });
                        // console.log('-----config network-----');
                        // console.log(state.configNetwork);
                    }
                });
        },
        setConfigNftInfo: function (state, host) {
            var param = {};
            axios
                .post(`${host}/config/nft/info`, param, {
                    headers: {
                        Pragma: "no-cache",
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        "Contcurrent-type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.returnCode === 0) {
                        state.configNftInfo = res.data.list;
                    }
                    // console.log('-----config NFT info-----');
                    // console.log(state.configNftInfo);
                });
        },
        setConfigTokenInfo: function (state, host) {
            var param = {};
            axios
                .post(`${host}/config/token/info`, param, {
                    headers: {
                        Pragma: "no-cache",
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        "Contcurrent-type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.returnCode === 0) {
                        state.configTokenInfo = res.data.list;
                    }
                    // console.log('-----config Token info-----');
                    // console.log(state.configTokenInfo);
                });
        },
        setCategoryList: function (state, host) {
            var param = {};
            axios
                .post(`${host}/nft/category/list`, param, {
                    headers: {
                        Pragma: "no-cache",
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        "Contcurrent-type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.returnCode === 0) {
                        state.categoryList = res.data.list;
                    }
                    // console.log('-----category list-----');
                    // console.log(state.categoryList);
                });
        },
        connectKaikas: function(state, account){
            state.isEnableKaikas = true,
            state.walletAddress = account
        },
        logout:function(state){
            state.id = "";
            state.ticket ="";

            state.aboutMe =  '';
            state.banner =  null;
            state.createDate =  "";
            state.collectionCount = 0;
            state.email =  "";
            state.erc20Address =  "";
            state.userName =  "";
            state.memberIdx = 0;
            state.profile =  null;

            state.flagAcceptOffer = 0;
            state.flagBid = 0;
            state.flagEmail = 0;
            state.flagExpiredSell = 0;
            state.flagItemSell = 0;
            state.flagMinOffer = 0;
            state.flagOverOffer = 0;
            state.minOfferPriceAtt = 0;
            state.minOfferPriceKlay = 0;

            state.wsAlram = null;
        },
        disconnectKaikas: function(state){
            state.walletAddress = '',
            state.isEnableKaikas = false;
            for(var i=0; i < state.balanceList.length; i++ ){
                state.balanceList[i].balance = 0;
                state.balanceList[i].usd = 0;
            }

        },
        disconnectWebSocket: function(state){
            (state.wsAlram = null),
            (state.intervalCheckWebSocketState = null),
            (state.wsCheckState = 0);
        },
        resetFloating: function (state) {
            (state.isShowFloating = false), (state.floatingData = null);
        },
    },
    getters: {
        getMyBalance:
            (state) =>
            (symbol) => {
                var balanceInfo = state.balanceList.find((balance) => balance.name === symbol);
                if(balanceInfo === undefined){
                    return 0;
                } else {
                    return balanceInfo.balance;
                }
            },
        getTokenUsdPrice:
            (state) =>
            (symbol, tokenAmount, decimalPoint = 0) => {
                // console.log(symbol);
                // console.log(tokenAmount);
                if(symbol === null || tokenAmount <= 0 || state.configTokenInfo === null){
                    return '0';
                }
                
                var tokenInfo = state.configTokenInfo.find((config) => config.symbol === symbol);
                if(tokenInfo === undefined){
                    return '0';
                }

                var strDecimal = '1';
                var numberDecimal = 1;
                for(var i=0; i<decimalPoint; i++){
                    strDecimal += '0';
                }
                numberDecimal = Number(strDecimal);

                if(decimalPoint > 0 ){
                    return Math.ceil((tokenAmount * tokenInfo.price) * numberDecimal) / numberDecimal;
                } else {
                    return Math.ceil(tokenAmount * tokenInfo.price);
                }

            },
        getTokenAmount:
            (state) =>
            (symbol, usdPrice, decimalPoint = 0) => {
                // console.log(symbol);
                // console.log(tokenAmount);
                if(symbol === null || usdPrice <= 0 || state.configTokenInfo === null){
                    return '0';
                }
                
                var tokenInfo = state.configTokenInfo.find((config) => config.symbol === symbol);
                if(tokenInfo === undefined){
                    return '0';
                }

                var strDecimal = '1';
                var numberDecimal = 1;
                for(var i=0; i<decimalPoint; i++){
                    strDecimal += '0';
                }
                numberDecimal = Number(strDecimal);

                if(decimalPoint > 0 ){
                    return Math.ceil((usdPrice / tokenInfo.price) * numberDecimal) / numberDecimal;
                } else {
                    return Math.ceil(usdPrice / tokenInfo.price);
                }

            },
        getCurrentUrlForTx:
            (state) =>
            (tx) => {
                if(state.configFront !== null){
                    var url = state.configFront.klayScanUrl;
                    if (url != "") {
                        return url + "tx/" + tx;
                    }
                }
                return "";
            },
        getCurrentUrlForAddress:
            (state) =>
            (addr) => {
                var url = state.configFront.klayScanUrl;
                if (url != "") {
                    return url + "address/" + addr;
                }
                return "";
            },
        getCurrentUrlForNft:
            (state) =>
            (addr) => {
                var url = state.configFront.klayScanUrl;
                if (url != "") {
                    return url + "nft/" + addr;
                }
                return "";
            },            
        getRemainDate:
            (state) =>
            (expireDate) => {
                if(expireDate === null || state.configFront === null){
                    return '';
                }

                var tsServerDate = Date.parse(state.configFront.dateServer.replace(/-/g, "/"));
                var tsExpireDate = Date.parse(expireDate.replace(/-/g, "/"));

                if (tsServerDate > tsExpireDate) {
                    return '';
                }

                var remainCount = tsExpireDate - tsServerDate;
                var _second = 1000;
                var _minute = _second * 60;
                var _hour = _minute * 60;
                var _day = _hour * 24;

                var days = Math.floor(remainCount / _day);
                var hours = Math.floor((remainCount % _day) / _hour);
                var minutes = Math.floor((remainCount % _hour) / _minute);
                var seconds = Math.floor((remainCount % _minute) / _second);

                if(days > 0){
                    return days + '일 남음';
                } else if (hours > 0){
                    return hours + '시간 남음';
                } else if (minutes > 0){
                    return minutes + '분 남음';
                } else if (seconds > 0){
                    return seconds + '초 남음';
                }
            },
        getLeftDate:
            (state) =>
            (leftDate) => {
                if(leftDate === null || state.configFront === null){
                    return '';
                }

                var tsServerDate = Date.parse(state.configFront.dateServer.replace(/-/g, "/"));
                var tsLeftDate = Date.parse(leftDate.replace(/-/g, "/"));

                var leftCount = tsServerDate - tsLeftDate;
                var _second = 1000;
                var _minute = _second * 60;
                var _hour = _minute * 60;
                var _day = _hour * 24;

                var days = Math.floor(leftCount / _day);
                var hours = Math.floor((leftCount % _day ) / _hour);
                var minutes = Math.floor((leftCount % _hour) / _minute);
                var seconds = Math.floor((leftCount % _minute) / _second);

                if(days > 0){
                    return days + '일 전';
                } else if (hours > 0){
                    return hours + '시간 전';
                } else if (minutes > 0){
                    return minutes + '분 전';
                } else if (seconds > 0){
                    return seconds + '초 전';
                }
            },
    },
    computed: {
        // calculatingTicket(){
        //     return this.$store.state.ticketCount + ((Date.now()/1000 - this.$store.state.blockDateLong) * this.$store.state.amountBySecond);
        // }
    },
    actions: {},
    modules: {
        // data: dataStore
    },
});
