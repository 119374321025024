<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box cancel padding">
            <div class="top">
                <p class="title">내역 삭제</p>
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
            </div>
            <div class="bottom">
                <p class="cont">선택하신 내역을 삭제하시겠습니까?</p>
                <div class="buttons">
                    <a class="left" @click="closePopup">{{ $t('cancel.btn1') }}</a>
                    <a class="right" @click="reqOfferListDelete">{{ $t('cancel.btn4') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PopupCancel",
    data() {
        return {
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initOfferListDeleteItem: {
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        closePopup() {
            this.$emit("close");
        },
        reqOfferListDelete(){
            // console.log(this.initCancelInstance);
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                offerIdx: this.initOfferListDeleteItem.makeOfferIdx
            }
            this.$Axios.post(`${this.host}/nft/offer/delete`, param, this.headers).then(async (res) => {
                // console.log('---nft offer list delete----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.$emit("completeOfferListdelete")
                } else {
                    this.$emit('error', res.data.returnCode);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
    },
};
</script>
