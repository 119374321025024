<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box list padding">
            <div class="top">
                <p v-html="initPopupTitle" class="popup-title"></p>
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
            </div>
            <div class="bottom">
                <div class="bottom-inner">
                    <b-row>
                        <b-col v-for="(item,idx) in initList" :key="idx">
                            <img src="@/assets/images/profile-menu/no-profile.png" alt="profile" class="photo" v-if="$store.state.configFront === null || item.memberFileName === null">
                            <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.memberFileName" class="photo" v-else />

                            <div class="info">
                                <router-link tag="p" :to="'/Profile/' + item.memberName" class="name">{{item.memberName}}</router-link>
                            </div>
                            <p class="quantity" v-if="initListType === 'owner'">{{item.quantity | comma}}개 소유</p>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupList",
        data() {
            return {
                
            };
        },
        props: {
            initIsShowPopup: {
                type: Boolean,
                default: false,
            },
            initPopupTitle: {
                type: String,
                default: "",
            },
            initListType: {
                type: String,
                default: ""
            },
            initList: {
                type: Array
            }
        },
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {
            closePopup() {
                this.$emit("close");
            },
        },
    };
</script>
