<template>
    <div class="collection-page profile-page">
        <Header :initHeaderType="initHeaderType" />
        <div class="collection-banner" @click="changeBannerImg()" :class="{file : $store.state.banner === null}">
            <img src="@/assets/images/profile-hover.svg" class="hover" />
            <img :src="$store.state.banner" v-if="$store.state.banner !== null" />
            <img src="@/assets/images/profileBanner.png" alt="background" v-else />
            <input type="file" name="file" id="bannerFiles" class="display-none" ref="bannerFiles" :accept="profileImgAccept" v-on:change="handleBannerFileUpload()"/>
        </div>
        <div class="links">
            <router-link tag="img" class="icon" :src="require('@/assets/images/option-icon.svg')" to="/AccountSetting"></router-link>
            <div class="bluetooth" v-click-outside="closeShare">
                <img src="@/assets/images/bluetooth-icon.svg" class="icon" @click="toggleShare()"/>
                <b-row v-show="isShare">
                    <b-col class="twitter" @click="snsShare('twitter')">
                        <img src="@/assets/images/icon/twitter-b.png"/>
                        <p>{{ $t('collectionPage.share1') }}</p>
                    </b-col>
                    <b-col @click="snsShare('telegram')">
                        <img src="@/assets/images/icon/telegram-b.png" class="telegram" />
                        <p>{{ $t('collectionPage.share2') }}</p>
                    </b-col>
                    <b-col @click="snsShare('facebook')">
                        <img src="@/assets/images/icon/facebook-b.png" class="facebook" />
                        <p>{{ $t('collectionPage.share3') }}</p>
                    </b-col>
                    <b-col class="link" @click="snsShare('copy')">
                        <img src="@/assets/images/icon/link-b.png" />
                        <p>{{ $t('collectionPage.share4') }}</p>
                    </b-col>
                </b-row>
            </div>
            <!-- <img src="@/assets/images/siren.png" class="icon" @click="initIsReport = !initIsReport"/> -->
        </div>
        <b-container>
            <div class="profile" @click="changeProfileImg()">
                <img src="@/assets/images/profile-hover.svg" class="hover" />
                <img src="@/assets/images/myProfile.png" alt="profile" v-if="$store.state.profile === null" >
                <img :src="$store.state.profile" v-else />
                <input type="file" name="file" id="profileFiles" class="display-none" ref="profileFiles" :accept="profileImgAccept" v-on:change="handleProfileFileUpload()"/>
            </div>
            <p class="title">{{$store.state.userName}}</p>
            <div class="profile-wallet-ad">
                <p v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler">{{($store.state.walletAddress).toString().substr(0,6)}}...{{($store.state.walletAddress).toString().substr(-6)}}</p>
                <img src="@/assets/images/icon/copy.svg" class="btn-copy" v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler"/>
            </div>
            <p class="description">{{$store.state.aboutMe}}</p>
            <div class="links mb">
                <router-link tag="img" class="icon setting" :src="require('@/assets/images/option-icon.svg')" to="/AccountSetting"></router-link>
                <div class="bluetooth" v-click-outside="closeShareMobile">
                    <img src="@/assets/images/bluetooth-icon.svg" class="icon" @click="toggleShareMobile()"/>
                    <b-row v-show="isShareMobile">
                        <b-col class="twitter" @click="snsShare('twitter')">
                            <img src="@/assets/images/icon/twitter-b.png"/>
                            <p>{{ $t('collectionPage.share1') }}</p>
                        </b-col>
                        <b-col @click="snsShare('telegram')">
                            <img src="@/assets/images/icon/telegram-b.png" class="telegram" />
                            <p>{{ $t('collectionPage.share2') }}</p>
                        </b-col>
                        <b-col @click="snsShare('facebook')">
                            <img src="@/assets/images/icon/facebook-b.png" class="facebook" />
                            <p>{{ $t('collectionPage.share3') }}</p>
                        </b-col>
                        <b-col class="link" @click="snsShare('copy')">
                            <img src="@/assets/images/icon/link-b.png" />
                            <p>{{ $t('collectionPage.share4') }}</p>
                        </b-col>
                    </b-row>
                </div>
                <!-- <img src="@/assets/images/siren.png" class="icon" @click="initIsReport = !initIsReport"/> -->
            </div>
        </b-container>
        <b-row class="profile-menu">
            <div class="underline">
                <b-col class="menu1" :class="{on : selectedTabNo === 1}" @click="$router.push('/MyProfile/MyItem')">{{ $t('collectionPage.menu1') }}</b-col>
                <b-col class="menu2" :class="{on : selectedTabNo === 2}" @click="$router.push('/MyProfile/TransactionHistory')">{{ $t('collectionPage.menu2') }}</b-col>
                <b-col class="menu3" :class="{on : selectedTabNo === 3}" @click="$router.push('/MyProfile/Offers')">{{ $t('collectionPage.menu3') }}</b-col>
                <b-col class="menu4" :class="{on : selectedTabNo === 4}" @click="$router.push('/MyProfile/Liked')">{{ $t('collectionPage.menu4') }}</b-col>
                <b-col class="menu5" :class="{on : selectedTabNo === 5}" @click="$router.push('/MyProfile/HiddenItem')">{{ $t('collectionPage.menu5') }}</b-col>
                <b-col class="menu6" :class="{on : selectedTabNo === 6}" @click="$router.push('/MyProfile/Loyalty')">{{ $t('collectionPage.menu6') }}</b-col>
            </div>
        </b-row>
        <div class="cont-box" id="cont-box">
            <MyProfileFilter 
                :initSelectedTab="selectedTabNo" 
                :initFlagSetCollection="initFlagSetCollection" 
                :initCollectionType="initCollectionType" 
                :initPreloadCollectionInfo="initPreloadCollectionInfo" 
                :initFollowFilter="initFollowFilter" 
                :initShowStatus="initShowStatus" 
                :initShowPrice="initShowPrice"  
                :initFlagResetFilter="flagResetFilter" 
                :initRemoveFilter="initRemoveFilter" 
                @changeFilterOptions="changeFilterOptions"
            />
            <div class="cont-area">
                <div class="top util" v-if="selectedTabNo === 1 || selectedTabNo === 5">
                    <div class="search">
                        <b-input placeholder="아이템을 검색하세요" v-model="searchText" @keyup.enter="searchItem()"></b-input>
                    </div>
                    <div class="input-group dropdown">
                        <b-dropdown :text="getSelectedOrderText()">
                            <b-dropdown-item v-for="(item) in orderOptions" :key="item.value" @click="changeOrderType(item.value)">{{item.text}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="filter-list" v-if="getIsShowFilterList()">
                    <div class="reset" @click="resetFilter()">
                        <p>{{ $t('marketplace.reset') }}</p>
                    </div>
                    <div v-if="searchedText !== ''">
                        <p>{{searchedText}}</p>
                        <i class="btn-close" @click="removeSearchText()" />
                    </div>
                    <div v-if="selectedStatus !== null">
                        <p v-if="selectedStatus === 1">{{ $t('selectFilter.buy') }}</p>
                        <p v-else-if="selectedStatus === 2">{{ $t('selectFilter.auction') }}</p>
                        <p v-else-if="selectedStatus === 3">{{ $t('selectFilter.new') }}</p>
                        <p v-else-if="selectedStatus === 4">{{ $t('selectFilter.offers') }}</p>
                        <i class="btn-close" @click="removeFilter('status')" />
                    </div>
                    <div v-if="selectedPrice !== null">
                        <p>
                            {{minPrice}}~{{maxPrice}}
                            <span v-if="selectedPrice === 1">USD</span> 
                            <span v-else-if="selectedPrice === 2">ATT</span> 
                            <span v-else-if="selectedPrice === 3">KLAY</span> 
                        </p>
                        <i class="btn-close" @click="removeFilter('price')" />
                    </div>
                    <div v-if="selectedCollectionIdx !== null">
                        <p>{{selectedCollectionName}}</p>
                        <i class="btn-close" @click="removeFilter('collection')" />
                    </div>
                    <div v-if="selectedSalesCoin !== null">
                        <p>{{selectedSalesCoin}}</p>
                        <i class="btn-close" @click="removeFilter('coin')" />
                    </div>
                </div>
                <div class="item-list-box" v-if="selectedTabNo === 1">
                    <div class="right">
                        <div class="bottom cont">
                            <b-row>
                                <b-col class="product" v-for="(item,idx) in nftInfoList" :key="idx">
                                    <Item :itemInfo="item" :initItemDropDown="true" :initIsMine="true" @completeHide="completeHide" @completeTransfer="completeTransfer"/>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
                <div class="history cont" v-else-if="selectedTabNo === 2">
                    <div class="list-box transaction">
                        <b-row class="list-head">
                            <b-col class="col-type">{{ $t('collectionPage.event') }}</b-col>
                            <b-col class="col-item">{{ $t('collectionPage.item') }}</b-col>
                            <b-col class="col-price">{{ $t('collectionPage.price') }}</b-col>
                            <b-col class="col-from">{{ $t('collectionPage.from') }}</b-col>
                            <b-col class="col-to">{{ $t('collectionPage.to') }}</b-col>
                            <b-col class="col-date">{{ $t('collectionPage.date') }}</b-col>
                            <b-col class="col-quantity">{{ $t('collectionPage.quantity') }}</b-col>
                            <b-col class="col-tx"></b-col>
                        </b-row>
                        <b-row v-for="(item,idx) in transactionList" :key="idx">
                            <b-col class="col-type">
                                <img src="@/assets/images/transaction/type1.svg" v-show="item.type === 2 || item.type === 15" />
                                <img src="@/assets/images/transaction/type2.svg" v-show="item.type === 11" />
                                <img src="@/assets/images/transaction/type3.svg" v-show="item.type === 9" />
                                <img src="@/assets/images/transaction/type4.svg" v-show="item.type === 10" />
                                <img src="@/assets/images/transaction/type5.svg" v-show="item.type === 12 || item.type === 28" />
                                <img src="@/assets/images/transaction/type6.svg" v-show="item.type === 7 || item.type === 21 || item.type === 25" />
                                <!-- <img src="@/assets/images/transaction/type7.svg" v-show="item.type === 1" /> -->
                                <img src="@/assets/images/transaction/type8.svg" v-show="item.type === 20" />
                                <img src="@/assets/images/transaction/type9.svg" v-show="item.type === 19" />
                                <img src="@/assets/images/transaction/type10.svg" v-show="item.type === 0" />
                                <img src="@/assets/images/transaction/type11.svg" v-show="item.type === 3 || item.type === 16" />
                                <img src="@/assets/images/transaction/type12.svg" v-show="item.type === 4 || item.type === 17" />
                                <p>{{ $t('transactional.event_type_' + item.type) }}</p>
                            </b-col>
                            <b-col class="col-item">
                                <router-link tag="a" :to="'/NftInfo/' + item.itemIdx" class="link-item">
                                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + item.itemFileFormat + '/' + item.itemFileName" class="photo" v-if="$store.state.configFront !== null && item.itemResourceType === 1" />
                                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/'+ item.itemThumbnailFileName" class="photo" v-if="$store.state.configFront !== null && item.itemResourceType === 2" />

                                    <div class="box">
                                        <p class="collection-name">{{item.collectionName}}</p>
                                        <p class="skip">{{item.itemName}}</p>
                                    </div>
                                </router-link>
                            </b-col>
                            
                            <b-col class="col-price col-blank" v-if="item.price === null">-</b-col>
                            <b-col class="col-price" v-else>
                                <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.symbol === 'ATT'" />
                                <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.symbol === 'KLAY'" />
                                <p>{{item.price | comma}}</p>
                            </b-col>
                            <b-col class="col-from col-blank" v-if="item.type === 1 || (item.fromId === null && item.from ===  null)">-</b-col>
                            <b-col class="col-from" v-else>
                                <img src="@/assets/images/icon/wallet.svg" class="img-wallet" v-if="item.fromId === null" />
                                <img src="@/assets/images/my-wallet.svg" alt="profile" class="photo" v-else-if="$store.state.configFront === null || item.fromFileName === null">
                                <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.fromFileName" class="photo" v-else />
                                
                                <router-link tag="p" :to="'/Profile/' + item.fromId" class="skip" v-if="item.fromId !== null">{{item.fromId}}</router-link>
                                <p v-else>{{item.from}}</p>
                            </b-col>
                            
                            <b-col class="col-to">
                                <img src="@/assets/images/icon/wallet.svg" class="img-wallet" v-if="item.toId === null" />
                                <img src="@/assets/images/my-wallet.svg" alt="profile" class="photo" v-else-if="$store.state.configFront === null || item.toFileName === null">
                                <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.toFileName" class="photo" v-else />
                                
                                <router-link tag="p" :to="'/Profile/' + item.toId" class="skip" v-if="item.toId !== null">{{item.toId}}</router-link>
                                <p v-else>{{item.to}}</p>
                            </b-col>
                            <b-col class="col-date" :class="{right : item.txConfirm === null}">{{$store.getters.getLeftDate(item.date)}}</b-col>
                            <b-col class="col-quantity">{{item.amount | comma}}<span class="">개 {{ $t('transactional.event_type_' + item.type) }}</span></b-col>
                            <b-col class="col-tx"><a :href="$store.getters.getCurrentUrlForTx(item.txConfirm)" target="_blank" v-if="item.txConfirm !== null"><img src="@/assets/images/icon_link_off.svg" class="link"></a></b-col>
                        </b-row>
                        <div class="next-list" v-if="totalRecordSize > 0 && transactionList.length < totalRecordSize">
                            <a @click="nextList()">{{ $t('rankings.more') }}</a>
                        </div>
                    </div>
                </div>
                <div class="offers-box" v-else-if="selectedTabNo === 3">
                    <div class="text">
                        <p>{{ $t('collectionPage.menu3Text1') }}</p>
                        <p>{{ $t('collectionPage.menu3Text2') }}</p>
                    </div>
                    <div class="switch">
                        <button :class="{on : selectedOfferSubTabNo === 1}" @click="changeOfferSubTabNo(1)">{{ $t('collectionPage.switch-button1') }}</button>
                        <button :class="{on : selectedOfferSubTabNo === 2}" @click="changeOfferSubTabNo(2)">{{ $t('collectionPage.switch-button2') }}</button>
                    </div>
                    <div v-if="selectedOfferSubTabNo === 1">
                        <div class="list-box transaction">
                            <div class="list-overflow">
                                <b-row class="list-head">
                                    <b-col class="col" cols="3">{{ $t('collectionPage.item') }}</b-col>
                                    <b-col class="col" cols="3">{{ $t('collectionPage.price') }}</b-col>
                                    <b-col class="col" cols="1">{{ $t('collectionPage.quantity') }}</b-col>
                                    <b-col class="col" cols="2">{{ $t('collectionPage.expiration') }}</b-col>
                                    <b-col class="col" cols="1">{{ $t('collectionPage.state') }}</b-col>
                                    <b-col class="col buttons" cols="2"></b-col>
                                </b-row>
                                <b-row v-for="(item,idx) in offerList" :key="idx">
                                    <b-col class="col" cols="3">
                                        <router-link tag="a" :to="'/NftInfo/' + item.productIdx" class="link-item">
                                            <!-- <img :src="$store.state.configFront.cdnUrl + 'resource/' + item.productFileFormat + '/' + item.productFileName" class="photo" v-if="$store.state.configFront !== null" /> -->
                                            <img :src="$store.state.configFront.cdnUrl + 'resource/' + item.productFileFormat + '/' + item.productFileName" class="photo" v-if="$store.state.configFront !== null && item.productResourceType === 1" />
                                            <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/'+ item.productThumbnailFileName" class="photo" v-if="$store.state.configFront !== null && item.productResourceType === 2" />

                                            <p class="skip">{{item.productName}}</p>
                                        </router-link>
                                    </b-col>
                                    <b-col class="price col" cols="3">
                                        <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.offerSymbol === 'ATT'" />
                                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.offerSymbol === 'KLAY'" />
                                        <p>{{item.offerPricePerOne | comma}} {{item.offerSymbol}} <span>(${{$store.getters.getTokenUsdPrice(item.offerSymbol, item.offerPricePerOne, 4)}})</span></p>
                                    </b-col>
                                    <b-col class="col" cols="1">{{item.remainAmount}}</b-col>
                                    <b-col class="col" cols="2">{{$store.getters.getLeftDate(item.tradeDate)}}</b-col>
                                    <b-col class="col" cols="1">{{ $t('collectionPage.offer_state_' + item.state) }}</b-col>
                                    <b-col class="col buttons" cols="2">
                                        <p v-if="item.state === 2 || item.state === 5" @click="showOfferCancelPopup(item)">{{ $t('collectionPage.cancel') }}</p>
                                        <p v-else @click="showOfferListDelete(item)">{{ $t('collectionPage.delete') }}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="selectedOfferSubTabNo === 2">
                        <div class="list-box transaction">
                            <div class="list-overflow">
                                <b-row class="list-head">
                                    <b-col class="col" cols="3">{{ $t('collectionPage.item') }}</b-col>
                                    <b-col class="col" cols="2">{{ $t('collectionPage.price') }}</b-col>
                                    <b-col class="col" cols="1">{{ $t('collectionPage.quantity') }}</b-col>
                                    <b-col class="col" cols="2">{{ $t('collectionPage.sellers') }}</b-col>
                                    <b-col class="col" cols="1">{{ $t('collectionPage.expiration') }}</b-col>
                                    <b-col class="col" cols="1">{{ $t('collectionPage.state') }}</b-col>
                                    <b-col class="col buttons" cols="2"></b-col>
                                </b-row>
                                <b-row v-for="(item,idx) in offerList" :key="idx">
                                    <b-col class="col" cols="3">
                                        <router-link tag="a" :to="'/NftInfo/' + item.productIdx" class="link-item">
                                            <img :src="$store.state.configFront.cdnUrl + 'resource/' + item.productFileFormat + '/' + item.productFileName" class="photo" v-if="$store.state.configFront !== null && item.productResourceType === 1" />
                                            <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/'+ item.productThumbnailFileName" class="photo" v-if="$store.state.configFront !== null && item.productResourceType === 2" />
                                            <p class="skip">{{item.productName}}</p>
                                        </router-link>
                                    </b-col>
                                    <b-col class="col price" cols="2">
                                        <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.offerSymbol === 'ATT'" />
                                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.offerSymbol === 'KLAY'" />
                                        <p>{{item.offerPricePerOne | comma}} {{item.offerSymbol}} <span>(${{$store.getters.getTokenUsdPrice(item.offerSymbol, item.offerPricePerOne, 4)}})</span></p>
                                    </b-col>
                                    <b-col class="col" cols="1">{{item.remainAmount}}</b-col>
                                    <b-col class="col" cols="2">
                                        <img src="@/assets/images/my-wallet.svg" alt="profile" class="photo" v-if="$store.state.configFront === null || item.memberFileName === null">
                                        <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.memberFileName" class="photo" v-else />

                                        <router-link tag="p" :to="'/Profile/' + item.memberName" class="skip" >{{item.memberName}}</router-link>
                                    </b-col>
                                    <b-col class="col" cols="1">{{$store.getters.getLeftDate(item.tradeDate)}}</b-col>
                                    <b-col class="col" cols="1">{{ $t('collectionPage.offer_state_' + item.state) }}</b-col>
                                    <b-col class="col buttons" cols="2" v-if="item.memberName !== $store.state.userName">
                                        <p v-if="item.state === 2" @click="showOfferAcceptPopup(item)">{{ $t('collectionPage.accept') }}</p>
                                        <p v-else @click="showOfferListDelete(item)">{{ $t('collectionPage.delete') }}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                    <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                </div>
                <div class="liked" v-else-if="selectedTabNo === 4">
                    <b-row>
                        <b-col class="col-cover" v-for="(item,idx) in likeList" :key="idx">
                            <Item :itemInfo="item" />
                        </b-col>
                    </b-row>
                </div>
                <div class="item-list-box" v-else-if="selectedTabNo === 5">
                    <div class="right">
                        <div class="bottom">
                            <b-row>
                                <b-col class="product" v-for="(item,idx) in hideList" :key="idx">
                                    <Item :itemInfo="item" :initItemDropDown="true" :initIsMine="true" @completeHideCancel="completeHideCancel"  @completeTransfer="completeTransfer"/>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
                <div class="loyalty history cont" v-else-if="selectedTabNo === 6">
                    <div class="text-box">
                        <p class="gray">{{ $t('loyalty.text1') }}</p>
                        <p class="title">{{ $t('loyalty.text2') }}</p>
                        <p class="sub-title">{{ $t('loyalty.text3') }}</p>
                        <!-- <p class="chart-title ">{{ $t('loyalty.text4') }}</p> -->
                    </div>
                    <div class="list-box loyalty transaction">
                        <b-row class="list-head">
                            <b-col class="col-item col">{{ $t('loyalty.list-title1') }}</b-col>
                            <b-col class="col-price col">{{ $t('loyalty.list-title2') }}</b-col>
                            <b-col class="col-quantity col">{{ $t('loyalty.list-title3') }}</b-col>
                            <b-col class="col-set col">{{ $t('loyalty.list-title4') }}</b-col>
                            <b-col class="col-date col">{{ $t('loyalty.list-title5') }}</b-col>
                            <b-col class="col-gain col">{{ $t('loyalty.list-title6') }}</b-col>
                        </b-row>
                        <b-row v-for="(item,idx) in loyaltyList" :key="idx">
                            <b-col class="col-item col">
                                <router-link tag="a" :to="'/NftInfo/' + item.itemIdx" class="link-item">
                                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + item.itemFileFormat + '/' + item.itemFileName" class="photo" v-if="$store.state.configFront !== null && item.itemResourceType === 1" />
                                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/'+ item.itemThumbnailFileName" class="photo" v-if="$store.state.configFront !== null && item.itemResourceType === 2" />

                                    <p class="skip">{{item.itemName}}</p>
                                </router-link>
                            </b-col>
                            <b-col class="col-price col-blank col" v-if="item.price === null">-</b-col>
                            <b-col class="col-price col" v-else>
                                <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.symbol === 'ATT'" />
                                <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.symbol === 'KLAY'" />
                                <p>{{item.price | comma}}</p>
                            </b-col>    
                            <b-col class="col-quantity col">{{item.amount | comma}}</b-col>
                            <b-col class="col-set col">{{item.royaltyPercent}}</b-col>
                            <b-col class="col-date col">{{$store.getters.getLeftDate(item.date)}}</b-col>
                            <b-col class="col-gain col">
                                <p>{{item.royalty | comma}} {{item.symbol}}</p>
                            </b-col>
                        </b-row>
                        <div class="no-loyalty" v-if="loyaltyList.length <= 0">
                            <img src="@/assets/images/icon/icon_no_loyalty.svg" />
                            <p>{{ $t('loyalty.no-loyalty') }}</p>
                        </div>
                        <div class="next-list" v-if="totalRecordSize > 0 && loyaltyList.length < totalRecordSize">
                            <a @click="nextList()">{{ $t('rankings.more') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <PopupReport :initIsShowPopup="initIsReport" :initTargetIdx="collectionIdx" :initReportType="initReportType" @close="close('Report')" /> -->
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupOfferCancel :initIsShowPopup="initIsOfferCancelPopup" :initProductDetail="initProductDetail" :initCancelOfferInfo="initSelectedOfferItem" @close="close('OfferCancel')" @error="error" @completeOfferCancel="completeOfferCancel" />
        <PopupOfferAccept :initIsShowPopup="initIsOfferAcceptPopup" :initProductDetail="initProductDetail" :initAcceptOfferInfo="initSelectedOfferItem" @close="close('OfferAccept')" @error="error" @completeAcceptOffer="completeAcceptOffer"/>
        <PopupOfferListDelete :initIsShowPopup="isShowPopupOfferListDelete" :initOfferListDeleteItem="initSelectedOfferItem" @close="close('OfferListDelete')" @error="error" @completeOfferListdelete="completeOfferListdelete"/>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import MyProfileFilter from '@/components/market/MyProfileFilter.vue';
import Item from '@/components/Item.vue';
// import PopupReport from '@/components/market/PopupReport.vue'
import PopupOK from '@/components/popup/PopupOK.vue';
import Pagination from '@/components/common/Pagination.vue';

import PopupOfferAccept from '@/components/market/PopupOfferAccept.vue'
import PopupOfferCancel from '@/components/market/PopupOfferCancel.vue'
import PopupOfferListDelete from '@/components/market/PopupOfferListDelete.vue'

import vClickOutside from 'v-click-outside'

export default {
    name: "MyProfile",
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        Header,
        MyProfileFilter,
        Item,
        // PopupReport,
        PopupOK,
        Pagination,
        PopupOfferAccept,
        PopupOfferCancel,
        PopupOfferListDelete,
    },
    data() {
        return {
            initHeaderType: 'type1',
            profileImgAccept: '.gif, .jpg, .png',
            
            initFollowFilter: false,
            tabName: '',
            selectedTabNo: 0,
            selectedOfferSubTabNo: 1,
            isShare: false,
            isShareMobile: false,
            
            nftInfoList: [],
            transactionList: [],
            likeList:[],
            hideList: [],
            loyaltyList: [],
            offerList: [],

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 500,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            //페이징
            listCount: 0,
            pageCount: 0,
            pageNo: 0,
            pageSize: 0,
            totalRecordSize: 0,

            pageList:[],

            //팝업
            initIsReport: false,

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            //정렬
            selectedOrder: 1,
            orderOptions:[
                { value: 1, text:'최신 순',},
                { value: 2, text:'인기 순',},
                { value: 3, text:'조회 순',},
                { value: 4, text:'오래된 순',},
            ],
            
            //필터
            initFlagSetCollection: false,
            initCollectionType: 0,  // 0 내가 소유한 컬렉션, 1 내가 제안한 컬렉션, 2 내가 즐겨찾기한 컬렉션
            initPreloadCollectionInfo: null,

            flagResetFilter: false,
            initRemoveFilter: null,
            initIsTransaction: false,
            initShowStatus: true,
            initShowPrice: true,

            searchText: '',
            searchedText: '',
            selectedStatus: null,
            selectedPrice: null,
            minPrice: null,
            maxPrice: null,
            selectedSalesCoin: null,
            selectedCollectionIdx: null, 
            selectedCollectionName: null,

            //제안
            initProductDetail: null,
            initSelectedOfferItem: null,

            initIsOfferCancelPopup: false,
            initIsOfferAcceptPopup: false,
            isShowPopupOfferListDelete: false,
        }
    },
    async created() {
        if(this.$store.state.id === "" || this.$store.state.ticket === ""){
            this.$router.push('/');
        }
        if(this.$route.params.tabName){
            this.tabName = this.$route.params.tabName;
        } else {
            this.tabName = 'MyItem';
        }
    },
    async mounted() {
        if(this.$route.params.collectionIdx){
            var isExistCollection = await this.checkExistCollection(this.$route.params.collectionIdx);
            if(!isExistCollection){
                this.initPopupOkMessage = "잘못된 접근 입니다."
                this.PopupOkCallbakPage = '/';
                this.isShowPopupOk = true;
                return;
            } else {
                this.setCollectionType();
                // this.resetList(true);
            }
        } else {
            this.setCollectionType();
            // this.resetList();
        }
    },
    beforeDestroy() {
    },
    methods: {
        toggleShare(){
            this.isShare = !this.isShare;
        },
        closeShare(){
            this.isShare = false;
        },
        toggleShareMobile(){
            this.isShareMobile = !this.isShareMobile;
        },
        closeShareMobile(){
            this.isShareMobile = false;
        },
        snsShare(shareType){
            this.isShare = false;
            this.isShareMobile = false;

            var targetURL = window.location.protocol + "//" + window.location.host + "/Profile/" + this.$store.state.userName;

            if(shareType === 'twitter'){
                window.open("https://twitter.com/intent/tweet?url=" + targetURL);
                //window.open("https://twitter.com/intent/tweet?text=" + sendText + "&url=" + sendUrl);
            } else if (shareType === 'telegram'){
                window.open("https://telegram.me/share/url?url=" + targetURL);
                //window.open("https://telegram.me/share/url?url=" + url + "&text=" + text);
            } else if (shareType === 'facebook'){
                window.open("http://www.facebook.com/sharer/sharer.php?u=" + targetURL);
            } else if (shareType === 'copy'){
                this.$copyText(targetURL);
                this.initPopupOkMessage = "링크가 복사되었습니다."
                this.isShowPopupOk = true;
            }
        },
        resetList(){
            this.currentPageNo = 1;
            this.listCount =  0;
            this.pageCount =  0;
            this.pageNo =  0;
            this.pageSize =  0;
            this.totalRecordSize =  0;
            this.pageList = [];

            if(this.tabName === 'MyItem'){
                this.nftInfoList = [];
                this.selectedTabNo = 1;
                this.getNftInfoListMine();
            } else if(this.tabName === 'TransactionHistory'){
                this.transactionList = [];
                this.selectedTabNo = 2;
                this.initIsTransaction = true;
                this.getTransactionList();
            } else if(this.tabName === 'Offers'){
                this.offerList = [];
                this.selectedTabNo = 3;
                this.initShowStatus = false;
                this.initShowPrice = false;
                this.selectedOfferSubTabNo === 1 ? this.getOfferParticipationList() : this.getOfferList();
            } else if(this.tabName === 'Liked'){
                this.likeList = [];
                this.selectedTabNo = 4;
                this.initShowStatus = false;
                this.initShowPrice = false;
                this.getNftLikeList();
            } else if(this.tabName === 'HiddenItem'){
                this.hideList = [];
                this.selectedTabNo = 5;
                this.initShowStatus = false;
                this.initShowPrice = false;
                this.getNftHiddenList();
            } else if(this.tabName === 'Loyalty'){
                this.loyaltyList = [];
                this.selectedTabNo = 6;
                this.initShowStatus = false;
                this.initShowPrice = false;
                this.getNftLoyaltyList();
            }
        },
        setCollectionType(isReset = false){
            this.initCollectionType = 0;
            if(this.tabName === 'Offers' && this.selectedOfferSubTabNo === 1){
                this.initCollectionType = 1;
            } else if(this.tabName === 'Liked'){
                this.initCollectionType = 2;
            }

            if(!isReset) this.initFlagSetCollection = true;
            else this.resetFilter();
        },
        nextList(){
            this.currentPageNo++;

            switch(this.selectedTabNo){
                case 1:
                    this.getNftInfoListMine();
                    break;
                case 2:
                    this.getTransactionList();
                    break;
                case 3:
                    this.selectedOfferSubTabNo === 1 ? this.getOfferParticipationList() : this.getOfferList();
                    break;
                case 4:
                    this.getNftLikeList();
                    break;
                case 5:
                    this.getNftHiddenList();
                    break;
                case 6:
                    this.getNftLoyaltyList();
                    break;
            }
        },
        changeTabNo(no){
            this.selectedTabNo = no;
        },
        changeOfferSubTabNo(no){
            if(this.selectedOfferSubTabNo === no){
                return;
            }
            this.selectedOfferSubTabNo = no;
            this.currentPageNo = 1;
            this.offerList = [];
            this.selectedOfferSubTabNo === 1 ? this.initCollectionType = 1 : this.initCollectionType = 0;
            // this.initFlagSetCollection = true;
            this.setCollectionType(true);

        },
        changeBannerImg(){
            this.$refs.bannerFiles.click();
        },
        changeProfileImg(){
            this.$refs.profileFiles.click();
        },
        clipboardSuccessHandler ({ value, event }) {
            value; event;
            this.initPopupOkMessage = "지갑 주소를 복사했습니다."
            this.isShowPopupOk = true;
        },
        getSelectedOrderText(){
            var item = this.orderOptions.find((item) => item.value === this.selectedOrder);
            return item.text;
        },
        changeOrderType(value){
            if(this.selectedOrder === value) return;
            this.selectedOrder = value;
            this.resetList();
        },
        async handleBannerFileUpload(){
            let userUploadFile = this.$refs.bannerFiles.files;
            let file = userUploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            await this.$Axios.post(`${this.host}/user/upload/banner/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--user upload banner--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '배너 사진이 변경 되었습니다';
                    this.isShowPopupOk = true;
                    this.$store.commit("setUserInfo", this.host);
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
                return false;
            });
        },
        async handleProfileFileUpload(){
            let userUploadFile = this.$refs.profileFiles.files;
            let file = userUploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            await this.$Axios.post(`${this.host}/user/upload/profile/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--user upload profile--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '프로필 사진이 변경 되었습니다';
                    this.isShowPopupOk = true;
                    this.$store.commit("setUserInfo", this.host);
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
                return false;
            });
        },
        async checkExistCollection(collectionIdx){
            var param = 
            {
                collectionIdx: collectionIdx
            }
            // console.log(param);
            return await this.$Axios.post(`${this.host}/nft/collection/info`, param, this.headers).then(async (res) => {
                // console.log('------ Collection Info --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.initPreloadCollectionInfo = res.data.data;
                    return true;
                } else {
                    this.error(res.data.returnCode);
                    return false;
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftInfoListMine(){
            // NFT 리스트 검색 요청 파라미터
            //order: // 1: latest // 2: popularity // 3: close to finish // 4: low price // 5: high price // 6: small bids // 7: a lot of bids // 8: most seen // 9: oldest
            // category: // 1: art // 2: music // 3: virtual world // 4: trading cards // 5: collectibles // 6: sports // 7: utility // 8: etc 
            // contractType: // 1: erc721 // 2: erc1155   <-- 이거만 사용예정 
            // itemType: // 1: 조각 아이템 // 2: 단일 아이템
            // resourceType: // 1: JPG // 2: PNG // 3: GIF // 4: SVG // 11: MP4 // 12: MP3 // 13: WEBM // 14: WAV // 15: OGG
            // state // 1: 숨김 // 2: 판매종료(expired) // 3: 판매완료(sold out)  // 4: 판매중

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                searchText: this.searchedText,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
                orderType: this.selectedOrder,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/info/allList`, param, this.headers).then(async (res) => {
                // console.log('------ my item list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.nftInfoList = this.nftInfoList.concat(res.data.list);
                    this.listCount       = res.data.page.listCount;
                    this.pageCount       = res.data.page.pageCount;
                    this.pageNo          = res.data.page.pageNo;
                    this.pageSize        = res.data.page.pageSize;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getTransactionList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: 20,
                searchText: this.searchedText,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/nft/info/transaction/mine`, param, this.headers).then(async (res) => {
                // console.log(res.data.list);
                if(res.data.returnCode === 0){
                    this.transactionList = this.transactionList.concat(res.data.list);
                    this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getOfferList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: 10,
                searchText: this.searchedText,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/nft/offer/member/list`, param, this.headers).then(async (res) => {
                // console.log('------ offer list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.offerList = res.data.list;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                    this.initPagingData = res.data.page;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getOfferParticipationList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: 10,
                searchText: this.searchedText,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/nft/offer/member/participation/list`, param, this.headers).then(async (res) => {
                // console.log('------ offer participation list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.offerList = res.data.list;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                    this.initPagingData = res.data.page;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftLikeList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/nft/like/list`, param, this.headers).then(async (res) => {
                // console.log('------ like list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.likeList = this.likeList.concat(res.data.list);
                    this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftHiddenList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                searchText: this.searchedText,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
                orderType: this.selectedOrder,
            }
            this.$Axios.post(`${this.host}/nft/hide/list`, param, this.headers).then(async (res) => {
                // console.log('------ hide list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.hideList = this.likeList.concat(res.data.list);
                    this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftLoyaltyList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: 20,
                searchText: this.searchedText,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
            }
            this.$Axios.post(`${this.host}/nft/info/user/royalty`, param, this.headers).then(async (res) => {
                // console.log('------ loyalty list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.loyaltyList = this.loyaltyList.concat(res.data.list);
                    this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        async getNftDetail(productId){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: productId
            }
            // console.log(param)
            return await this.$Axios.post(`${this.host}/nft/detail`, param, this.headers).then((res) => {
                // console.log('------- get item detail --------')
                // console.log(res.data.data)
                if(res.data.returnCode === 0){
                    return res.data.data;
                } else {
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return null;
            });
        },
        async showOfferListDelete(item){
            this.initProductDetail = await this.getNftDetail(item.productIdx);
            this.initSelectedOfferItem = item;
            this.isShowPopupOfferListDelete = true;
        },
        async showOfferCancelPopup(item){
            this.initProductDetail = await this.getNftDetail(item.productIdx);
            this.initSelectedOfferItem = item;
            this.initIsOfferCancelPopup = true;
        },
        async showOfferAcceptPopup(item){
            this.initProductDetail = await this.getNftDetail(item.productIdx);
            if(this.initProductDetail.saleType === 2){
                this.initPopupOkMessage = '경매 판매 중에는 제안을 수락할 수 없습니다.';
                this.isShowPopupOk = true;
                return;
            }
            else if(this.initProductDetail.productInfo.itemCountMine  - this.initProductDetail.productInfo.itemCountOnSaleMine - this.initProductDetail.productInfo.itemCountReservedMine <= 0){
                this.initPopupOkMessage = '수락을 할려면 판매 등록을 취소 후 시도하세요';
                this.isShowPopupOk = true;
                return;
            }
            this.initSelectedOfferItem = item;
            this.initIsOfferAcceptPopup = true;
        },

        completeOfferListdelete(){
            this.isShowPopupOfferListDelete = false;
            this.initPopupOkMessage = "선택하신 내역이 삭제됐습니다."
            this.isShowPopupOk = true;
            this.selectedOfferSubTabNo === 1 ? this.getOfferParticipationList() : this.getOfferList();
        },
        completeOfferCancel(){
            this.initIsOfferCancelPopup = false;
            this.initPopupOkMessage = '제안 취소가 완료되었습니다.';
            this.isShowPopupOk = true;
            this.selectedOfferSubTabNo === 1 ? this.getOfferParticipationList() : this.getOfferList();
        },
        completeAcceptOffer(){
            this.initIsOfferAcceptPopup = false;
            this.initPopupOkMessage = '제안 수락이 완료되었습니다.';
            this.isShowPopupOk = true;
            this.selectedOfferSubTabNo === 1 ? this.getOfferParticipationList() : this.getOfferList();
        },
        completeHide(){
            this.initPopupOkMessage = '숨김 처리가 완료되었습니다.';
            this.isShowPopupOk = true;
            this.resetList();
        },
        completeHideCancel(){
            this.initPopupOkMessage = '숨김 해제 처리가 완료되었습니다.';
            this.isShowPopupOk = true;
            this.resetList();
        },
        completeTransfer(){
            this.initPopupOkMessage = '양도 요청이 완료되었습니다.';
            this.isShowPopupOk = true;
            this.resetList();
        },
        searchItem(){
            if(this.searchedText === this.searchText) return;
            this.searchedText = this.searchText;
            this.resetList();
        },
        changeFilterOptions(option){
            var that = this;
            switch(option.type){
                case 'setCollection':
                    setTimeout(()=>{that.initFlagSetCollection = false}, 100);
                    break;
                case 'status':
                    this.selectedStatus = option.value;
                    break;
                case 'price':
                    this.selectedPrice = option.priceType;
                    this.minPrice = option.minPrice;
                    this.maxPrice = option.maxPrice;
                    break;
                case 'collection':
                    this.selectedCollectionIdx = option.collectionIdx;
                    this.selectedCollectionName = option.collectionName;
                    break;
                case 'coin':
                    this.selectedSalesCoin = option.value;
                    break;
                case 'reset':
                    this.flagResetFilter = false;
                    break;
            }
            if(option.isRemove){
                this.initRemoveFilter = null;
            }
            this.resetList();
        },
        removeFilter(type){
            this.initRemoveFilter = type;
        },
        removeSearchText(){
            this.searchedText = '';
            this.resetList();
        },
        resetFilter(){
            this.searchedText = '';
            this.selectedStatus = null;
            this.selectedPrice = null;
            this.minPrice = null;
            this.maxPrice = null;
            this.selectedSalesCoin = null;
            this.selectedCollectionIdx = null;
            this.selectedCollectionName = null;

            this.initPreloadCollectionInfo = null;
            
            this.flagResetFilter = true;
        },
        getIsShowFilterList(){
            if(this.searchedText !== '' || this.selectedStatus !== null || this.selectedPrice !== null || this.selectedCollectionIdx !== null || this.selectedSalesCoin !== null){
                return true;
            } else {
                return false;
            }
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.selectedOfferSubTabNo === 1 ? this.getOfferParticipationList() : this.getOfferList();
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'Report':
                    this.initIsReport = false;
                    break;
                case 'OfferCancel':
                    this.initIsOfferCancelPopup = false;
                    break;
                case 'OfferAccept':
                    this.initIsOfferAcceptPopup = false;
                    break;
                case 'OfferListDelete':
                    this.isShowPopupOfferListDelete = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
