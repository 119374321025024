<template>
    <div class="new-collection">
        <Header :initHeaderType="initHeaderType" />
        <b-container>
            <div class="create-box">
                <p class="title">{{ $t('newCollection.title2') }}</p>
                <div class="logo-box">
                    <p class="mini-title star">{{ $t('newCollection.mini-title1') }}</p>
                    <p class="cont">{{ $t('newCollection.logo-cont') }}</p>
                    <div class="photo-outer">
                        <div class="photo-inner">
                            <label class="choose-file" for="idUploadLogo" @click="resetUploadFile('logo')">
                                <img src="@/assets/images/icon/photo.svg" v-if="uploadLogo === null" />
                                <img :src="uploadLogo.path" class="user-pick" v-else />
                                <p v-if="uploadLogo === null" v-html="$t('newCollection.logo-img')"></p>
                                <input class="display-none" id="idUploadLogo" name="fileLogo" ref="fileLogo" @change="handleFileUpload('logo')" :accept="accept" type="file">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="banner-box">
                    <p class="mini-title">{{ $t('newCollection.mini-title3') }}</p>
                    <p class="cont">{{ $t('newCollection.banner-cont1') }}</p>
                    <p class="cont">{{ $t('newCollection.banner-cont2') }}</p>
                    <p class="gray">{{ $t('newCollection.banner-cont3') }}</p>
                    <!-- 피쳐드 이미지 -->
                    <div class="photo-outer featured">
                        <div class="photo-inner">
                            <label class="choose-file" for="idUploadFeatured" @click="resetUploadFile('featured')">
                                <img src="@/assets/images/icon/photo.svg" v-if="uploadFeatured === null" />
                                <img :src="uploadFeatured.path" class="user-pick" v-else />
                                <p v-if="uploadFeatured === null" v-html="$t('newCollection.featured-img')"></p>
                                <input class="display-none" id="idUploadFeatured" name="fileFeatured" ref="fileFeatured" @change="handleFileUpload('featured')" :accept="accept" type="file">
                            </label>
                        </div>
                    </div>
                    <!-- 컬렉션 배너 -->
                    <div class="photo-outer">
                        <div class="photo-inner">
                            <label class="choose-file" for="idUploadBanner" @click="resetUploadFile('banner')">
                                <img src="@/assets/images/icon/photo.svg" v-if="uploadBanner === null" />
                                <img :src="uploadBanner.path" class="user-pick" v-else />
                                <p v-if="uploadBanner === null" v-html="$t('newCollection.col-banner')"></p>
                                <input class="display-none" id="idUploadBanner" name="fileBanner" ref="fileBanner" @change="handleFileUpload('banner')" :accept="accept" type="file">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="name-box">
                    <p class="mini-title star">{{ $t('newCollection.mini-title2') }}</p>
                    <b-input placeholder="예) 분위기 있는 야경 사진 모음" v-model="collectionName"></b-input>
                    <!-- <div class="warn">
                        <p>{{ $t('newCollection.overlap') }}</p>
                    </div> -->
                </div>
                <div class="description-box">
                    <p class="mini-title">{{ $t('newCollection.mini-title4') }}</p>
                    <b-textarea placeholder="예시: 별자리를 중심으로 밤하늘을 촬영하고 도시의 야경을 촬영했습니다." v-model="collectionDesc"></b-textarea>
                </div>
                <!-- <div class="category-box">
                    <p class="mini-title">{{ $t('newCollection.mini-title5') }}</p>
                    <p class="cont">{{ $t('newCollection.category-cont') }}</p>
                    <div class="filter-box">
                        <button class="art on">{{ $t('newCollection.category-filter1') }}</button>
                        <button class="music">{{ $t('newCollection.category-filter2') }}</button>
                        <button class="virtual-world">{{ $t('newCollection.category-filter3') }}</button>
                        <button class="trading-cards">{{ $t('newCollection.category-filter4') }}</button>
                        <button class="collectibles on">{{ $t('newCollection.category-filter5') }}</button>
                        <div>
                            <button class="sports">{{ $t('newCollection.category-filter6') }}</button>
                            <button class="utility on">{{ $t('newCollection.category-filter7') }}</button>
                            <button class="etc">{{ $t('newCollection.category-filter8') }}</button>
                        </div>
                    </div>
                </div> -->
                <div class="loyalty-box">
                    <p class="mini-title">{{ $t('newCollection.mini-title6') }}</p>
                    <p class="cont" v-html="$t('newCollection.loyalty-cont')"></p>
                    <div class="percent">
                        <input type="text" class="form-control" v-model="collectionRoyalty" @input="inputLoyalty" />
                        <!-- <b-input >{{ $t('newCollection.loyalty-cont') }}</b-input> -->
                    </div>
                </div>
            </div>
            <div class="end-buttons">
                <button class="collection-button on" @click="reqRegisterCollection">{{ $t('newCollection.end-button1') }}</button>
                <!-- <div>
                    <button class="collection-button">{{ $t('newCollection.end-button2') }}</button>
                    <button class="collection-button save on">{{ $t('newCollection.end-button3') }}</button>
                </div> -->
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "CollectionRegist",
    krName: '컬렉션 생성',
    components: {
        Header,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type2',
            uploadLogo: null,
            uploadFeatured: null,
            uploadBanner: null,
            collectionName: '',
            collectionDesc: '',
            collectionRoyalty: '0',

            accept: '.gif, .jpg, .png',

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        resetUploadFile(type){
            if(type === 'logo'){
                this.uploadLogo = null;
            } else if(type === 'featured'){
                this.uploadFeatured = null;
            } else if(type === 'banner'){
                this.uploadBanner = null;
            }
        },
        handleFileUpload(type){
            // console.log(e);
            // let files = e.target.files || e.dataTransfer.files;
            // console.log(files);
            var fileMaxSize = 10 * 1024 * 1024;
            let uploadFile;
            if(type === 'logo'){
                uploadFile = this.$refs.fileLogo.files;
            } else if(type === 'featured'){
                uploadFile = this.$refs.fileFeatured.files;
            } else if(type === 'banner'){
                uploadFile = this.$refs.fileBanner.files;
            }
             
            if(uploadFile.length <= 0){
                return;
            }
            let file = uploadFile[0];
            if(this.checkImageFileExt(file.name)){
                this.initPopupOkMessage = '지원하지 않는 파일 형식입니다.';
                this.isShowPopupOk = true;
                return;
            }
            if(file.size > fileMaxSize){
                // alert("파일사이즈 : "+ file.size +", 최대파일사이즈 : 1MB");
                this.initPopupOkMessage = '파일 크기는 최대 10MB까지만 가능합니다.';
                this.isShowPopupOk = true;
                return;
            }

            this.showImage(file, type);

            // this.reqUploadResource(file, 208, 140);  //이미지 가로/세로 사이즈 체크 필요 시
            // if (document.getElementById(this.idUploadFile)) {
            //     document.getElementById(this.idUploadFile).value = '';
            // }
        },
        showImage (file, type){ //,limit_width, limit_height) {
            var that = this;
            let reader = new FileReader();
            reader.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    if(type === 'logo'){
                        that.uploadLogo = {name: file.name, path: image.src};
                    } else if(type === 'featured'){
                        that.uploadFeatured = {name: file.name, path: image.src};
                    } else if(type === 'banner'){
                        that.uploadBanner = {name: file.name, path: image.src};
                    }
                    
                    // var height = this.height;
                    // var width = this.width;
                    // if (height !== limit_height || width !== limit_width) {
                    //     that.initPopupOkMessage = '썸네일 이미지 크기는 208*140px 로 제한됩니다.'
                    //     that.isShowPopupOk = true;
                    // } else {
                    //  that.thumbnail = {name: file.name, path: image.src};
                    // }
                };
            }
            reader.readAsDataURL(file);
        },
        async reqUploadLogo(){
            let uploadFile = this.$refs.fileLogo.files;
            let file = uploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            return await this.$Axios.post(`${this.host}/nft/collection/upload/logo/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--collection upload logo--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data;
                } else {
                    this.error(res.data.returnCode);
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return null;
            });
        },
        async reqUploadFeatured(){
            let uploadFile = this.$refs.fileFeatured.files;
            let file = uploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            return await this.$Axios.post(`${this.host}/nft/collection/upload/featured/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--collection upload featured--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data;
                } else {
                    this.error(res.data.returnCode);
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return null;
            });
        },
        async reqUploadBanner(){
            let uploadFile = this.$refs.fileBanner.files;
            let file = uploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            return await this.$Axios.post(`${this.host}/nft/collection/upload/banner/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--collection upload banner--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data;
                } else {
                    this.error(res.data.returnCode);
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return null;
            });
        },
        async reqRegisterCollection(){
            
            var uploadLogoInfo = null;
            var uploadFeaturedInfo = null;
            var uploadBannerInfo = null;
            if(this.uploadLogo === null){
                this.initPopupOkMessage = '로고 이미지를 선택해 주세요';
                this.isShowPopupOk = true;
                return;
            }

            if(this.collectionName.length <= 0){
                this.initPopupOkMessage = '컬렉션 이름을 입력해 주세요.';
                this.isShowPopupOk = true;
                return;
            } else if(this.collectionName.length > 100){
                this.initPopupOkMessage = '컬렉션 이름은 최대 100자까지만 등록할 수 있습니다.';
                this.isShowPopupOk = true;
                return;
            }

            if(this.collectionDesc.length > 0 && this.collectionDesc.length > 1000){
                this.initPopupOkMessage = '컬렉션 설명은 최대 1000자까지만 등록할 수 있습니다.';
                this.isShowPopupOk = true;
                return;
            }

            if(this.uploadLogo !== null){
                uploadLogoInfo = await this.reqUploadLogo();
                if(uploadLogoInfo === null) return;
            }
            if(this.uploadFeatured !== null){
                uploadFeaturedInfo = await this.reqUploadFeatured();
                if(uploadFeaturedInfo === null) return;
            } 
            if(this.uploadBanner !== null){
                uploadBannerInfo = await this.reqUploadBanner();
                if(uploadBannerInfo === null) return;
            }

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                collectionName: this.collectionName,
                collectionDesc: this.collectionDesc,
                royalty: this.collectionRoyalty,
                logoFileName: uploadLogoInfo.fileName,
                featuredFileName: this.uploadFeatured !== null ? uploadFeaturedInfo.fileName : null,
                bannerFileName:  this.uploadBanner !== null ? uploadBannerInfo.fileName : null,
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/nft/collection/register`, param, this.headers).then(async(res) => {
                // console.log('--register collection--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '컬렉션 등록 성공';
                    this.PopupOkCallbakPage = "/MyCollection";
                    this.isShowPopupOk = true;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        inputLoyalty() {
            var x = this.collectionRoyalty.toString();
            this.collectionRoyalty = this.numberOnlyWithMax(x, 10, true);
            
            // this.collectionRoyalty = Number(x);
            // this.amount = this.setComma(x);
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'Report':
                    this.initIsReport = false;
                    break;
            }
        },
        error(returnCode){
            this.initPopupTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
