<template>
    <div class="header" :class="initHeaderType">
        <b-container>
            <div class="top" v-if="!searchTyping">
                <router-link tag="a" to="/" class="logo">Artube</router-link>
                <div class="right">
                    <div class="flex">
                        <div class="dropdown">{{ $t('header.dropdown-kor') }}</div>
                        <div class="profile-mypage" @mouseleave="closeMyPage">
                            <router-link tag="a" to="/MyProfile" href="/MyProfile" @mouseover.native="openMyPage" v-if="$store.state.isEnableKaikas && $store.state.erc20Address !== ''">
                                <img src="@/assets/images/profile-menu/no-profile.png" v-if="$store.state.profile === '' || $store.state.profile === null" />
                                <img :src="$store.state.profile" v-else/>
                            </router-link>
                            <a @mouseover="openMyPage" @click="connectKaikas" v-else>
                                <img src="@/assets/images/profile-menu/no-profile.png" v-if="$store.state.profile === '' || $store.state.profile === null" />
                                <img :src="$store.state.profile" v-else/>
                            </a>
                            <ul class="sub-menu" v-if="isShowMyPage" >
                                <router-link tag="li" to="/MyProfile" v-if="$store.state.isEnableKaikas && $store.state.erc20Address !== ''" class="my-profile">
                                    <img src="@/assets/images/profile-menu/profile.png" />
                                    <p>{{ $t('header.subMenu1') }}</p>
                                </router-link>
                                <li @click="connectKaikas" v-else class="my-profile">
                                    <img src="@/assets/images/profile-menu/profile.png" />
                                    <p>{{ $t('header.subMenu1') }}</p>
                                </li>
                                <router-link tag="li" to="/MyProfile/Liked" v-if="$store.state.isEnableKaikas && $store.state.erc20Address !== ''" class="like-list">
                                    <img src="@/assets/images/profile-menu/heart.svg" />
                                    <p>{{ $t('header.subMenu2') }}</p>
                                </router-link>
                                <li @click="connectKaikas" v-else class="like-list">
                                    <img src="@/assets/images/profile-menu/heart.svg" />
                                    <p>{{ $t('header.subMenu2') }}</p>
                                </li>
                                <li @click="moveCreateItem()" v-if="$store.state.isEnableKaikas && $store.state.erc20Address !== ''" class="nft-publish">
                                    <img src="@/assets/images/profile-menu/publish.svg" />
                                    <p>{{ $t('header.subMenu3') }}</p>
                                </li>
                                <li @click="connectKaikas" v-else class="nft-publish">
                                    <img src="@/assets/images/profile-menu/publish.svg" />
                                    <p>{{ $t('header.subMenu3') }}</p>
                                </li>
                                <router-link tag="li" to="/MyCollection" v-if="$store.state.isEnableKaikas && $store.state.erc20Address !== ''" class="collection-icon">
                                    <img src="@/assets/images/profile-menu/collection.svg" />
                                    <p>{{ $t('footer.account2') }}</p>
                                </router-link>
                                <li @click="connectKaikas" class="collection-icon" v-else>
                                    <img src="@/assets/images/profile-menu/collection.svg" />
                                    <p>{{ $t('footer.account2') }}</p>
                                </li>
                                <router-link tag="li" to="/AccountSetting" v-if="$store.state.isEnableKaikas && $store.state.erc20Address !== ''" class="setting-icon">
                                    <img src="@/assets/images/profile-menu/setting.svg" />
                                    <p>{{ $t('footer.account4') }}</p>
                                </router-link>
                                <li @click="connectKaikas" class="setting-icon" v-else>
                                    <img src="@/assets/images/profile-menu/setting.svg" class="setting-icon" />
                                    <p>{{ $t('footer.account4') }}</p>
                                </li>
                                <li @click="logout()" class="logout" v-if="$store.state.isEnableKaikas && $store.state.erc20Address !== ''">
                                    <img src="@/assets/images/profile-menu/logout.svg" />
                                    <p>{{ $t('myWallet.logout') }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="connect-cover">
                            <a class="connect" @click="toggleWalletInfo" >
                                <img src="@/assets/images/icon/wallet.svg" />
                            </a>
                            <!-- <p class="connect" @click="toggleWalletInfo" v-else>{{ $store.state.walletAddress.toString().substr(0, 6) }}...{{ $store.state.walletAddress.toString().substr(-4) }}</p> -->
                            <MyWallet :initIsShowPopup="isShowWalletInfo" @connectKaikas="connectKaikas" @getTokenInfo="getTokenInfo" v-click-outside="closeWalletInfo" />
                        </div>
                    </div>
                    <!-- <div class="search-sit"></div> -->
                </div>
                <img src="@/assets/images/icon/search-black.svg" class="m-search-button" @click="searchTyping = !searchTyping" />
                <b-button v-b-toggle.sidebar-right class="m-menu-button"></b-button>
                <b-sidebar id="sidebar-right" class="slidebar-menu" right :class="{dark : changeAccount}">
                    <div class="profile-img">
                        <div v-if="$store.state.erc20Address === ''">
                            <img src="@/assets/images/profile-menu/no-profile.png" alt="profile" >
                            <p>{{ $t('header.m-wallet') }}</p>
                        </div>
                        <div class="change-account" :class="{on : changeAccount === true}" v-else>
                            <div @click="changeAccount = !changeAccount">
                                <img src="@/assets/images/profile-menu/no-profile.png" v-if="$store.state.profile === null" alt="profile" class="profile-photo">
                                <img class="profile-photo" :src="$store.state.profile" v-else />
                                <p>{{ $t('header.m-wallet') }}</p>
                                <img class="arrow" src="@/assets/images/icon/icon_down_arrow.svg" />
                            </div>
                            <!-- <div v-show="changeAccount">
                                <img src="@/assets/images/fox.png" />
                                <p>{{ $t('header.metamask') }}</p>
                            </div> -->
                            <div v-show="changeAccount">
                                <img src="@/assets/images/icon_klaytn.svg" />
                                <p>{{ $t('header.kaikas') }}</p>
                            </div>
                            <div v-show="changeAccount" @click="logout()">
                                <img src="@/assets/images/icon/logout.svg" />
                                <p>{{ $t('myWallet.logout') }}</p>
                            </div>
                        </div>
                        <div class="fox" v-if="$store.state.erc20Address !== ''">
                            <img src="@/assets/images/icon_klaytn.svg" />
                            <p>{{$store.state.walletAddress}}</p>
                            <img src="@/assets/images/icon/copy.svg" class="btn-copy" v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler"/>
                        </div>
                    </div>
                    <div class="wallet">
                        <div  @click="connectKaikas" v-if="$store.state.erc20Address === ''">
                            <img src="@/assets/images/icon_klaytn.svg" />
                            <p>{{ $t('header.kaikas') }}</p>
                        </div>
                        <div class="flex" v-for="(item, index) in $store.state.balanceList" :key="index" v-else>
                            <div class="left">
                                <img :src="item.iconImg" />
                                <p>{{item.name}}</p>
                            </div>
                            <div class="right">
                                <p>{{item.balance | comma}}</p>
                                <!-- <p class="usd">${{item.usd | comma}} USD</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-1 variant="info">{{ $t('header.menu1') }}</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <router-link tag="li" to="/Marketplace">
                                        <p>{{ $t('header.allNft') }}</p>
                                    </router-link>
                                    <router-link tag="li" :to="'/Marketplace/' + item.name" v-for="(item, index) in $store.state.categoryList" :key="index">
                                        <p>{{ item.name }}</p>
                                    </router-link>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-2 variant="info">{{ $t('header.menu2') }}</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <router-link tag="li" to="/Ranking" class="first">{{ $t('footer.explore1') }}</router-link>
                                    <router-link tag="li" to="/Transactional" class="last">{{ $t('footer.explore2') }}</router-link>
                                    <!-- <li @click="moveRanking()">{{ $t('footer.explore1') }}</li> -->
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-3 variant="info">{{ $t('header.menu3') }}</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <router-link tag="li" to="/Notice" class="first">{{ $t('footer.support1') }}</router-link>
                                    <router-link tag="li" to="/FAQ">{{ $t('footer.support2') }}</router-link>
                                    <router-link tag="li" to="/Inquiry" class="last" v-if="$store.state.erc20Address !== ''">{{ $t('footer.support3') }}</router-link>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1" v-if="$store.state.erc20Address !== ''">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-4 variant="info">{{ $t('header.menu4') }}</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <router-link tag="li" to="/MyProfile" class="first">{{ $t('header.subMenu1') }}</router-link>
                                    <router-link tag="li" to="/MyCollection">{{ $t('footer.account2') }}</router-link>
                                    <li @click="moveCreateItem()">{{ $t('header.subMenu3') }}</li>
                                    <router-link tag="li" to="/MyProfile/Liked">{{ $t('footer.account3') }}</router-link>
                                    <router-link tag="li" to="/AccountSetting" class="last">{{ $t('footer.account4') }}</router-link>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block variant="info" class="no-arrow"><a href="https://www.artube.video/" target="_blank">{{ $t('header.webtoon') }}</a></b-button>
                            </b-card-header>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block variant="info" class="no-arrow"><a href="https://governance.artube.video/" target="_blank">{{ $t('header.governance') }}</a></b-button>
                            </b-card-header>
                        </b-card>
                    </div>
                    <!-- <div class="dropdown">{{ $t('header.dropdown-eng') }}</div> -->
                </b-sidebar>
            </div>
            <div class="bottom">
                <div class="menu">
                    <div class="link menu1">
                        <router-link tag="a" to="/Marketplace">{{ $t('header.menu1') }}</router-link>
                        <ul class="sub-menu">
                            <router-link tag="li" to="/Marketplace">
                                <img src="@/assets/images/icon/all_category.png"/>
                                <p>{{ $t('header.allNft') }}</p>
                            </router-link>
                            <router-link tag="li" :to="'/Marketplace/' + item.name" v-for="(item, index) in $store.state.categoryList" :key="index">
                                <img :src="$store.state.configFront.cdnUrl + 'category/' + item.fileName" v-if="$store.state.configFront !== null && item.fileName !== null && item.fileName !== ''"/>
                                <p>{{ item.name }}</p>
                            </router-link>
                        </ul>
                    </div>
                    <div class="link menu2">
                        <router-link tag="a" to="/Ranking">{{ $t('header.menu2') }}</router-link>
                        <ul class="sub-menu">
                            <router-link tag="li" to="/Ranking" class="first">{{ $t('footer.explore1') }}</router-link>
                            <router-link tag="li" to="/Transactional" class="last">{{ $t('footer.explore2') }}</router-link>
                            <!-- <li @click="moveRanking()">{{ $t('footer.explore1') }}</li> -->
                        </ul>
                    </div>
                    <div class="link menu3">
                        <router-link tag="a" to="/Notice">{{ $t('header.menu3') }}</router-link>
                        <ul class="sub-menu">
                            <router-link tag="li" to="/Notice" class="first">{{ $t('footer.support1') }}</router-link>
                            <router-link tag="li" to="/FAQ">{{ $t('footer.support2') }}</router-link>
                            <router-link tag="li" to="/Inquiry" class="last" v-if="$store.state.erc20Address !== ''">{{ $t('footer.support3') }}</router-link>
                        </ul>
                    </div>
                    <!-- <div class="link menu4" v-if="$store.state.erc20Address !== ''">
                        <router-link tag="a" to="/MyProfile">{{ $t('header.menu4') }}</router-link>
                        <ul class="sub-menu">
                            <router-link tag="li" to="/MyProfile" class="first">{{ $t('footer.account1') }}</router-link>
                            <router-link tag="li" to="/MyProfile" class="first">{{ $t('header.subMenu1') }}</router-link>
                            <router-link tag="li" to="/MyCollection">{{ $t('footer.account2') }}</router-link>
                            <li @click="moveCreateItem()">{{ $t('header.subMenu3') }}</li>
                            <router-link tag="li" to="/MyProfile/Liked">{{ $t('footer.account3') }}</router-link>
                            <router-link tag="li" to="/AccountSetting" class="last">{{ $t('footer.account4') }}</router-link>
                        </ul>
                    </div> -->
                    <div class="link menu5">
                        <a href="https://www.artube.video/" target="_blank">{{ $t('header.webtoon') }}</a>
                    </div>
                    <div class="link menu6">
                        <a href="https://governance.artube.video/" target="_blank">{{ $t('header.governance') }}</a>
                    </div>
                </div>
                <div class="search">
                    <b-input placeholder="아이템을 검색해주세요" v-model="itemSearchText" @keyup.enter="searchItem()"></b-input>
                </div>
            </div>
            <div class="type-menu two">
                <button class="back-btn" @click="$router.go(-1)">
                    <img src="@/assets/images/icon/back-btn.svg" />
                </button>
                <p class="title">{{$parent.$options.krName}}</p>
            </div>
            <div class="type-menu three">
                <button class="back-btn" @click="$router.go(-1)">
                    <img src="@/assets/images/icon/back-btn.svg" />
                </button>
                <p class="title">{{$parent.$options.krName}}</p>
                <router-link tag="a" to="/" class="home"><img src="@/assets/images/icon/home.svg" /></router-link>
            </div>
            <div class="typing-area" v-if="searchTyping">
                <button class="back-btn" @click="searchTyping = !searchTyping">
                    <img src="@/assets/images/icon/back-btn.svg" />
                </button>
                <b-input placeholder="아이템을 검색해주세요" v-model="itemSearchText" @keyup.enter="searchItem()"></b-input>
            </div>
        </b-container>
        <Kaikas :initLoadKaikas="$store.state.isEnableKaikas" :initIsMainNetForce="initIsMainNetForce" @onComplete="onComplete" />
        <FloatingAlram :initConnectWebSocket="initConnectWebSocket" />
        <PopupSignUp :initIsShowPopup="isShowPopupSignup" @close="close('PopupSignUp')" @signupComplete="signupComplete" />
        <PopupMailSent :initIsShowPopup="isShowPopupMailSent" :initBeforeSendEmail="beforeSendEmail" @close="close('PopupMailSent')" />
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupTwoButton :initIsShowPopup="isShowPopupTB" :PopupTitle="iniPopupTBTitle" :PopupMessage="initPopupTBMessage" :ButtonLeft="'취소'" :ButtonRight="'확인'" @clickLeftBtn="close('PopupTB')" @clickRightBtn="$router.push('/CollectionRegist')" @close="close('PopupTB')" />
    </div>
</template>

<script>
import Kaikas from "@/components/common/Kaikas.vue"
import MyWallet from '@/components/common/MyWallet.vue'
import FloatingAlram from '@/components/common/FloatingAlram.vue'

import PopupOK from '@/components/popup/PopupOK.vue'
import PopupTwoButton from '@/components/popup/PopupTwoButton.vue'
import PopupSignUp from '@/components/popup/PopupSignUp.vue'
import PopupMailSent from '@/components/popup/PopupMailSent.vue'

import vClickOutside from 'v-click-outside'
import Caver from "caver-js";

export default {
    name: "Header",
    directives: {
        clickOutside: vClickOutside.directive
    },
    components:{
        Kaikas,
        MyWallet,
        FloatingAlram,
        PopupOK,
        PopupSignUp,
        PopupMailSent,
        PopupTwoButton,
    },
    data() {
        return {
            initConnectWebSocket: false,
            isShowMyPage: false,
            isShowWalletInfo: false,
            initIsMainNetForce: true,      //메인넷으로 고정할지 여부

            isShowPopupSignup: false,
            isShowPopupMailSent: false,
            beforeSendEmail: '',

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupTB: false,
            iniPopupTBTitle: '',
            initPopupTBMessage: '',

            intervalConfig: null,
            intervalTokenInfo: null,
            tokenInfoTimerCount: 0,
            intervalSetUserInfo: null,

            searchTyping: false,
            itemSearchText: '',

            changeAccount: false,
        };
    },
    props: {
        initHeaderType: {
            type: String,
            default: 'type0',
        },
    },
    async created() {
        var that = this;
        this.$store.commit("setCategoryList", this.host);
        this.$store.commit("setConfigFront", this.host);
        this.$store.commit("setConfigNetwork", this.host);
        this.$store.commit("setConfigNftInfo", this.host);
        this.$store.commit("setConfigTokenInfo", this.host);

        this.intervalConfig = setInterval(()=>{
            this.$store.commit("setConfigFront", this.host);
            this.$store.commit("setConfigTokenInfo", this.host);
            setTimeout(()=>{that.$emit('refreshConfigInfo')}, 1000); //토큰 인포 갱신 요청했음을 알림
        }, 30000);

        
        await this.checkWallet();

        if(this.$store.state.isEnableKaikas && this.$store.state.erc20Address !== null && this.$store.state.erc20Address !== '' && this.$store.state.walletAddress !== ''){
            that.getTokenInfo();
        }

        this.intervalTokenInfo = setInterval(() => {
            if(that.tokenInfoTimerCount <= 0){
                if(that.$store.state.isEnableKaikas && that.$store.state.erc20Address !== null && that.$store.state.erc20Address !== '' && that.$store.state.walletAddress !== ''){
                    that.getTokenInfo();
                    that.tokenInfoTimerCount = 10;
                }
            }else{
                that.tokenInfoTimerCount--;
            }
        }, 1000);
    },
    mounted() {},
    beforeDestroy() {
        clearInterval(this.intervalTokenInfo);
        clearInterval(this.intervalConfig);
        clearInterval(this.intervalSetUserInfo);
    },
    methods: {
        
        toggleMyPage(){
            this.isShowMyPage = !this.isShowMyPage;
        },
        openMyPage(){
            this.isShowMyPage = true;
        },
        closeMyPage(){
            this.isShowMyPage = false;
        },
        toggleWalletInfo(){
            this.isShowWalletInfo = !this.isShowWalletInfo;
        },
        closeWalletInfo(){
            this.isShowWalletInfo = false;
        },
        clipboardSuccessHandler ({ value, event }) {
            value; event;
            this.initPopupOkMessage = "지갑 주소를 복사했습니다."
            this.isShowPopupOk = true;
        },
        searchItem(){
            if(this.itemSearchText === '') return;
            this.$router.push('/Search/' + this.itemSearchText);
        },
        async checkWallet(){  //카이카스 체크
			var that = this;
            if(this.$store.state.isEnableKaikas){
                var klaytn = await window.klaytn;
                if(typeof klaytn !== 'undefined') {
                    setTimeout(async function(){
                        await klaytn.enable().then(accounts => {
                            var account = accounts[0];
                            that.$store.commit("connectKaikas", account);
                            setTimeout(()=>{that.setUserInfo();}, 500);
                        });
                    }, 500)
                }
            }
        },
        async connectKaikas(){
            var that = this;
            var klaytn = await window.klaytn;
            if(typeof klaytn !== 'undefined'){
                await klaytn.enable().then(accounts => {
                    var account = accounts[0].toLowerCase();
                    that.$store.commit("connectKaikas", account);
                    setTimeout(()=>{that.setUserInfo();}, 500);
                });
            }
            else{
                this.initPopupOkMessage = 'Kaikas 미설치 또는 잠금 상태입니다.<br/>설치된 상태면 실행하여 로그인 해주세요.<br/><a href="https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?hl=ko" target="_blank" class="metamask_download">Kaikas 설치하기</a>';
                this.isShowPopupOk = true;
            }
        },
        async setUserInfo() {
            var that = this;
            var isUserExists = await this.checkUserExist(); //회원가입된 지갑인지 체크
            if (isUserExists) {

                //회원가입 되어 있는 지갑일 경우
                var isValidTicket = await this.checkTicket(); //기존에 받아둔 티켓이 있는지, 있다면 유효한지 체크
                if (isValidTicket) {
                    //티켓이 유효할 경우 종료
                    this.initConnectWebSocket = true;
                    await this.$store.commit("setUserInfo", this.host);
                    this.intervalSetUserInfo = setInterval(()=>{
                        if(that.$store.state.erc20Address !== ''){
                            clearInterval(that.intervalSetUserInfo);
                            that.$emit('completeSetUserInfo');
                        }                        
                    }, 200);
                    return;
                }

                var returnSignature = await this.signature(); //티켓이 없거나 만료된 경우 다시 싸이닝 및 티켓 요청
                // console.log(returnSignature);
                if (!returnSignature.valid) {
                    //싸이닝 혹은 티켓 요청 실패 했을 경우 종료
                    return;
                } else {
                    //싸이닝 및 티켓 요청 정상일 경우 사용자 정보 세팅
                    var isSuccessReqTicket = await this.reqTicket(returnSignature);
                    if (isSuccessReqTicket) {
                        this.initConnectWebSocket = true;
                        await this.$store.commit("setUserInfo", this.host);
                        this.intervalSetUserInfo = setInterval(()=>{
                            if(that.$store.state.erc20Address !== ''){
                                clearInterval(that.intervalSetUserInfo);
                                that.$emit('completeSetUserInfo');
                            }                        
                        }, 200);
                    }
                }
            }
        },
        async checkUserExist() {
            //회원가입 된 지갑인지 체크
            var param = {
                address: this.$store.state.walletAddress,
            };
            return await this.$Axios
                .post(`${this.host}/user/exist`, param, this.headers)
                .then(async (res) => {
                    // console.log(res.data);
                    // 이메일 인증 후 유저 테이블에 있으면:    42, "Account already exist"
                    // 이메일 요청내역 없으면:               41, "Account not found"
                    // 이메일 요청내역 있고 아직 만료 안됨:    13, "Prior request exists"
                    // 이메일 요청내역은 있는데 모두 만료:     85, "date expired"
                    if (res.data.returnCode === 42) {
                        //회원가입된 지갑
                        return true;
                    } else if(res.data.returnCode === 41 || res.data.returnCode === 85) {
                        //가입되지 않은 지갑(이메일 인증 요청 없음)
                        this.isShowPopupSignup = true;
                        return false;
                    } else if(res.data.returnCode === 13) {
                        //가입되지 않은 지갑(이메일 인증 중)
                        this.beforeSendEmail = res.data.email;
                        this.isShowPopupMailSent = true;
                        return false;
                    } else {
                        return false;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    return false;
                });
        },
        async checkTicket() {
            if (this.$store.state.ticket === "") {
                return false;
            }
            var param = {
                address: this.$store.state.walletAddress,
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket
            };
            return await this.$Axios
                .post(`${this.host}/ticket/check`, param, this.headers)
                .then(async (res) => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    return false;
                });
        },
        async reqTicket(data) {
            var param = {
                address: data.account,
                message: data.nonce,
                signature: data.signature,
                authType: 2 //1: 메타마스크, 2: 카이카스
            };
            // console.log(param);
            return await this.$Axios
                .post(`${this.host}/ticket/request`, param, this.headers)
                .then(async (res) => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        await this.$store.commit("updateId", res.data.id);
                        await this.$store.commit("updateTicket", res.data.ticketId);
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    return false;
                });
        },
        async signature(){  //카이카스 사이닝
            var caver = new Caver(window.klaytn);
            var message = 'ArtubeNFT Signature(' + Date.now().toString() + ')';

            return new Promise((resolve, reject)=> 
                caver.klay.sign(message, this.$store.state.walletAddress).then(signature => {
                    var returnValue = {
                        valid: true,
                        account: this.$store.state.walletAddress,
                        signature: signature,
                        nonce: message,
                    };
                    return resolve(returnValue);
                }).catch(err => {
                    reject({ valid: false, err: err });
                })
            );
        },
        async onComplete(data){   //메타 마스크, 카이카스, Klip 리턴 대기
            // console.log(data);
            if(data.type === "CHANGED_ACCOUNT"){
                this.initPopupOkMessage = 'Kaikas 지갑이 변경되었습니다.';
                // this.PopupOkCallbakPage = 'reload'
                this.PopupOkCallbakPage = '/'
                this.isShowPopupOk = true;
                this.disconnectKaikas();
			}
            else if(data.type === "LOCKED_WALLET"){
                this.initPopupOkMessage = 'Kaikas가 잠금 설정 중입니다.';
                this.PopupOkCallbakPage = '/'
                this.isShowPopupOk = true;
				this.disconnectKaikas();
			}
            else if(data.type === "EMPTY_WALLET_ACCOUNT"){
                this.initPopupOkMessage = 'Kaikas에 연결된 지갑 없습니다.';
                this.PopupOkCallbakPage = '/'
                this.isShowPopupOk = true;
				this.disconnectKaikas();
			}
            else if(data.type === "NOT_INSTALL"){
                this.initPopupOkMessage = 'Kaikas가 설치되지 않았습니다.';
                this.PopupOkCallbakPage = '/'
                this.isShowPopupOk = true;
                this.disconnectKaikas();
            }
            else if(data.type === 'MAIN_NET_FORCE'){
                this.initPopupOkMessage = 'Kaikas 네트워크를 메인넷으로 변경해 주세요.';
                // this.PopupOkCallbakPage = 'reload'
                this.PopupOkCallbakPage = '/'
                this.isShowPopupOk = true;
            }
            // else if(data.type === 'TEST_NET'){
            //     this.initPopupOkMessage = 'Kaikas 네트워크가 메인넷이 아닙니다.';
            //     this.isShowPopupOk = true;
            //     this.disconnectKaikas();
            // }
            else if(data.type === 'CHANGED_NETWORK'){
                this.initPopupOkMessage = 'Kaikas 네트워크가 변경되었습니다.';
                // this.PopupOkCallbakPage = 'reload'
                this.PopupOkCallbakPage = '/'
                this.isShowPopupOk = true;
                this.disconnectKaikas();
            }
        },
        disconnectKaikas(){   //메타 마스크, 카이카스 연결 끊기
            this.$store.commit("disconnectKaikas");
            this.$store.commit("logout");
            this.initConnectWebSocket = false;
		},
        async getTokenInfo(){
            var attTokenInfo = this.$store.state.configTokenInfo.find((config) => config.symbol === 'ATT');
            // var klayTokenInfo = this.$store.state.configTokenInfo.find((config) => config.symbol === 'KLAY');
            if(attTokenInfo === undefined) return;

            var klayBalance = 0;
            var attBalance = 0;
            var klayUsd = 0;
            var attUsd = 0;

            await this.getKlayBalance((balance)=>{klayBalance = balance});
            await this.getTokenBalance(attTokenInfo.address, (balacne)=>{attBalance = balacne;})

            klayBalance = (Math.floor(klayBalance * 10000))/ 10000;
            attBalance  = (Math.floor(attBalance * 10000))/ 10000;

            klayUsd = this.$store.getters.getTokenUsdPrice('KLAY', klayBalance, 4);
            attUsd  = this.$store.getters.getTokenUsdPrice('ATT', attBalance, 4);

            for(var i=0; i < this.$store.state.balanceList.length; i++){
                if(this.$store.state.balanceList[i].name === 'ATT'){
                    this.$store.state.balanceList[i].balance = attBalance;
                    this.$store.state.balanceList[i].usd = attUsd;
                } else if(this.$store.state.balanceList[i].name === 'KLAY'){
                    this.$store.state.balanceList[i].balance = klayBalance;
                    this.$store.state.balanceList[i].usd = klayUsd;
                }
            }
        },
        async getKlayBalance(callback) {
            const nodeApiUrl = process.env.VUE_APP_NODE_API_URL;
            const accessKeyId = process.env.VUE_APP_ACCESS_KEY_ID;
            const secretAccessKey = process.env.VUE_APP_SECRET_ACCESS_KEY;
            const chainId = process.env.VUE_APP_CHAIN_ID;
            const option = {
                headers: [
                    {
                        name: 'Authorization',
                        value: `Basic ${Buffer.from(`${accessKeyId}:${secretAccessKey}`).toString('base64')}`,
                    },
                    { name: 'x-chain-id', value: chainId },
                ],
            }
            
            try{
                var caver = null;
                if(this.isPc()){
                    caver = new Caver(window.klaytn);   //PC일 경우 klaytn API 사용
                } else {
                    caver = new Caver(new Caver.providers.HttpProvider(nodeApiUrl, option));    //mobile 일 경우 KAS API 사용
                }
                await caver.klay.getBalance(this.$store.state.walletAddress).then((balance) => {
                    balance = caver.utils.fromPeb(balance.toString(),"KLAY");
                    callback(balance);
                })
            }
            catch(err){
                return;
            }
		},
        async getTokenBalance(tokenAddress, callback) {
            const nodeApiUrl = process.env.VUE_APP_NODE_API_URL;
            const accessKeyId = process.env.VUE_APP_ACCESS_KEY_ID;
            const secretAccessKey = process.env.VUE_APP_SECRET_ACCESS_KEY;
            const chainId = process.env.VUE_APP_CHAIN_ID;
            const option = {
                headers: [
                    {
                        name: 'Authorization',
                        value: `Basic ${Buffer.from(`${accessKeyId}:${secretAccessKey}`).toString('base64')}`,
                    },
                    { name: 'x-chain-id', value: chainId },
                ],
            }
            
            try{
                var caver = null;
                if(this.isPc()){
                    caver = new Caver(window.klaytn);   //PC일 경우 klaytn API 사용
                } else {
                    caver = new Caver(new Caver.providers.HttpProvider(nodeApiUrl, option));    //mobile 일 경우 KAS API 사용
                }
                var objKip7 = await new caver.kct.kip7(tokenAddress);
                await objKip7.balanceOf(this.$store.state.walletAddress).then(balance => {
                    balance = caver.utils.fromPeb(balance.toString(),"KLAY");
                    callback(balance);
                });
            }
            catch(err){
                return;
            }
		},
        signupComplete(){
            this.isShowPopupSignup = false;
            this.checkUserExist();
            // this.setUserInfo();
        },
        moveRanking(){
            this.initPopupOkMessage = "정식 서비스에 오픈됩니다."
            this.isShowPopupOk = true;
        },
        moveCreateItem(){
            if(this.$store.state.collectionCount > 0){
                this.$router.push('/NftPublish')
            } else {
                this.initPopupTBMessage = 'NFT를 생성하기 위해선 최소 1개의 컬렉션이 필요합니다.<br/>컬렉션 생성 페이지로 이동 하겠습니까 ? ';
                this.isShowPopupTB = true;
            }
        },
        close(popupType){
            switch(popupType){
                case 'PopupMailSent':
                    this.isShowPopupMailSent = false;
                    break;
                case 'PopupSignUp':
                    this.isShowPopupSignup = false;
                    break;
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'PopupTB':
                    this.isShowPopupTB = false;
            }
        },
        logout(){
            this.$store.commit("disconnectKaikas");
            this.$store.commit("logout");
            this.initConnectWebSocket = false;
            this.$router.push('/');
        },
    },
};
</script>
