<template>
    <div id="service">
        <div class="terms_box">
            <div class="title">
                <h1>아튜브 NFT 이용약관</h1>
            </div>
            <div class="article_box">
                <div class="article">
                    <h2>제1조 (목적)</h2>
                    <p>본 약관은 (주)넘버스(전자상거래 사업자)가 운영하는 아튜브 (이하 “회사”)에서 제공하는 NFT 플랫폼(이하 “플랫폼”)을 이용함에 있어 회사와 회원의 권리/의무 및 책임사항을 규정함을 목적으로 합니다.</p>
                </div>
                <div class="article">
                    <h2>제2조 (용어의 정의)</h2>
                    <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                    <p>본 조에서 정의하지 않은 용어에 대해서는 관계 법령, 안내, “회사”의 서비스 정책 등에서 정하는 바에 의하며, 정하지 않은 것은 일반적 관례에 의합니다.</p>
                    <p class="sub_p">1. “NFT”란, 대체불가능한 토큰(Non-fungible Token)을 말하며, “디지털 저작물”에 관한 권리가 부여되는 블록체인 기반의 디지털 자산을 의미합니다.</p>
                    <p class="sub_p">2. “NFT 서비스”란, “플랫폼”과 전반적인 “NFT” 거래관련 지원 업무를 말합니다.</p>
                    <p class="sub_p">3.	“작품”이란, “NFT”와 연계된 이미지, 음원, 영상, 서적 등의 디지털 저작물을 의미합니다</p>
                    <p class="sub_p">4.	“작가”란, “플랫폼”과 계약을 맺고 “작품”을 공식적으로 판매하는 아튜브 회원을 말합니다.</p>
                    <p class="sub_p">5.	“발행인”이란, “플랫폼”에서 “디지털 저작물”을 적법하게 확보한 후, NFT를 발행하여 최초로 배포하는 자를 의미합니다.</p>
                    <p class="sub_p">6.	“판매자”란, “플랫폼”에서 “작품”을 판매하는 주체를 말합니다.</p>
                    <p class="sub_p">7.	“구매자”란, “플랫폼”에서 “작품”을 구매하는 자를 말합니다.</p>
                    <p class="sub_p">8.	“회원”이라 함은 “작가”, “발행인”, “판매자”, “구매자”를 통칭한 것을 말합니다.</p>
                </div>
                <div class="article">
                    <h2>제3조 (NFT 서비스의 내용)</h2>
                    <p>1. “회사”가 제공하는 “NFT 서비스”는 탈중앙화 서비스로 “회원”간에 “작품”을 등록하고 거래하는 “플랫폼”을 제공하는 것을 말합니다.</p>
                    <p>2. “구매자”는 “플랫폼”에 등록된 “작품”을 즉시 구매, 혹은 입찰할 수 있습니다.</p>
                    <p>3. “판매자” 및 “작가”는 “작품”을 등록하고 판매할 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제4조 (NFT 서비스 비용)</h2>
                    <p>1.	“회원”은 플랫폼을 이용함에 있어 다음 각 호의 비용을 부담하게 됩니다.</p>
                    <p class="sub_p">① “플랫폼”에 NFT를 등록할 때 발생하는 네트워크 수수료(계정 등록 비용)</p>
                    <p class="sub_p">② “플랫폼”에서 NFT 구매할때 발생하는 네트워크 수수료(민팅비용)</p>
                    <p class="sub_p">③ 낙찰 시 발생하는 낙찰가의 2.5%에 대한 비용 </p>
                    <p class="sub_p">④ 낙찰 시 발생하는 작품 최초 창작자가 설정한 만큼 지급되는 로열티</p>
                </div>
                <div class="article">
                    <h2>제5조 (NFT 서비스 이용)</h2>
                    <p>1.	“작가” 혹은 “판매자”는 입찰 가능 시간을 설정할 수 있습니다. 기간 내 입찰이 없을 경우, 판매는 종료됩니다.</p>
                    <p>2.	“회원”은 낙찰 후에는 입찰을 취소할 수 없습니다.</p>
                    <p>3.	“구매자”는 입찰에 응할 시 지갑에서 해당 금액만큼 출금됩니다.</p>
                    <p>4.	낙찰시에는 수수료를 제외한 금액이 “판매자”에게 지급됩니다.</p>
                    <p>5.	“플랫폼”에서 배포되는 모든 “NFT”는 거래 금액 및 판매 정보가 서비스 화면에 전부 또는 일부 공개될 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제6조 (스마트컨트랙트)</h2>
                    <p>“플랫폼”에서의 모든 거래는 클레이튼 메인넷의 스마트 컨트랙트를 통해 기록되며, 이는 회사의 고의 또는 중대한 과실 없이 블록체인 상의 기술적 문제로 발생한 손해에 대하여 책임지지 아니합니다.</p>
                </div>
                <div class="article">
                    <h2>제7조 (작가 및 발행인의 권리)</h2>
                    <p>1.	“작가” 및 “발행인”은  “작품”의 판매로 발생하는 창작자 로열티를 지급받을 수 있습니다.</p>
                    <p>2.	로열티는 “플랫폼”에서 발생하는 대가로 “회사”는 제1항의 로열티를 보장하지 않습니다.</p>
                </div>
                <div class="article">
                    <h2>제8조 (작가 및 발행인의 의무)</h2>
                    <p>1.	“작가” 및 “발행인”은 다음 각 호의 권리를 하는데 필요한 모든 권한을 적법하게 확보하여야 합니다.</p>
                    <p class="sub_p">① “작품” 제작 (“작품”에 대한 지식 재산권 취득 또는 원저작자로부터 라이선스 또는 이용허락 필요)</p>
                    <p class="sub_p">② “작품”과 연계된 “NFT” 발행 및 유통 (“작품”에 관한 복제권, 전송권 등 허락 필요)</p>
                    <p class="sub_p">③ 발행 및 유통된 “NFT”를 소유한 자에게 본 약관상 규정된 권리 보장 (“작품”에 권한 권리소진 허락 필요)</p>
                    <p>2.	“작가” 및 “발행인”은 저작권을 침여하거나 허위사실을 “작품”에 포함하여서는 안됩니다.</p>
                </div>
                <div class="article">
                    <h2>제9조 (구매자의 권리)</h2>
                    <p>1.	“구매자”는 적법하게 “작품”을 구매할 시에 “작가” 또는 “발행인”의 “작품”을 재판매하고 복제 및 전송을 할 수 있지만, 이것은 “작품”에 대한 지식재산권 및 소유권을 양도받는 것은 아닙니다.</p>
                    <p>2.	“회사”는 “회원”에 대하여 완전한 소유권의 이전, 저작권의 실현 등 권리를 보장하지 않습니다.</p>
                </div>
                <div class="article">
                    <h2>제10조 (구매자의 의무)</h2>
                    <p>1.	“구매자”는 작가의 명예나 명성에 해를 끼쳐서는 안됩니다.</p>
                    <p>2.	“구매자”는 상업적 이익을 위해 사용하거나 상품을 상업화해서는 안됩니다.</p>
                    <p>3.	“구매자”는 동일한 “작품”을 사용하여 내/외부에서 동일한 NFT를 발행해서는 안됩니다.</p>
                </div>
                <div class="article">
                    <h2>제11조 (NFT 서비스 유의사항)</h2>
                    <p>1.	“회사”는 “회원”이 다음 각 호에 해당하는 경우, 계약을 유보하거나 거래 취소, “작품” 공개 금지 또는 삭제, 이용 정지, 이용 계약 해지를 할 수 있습니다.</p>
                    <p class="sub_p">①	“작품”에 대해 허위 사실을 기재하거나, 필수 사항을 기재하지 않은 경우</p>
                    <p class="sub_p">②	타인으로 가장하거나 오해의 소지가 있는 닉네임 또는 작품 정보를 기록한 경우 </p>
                    <p class="sub_p">③	자금세탁, 테러자금조달 등 위법 활동에 참여한 경우</p>
                    <p class="sub_p">④	만 19세 미만의 미성년자 </p>
                    <p class="sub_p">⑤	자살 및 자해, 타인에 대한 증오 또는 폭력을 유발하는 등 사회의 안녕질서 또는 미풍 양속을 저해하는 경우</p>
                    <p class="sub_p">⑥	“작품” 등록 및 판매가 지적재산권 혹은 관련 법령에  위배되거나, “회사”또는 제 3자의 이익을 훼손할 위험이 있다고 판단된 경우</p>
                    <p class="sub_p">⑦	“플랫폼”또는 “플랫폼”에서 제공하는 서비스를 방해하거나 절차를 위반하는 경우</p>
                    <p class="sub_p">⑧	불공정한 거래를 조장하거나 시세를 조작 또는 조종하는 경우</p>
                    <p class="sub_p">⑨	타인에게 손해를 끼치거나 건전한 질서를 방해하는 행위를 한 경우</p>
                    <p>2.	“회원”은 위 항에 따른 계약 해지 시, “작품”에 대한 소유권, 로열티 등 일체 권리를 포기합니다.</p>
                    <p>3.	“작가” 혹은 “발행인”의 지갑으로는 NFT 이전이 불가능합니다. 단, 업데이트로 이전이 가능하게되는 경우 이전에 필요한 가스비, 로열티 등의 비용 일체는  “작가” 혹은 “발행인”이 부담합니다.</p>
                    <p>4.	“회사”는 “NFT”에 연계된 “작품”의 진정성, 권리의 완전성 등에 대하여 보증하지 않습니다. 단, 회사의 고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 “회사”가 책임을 부담합니다.</p>
                    <p>5.	"회사”는 “NFT” 거래의 당사자가 아닙니다. “NFT” 거래에 관한 책임은 “판매자”가 부담하고, “회사”는 “판매자”를 대리하거나 그 어떠한 보증을 하지 않습니다.</p>
                    <p>6.	“회사”는 회사의 사정 및 관련 법령의 제·개정, “NFT” 및 관련 가상자산의 정책 변경 등의 경우 “NFT 서비스”의 내용을 변경하거나 중단할 수 있습니다. 단, 이에 관하여 “회원”에게 통지합니다.</p>
                </div>
                <div class="article">
                    <h2>제12조 (NFT 저작권 유의 사항)</h2>
                    <p>1.	“작가” 및 “발행인”이 원저작자가 아닌 경우, 본인에게 귀속된 저작 재산권(복제권, 공연권, 공중송신권, 전시권, 배포권, 대여권, 2차저작물작성권 등)을 활용하여야 합니다.</p>
                    <p>2.	“작가” 및 “발행인”이 원저작자가 아닌 경우, 원저작물의 동일성을 해하여서는 안됩니다.</p>
                    <p>3.	“작가” 및 “발행인”이 원저작자가 아닌 경우, 원저작의 성명을 NFT 설명란에 기록하여야 합니다.</p>
                    <p>4.	“작품”이 공동저작물인 경우, 모든 저작자의 동의를 받아야 합니다.</p>
                    <p>5.	“작품”의 저작자라도 저작인접권자 (실연자, 음반제작자, 방송사업자)의 동의를 받아야 합니다.</p>
                    <p>6.	“작품”에 타인의 초상이 포함된 경우, 초상권자의 동의를 받아야 합니다.</p>
                    <p>7.	“회원은”은 한국저작권위원회 내 등록 저작물 검색 시스템을 활용하여 “작품”의 원저작자와 저작재산권자를 확인할 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제13조 (외부 플랫폼에 이용에 관한 면책)</h2>
                    <p>아튜브 플랫폼에서 제공하는 서비스는 탈중앙화 서비스로서 “외부 플랫폼”의 서비스와 “작품”을보장하지 않으며, “외부 플랫폼”을 통해 발생된 문제를 책임지지 않습니다.</p>
                </div>
                <div class="article">
                    <h2>제14조 (저작권 및 라이선스 침해에 관한 면책)</h2>
                    <p>1.	“플랫폼”을 통하여 유통되는 NFT 또는 연계된 “작품”에 의하여 본인의 저작권 또는 기타 권리가 침해된다고 주장하는 자는 해당 사실을 소명하여 “회사”에 “NFT” 이용 및 유통 중단을 요청할 수 있습니다.</p>
                    <p>2.	“회사는” 1항에 따라 유통 중단을 요구 받은 경우, 즉시 “NFT”의 이용 및 유통을 중단하고, 요청한 자와, 해당 NFT를 보유하고 있는 “회원” 및 “작가”, “발행인”에게 해당 사실을 통보합니다.</p>
                    <p>3.	2항에 따라 통보를 받은 “작가” 또는 “발행인”이 소명 절차를 거쳐, 자신에게 정당한 권리가 있음을 소명하는 경우 “회사”는 “회원”에게 해당 사실 및 재개예정일을 통보하고, “NFT”의 이용 및 유통을 재개합니다. 단, 재개예정일 전에 소를 제기한 사실을 권리를 주장하는 자가 회사에 통보한 경우에는 그러지 않습니다.</p>
                    <p>4.	“회사”는 “회원”의 “작품”에 대한 저작권 혹은 라이선스 위반으로 발생한 손해(“작가”와 “권리주장자” 사이의 분쟁이 해결되지 않아 해당 “NFT”를 보유하고 있는 회원에게 발생하는 손해를 포함하며, 이에 한정하지 않습니다)에 대해 책임을 지지 않습니다.</p>
                    <p>5.	“회원”은 “플랫폼”에 등록된 “작품”이 복제, 전송 및 2차저작물이 제작될 수 있다는 점을 인지하고 있으며, 재판매된 “작품”에 대해서는 저작권을 주장하지 않습니다.</p>
                </div>
                <div class="article">
                    <h2>제15조 (분쟁조정)</h2>
                    <p>“작품”에 대한 저작권 침해가 발생하는 경우에는 저작권자는 한국저작권위원회의 조정절차를 통하여 분쟁을 해결하도록 합니다.</p>
                </div>
                <div class="article">
                    <h2>제16조 (관할법원)</h2>
                    <p>본 약관에 의한 거래에서 발생한 분쟁에 대하여 “회사”와 “회원” 사이에 법원의 판단이 필요한 경우 그 관할법원은 민사소송법을 준용합니다.</p>
                </div>
                <div class="article">
                    <h2>제17조 (관계 법령)</h2>
                    <p>1.	본 약관에서 정해지지 않은 사항은 “아튜브 서비스 이용약관” 및 관계 법령에서 정하는 바에 따르며, 관계 법령에서 정하지 아니한 사항은 상관례를 따릅니다. </p>
                    <p>2.	본 이용약관과 “아튜브 서비스 이용약관”이 충돌할 경우 본 이용약관이 우선하여 적용됩니다.</p>
                </div>
                <div class="article last">
                    <h2>&lt;부칙&gt;</h2>
                    <p>이 약관은 2022년 4월 25일부터 적용됩니다.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "UserTerms",
    components: {
    },
    data() {
        return {
        };
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {},
    methods: {}
};
</script>
