import Vue from "vue";
import VueRouter from "vue-router";

import index from "../views/index.vue";

import CollectionDetail from "../views/Collection/CollectionDetail.vue";
import CollectionRegist from "../views/Collection/CollectionRegist.vue";
import CollectionModify from "../views/Collection/CollectionModify.vue";
import MyCollection     from "../views/Collection/MyCollection.vue";

import Marketplace      from "../views/Market/Marketplace.vue";

import Ranking          from "../views/Stats/Ranking.vue";
import Transactional    from "../views/Stats/Transactional.vue";

import NftPublish       from "../views/NFT/NftPublish.vue";
import NftSell          from "../views/NFT/NftSell.vue";
import NftInfo          from "../views/NFT/NftInfo.vue";
import NftModify        from "../views/NFT/NftModify.vue";

import AccountSetting   from "../views/MyPage/AccountSetting.vue";
import MyProfile        from "../views/MyPage/MyProfile.vue";
import Profile        from "../views/MyPage/Profile.vue";

// import SignUp           from "../views/SignUp/SignUp.vue";
import SignUpComplete   from "../views/SignUp/SignUpComplete.vue";
import EmailVerify      from '../views/SignUp/EmailVerify.vue'

import FAQ              from "../views/Support/FAQ.vue";
import Inquiry          from "../views/Support/Inquiry.vue";
import InquiryWrite     from "../views/Support/InquiryWrite.vue";
import Notice           from "../views/Support/Notice.vue";
import NoticeInfo       from "../views/Support/NoticeInfo.vue";
import PrivacyPolicy    from "../views/Support/PrivacyPolicy.vue";
import UserTerms        from "../views/Support/UserTerms.vue";


// import publish from "../views/market/PublishHome.vue";
// import loadcontract from "../views/market/LoadContract.vue";
// import loyalty from "../views/market/GiveLoyaltyHistory.vue";
// import login from "../views/mypage/Login.vue";
// import trasnfer from "../views/mypage/Transfer.vue";
// import profileyangdo from "../views/mypage/ProfileYangdo.vue";
// import profilemovecollection from "../views/mypage/ProfileMoveCollection.vue";


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {
        return window.location.reload();
    });
};

Vue.use(VueRouter);

const routes = [
    { path: "/",                                    name: "index",                       component: index },
    { path: '*',                                    name: '404',                         redirect: "/"},

    { path: "/Collection/:idx",                     name: "CollectionDetail",            component: CollectionDetail},
    { path: "/Collection/:tabName/:idx",            name: "CollectionDetailTab",         component: CollectionDetail},
    { path: "/CollectionRegist",                    name: "CollectionRegist",            component: CollectionRegist },

    { path: "/Marketplace",                         name: "Marketplace",                 component: Marketplace },
    { path: "/Marketplace/:category",               name: "MarketplaceCategory",         component: Marketplace },
    { path: "/Search/:searchText",                  name: "MarketplaceSearch",           component: Marketplace },

    { path: "/Ranking",                             name: "Ranking",                     component: Ranking },
    // { path: "/Ranking/:category",                   name: "RankingCategory",             component: Ranking },
    { path: "/Transactional",                       name: "Transactional",               component: Transactional },
    { path: "/Transactional/:collectionIdx",        name: "TransactionalCollection",     component: Transactional },

    { path: "/NftPublish",                          name: "NftPublish",                  component: NftPublish },
    { path: "/NftSell/:productId",                  name: "NftSell",                     component: NftSell },
    { path: "/NftInfo/:productId",                  name: "NftInfo",                     component: NftInfo },
    { path: "/NftModify/:productId",                name: "NftModify",                   component: NftModify },
    
    // { path: "/publish-home",                        name: "publish",                     component: publish },
    // { path: "/load-contract",                       name: "loadcontract",                component: loadcontract },
    // { path: "/loyalty",                             name: "loyalty",                     component: loyalty },
    
    { path: "/MyProfile",                           name: "MyProfile",                   component: MyProfile },
    { path: "/MyProfile/:tabName",                  name: "MyProfileTab",                component: MyProfile },
    { path: "/MyProfile/:tabName/:collectionIdx",   name: "MyProfileTabCollection",      component: MyProfile },
    { path: "/Profile/:userId",                     name: "Profile",                     component: Profile },
    { path: "/Profile/:userId/:tabName",            name: "ProfileTab",                  component: Profile },
    { path: "/MyCollection",                        name: "MyCollection",                component: MyCollection },
    { path: "/CollectionModify/:idx",               name: "CollectionModify",            component: CollectionModify },
    { path: "/AccountSetting",                      name: "AccountSetting",              component: AccountSetting },
    // { path: "/Accountsetting/:tabName",             name: "AccountSettingTab",           component: AccountSetting },
    
    // { path: "/login",                               name: "login",                       component: login  },
    // { path: "/transfer",                            name: "trasnfer",                    component: trasnfer  },
    // { path: "/profileyangdo",                       name: "profileyangdo ",              component: profileyangdo  },
    // { path: "/profilemovecollection",               name: "profilemovecollection ",      component: profilemovecollection },

    // { path: "/signup",                              name: "signup",                      component: signup },
    { path: "/SignUpComplete",                      name: "SignUpComplete",              component: SignUpComplete },
    { path: "/EmailVerify/:email/:cert/:address",   name: "EmailVerify",                 component: EmailVerify },
    
    { path: "/Notice",                              name: "Notice",                      component: Notice },
    { path: "/Notice/:idx",                         name: "NoticeInfo",                  component: NoticeInfo },
    { path: "/FAQ",                                 name: "FAQ",                         component: FAQ },
    { path: "/Inquiry",                             name: "Inquiry",                     component: Inquiry },
    { path: "/Inquiry/Write",                       name: "InquiryWrite",                component: InquiryWrite },
    { path: "/PrivacyPolicy",                       name: "PrivacyPolicy",               component: PrivacyPolicy },
    { path: "/UserTerms",                           name: "UserTerms",                   component: UserTerms },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

export default router;
