<template>    
    <div class="transactional">
        <Header :initHeaderType="initHeaderType" />
        <b-container>
            <!-- <b-row class="rank-menu">
                <router-link tag="div" to="/Ranking" :class="{'on' : selectedCategoryName === ''}">{{ $t('marketplace.all') }}</router-link>
                <router-link tag="div" :to="'/Ranking/' + item.name" v-for="(item, index) in $store.state.categoryList" :key="index" :class="{'on' : selectedCategoryName === item.name}">
                    <p>{{ item.name }}</p>
                </router-link>
            </b-row> -->
        </b-container>
        <div class="cont-box" id="cont-box">
            <SelectFilter 
                :initIsTransaction="initIsTransaction" 
                :initFlagSetCollection="initFlagSetCollection" 
                :initCollectionType="initCollectionType" 
                :initPreloadCollectionInfo="initPreloadCollectionInfo" 
                :initFollowFilter="initFollowFilter" 
                :initShowStatus="initShowStatus" 
                :initShowPrice="initShowPrice"  
                :initFlagResetFilter="flagResetFilter" 
                :initRemoveFilter="initRemoveFilter" 
                @changeFilterOptions="changeFilterOptions"
            />
            <div class="cont-area">            
                <p class="title">{{ $t('transactional.title') }}</p>
                <p class="intro" v-html="$t('transactional.intro')"></p>
                <div class="filter-list" v-if="getIsShowFilterList()">
                    <div class="reset" @click="resetFilter()">
                        <p>{{ $t('marketplace.reset') }}</p>
                    </div>
                    <div v-if="selectedStatus !== null">
                        <p v-if="selectedStatus === 1">{{ $t('selectFilter.sell') }}</p>
                        <p v-else-if="selectedStatus === 2">{{ $t('selectFilter.sell2') }}</p>
                        <p v-else-if="selectedStatus === 3">{{ $t('selectFilter.deal') }}</p>
                        <p v-else-if="selectedStatus === 4">{{ $t('selectFilter.send') }}</p>
                        <i class="btn-close" @click="removeFilter('status')" />
                    </div>
                    <div v-if="selectedPrice !== null">
                        <p>
                            {{minPrice}}~{{maxPrice}}
                            <span v-if="selectedPrice === 1">USD</span> 
                            <span v-else-if="selectedPrice === 2">ATT</span> 
                            <span v-else-if="selectedPrice === 3">KLAY</span> 
                        </p>
                        <i class="btn-close" @click="removeFilter('price')" />
                    </div>
                    <div v-if="selectedCollectionIdx !== null">
                        <p>{{selectedCollectionName}}</p>
                        <i class="btn-close" @click="removeFilter('collection')" />
                    </div>
                    <div v-if="selectedSalesCoin !== null">
                        <p>{{selectedSalesCoin}}</p>
                        <i class="btn-close" @click="removeFilter('coin')" />
                    </div>
                    <!-- <div class="switch" @change="changeOrder()">
                        <img src="@/assets/images/exchange.svg" />
                        <p>{{ $t('transactional.switch') }}</p>
                    </div> -->
                </div>

                <div class="list-box transaction">
                    <b-row class="list-head">
                        <b-col class="col-type">{{ $t('collectionPage.event') }}</b-col>
                        <b-col class="col-item">{{ $t('collectionPage.item') }}</b-col>
                        <b-col class="col-price">{{ $t('collectionPage.price') }}</b-col>
                        <b-col class="col-from">{{ $t('collectionPage.from') }}</b-col>
                        <b-col class="col-to">{{ $t('collectionPage.to') }}</b-col>
                        <b-col class="col-date">{{ $t('collectionPage.date') }}</b-col>
                        <b-col class="col-quantity">{{ $t('collectionPage.quantity') }}</b-col>
                        <b-col class="col-tx"></b-col>
                    </b-row>
                    <b-row v-for="(item,idx) in transactionlist" :key="idx">
                        <!-- <b-col class="col-type" v-if="item.type === 1"><img src="@/assets/images/icon/sell.svg" />{{ $t('collectionPage.mint') }}</b-col>
                        <b-col class="col-type" v-else-if="item.type === 2"><img src="@/assets/images/icon/sell.svg" />{{ $t('collectionPage.sell') }}</b-col>
                        <b-col class="col-type" v-else-if="item.type === 3"><img src="@/assets/images/icon/send.svg" />{{ $t('collectionPage.transfer') }}</b-col>
                        <b-col class="col-type" v-else-if="item.type === 4"><img src="@/assets/images/icon/bid.svg" />{{ $t('collectionPage.buy') }}</b-col>  -->
                        <b-col class="col-type">
                            <img src="@/assets/images/transaction/type1.svg" v-show="item.type === 2 || item.type === 15" />
                            <img src="@/assets/images/transaction/type2.svg" v-show="item.type === 11" />
                            <img src="@/assets/images/transaction/type3.svg" v-show="item.type === 9" />
                            <img src="@/assets/images/transaction/type4.svg" v-show="item.type === 10" />
                            <img src="@/assets/images/transaction/type5.svg" v-show="item.type === 12 || item.type === 28" />
                            <img src="@/assets/images/transaction/type6.svg" v-show="item.type === 7 || item.type === 21 || item.type === 25" />
                            <!-- <img src="@/assets/images/transaction/type7.svg" v-show="item.type === 1" /> -->
                            <img src="@/assets/images/transaction/type8.svg" v-show="item.type === 20" />
                            <img src="@/assets/images/transaction/type9.svg" v-show="item.type === 19" />
                            <img src="@/assets/images/transaction/type10.svg" v-show="item.type === 0" />
                            <img src="@/assets/images/transaction/type11.svg" v-show="item.type === 3 || item.type === 16" />
                            <img src="@/assets/images/transaction/type12.svg" v-show="item.type === 4 || item.type === 17" />
                            <p>{{ $t('transactional.event_type_' + item.type) }}</p>
                        </b-col>
                        <!-- <b-col class="col-type" v-else-if="item.type === 3">{{ $t('collectionPage.assignment') }}</b-col>-->
                        
                        <b-col class="col-item">
                            <router-link tag="a" :to="'/NftInfo/' + item.itemIdx" class="link-item">
                                <img :src="$store.state.configFront.cdnUrl + 'resource/' + item.itemFileFormat + '/' + item.itemFileName" class="photo" v-if="$store.state.configFront !== null && item.itemResourceType === 1" />
                                <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/'+ item.itemThumbnailFileName" class="photo" v-if="$store.state.configFront !== null && item.itemResourceType === 2" />
                                <div class="box">
                                    <p class="collection-name">{{item.collectionName}}</p>
                                    <p class="skip">{{item.itemName}}</p>
                                </div>
                            </router-link>
                        </b-col>

                        <!-- <p class="more" v-b-toggle="'mb-list' + item.transactionIdx">+ {{ $t('rankings.more') }}</p> -->

                        <b-col class="col-price col-blank" v-if="item.price === null">-</b-col>
                        <b-col class="col-price" v-else>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.symbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.symbol === 'KLAY'" />
                            <p>{{item.price | comma}}</p>
                        </b-col>
                        
                        <b-col class="col-from col-blank" v-if="item.type === 1 || (item.fromId === null && item.from ===  null)">-</b-col>
                        <b-col class="col-from" v-else>
                            <img src="@/assets/images/icon/wallet.svg" class="img-wallet" v-if="item.fromId === null" />
                            <img src="@/assets/images/profile-menu/no-profile.png" alt="profile" class="photo" v-else-if="$store.state.configFront === null || item.fromFileName === null || item.fromFileName === ''">
                            <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.fromFileName" class="photo" v-else />
                            
                            <router-link tag="p" :to="'/Profile/' + item.fromId" class="skip" v-if="item.fromId !== null">{{item.fromId}}</router-link>
                            <p v-else>{{item.from}}</p>
                        </b-col>
                        
                        <b-col class="col-to">
                            <img src="@/assets/images/icon/wallet.svg" class="img-wallet" v-if="item.toId === null" />
                            <img src="@/assets/images/profile-menu/no-profile.png" alt="profile" class="photo" v-else-if="$store.state.configFront === null || item.toFileName === null || item.toFileName === ''">
                            <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.toFileName" class="photo" v-else />
                            
                            <router-link tag="p" :to="'/Profile/' + item.toId" class="skip" v-if="item.toId !== null">{{item.toId}}</router-link>
                            <p v-else>{{item.to}}</p>
                        </b-col>
                        
                        <b-col class="col-date" :class="{right : item.txConfirm === null}">{{$store.getters.getLeftDate(item.date)}}</b-col>
                        <b-col class="col-quantity">{{item.amount | comma}}<span class="">개 {{ $t('transactional.event_type_' + item.type) }}</span></b-col>
                        <b-col class="col-tx"><a :href="$store.getters.getCurrentUrlForTx(item.txConfirm)" target="_blank" v-if="item.txConfirm !== null"><img src="@/assets/images/icon_link_off.svg" class="link"></a></b-col>
                        <!-- <b-collapse class="mb-list" :id="'mb-list' + item.transactionIdx" accordion="my-accordion" role="tabpanel">
                            <b-col>
                                <div>
                                    <p class="title">{{ $t('collectionPage.quantity') }}</p>
                                    <p>{{item.amount | comma}}</p>
                                </div>
                                <div>
                                    <p class="title">{{ $t('collectionPage.from') }}</p>
                                    <p v-if="item.fromId !== null">{{item.fromId}}</p>
                                    <p v-else>{{item.from}}</p>
                                </div>
                                <div>
                                    <p class="title">{{ $t('collectionPage.to') }}</p>
                                    <p v-if="item.toId !== null">{{item.toId}}</p>
                                    <p v-else>{{item.to}}</p>
                                </div>
                            </b-col>
                        </b-collapse> -->
                    </b-row>
                    <div class="next-list" v-if="totalRecordSize > 0 && transactionlist.length < totalRecordSize">
                        <a @click="nextList()">{{ $t('rankings.more') }}</a>
                    </div>
                </div>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import SelectFilter from '@/components/market/SelectFilter.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "Transactional",
    krName: '트랜잭션',
    components: {
        Header,
        SelectFilter,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type1',
            

            //필터
            initFollowFilter: false,
            initFlagSetCollection: false,
            initCollectionType: 0,  // 0 내가 소유한 컬렉션, 1 내가 제안한 컬렉션, 2 내가 즐겨찾기한 컬렉션
            initPreloadCollectionInfo: null,

            flagResetFilter: false,
            initRemoveFilter: null,
            initIsTransaction: true,
            initShowStatus: true,
            initShowPrice: true,

            selectedStatus: null,
            selectedPrice: null,
            minPrice: null,
            maxPrice: null,
            selectedSalesCoin: null,
            selectedCollectionIdx: null, 
            selectedCollectionName: null,

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 20,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            totalRecordSize: 0,

            transactionlist: [],

            pageList:[],

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이
        };
    },
    props: {
    },
    created() {
    },
    async mounted() {
        if(this.$route.params.collectionIdx){
            var isExistCollection = await this.checkExistCollection(this.$route.params.collectionIdx);
            if(!isExistCollection){
                this.initPopupOkMessage = "잘못된 접근 입니다."
                this.PopupOkCallbakPage = '/';
                this.isShowPopupOk = true;
                return;
            } else {
                this.initFlagSetCollection = true
            }
        } else {
            this.initFlagSetCollection = true
        }
    },
    beforeDestroy() {},
    methods: {
        resetList(){
            this.currentPageNo = 1;
            this.totalRecordSize =  0;
            this.transactionlist = [];

            this.getTransactionList();
        },
        nextList(){
            this.currentPageNo++;
            this.getTransactionList();
        },
        async checkExistCollection(collectionIdx){
            var param = 
            {
                collectionIdx: collectionIdx
            }
            // console.log(param);
            return await this.$Axios.post(`${this.host}/nft/collection/info`, param, this.headers).then(async (res) => {
                // console.log('------ Collection Info --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.initPreloadCollectionInfo = res.data.data;
                    return true;
                } else {
                    this.error(res.data.returnCode);
                    return false;
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getTransactionList(){
            // NFT 리스트 검색 요청 파라미터
            // order: // 1: latest // 2: popularity // 3: close to finish // 4: low price // 5: high price // 6: small bids // 7: a lot of bids // 8: most seen // 9: oldest 
            // itemType: // 1: 조각 아이템 // 2: 단일 아이템
            // state // 1: 숨김 // 2: 판매종료(expired) // 3: 판매완료(sold out)  // 4: 판매중

            // FilterCategory 1: art 2: music 3: virtual world 4: trading cards 5: collectibles 6: sports 7: utility 8: etc 
            // FilterPrice 1:usd 2:att 3:klay 
            // -startPrice : 최소 가격
            // -endPrice : 최대 가격
            // FilterCoin 심볼 쓰시면됩니다. ATT, KLAY
            // Status는 아직 작업안되있고 <- 판매중 경매 요런거 추가 작업 예정입니다.
            // orderType 1:최신순 2:인기순 3:마감 임박순 4:낮은 가격순 5:높은 가격순 6:조회순 7:오래된순
            
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/info/transaction/all`, param, this.headers).then(async (res) => {
                // console.log('------ transaction all list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.transactionlist = this.transactionlist.concat(res.data.list);
                    // this.transactionlist = res.data.list;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        changeOrder(){
            this.resetList();
        },
        changeCategory(idx){
            this.selectedCategoryIdx = idx;
            this.resetList();
        },
        changeFilterOptions(option){
            var that = this;
            switch(option.type){
                case 'setCollection':
                    setTimeout(()=>{that.initFlagSetCollection = false}, 100);
                    break;
                case 'status':
                    this.selectedStatus = option.value;
                    break;
                case 'price':
                    this.selectedPrice = option.priceType;
                    this.minPrice = option.minPrice;
                    this.maxPrice = option.maxPrice;
                    break;
                case 'collection':
                    this.selectedCollectionIdx = option.collectionIdx;
                    this.selectedCollectionName = option.collectionName;
                    break;
                case 'coin':
                    this.selectedSalesCoin = option.value;
                    break;
                case 'reset':
                    this.flagResetFilter = false;
                    break;
            }
            if(option.isRemove){
                this.initRemoveFilter = null;
            }
            this.resetList();
        },
        removeFilter(type){
            this.initRemoveFilter = type;
        },
        resetFilter(){
            this.selectedStatus = null;
            this.selectedPrice = null;
            this.minPrice = null;
            this.maxPrice = null;
            this.selectedSalesCoin = null;
            this.selectedCollectionIdx = null;
            this.selectedCollectionName = null;

            this.initPreloadCollectionInfo = null;
            
            this.flagResetFilter = true;
        },
        getIsShowFilterList(){
            if(this.selectedStatus !== null || this.selectedPrice !== null || this.selectedCollectionIdx !== null || this.selectedSalesCoin !== null){
                return true;
            } else {
                return false;
            }
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
