<template>
    <div class="signup-complete">
        <Header />
        <div class="popup-box signup2 popup-two-button-box">
            <p class="popup-title">{{ $t('singupComplete.title') }}</p>
            <p class="popup-cont">{{ $t('singupComplete.text') }}</p>
            <div class="buttons">
                <router-link tag="a" to="/" class="left">{{ $t('singupComplete.button1') }}</router-link>
                <router-link tag="a" to="/CollectionRegist" class="right">{{ $t('singupComplete.button2') }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'

export default {
    name: "SignUpComplete",
    krName: '회원가입 완료',
    components: {
        Header,
    },
    data() {
        return {
        };
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
    },
};
</script>
