<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box sign-need">
            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <p v-html="$t('popupProgress.text')"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupProgress",
        data() {
            return {};
        },
        props: {
            initIsShowPopup: {
                type: Boolean,
                default: false,
            },
        },
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {
        },
    };
</script>
