<template>
    <div class="popup" v-if="initIsShowPopup && initBuyInstance !== null">
        <div class="popup-box padding buy">
            <div class="top">
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
                <p class="popup-title" v-if="initBuyInstance.saleType === 1">{{ isCompleteBuy ? $t('buy.title2') : $t('buy.title') }}</p>
                <p class="popup-title" v-if="initBuyInstance.saleType === 2">{{ $t('buy.title3') }}</p>
                <p class="popup-title" v-if="isRequestWalletConfirm">{{ $t('buy.title4') }}</p>
            </div>
            <div class="bottom">
                <!-- <div class="warn">
                    <p>{{ $t('buy.warn') }}</p>
                </div> -->
                <div class="title-box" v-if="!isCompleteBuy">
                    <!-- <p class="title bundle" v-if="bundle">{{ $t('buy.bundleItem') }}</p> -->
                    <p class="title">{{ $t('buy.item') }}</p>
                    <p class="title subtotal">{{ $t('buy.subtotal') }}</p>
                </div>
                <div class="product" v-if="!isCompleteBuy">
                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + initBuyInstance.fileType + '/' + initBuyInstance.fileName" v-if="$store.state.configFront !== null && initBuyInstance.productInfoVO.pdResourceType === 1"/>
                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + initBuyInstance.productInfoVO.pdThumbnailFileName" v-if="$store.state.configFront !== null && initBuyInstance.productInfoVO.pdResourceType === 2"/>

                    <div class="basic-info">
                        <p class="amount" v-if="initBuyInstance.itemType === 1">{{ $t('buy.amount') }} x {{initBuyInstance.remainCount}}</p>
                        <router-link tag="p" :to="'/Collection/' + initBuyInstance.collectionIdx" class="writer">{{initBuyInstance.collectionName}}</router-link>
                        <p class="name">{{initBuyInstance.productInfoVO.pdName}}</p>
                    </div>
                    <div class="price" v-if="initBuyInstance.saleType === 1">
                        <p v-if="initBuyInstance.itemType === 1" class="top-text">{{ $t('buy.per-piece') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                            <p>{{initBuyInstance.priceAmount | comma}}</p>
                            <span>(${{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, initBuyInstance.priceAmount, 4)}})</span>
                        </div>
                    </div>
                    <div class="price" v-else-if="initBuyInstance.saleType === 2">
                        <!-- <p class="top-text">{{ $t('buy.bidMinPossible') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                            <p>{{minBidPrice | comma}}</p>
                            <span>($ {{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, minBidPrice, 4) | comma}})</span>
                        </div> -->
                        <p class="top-text" v-if="initBuyInstance.bidMaxPrice === null">{{ $t('iteminfo.price3') }}</p>
                        <p class="top-text" v-else>{{ $t('iteminfo.price2') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                            <p>{{initBuyInstance.bidMaxPrice === null ? initBuyInstance.priceAmount : initBuyInstance.bidMaxPrice | comma}}</p>
                            <span>($ {{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, initBuyInstance.bidMaxPrice === null ? initBuyInstance.priceAmount : initBuyInstance.bidMaxPrice, 4) | comma}})</span>
                        </div>
                    </div>
                </div>
                <div class="m-product-price" v-if="!isCompleteBuy">
                    <p class="title subtotal">{{ $t('buy.subtotal') }}</p>
                    <div class="price" v-if="initBuyInstance.saleType === 1">
                        <p v-if="initBuyInstance.itemType === 1" class="top-text">{{ $t('buy.per-piece') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                            <p>{{initBuyInstance.priceAmount | comma}}</p>
                            <span>(${{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, initBuyInstance.priceAmount, 4)}})</span>
                        </div>
                    </div>
                    <div class="price" v-else-if="initBuyInstance.saleType === 2">
                        <p class="top-text" v-if="initBuyInstance.bidMaxPrice === null">{{ $t('iteminfo.price3') }}</p>
                        <p class="top-text" v-else>{{ $t('iteminfo.price2') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                            <p>{{initBuyInstance.bidMaxPrice === null ? initBuyInstance.priceAmount : initBuyInstance.bidMaxPrice | comma}}</p>
                            <span>($ {{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, initBuyInstance.bidMaxPrice === null ? initBuyInstance.priceAmount : initBuyInstance.bidMaxPrice, 4) | comma}})</span>
                        </div>
                    </div>
                </div>
                <div class="buy-piece" v-if="!isCompleteBuy && initBuyInstance.itemType === 1">
                    <p class="title">{{ $t('buy.buyPiece') }}</p>
                    <input class="form-control" v-model="quantity" @input="commaAmount" />
                    <p class="selling">{{ $t('buy.sellingPiece') }} : {{initBuyInstance.remainCount}}</p>
                </div>
                <div class="saleType2" v-if="initBuyInstance.saleType === 2">
                    <div class="flex">
                        <div class="unit">
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                            <span>{{initBuyInstance.priceSymbol}}</span>
                        </div>
                        <input class="form-control" v-model="bidPrice" @input="commaBidPrice" />
                    </div>
                    <img src="@/assets/images/equal.svg" class="equal" />
                    <div class="flex usd">
                        <div class="unit">
                            <img src="@/assets/images/icon/dollar.svg" />
                            <span>USD</span>
                        </div>
                        <input class="form-control" v-model="usdPrice" @input="commaUsdPrice" />
                        <!-- <p>{{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, numberBidPrice, 4)}}</p> -->
                    </div>
                </div>
                <div class="total" v-if="!isCompleteBuy">
                    <div>
                        <p class="title" v-if="initBuyInstance.saleType === 1">{{ $t('buy.total') }}</p>
                        <p class="title" v-else-if="initBuyInstance.saleType === 2">{{ $t('iteminfo.bid2') }}</p>
                        <!-- <p class="balance">{{ $t('buy.balance') }}</p> -->
                    </div>
                    <p class="price">
                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                        {{totalPrice | comma}}
                        <span>(${{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, totalPrice, 4) | comma}})</span>
                    </p>
                </div>
                <div class="bid" v-if="isCompleteBuy">
                    <div class="bid-inner">
                        <p class="name">{{initBuyInstance.productInfoVO.pdName}}</p>
                        <p class="writer">{{initBuyInstance.sellerName}}</p>
                        <!-- <router-link tag="p" :to="'/Collection/' + initBuyInstance.collectionIdx" class="writer">{{initBuyInstance.collectionName}}</router-link> -->
                        <img :src="$store.state.configFront.cdnUrl + 'resource/' + initBuyInstance.fileType + '/' + initBuyInstance.fileName" v-if="$store.state.configFront !== null && initBuyInstance.productInfoVO.pdResourceType === 1"/>
                        <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + initBuyInstance.productInfoVO.pdThumbnailFileName" v-if="$store.state.configFront !== null && initBuyInstance.productInfoVO.pdResourceType === 2"/>
                        <p class="hash">{{ $t('buy.hash') }}</p>
                        <a class="hash num" :href="$store.getters.getCurrentUrlForTx(txReceipt)" target="_blank">{{txReceipt.substr(0, 12) }}......{{ txReceipt.substr(-12)}}</a>
                    </div>
                </div>
                <p class="red" v-if="isShowErrorMinBid">{{ $t('buy.warnMinBid') }}</p>
                <p class="red" v-if="isShowErrorNoCoin">{{ $t('buy.warnNoCoin') }}</p>
                <div class="check" v-if="!isCompleteBuy" :class="{nopadding : isAllowance}">
                    <p class="err-min-bid" v-if="initBuyInstance.saleType === 2">{{ $t('buy.tip') }}</p>
                    <!-- <b-form-checkbox class="review" v-model="checkedAgree1" >{{ $t('buy.checkbox1') }}</b-form-checkbox> -->
                    <b-form-checkbox class="agree" v-model="checkedAgree2"><p v-html="$t('buy.checkbox2')"></p></b-form-checkbox>
                </div>
                <a class="ok" v-if="!isCompleteBuy" @click="purchase">{{ initBuyInstance.saleType === 1 ? $t('buy.button') : $t('buy.button3') }}</a>
                <a class="ok" v-else @click="closePopup">{{ $t('buy.button2') }}</a>
            </div>
            <div class="bottom sign" v-if="isRequestWalletConfirm">
                <span class="spinner"></span>
                <img src="@/assets/images/icon/complete.svg" />
                <p>{{ $t('buy.sign') }}</p>
                <a @click="closePopup" class="need-wallet-sign">{{ $t('buy.button2') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import Caver from "caver-js";
import AuctionContract from "@/assets/json/NftAuction.json";
import MarketContract from "@/assets/json/NftMarket.json";

export default {
    name: "PopupBuy",
    data() {
        return {
            isAllowance: false,
            quantity: '',
            numberQuantity: 0,
            totalPrice: 0,
            bidPrice: '',
            numberBidPrice: 0,

            usdPrice: '',
            numberUsdPrice: 0,

            checkedAgree1: true,
            checkedAgree2: false,

            isRequestWalletConfirm: false,

            isCompleteBuy: false,
            txReceipt: '',

            auctionContract: AuctionContract,
            marketContract: MarketContract,
            auctionAvailableRate: 0,

            isShowErrorMinBid: false,
            isShowErrorNoCoin: false,
            minBidPrice: 0,

            myMaxBidPrice: 0,
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initBuyInstance: {
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    watch:{
        initIsShowPopup(){
            this.resetData();
            if(this.initIsShowPopup && this.initBuyInstance !== null){
                this.checkApprove();
                if(this.initBuyInstance.saleType === 1){
                    if(this.initBuyInstance.itemType === 2){    //단일 아이템의 경우 수량은 1개로 고정
                        this.quantity = "1";
                        this.commaAmount();
                    }
                } else if(this.initBuyInstance.saleType === 2){
                    this.getAvailableRate();
                }
            }
        }
    },
    methods: {
        resetData(){
            this.isAllowance = false;
            this.quantity = ''
            this.numberQuantity = 0;
            this.totalPrice = 0;
            this.bidPrice = '';
            this.numberBidPrice= 0;
            // this.checkedAgree1 = false;
            this.checkedAgree2 = false;
            this.isCompleteBuy = false;
            this.txReceipt = '';
        },
        async checkApprove(){
            // console.log(this.initBuyInstance.saleType);
            if(this.initBuyInstance.priceSymbol === 'KLAY'){
                this.isAllowance = true;
                // this.checkedAgree1 = true;
            } else {
                var currTokenInfo = this.$store.state.configTokenInfo.find((config) => config.symbol === this.initBuyInstance.priceSymbol);
                var tokenContract = currTokenInfo.address;
                var targetAddress = this.initBuyInstance.saleType === 1 ? this.$store.state.configFront.contractJoin : this.$store.state.configFront.contractJoin;
                var walletAddress = this.$store.state.walletAddress;

                this.checkAllowance(tokenContract, targetAddress, walletAddress, (allowance)=>{
                    if(Number(allowance) > 0){
                        this.isAllowance = true;
                        // this.checkedAgree1 = true;
                    } else {
                        this.isAllowance = false;
                    }
                })

            }
        },
        async getAvailableRate(){
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var auctionContractAddress = this.$store.state.configFront.contractJoin;
                var contractInstance = caver.contract.create(this.auctionContract.abi, auctionContractAddress);
                var receipt = await contractInstance.call('availableRate');
                this.auctionAvailableRate = (receipt / 10000);

                if(this.initBuyInstance.bidMaxPrice === null){
                    this.minBidPrice = this.initBuyInstance.priceAmount;
                } else {
                    this.minBidPrice = this.initBuyInstance.bidMaxPrice + (this.initBuyInstance.bidMaxPrice * this.auctionAvailableRate);
                }
                this.minBidPrice = Math.ceil(this.minBidPrice * 10000) / 10000;
                this.bidPrice = this.minBidPrice.toString();
                this.commaBidPrice();
                // console.log(this.auctionAvailableRate);
            }
        },
        async purchase(){
            if(this.isShowErrorMinBid || this.isShowErrorNoCoin){
                return;
            }
            if(this.initBuyInstance.saleType === 2){
                this.reqPlaceBid();
                return;
            }

            if(this.numberQuantity <= 0 || !this.checkedAgree1 || !this.checkedAgree2){
                return;
            }

            if(!this.isAllowance){
                var currTokenInfo = this.$store.state.configTokenInfo.find((config) => config.symbol === this.initBuyInstance.priceSymbol);
                var tokenContract = currTokenInfo.address;
                var marketAddress = this.$store.state.configFront.contractJoin;
                var walletAddress = this.$store.state.walletAddress;

                this.ApproveToken(tokenContract, marketAddress, walletAddress, null,
                    (result)=>{result; this.isAllowance = true; this.purchase()},
                    (err)=>{console.error(err)}
                );
                return;
            }
            
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                instanceId: this.initBuyInstance.instanceId,
                quantity: this.initBuyInstance.itemType === 1 ? this.numberQuantity : 1
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/nft/instance/purchase`, param, this.headers).then(async (res) => {
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.reqMint(res.data.data);
                }
                else {
                    this.$emit('error', res.data.returnCode);
                    // todo: 
                    // 28: Ticket is expired, 로그인 티켓 만료
                    // 18: Invalid request data, 잘못된 요청
                    // 85: date expired, 마감시간 초과
                    // 84: out of remain quantity, 잔여 수량 부족
                    // 101: Internal error
                }
            })
            .catch((error) => {
                console.error(error);
            });
            // // psd 시작이 컨트랙트 호출 파라미터
            // // signResult: 시그니쳐 결과
            // // flagMint: 컨트랙트 호출시 함수명, 파라미터는 동일,   0: buyNftWithMint 1: buyNft
            // {
            // "returnCode": 0,
            // "description": "Success",
            // "extraMessage": null,
            // "data": {
            // "createDate": "2022-05-10 11:38:03",
            // "expireDate": "2022-05-10 23:00:00",        // 기간이후 만료
            // "tradeDate": null,
            // "state": 0,
            // "instanceId": 11,
            // "productId": 2,
            // "flagMint": 0,              // 0: 첫 민팅, 1: 중고
            // "purchaseType": 1,          // 고정가 구매
            // "paymentAmount": 1,         // 지불 코인 수량
            // "paymentSymbol": "ATT",
            // "signResult": "0x01955e17589692cffd5f67d92fa1ae3acc1a03c9092cfc67ef9d13e92b0a293a108358f164e9c13d85a2239d7147f027e4ae5bf3bd5e48c9f28616bc44b06ab91b",
            // psdId: "0000000000000000000000000000000000000000000daa90768a7cf1491fa84f",
            // psdContractAddress: "167406517befac73c3a7f92a4833647b596b37e2",
            // psdContractType: "0000000000000000000000000000000000000000000000000000000000000002",
            // psdItemId: "0000000000000000000000000000000000000000000000000000000000000002",
            // psdAmount: "0000000000000000000000000000000000000000000000000000000000000001",
            // psdAuthor: "1479a9bfa1ee3c33956ea946827a6d9f3a96dc5b",
            // psdSeller: "1479a9bfa1ee3c33956ea946827a6d9f3a96dc5b",
            // psdToken: "bf6a2ac66cbb5182c5a807437c3880ec3fd42a27",
            // psdPrice: "0000000000000000000000000000000000000000000000000de0b6b3a7640000",
            // psdAuthorFee: "00000000000000000000000000000000000000000000000000000000000000c8",
            // psdExpired: "000000000000000000000000000000000000000000000000000000006279d137"
            // }
            // }  
        },
        reqMint(purchaseData){
            //flagMint0: buyNftWithMint 1: buyNft

            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractAddress = this.$store.state.configFront.contractJoin;
                // var contractInstance = caver.contract.create(this.marketContract.abi, contractAddress);
                // var data = contractInstance.methods[purchaseData.flagMint === 0 ? 'buyNftWithMint' : 'buyNft'](
                //     purchaseData.psdId,
                //     purchaseData.psdContractAddress,
                //     purchaseData.psdContractType,
                //     purchaseData.psdItemId,
                //     purchaseData.psdAmount,
                //     purchaseData.psdAuthor,
                //     purchaseData.psdSeller,
                //     purchaseData.psdToken,
                //     purchaseData.psdPrice,
                //     purchaseData.psdAuthorFee,
                //     purchaseData.psdExpired,
                //     purchaseData.signResult,
                // ).encodeABI();
                
                let price = "0x0000000000000000000000000000000000000000000000000000000000000000";
                if(purchaseData.buyNftParam.token === "0x0000000000000000000000000000000000000000"){
                    price = purchaseData.buyNftParam.price;
                }
                const abi = this.marketContract.abi.find((contract) => contract.name === 'buyNft');
                const data = caver.klay.abi.encodeFunctionCall(abi,
                    [
                        caver.utils.toBN(purchaseData.psdId),
                        [
                            purchaseData.buyNftParam.contractAddress,
                            purchaseData.buyNftParam.contractType,
                            purchaseData.buyNftParam.itemId,
                            purchaseData.buyNftParam.amount,
                            purchaseData.buyNftParam.author,
                            purchaseData.buyNftParam.seller,
                            purchaseData.buyNftParam.token,
                            purchaseData.buyNftParam.price,
                            purchaseData.buyNftParam.authorFee,
                            purchaseData.buyNftParam.mintAmount,
                            // Number(purchaseData.auc.is_minting)
                        ],
                        caver.utils.toBN(purchaseData.psdExpired),
                        purchaseData.signResult,
                    ]
                )
                // console.log(data);
                
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: contractAddress,
                    value: price,
                    gas: '300000',
                    data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    // receipt;
                    this.isCompleteBuy = true;
                    this.txReceipt = receipt.transactionHash;
                    // console.log('receipt');
                    // console.log(receipt);
                })
                .on('error', (error) =>{
                    console.error('error : ' + error);
                });
            }
        },
        reqPlaceBid(){

            if(this.numberBidPrice < this.minBidPrice || !this.checkedAgree1 || !this.checkedAgree2){
                return;
            }

            var auctionAddress = this.$store.state.configFront.contractJoin;
            var walletAddress = this.$store.state.walletAddress;

            if(!this.isAllowance){
                var currTokenInfo = this.$store.state.configTokenInfo.find((config) => config.symbol === this.initBuyInstance.priceSymbol);
                var tokenContract = currTokenInfo.address;

                this.ApproveToken(tokenContract, auctionAddress, walletAddress, null,
                    (result)=>{result; this.isAllowance = true; this.reqPlaceBid()},
                    (err)=>{console.error(err)}
                );
                return;
            }

            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractInstance = caver.contract.create(this.auctionContract.abi, auctionAddress);
                var data = contractInstance.methods.placeBid(caver.utils.toBN(this.initBuyInstance.auctionId), caver.utils.toPeb(this.numberBidPrice.toString(), 'KLAY')).encodeABI();
                let price = "0x0000000000000000000000000000000000000000000000000000000000000000";
                if(this.initBuyInstance.priceSymbol === 'KLAY'){
                    price = caver.utils.toPeb(this.numberBidPrice.toString(), 'KLAY')
                }
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: walletAddress,
                    to: auctionAddress,
                    value: price,
                    gas: '300000',
                    data: data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    this.$emit("completePlaceBid");
                    // console.log(receipt);
                })
                .on('error', (error) =>{
                    console.log('error : ' + error);
                });
            }
        },
        commaAmount() {
            this.isShowErrorMinBid = false;
            this.isShowErrorNoCoin = false;

            var x = "";
            var max;
            x = this.quantity.toString();
            max = this.initBuyInstance.remainCount;

            x = this.numberOnlyWithMax(x, max);
            
            this.numberQuantity = Number(x);
            this.quantity = this.setComma(x.toString());
            this.totalPrice = (Math.floor(this.numberQuantity * (this.initBuyInstance.priceAmount * 10000))) / 10000;

            var myBalance = this.$store.getters.getMyBalance(this.initBuyInstance.priceSymbol);

            if(myBalance < this.totalPrice){
                this.isShowErrorNoCoin = true;
            } else {
                this.isShowErrorNoCoin = false;
            }
        },
        commaBidPrice() {
            this.isShowErrorMinBid = false;
            this.isShowErrorNoCoin = false;
            var x = "";
            var max;
            x = this.bidPrice.toString();
            max = this.$store.getters.getMyBalance(this.initBuyInstance.priceSymbol);
            // console.log(max);

            x = this.numberOnlyWithMax(x, max);
            
            this.numberBidPrice = Number(x);
            this.bidPrice = this.setComma(x.toString());

            this.numberUsdPrice = this.$store.getters.getTokenUsdPrice(this.initBuyInstance.priceSymbol, this.numberBidPrice, 4);
            this.usdPrice = this.setComma(this.numberUsdPrice.toString());
            this.totalPrice = this.numberBidPrice;

            if(this.numberBidPrice < this.minBidPrice) {
                this.isShowErrorMinBid = true;
            }
            if(this.minBidPrice > max){
                this.isShowErrorNoCoin = true;
            }
            // 차액 전송일 경우
            // this.totalPrice = ((this.numberBidPrice * 10000) - (this.myMaxBidPrice * 10000)) / 10000 ;
            // if(this.totalPrice < 0){
            //     this.totalPrice = 0;
            // }

        },
        commaUsdPrice(){
            this.isShowErrorMinBid = false;
            this.isShowErrorNoCoin = false;

            var x = "";
            var max;
            var myMaxTokenBalance = this.$store.getters.getMyBalance(this.initBuyInstance.priceSymbol);
            x = this.usdPrice.toString();
            max = this.$store.getters.getTokenUsdPrice(this.initBuyInstance.priceSymbol, myMaxTokenBalance, 4);
            // console.log(max);

            x = this.numberOnlyWithMax(x, max);
            
            this.numberUsdPrice = Number(x);
            this.usdPrice = this.setComma(x.toString());

            this.numberBidPrice = this.$store.getters.getTokenAmount(this.initBuyInstance.priceSymbol, this.numberUsdPrice, 4);
            this.bidPrice = this.setComma(this.numberBidPrice.toString());
            this.totalPrice = this.numberBidPrice;

            if(this.numberBidPrice < this.minBidPrice) {
                this.isShowErrorMinBid = true;
            }
            if(this.minBidPrice > max){
                this.isShowErrorNoCoin = true;
            }

            // if(this.initBuyInstance.bidMaxPrice === null){
            //     return;
            // } else if(this.numberBidPrice < this.minBidPrice) {
            //     this.isShowErrorMinBid = true;
            // }
        },
        closePopup() {
            if(this.isCompleteBuy){
                this.$emit("completeBuy");
            }
            else {
                this.$emit("close");
            }
        },
    },
};
</script>
