<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box">
            <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
            <p v-html="initPopupTitle" class="popup-title"></p>
            <p v-html="initPopupMessage" class="popup-cont"></p>
            <a class="ok" @click="closePopup">{{ $t('mailsent.ok') }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupOk",
        data() {
            return {};
        },
        props: {
            initIsShowPopup: {
                type: Boolean,
                default: false,
            },
            initPopupTitle: {
                type: String,
                default: "",
            },
            initPopupMessage: {
                type: String,
                default: "",
            },
        },
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {
            closePopup() {
                this.$emit("close");
            },
        },
    };
</script>
