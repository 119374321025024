<template>
    <div class="popup" v-if="initIsShowPopup && initProductDetail !== null">
        <div class="popup-box padding buy offer-cancel">
            <div class="top">
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
                <p class="popup-title">{{ $t('offerCancel.title') }}</p>
            </div>
            <div class="bottom">
                <div class="title-box">
                    <p class="title">{{ $t('buy.item') }}</p>
                    <p class="title subtotal">{{ $t('buy.subtotal') }}</p>
                </div>

                <div class="product">
                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + initProductDetail.productInfo.filePath" v-if="$store.state.configFront !== null && initProductDetail.productInfo.resourceType === 1"/>
                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + initProductDetail.productInfo.thumbnailFileName" v-if="$store.state.configFront !== null && initProductDetail.productInfo.resourceType === 2"/>
                    
                    <div class="basic-info">
                        <p class="amount" v-if="initProductDetail.productInfo.itemType === 1">{{ $t('buy.amount') }} x {{initProductDetail.productInfo.totalItemCount}}</p>
                        <router-link tag="p" :to="'/Collection/' + initProductDetail.productInfo.collectionIdx" class="writer">{{initProductDetail.productInfo.collectionName}}</router-link>
                        <p class="name">{{initProductDetail.productInfo.name}}</p>
                    </div>
                    <div class="price">
                        <p v-if="initProductDetail.productInfo.itemType === 1" class="top-text">{{ $t('buy.per-piece') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initCancelOfferInfo.offerSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initCancelOfferInfo.offerSymbol === 'KLAY'" />
                            <p>{{initCancelOfferInfo.offerPricePerOne | comma}}</p>
                            <span>(${{$store.getters.getTokenUsdPrice(initCancelOfferInfo.offerSymbol, initCancelOfferInfo.offerPricePerOne, 4)}})</span>
                        </div>
                    </div>
                </div>

                <div class="total" v-if="initProductDetail.productInfo.itemType === 1">
                    <div>
                        <p class="title">{{ $t('offerCancel.sum') }}</p>
                    </div>
                    <p class="price">
                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initCancelOfferInfo.offerSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initCancelOfferInfo.offerSymbol === 'KLAY'" />
                        {{totalAmount | comma}}
                        <span>(${{$store.getters.getTokenUsdPrice(initCancelOfferInfo.offerSymbol, totalAmount, 4) | comma}})</span>
                    </p>
                </div>

                <div class="buy-piece" v-if="initProductDetail.productInfo.itemType === 1">
                    <p class="title">{{ $t('offerCancel.count') }}</p>
                    <input class="form-control" v-model="amount" @input="commaAmount" />
                    <p>{{ $t('offerCancel.offered') }} : {{initCancelOfferInfo.remainAmount}} </p>
                </div>

                <div class="check">
                    <p>{{ $t('offerOk.ps') }}</p>
                    <p>{{ $t('offerCancel.ps') }}</p>
                </div>
                <a class="ok" @click="reqCancelOffer">{{$t('buy.button2')}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import Caver from "caver-js";
import OfferContract from "@/assets/json/NftOffer.json";

export default {
    name: "PopupCancel",
    data() {
        return {
            offerContract: OfferContract,

            amount: '',
            numberAmount: 0,

            totalAmount: 0,
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initProductDetail: {
            type: Object,
            default: null
        },
        initCancelOfferInfo: {
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    watch:{
        initIsShowPopup(){
            if(this.initIsShowPopup){
                if(this.initProductDetail.productInfo.itemType === 2){    //단일 아이템의 경우 수량은 1개로 고정
                    this.amount = "1";
                    this.numberAmount = 1;
                } else {
                    this.amount = '';
                    this.numberAmount = 0;
                }
                this.commaAmount();
            }
        }
    },
    methods: {
        closePopup() {
            this.$emit("close");
        },
        reqCancelOffer(){
            if(this.numberAmount <= 0){
                return;
            }
            if(typeof window.klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractAddress = this.$store.state.configFront.contractJoin;
                var contractInstance = caver.contract.create(this.offerContract.abi, contractAddress);

                var data = contractInstance.methods.cancelOffer(this.initCancelOfferInfo.osdId, caver.utils.toBN(this.numberAmount)).encodeABI();
                // console.log(data);

                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: contractAddress,
                    gas: '300000',
                    data: data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    // console.log(receipt);
                    this.$emit('completeOfferCancel')
                })
                .on('error', (error) =>{
                    console.error('error : ' + error);
                });
            }
        },
        commaAmount() {
            this.isShowErrorNoCoin = false;

            var x = "";
            var max;
            x = this.amount.toString();
            max = this.initCancelOfferInfo.remainAmount;
            x = this.numberOnlyWithMax(x, max, true);
            
            this.numberAmount = Number(x);
            this.amount = this.setComma(x.toString());
            this.totalAmount = (Math.floor(this.numberAmount * (this.initCancelOfferInfo.offerPricePerOne * 10000))) / 10000;

            var myBalance = this.$store.getters.getMyBalance(this.initCancelOfferInfo.offerSymbol);

            if(myBalance < this.totalAmount){
                this.isShowErrorNoCoin = true;
            }
        },
    },
};
</script>
