<template>
    <div >
        <Header :initHeaderType="initHeaderType" />
        <div class="balhang-big-box">
            <b-container class="balhang-box second">
                <div class="balhang-inner">
                    <div class="balhang-inner-method" v-if="productDetail !== null && productDetail.productInfo.itemType === 2">
                        <h3>{{ $t('balhang2.title1') }}</h3>
                        <ul>
                            <li :class="{'on' : selectedTab === 1}" @click="changeTab(1)" class="fix-sale">
                                <img src="@/assets/images/icon/fix-sale.svg" />
                                <h4>{{ $t('balhang2.menu1Title') }}</h4>
                                <p>{{ $t('balhang2.menu1Cont') }}</p>
                            </li>
                            <li :class="{'on' : selectedTab === 2}" @click="changeTab(2)" class="auction">
                                <img src="@/assets/images/icon/auction.svg" />
                                <h4>{{ $t('balhang2.menu2Title') }}</h4>
                                <p>{{ $t('balhang2.menu2Cont') }}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="balhang-inner-method sell" v-if="selectedTab === 1 && productDetail !== null && productDetail.productInfo.itemType === 1">
                        <div class="col-7">
                            <h3>{{ $t('balhang2.sell') }}</h3>
                            <p>{{ $t('balhang2.sell2') }}</p>
                            <p class="tip">{{ $t('balhang2.sell3') }}</p>
                        </div>
                        <div class="col-5 piece">
                            <input class="form-control" v-model="amount" @input="commaAmount('amount')" />
                            <p class="have" v-if="productDetail !== null && $store.state.configFront !== null">{{ $t('balhang2.sell4') }} : {{productDetail.productInfo.itemCountMine - productDetail.productInfo.itemCountOnSaleMine - productDetail.productInfo.itemCountReservedMine | comma}}개</p>
                        </div>
                    </div>
                    
                    <section class="balhang-sec fixed-price">
                        <div class="balhang-sec1-inner row" v-show="selectedTab === 2">
                            <div>
                                <h3>{{ $t('balhang2.auctionSelect') }}</h3>
                                <div class="selected">
                                    <p class="big">{{ $t('balhang2.auctionSelect2') }}</p>
                                    <p class="op8">{{ $t('balhang2.auctionSelect3') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="balhang-sec1-inner row auction">
                            <div class="col-8">
                                <h3 v-if="selectedTab === 1">{{ $t('balhang2.title2') }}</h3>
                                <h3 v-if="selectedTab === 2">{{ $t('balhang2.title2Aution') }}</h3>
                                <p class="op8" v-show="selectedTab === 1" v-html="$t('balhang2.title2Cont')"></p>
                                <p class="op8" v-show="selectedTab === 2" v-html="$t('balhang2.title2Cont2')" ></p>
                            </div>
                            <div class="col-4 balhang-df">
                                <div class="input-group">
                                    <b-dropdown>
                                        <template v-slot:button-content v-if="selectedSymbol === 0"><img src="@/assets/images/icon/icon_symbol_artube.png" /> ATT</template>
                                        <template v-slot:button-content v-else-if="selectedSymbol === 1"><img src="@/assets/images/icon/icon_symbol_klaytn.svg" /> KLAY</template>
                                        <b-dropdown-item @click="selectedSymbol = 0"><img src="@/assets/images/icon/icon_symbol_artube.png" /> ATT</b-dropdown-item>
                                        <b-dropdown-item @click="selectedSymbol = 1"><img src="@/assets/images/icon/icon_symbol_klaytn.svg" /> KLAY</b-dropdown-item>
                                    </b-dropdown>
                                    <input class="form-control" v-model="price" @input="commaAmount('price')" />
                                </div>
                            </div>
                            <p class="have">(${{$store.getters.getTokenUsdPrice(selectedSymbol === 0 ? 'ATT' : selectedSymbol === 1 ? 'KLAY' : null, numberPrice, 4) | comma}})</p>
                        </div>
                        <div class="balhang-sec1-inner row">
                            <div class="col-7">
                                <h3>{{ $t('balhang2.title3') }}</h3>
                                <p class="op8">{{ $t('balhang2.title3Cont') }}</p>
                            </div>
                            <div class="col-5 balhang-df calendar-btn" @click="isShowCalendar = !isShowCalendar">
                                <img src="@/assets/images/icon/calendar.svg" />
                                <p v-if="saleStartDate !== '' && saleEndDate !==  ''">{{saleStartDate}} ~ {{saleEndDate}}</p>
                            </div>
                            <!-- <div class="col-5 balhang-df calendar-btn">
                                <img src="@/assets/images/icon/calendar.svg" />
                                <input v-model="expireMinute" @input="commaAmount('expireMinute')" />
                            </div> -->
                        </div>
                        
                        <!-- <div class="balhang-sec1-inner row last"  v-show="selectedTab === 1">
                            <div class="col-11">
                                <h3>{{ $t('balhang2.title4') }}</h3>
                                <p>{{ $t('balhang2.title4Cont') }}</p>
                            </div>
                            <div class="col-1 balhang-df">
                                <div class="balhang-inner-toggle">
                                    <div class="switch-cover"><input type="checkbox" id="switch" v-model="isCheckedUnlockedContent" /><label for="switch">Toggle</label></div>
                                </div>
                            </div>
                            <b-input placeholder="구매자 지갑 주소 / 예시 ) 0x8df35..."></b-input>
                        </div> -->
                        <div class="precautions"  v-show="selectedTab === 2">
                            <ul>
                                <li>{{ $t('balhang2.precautions') }}</li>
                                <li>{{ $t('balhang2.precautions2') }}</li>
                                <li>{{ $t('balhang2.precautions3') }}</li>
                            </ul>
                        </div>
                    </section>
                </div>
            </b-container>
            <div class="balhang-box balhang-right second">
                <div class="balhang-right-top">
                    <h3>{{ $t('balhang2.rightTab0Text1') }}</h3>
                    <div v-show="selectedTab === 1">
                    </div>
                </div>
                <div class="balhang-right-bottom">
                    <ul class="first-area">
                        <li>
                            <p v-if="numberPrice > 0"><span>{{numberPrice | comma}} {{selectedSymbol === 0 ? 'ATT' : 'KLAY'}}</span> {{ $t('balhang2.salePrice') }}</p>
                        </li>
                    </ul>
                    <ul>
                        <p class="commission">{{ $t('balhang2.rightBottomT') }}</p>
                        <li v-if="$store.state.configFront !== null">
                            <p>{{ $t('balhang2.rightBottomT1') }}</p>
                            <p>{{$store.state.configFront.platformFee}}%</p>
                        </li>
                        <li v-if="productDetail !== null">
                            <p>{{ $t('balhang2.rightBottomT2') }}</p>
                            <p>{{productDetail.royalty}}%</p>
                        </li>
                    </ul>
                </div>
                <div class="balhang-right-btn">
                    <p @click="checkApprove()">{{ $t('balhang2.rightBottomBtn') }}</p>
                </div>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupCalendar :initIsShowPopup="isShowCalendar" @close="close('PopupCalendar')" @applyDate="applyDate" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue'
import PopupCalendar from '@/components/popup/PopupCalendar.vue'
import Caver from "caver-js";
import AuctionContract from "@/assets/json/NftAuction.json";

export default {
    name:'NftSell',
    krName: '아이템 판매',
    components: {
        Header,
        PopupOK,
        PopupCalendar
    },
    data() {
        return {
            initHeaderType: 'type2',

            productId: null,
            productDetail: null,

            selectedTab: 1,     // 1: 고정가 판매, 2: 경매
            selectedSymbol: 0,
            amount: '',
            numberAmount: 0,
            price: '',
            numberPrice: 0,
            
            saleStartDate: '',
            saleEndDate: '',
            expireMinute: '',
            numberExpireMinute: 0,
            
            isToggleBtn:true,
            isToggleBtn2:true,
            isToggleBtn3:true,
            isToggleBtn4:true,

            isAlertOpen :"",

            isWorkingRegist: false,

            isShowPopupOk: false,
            isShowCalendar: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isCheckedUnlockedContent: false,
            quantity: '',

            auctionContract: AuctionContract,
        }},
    created(){
        if(this.$route.params.productId){
            this.productId = this.$route.params.productId;
            this.getNftDetail();
            // this.getNftInfo();
        } else {
            this.$router.push("/");
        }
    },
    methods:{
        toggleBtn(){
            this.isToggleBtn =!this.isToggleBtn
            // console.log(1);
        },
        toggleBtn2(){
            this.isToggleBtn2 =!this.isToggleBtn2
            // console.log(1);
        },
        toggleBtn3(){
            this.isToggleBtn3 =!this.isToggleBtn3
            // console.log(1);
        },
        toggleBtn4(){
            this.isToggleBtn4 =!this.isToggleBtn4
            // console.log(1);
        },
        changeTab(tabNo){
            if(tabNo === 2 && this.productDetail.productInfo.itemType !== 2){    //경매 판매를 선택한 경우, 조각 아이템이면 탭 변경 불가,
                return;
            }
            this.selectedTab = tabNo;
        },
        alertOpen(alertNo){
            this.isAlertOpen = alertNo;
        },
        alertClose(){
            this.isAlertOpen = 2;
        },
        getNftDetail(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: this.productId
            }
            this.$Axios.post(`${this.host}/nft/detail`, param, this.headers).then(async (res) => {
                // console.log('---------- nft detail ------------');
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.productDetail = res.data.data;
                    if(this.productDetail.productInfo.itemType == 2){
                        this.numberAmount = 1;
                    }
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        checkApprove(){
            if(this.selectedTab === 2){
                this.reqNftSale();
                return;
            }
            var NFTContract = this.$store.state.configFront.contract1155;
            var marketAddress = this.$store.state.configFront.contractJoin;
            var walletAddress = this.$store.state.walletAddress;

            this.isApprovedForAll(NFTContract, marketAddress, walletAddress, (approved)=>{
                if(approved) {
                    this.reqNftSale();
                } else {
                    this.setApprovalForAll(NFTContract, marketAddress, walletAddress, null,
                        (result)=>{result; this.reqNftSale()},
                        (err)=>{console.log(err)}
                    );
                }
            });
        },
        async checkAuctionApprove(auctionData){
            if(this.selectedSymbol === 'KLAY'){
                this.reqAuctionRegist(auctionData);
            } else {
                var NFTContract = this.$store.state.configFront.contract1155;
                var auctionAddress = this.$store.state.configFront.contractJoin;
                var walletAddress = this.$store.state.walletAddress;

                this.isApprovedForAll(NFTContract, auctionAddress, walletAddress, (approved)=>{
                    if(approved) {
                        this.reqAuctionRegist(auctionData);
                    } else {
                        this.setApprovalForAll(NFTContract, auctionAddress, walletAddress, null,
                            (result)=>{result; this.reqAuctionRegist(auctionData);},
                            (err)=>{console.log(err)}
                        );
                    }
                });
            }
        },
        reqNftSale(){
            if(this.isWorkingRegist){
                this.initPopupOkMessage = '판매 등록 중입니다. 잠시만 기다려 주세요.';
                this.isShowPopupOk = true;
                return;
            }

            if(this.selectedTab === 1 && this.numberAmount <= 0){
                this.initPopupOkMessage = '판매 수량을 입력해 주세요';
                this.isShowPopupOk = true;
                return;
            }

            if(this.numberPrice <= 0){
                this.initPopupOkMessage = '판매 가격을 입력해 주세요';
                this.isShowPopupOk = true;
                return;
            }

            if(this.numberExpireMinute <= 0){
                this.initPopupOkMessage = '판매 기간을 입력해 주세요';
                this.isShowPopupOk = true;
                return;
            }

            this.isWorkingRegist = true;
            
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: this.productId,
                saleQuantity: this.selectedTab === 1 ? this.numberAmount : 1,
                saleType: this.selectedTab, // 1: 고정가, 2: 경매
                saleSymbol: this.selectedSymbol === 0 ? "ATT" : "KLAY",
                salePrice: this.numberPrice,
                expireMinute: this.numberExpireMinute,
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/nft/all/sale`, param, this.headers).then(async (res) => {
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    if(this.selectedTab === 2){
                        this.checkAuctionApprove(res.data.data);
                        // if(res.data.data.auc.is_minting === '00'){
                        //     this.checkAuctionApprove(res.data.data);
                        // } else {
                        //     this.reqAuctionRegist(res.data.data);
                        // }
                    } else {
                        this.initPopupOkMessage = '판매 등록 완료';
                        this.PopupOkCallbakPage = '/NftInfo/' + this.productId
                        this.isShowPopupOk = true;
                    }
                    // this.productDetail = res.data.data;
                } else {
                    this.error(res.data.returnCode);
                    this.isWorkingRegist = false;
                }
            })
            .catch((error) => {
                this.isWorkingRegist = false;
                this.error(error);
            });
        },
        
        // auc: [
        //    amount: "0x0000000000000000000000000000000000000000000000000000000000000001"
        //    auction_end: "0x0000000000000000000000000000000000000000000000000000000062b30d5c"
        //    author: "0x0f50fbf6ad053058c1f5bc77d1ccbe255c9eb0ce"
        //    authorFee: "0x0000000000000000000000000000000000000000000000000000000000000258"
        //    contractAddress: "0x167406517befac73c3a7f92a4833647b596b37e2"
        //    contractType: "0x0000000000000000000000000000000000000000000000000000000000000002"
        //    is_minting: "00"
        //    itemId: "0x000000000000000000000000000000000000000000000000000000000000008e"
        //    seller: "0x0f50fbf6ad053058c1f5bc77d1ccbe255c9eb0ce"
        //    start_price: "0x0000000000000000000000000000000000000000000000000de0b6b3a7640000"
        //    token: "0xbf6a2ac66cbb5182c5a807437c3880ec3fd42a27"
        // ]
        // bidType: 0
        // bsdExpired: "0x0000000000000000000000000000000000000000000000000000000062b1bd08"
        // bsdId: "0x000000000000000000000000000000000000000000000000000000000000016a"
        // contractIdx: 2
        // createDate: "2022-06-21 21:38:52"
        // expireDate: "2022-06-21 21:43:52"
        // flagMint: 0
        // instanceIdx: 362
        // memberIdx: 38
        // paymentAmount: 1
        // paymentSymbol: "ATT"
        // price: null
        // productIdx: 142
        // signResult: "0xc0e0d1a40c865906550a447181b5516756ec4e51b9c0478b6f2b02e2f2466aa15fc4197272e252b42c23d09c7e7703459d000130159d08d82d0deff6b7cdc1bc1c"
        // state: 0
        // tradeDate: null
        async reqAuctionRegist(auctionData){
             var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);

                var targetContract = this.$store.state.configFront.contractJoin;
                var walletAddress = this.$store.state.walletAddress;

                const abi = this.auctionContract.abi.find((contract) => contract.name === 'registerAuction');
                // console.log(abi)
                const data = caver.klay.abi.encodeFunctionCall(abi
                    ,
                    [
                        caver.utils.toBN(auctionData.bsdId),
                        [
                            auctionData.auc.contractAddress,
                            auctionData.auc.contractType,
                            auctionData.auc.itemId,
                            auctionData.auc.amount,
                            auctionData.auc.author,
                            auctionData.auc.authorFee,
                            auctionData.auc.seller,
                            auctionData.auc.token,
                            auctionData.auc.start_price,
                            auctionData.auc.auction_end,
                            Number(auctionData.auc.is_minting)
                        ],
                        caver.utils.toBN(auctionData.bsdExpired),
                        auctionData.signResult,
                    ]
                )
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: walletAddress,
                    to: targetContract,
                    gas: '400000',
                    data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    // console.log(receipt);
                    this.initPopupOkMessage = '판매 등록 완료';
                    this.PopupOkCallbakPage = '/NftInfo/' + this.productId
                    this.isShowPopupOk = true;
                })
                .on('error', (error) =>{
                    console.log('error : ' + error);
                });
            }
        },
        commaAmount(type) {
            var x = "";
            var max;
            switch(type){
                case 'amount':
                    x = this.amount.toString();
                    max = this.productDetail.productInfo.itemCountMine;
                    x = this.numberOnlyWithMax(x, max, true);
                    this.numberAmount = Number(x);
                    this.amount = this.setComma(x);
                    break;
                case 'price':
                    x = this.price.toString();
                    x = this.numberOnly(x, true);
                    
                    // if(this.selectedSymbol === 0 ){ //ATT
                    //     x = this.numberOnly(x, false, 3);
                    // } else if (this.selectedSymbol === 1){  //KLAY
                    //     x = this.numberOnly(x, false, 3);
                    // }
                    
                    this.numberPrice = Number(x);
                    this.price = this.setComma(x);
                    break;
                // case 'expireDay':
                //     x = this.expireDay.toString();
                //     x = this.numberOnlyWithMax(x, 180, true);
                //     this.numberExpireDay = Number(x);
                //     this.expireDay = this.setComma(x);
                //     this.numberExpireMinute = this.numberExpireDay * 1440;

                //     this.expireDate = this.getAddDateString(this.numberExpireMinute, 'minute', 'YYYY-MM-DD')
                //     break;
                case 'expireMinute':
                    x = this.expireMinute.toString();
                    x = this.numberOnly(x, true);
                    this.numberExpireMinute = Number(x);
                    this.expireMinute = this.setComma(x);
                    break;
            }
        },
        applyDate(startDate, endDate){
            this.saleStartDate = this.getLocalizedDayString(startDate);
            this.saleEndDate = this.getLocalizedDayString(endDate);
            const diffDate = startDate.getTime() - endDate.getTime();
            this.numberExpireMinute = Math.ceil(Math.abs(diffDate / (1000 * 60)));
            this.isShowCalendar = false;
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'PopupCalendar':
                    this.isShowCalendar = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
}
</script>
