export default [
    {
        locale: 'en',
        message_list : [
            {id:   0, message: "Success"},
            {id:   1, message: "Partially failed"},
            {id:   2, message: "Out of Authority"},
            {id:   3, message: "Bad request"},
            {id:   4, message: "Password does not match"},
            {id:   5, message: "Field validation failed"},
            {id:   6, message: "Required fields missing"},
            {id:   7, message: "IP is not authorized"},
            {id:   8, message: "Data not found"},
            {id:   9, message: "Data update failed"},
            {id:  10, message: "Data already exists"},
            {id:  11, message: "Request already processed"},
            {id:  12, message: "Request already rejected"},
            {id:  13, message: "Prior request exists"},
            {id:  14, message: "Invalid config"},
            {id:  15, message: "IP not authorized"},
            {id:  16, message: "Invalid time format required : UTC, millisecond, long"},
            {id:  17, message: "Invalid date range"},
            {id:  18, message: "Invalid request data"},
            {id:  19, message: "Invalid sql request"},
            {id:  20, message: "Prior state is wrong"},
            {id:  21, message: "Request timeout"},
            {id:  22, message: "Related data exist"},
            {id:  23, message: "Data not ready"},
            {id:  24, message: "password is not match"},
            {id:  25, message: "Too many wrong request Account is blocked in 3 minute"},
            {id:  26, message: "Cannot request this time"},
            {id:  27, message: "Ticket not exists"},
            {id:  28, message: "Ticket is expired"},
            {id:  29, message: "Ticket validation failed"},
            {id:  30, message: "Ticket generation failed"},
            {id:  41, message: "Account not found"},
            {id:  42, message: "Account already exist"},
            {id:  43, message: "Account is blocked"},
            {id:  44, message: "Account is deleted"},
            {id:  45, message: "Account balance is not sufficient"},
            {id:  46, message: "중복 가입은 제한되어 있습니다"},
            {id:  47, message: "만19세 미만은 가입할 수 없습니다"},
            {id:  48, message: "Account balance is not sufficient"},
            {id:  63, message: "Withdrawal is locked on this token"},
            {id:  64, message: "Failed to get token price"},
            {id:  65, message: "Cannot transfer to self"},
            {id:  66, message: "Under min withdraw amount"},
            {id:  81, message: "upload file not found"},
            {id:  82, message: "not on sale"},
            {id:  83, message: "sold out"},
            {id:  84, message: "out of remain quantity"},
            {id:  85, message: "date expired"},
            {id: 101, message: "Internal error"},
            {id: 102, message: "Service Maintenance"},
            {id: 103, message: "System is busy, try again after seconds"},
            {id: 104, message: "Not implemented"},
            {id: 105, message: "Config disabled"},
            {id: 106, message: "Disabled for scheduling task"},
            {id: 107, message: "시스템 업데이트중 3분정도 뒤에 다시 시도하세요"},
            {id: 108, message: "Api is restricted for a moment"},
            {id: 111, message: "Too many certification request"},
            {id: 112, message: "Sent by SMS"},
            {id: 113, message: "SMS service error"},
            {id: 114, message: "Certification wrong"},
            {id: 115, message: "Certification match"},
            {id: 116, message: "SMS is not supported on your region"},
            {id: 117, message: "Certification service is limited temporary"},
            {id: 118, message: "Failed to send email"},
            {id: 121, message: "Password length is short"},
            {id: 122, message: "Temporary password is sent by sms"},
            {id: 131, message: "ERC20 address not set"},
            {id: 132, message: "Token type not match"},
            {id: 133, message: "ERC20 API error"},
            {id: 134, message: "ERC20 Gas API response error"},
            {id: 201, message: "이미 등록된 연락처입니다"},
            {id: 202, message: "Phone info is not registered"},
            {id: 203, message: "Phone info is registered already"},
            {id: 204, message: "Phone info is registered now"},
            {id: 221, message: "이미 등록된 연락처입니다"},
            {id: 222, message: "Email info is not registered"},
            {id: 223, message: "Email info is registered already"},
            {id: 224, message: "Email info is registered now"},
            {id: 225, message: "Email already request"},
            {id: 226, message: "address already request"},
            {id: 241, message: "OTP 번호가 일치하지 않습니다"},
            {id: 242, message: "인증번호가 일치하지 않습니다"},
            {id: 243, message: "문자 인증번호가 일치하지 않습니다"},
            {id: 250, message: "컬렉션 오너가 아닙니다"},
            {id: 251, message: "컬렉션 아이템 판매기록이 있습니다"},
            {id: 252, message: "로열티가 잘못 설정 되었습니다"},
            {id: 253, message: "동일한 컬렉션 이름이 존재합니다"},
            {id: 254, message: "존재하지 않는 컬렉션 입니다"},
            {id: 255, message: "존재하지 않는 멤버 입니다"},
            {id: 256, message: "존재하지 않는 인스턴스 입니다"},
            {id: 257, message: "존재하지 않는 큐레이션 입니다"},
            {id: 258, message: "존재하지 않는 프로덕트 입니다"},
            {id: 259, message: "멤버 디테일이 존재하지 않습니다"},
            {id: 260, message: "존재하지 않는 컬렉션 디테일 입니다"},
            {id: 261, message: "존재하지 않는 신고 카테고리 입니다"},
            {id: 262, message: "좋아요 하지 않은 아이템 입니다"},
            {id: 263, message: "이미 좋아요 한 아이템 입니다"},
            {id: 264, message: "트랜잭션이 존재하지 않습니다"},
            {id: 265, message: "타입이 존재하지 않습니다"},
            {id: 266, message: "프로덕트 인덱스를 입력하지 않았습니다"},
            {id: 267, message: "컬렉션 인덱스를 입력하지 않았습니다"},
            {id: 268, message: "멤버 인덱스를 입력하지 않았습니다"},
            {id: 269, message: "존재하지 않는 프로덕트 디테일 입니다"},
            {id: 270, message: "판매중인 인스턴스가 없습니다"},
            {id: 271, message: "거래 대기중인 인스턴스 입니다"},
            {id: 273, message: "판매 중인 상품입니다"},
            {id: 274, message: "프로덕트 오너가 아닙니다."},
            {id: 275, message: "경매는 1개인 아이템만 가능합니다."},
            {id: 276, message: "5%이상의 금액으로만 입찰할수있습니다."},
            {id: 277, message: "판매 등록 중입니다."},
            {id: 278, message: "카테고리가 다릅니다."},
            {id: 279, message: "존재하지 않는 오퍼 입니다."},
            {id: 280, message: "경매중인 아이템이 있습니다."},
            {id: 281, message: "오퍼한 사람입니다."},
            {id: 282, message: "이미 민트된 아이템입니다."},
            {id: 283, message: "컬렉션에 아이템이 존재합니다."},

            {id: 901, message: "Http trouble"},
            {id: 999, message: "Error code not defined"},
        ]
    },
    {
        locale: 'ko',
        message_list : [
            {id:   0, message: "성공"},
            {id:   1, message: "일부 실패 내역을 확인하세요"},
            {id:   2, message: "권한이 없습니다"},
            {id:   3, message: "잘못된 요청입니다"},
            {id:   4, message: "비밀번호가 일치하지 않습니다"},
            {id:   5, message: "유효성 검사에 실패하였습니다"},
            {id:   6, message: "필수 파라미터가 누락되었습니다"},
            {id:   7, message: "허용되지 않은 IP 입니다"},
            {id:   8, message: "데이터가 없습니다"},
            {id:   9, message: "데이터 업데이트가 실패했습니다 "},
            {id:  10, message: "기존 데이터가 있습니다"},
            {id:  11, message: "이미 성공한 내역입니다"},
            {id:  12, message: "이미 거절된 내역입니다"},
            {id:  13, message: "기존 요청이 있습니다"},
            {id:  14, message: "잘못된 설정입니다"},
            {id:  15, message: "잘못된 IP 입니다"},
            {id:  16, message: "잘못된 날짜 형식입니다. 필요: UTC, millisecond, long"},
            {id:  17, message: "잘못된 날짜 범위입니다"},
            {id:  18, message: "잘못된 요청내역이 있습니다"},
            {id:  19, message: "잘못된 SQL 요청이 있습니다"},
            {id:  20, message: "기존 상태가 잘못되었습니다"},
            {id:  21, message: "요청 타임아웃"},
            {id:  22, message: "관련된 데이터가 있습니다"},
            {id:  23, message: "준비된 데이터가 아닙니다"},
            {id:  24, message: "비밀번호가 일치하지 않습니다"},
            {id:  25, message: "잘못된 요청으로 계정이 5분간 제한됩니다"},
            {id:  26, message: "잠시후 다시 시도해주세요"},
            {id:  27, message: "티켓이 없습니다"},
            {id:  28, message: "티켓이 만료되었습니다"},
            {id:  29, message: "티켓 검증에 실패하였습니다"},
            {id:  30, message: "티켓 생성에 실패했습니다"},
            {id:  41, message: "계정을 찾을 수 없습니다"},
            {id:  42, message: "이미 존재하는 이름입니다"},
            {id:  43, message: "제한된 계정입니다"},
            {id:  44, message: "삭제된 계정입니다"},
            {id:  45, message: "잔액이 부족합니다"},
            {id:  46, message: "중복 가입은 제한되어 있습니다"},
            {id:  47, message: "만19세 미만은 가입할 수 없습니다"},
            {id:  48, message: "잔액이 부족합니다"},
            {id:  63, message: "출금이 제한된 토큰입니다"},
            {id:  64, message: "토큰 시세 갱신에 실패했습니다"},
            {id:  65, message: "본인에게 송금할 수 없습니다"},
            {id:  66, message: "최소 출금금액 이하는 출금할 수 없습니다"},
            {id:  81, message: "업로드 파일을 찾을 수 없습니다"},
            {id:  82, message: "판매중이 아닙니다 "},
            {id:  83, message: "매진되었습니다"},
            {id:  84, message: "잔여 수량이 부족합니다"},
            {id:  85, message: "시간이 만료되었습니다"},
            {id: 101, message: "서버 오류"},
            {id: 102, message: "서비스 점검중입니다"},
            {id: 103, message: "잠시 후 다시 시도해 주세요"},
            {id: 104, message: "미구현 기능입니다"},
            {id: 105, message: "설정이 비활성화 상태입니다"},
            {id: 106, message: "스케쥴된 작업이라 직접 요청할 수 없습니다"},
            {id: 107, message: "시스템 업데이트중  3분정도 뒤에 다시 시도하세요"},
            {id: 108, message: "API 사용이 잠시 제한된 상태입니다"},
            {id: 111, message: "잦은 요청으로 추가 요청이 제한됩니다. 잠시 후 다시 시도해주세요"},
            {id: 112, message: "SMS가 전송되었습니다"},
            {id: 113, message: "SMS 서비스 오류"},
            {id: 114, message: "인증번호가 잘못되었습니다"},
            {id: 115, message: "인증번호가 일치합니다"},
            {id: 116, message: "SMS 서비스를 이용할 수 없는 지역입니다"},
            {id: 117, message: "인증 서비스 점검중입니다"},
            {id: 118, message: "EMAIL 전송에 실패했습니다"},
            {id: 121, message: "비밀번호 길이가 너무 짧습니다"},
            {id: 122, message: "임시 비밀번호가 전송되었습니다"},
            {id: 131, message: "ERC20 주소가 설정되지 않았습니다"},
            {id: 132, message: "토큰 타입이 일치하지 않습니다"},
            {id: 133, message: "ERC20 API 오류"},
            {id: 134, message: "ERC20 Gas API 오류"},
            {id: 201, message: "이미 등록된 연락처입니다"},
            {id: 202, message: "연락처가 등록되지 않았습니다"},
            {id: 203, message: "연락처가 이미 등록되어있습니다"},
            {id: 204, message: "연락처가 등록되었습니다"},
            {id: 221, message: "이미 등록된 연락처입니다"},
            {id: 222, message: "이메일이 등록되지 않았습니다"},
            {id: 223, message: "이메일이 이미 등록되어있습니다"},
            {id: 224, message: "이메일이 등록되었습니다"},
            {id: 225, message: "요청중인 이메일이 있습니다"},
            {id: 226, message: "요청중인 ERC20 주소가 있습니다"},
            {id: 241, message: "OTP 번호가 일치하지 않습니다"},
            {id: 242, message: "인증번호가 일치하지 않습니다"},
            {id: 243, message: "문자 인증번호가 일치하지 않습니다"},
            {id: 250, message: "컬렉션 오너가 아닙니다"},
            {id: 251, message: "컬렉션 아이템 판매기록이 있습니다"},
            {id: 252, message: "로열티가 잘못 설정 되었습니다"},
            {id: 253, message: "동일한 컬렉션 이름이 존재합니다"},
            {id: 254, message: "존재하지 않는 컬렉션 입니다"},
            {id: 255, message: "존재하지 않는 멤버 입니다"},
            {id: 256, message: "존재하지 않는 인스턴스 입니다"},
            {id: 257, message: "존재하지 않는 큐레이션 입니다"},
            {id: 258, message: "존재하지 않는 프로덕트 입니다"},
            {id: 259, message: "멤버 디테일이 존재하지 않습니다"},
            {id: 260, message: "존재하지 않는 컬렉션 디테일 입니다"},
            {id: 261, message: "존재하지 않는 신고 카테고리 입니다"},
            {id: 262, message: "좋아요 하지 않은 아이템 입니다"},
            {id: 263, message: "이미 좋아요 한 아이템 입니다"},
            {id: 264, message: "트랜잭션이 존재하지 않습니다"},
            {id: 265, message: "타입이 존재하지 않습니다"},
            {id: 266, message: "프로덕트 인덱스를 입력하지 않았습니다"},
            {id: 267, message: "컬렉션 인덱스를 입력하지 않았습니다"},
            {id: 268, message: "멤버 인덱스를 입력하지 않았습니다"},
            
            {id: 269, message: "존재하지 않는 프로덕트 디테일 입니다."},
            {id: 270, message: "판매중인 인스턴스가 없습니다."},
            {id: 271, message: "거래 대기중인 인스턴스 입니다."},
            {id: 272, message: "로열티가 너무 큽니다."},
            {id: 273, message: "판매중인 상품입니다.."},
            {id: 274, message: "프로덕트 오너가 아닙니다."},
            {id: 275, message: "경매는 1개인 아이템만 가능합니다."},
            {id: 276, message: "5%이상의 금액으로만 입찰할수있습니다."},
            {id: 277, message: "판매등록중입니다."},
            {id: 278, message: "카테고리가 다릅니다."},
            {id: 279, message: "존재하지 않는 오퍼 입니다."},
            {id: 280, message: "경매중인 아이템이 있습니다."},
            {id: 281, message: "오퍼한 사람입니다."},
            {id: 282, message: "이미 민트된 아이템입니다."},
            {id: 283, message: "컬렉션에 아이템이 존재합니다."},
        ]
    }
]