<template>
    <div class="popup" v-if="initIsShowPopup && initProductDetail !== null">
        <div class="popup-box padding buy offer-ok">
            <div class="top">
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
                <p class="popup-title">{{ $t('offerOk.title') }}</p>
            </div>
            <div class="bottom">
                <div class="title-box">
                    <p class="title">{{ $t('buy.item') }}</p>
                    <p class="title subtotal">{{ $t('buy.subtotal') }}</p>
                </div>

                <div class="product">
                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + initProductDetail.productInfo.filePath" v-if="$store.state.configFront !== null && initProductDetail.productInfo.resourceType === 1"/>
                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + initProductDetail.productInfo.thumbnailFileName" v-if="$store.state.configFront !== null && initProductDetail.productInfo.resourceType === 2"/>

                    <div class="basic-info">
                        <p class="amount" v-if="initProductDetail.productInfo.itemType === 1">{{ $t('buy.amount') }} x {{initProductDetail.productInfo.totalItemCount}}</p>
                        <router-link tag="p" :to="'/Collection/' + initProductDetail.productInfo.collectionIdx" class="writer">{{initProductDetail.productInfo.collectionName}}</router-link>
                        <p class="name">{{initProductDetail.productInfo.name}}</p>
                    </div>
                    <div class="price">
                        <p v-if="initProductDetail.productInfo.itemType === 1" class="top-text">{{ $t('buy.per-piece') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initAcceptOfferInfo.offerSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initAcceptOfferInfo.offerSymbol === 'KLAY'" />
                            <p>{{initAcceptOfferInfo.offerPricePerOne | comma}}</p>
                            <span>(${{$store.getters.getTokenUsdPrice(initAcceptOfferInfo.offerSymbol, initAcceptOfferInfo.offerPricePerOne, 4)}})</span>
                        </div>
                    </div>
                </div>

                <div class="commission" v-if="$store.state.configFront !== null">
                    <p class="title">{{ $t('offerOk.commission') }}</p>
                    <p class="percent">{{$store.state.configFront.platformFee}}%</p>
                </div>

                <div class="loyalty">
                    <p class="title">{{ $t('offerOk.loyalty') }}</p>
                    <p class="percent">{{initProductDetail.royalty}}%</p>
                </div>

                <div class="total" v-if="initProductDetail.productInfo.itemType === 1">
                    <div>
                        <p class="title">{{ $t('buy.total') }}</p>
                    </div>
                    <p class="price">
                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initAcceptOfferInfo.offerSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initAcceptOfferInfo.offerSymbol === 'KLAY'" />
                        {{numberOnly(totalAmount) | comma}}
                        <span>(${{$store.getters.getTokenUsdPrice(initAcceptOfferInfo.offerSymbol, totalAmount, 4) | comma}})</span>
                    </p>
                </div>

                <div class="buy-piece" v-if="initProductDetail.productInfo.itemType === 1">
                    <p class="title">{{ $t('offerOk.piece') }}</p>
                    <input class="form-control" v-model="amount" @input="commaAmount" />
                    <p>{{ $t('offerOk.offerPiece') }} : {{initAcceptOfferInfo.remainAmount | comma}}</p>
                    <p>{{ $t('offerOk.havePiece') }} : {{initProductDetail.productInfo.itemCountMine | comma}}</p>
                    <p>{{ $t('offerOk.workablePiece') }} : {{initProductDetail.productInfo.itemCountMine - initProductDetail.productInfo.itemCountOnSaleMine - initProductDetail.productInfo.itemCountReservedMine | comma}}</p>
                </div>
                
                <p class="ps">{{$t('offerOk.ps2')}}</p>
                <a class="ok" @click="acceptOffer">{{$t('offerOk.ok')}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import Caver from "caver-js";
import OfferContract from "@/assets/json/NftOffer.json";

export default {
    name: "PopupBuy",
    data() {
        return {
            amount: '',
            numberAmount: 0,
            totalAmount: 0,
            isAllowance: false,

            offerContract: OfferContract,
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initProductDetail: {
            type: Object,
            default: null
        },
        initAcceptOfferInfo: {
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    watch:{
        initIsShowPopup(){
            this.amount = '';
            this.numberAmount = 0;
            this.totalAmount = 0;
            this.isAllowance = false;

            if(this.initIsShowPopup && this.initAcceptOfferInfo !== null){
                this.checkApprove();
                if(this.initProductDetail.productInfo.itemType === 2){    //단일 아이템의 경우 수량은 1개로 고정
                    this.amount = "1";
                    this.numberAmount = 1;
                    this.commaAmount();
                }
            }
        }
    },
    methods: {
        async checkApprove(){
            // console.log(this.initBuyInstance.saleType);
            var NFTContract = this.$store.state.configFront.contract1155;
            var targetAddress = this.$store.state.configFront.contractJoin;
            var walletAddress = this.$store.state.walletAddress;

            this.isApprovedForAll(NFTContract, targetAddress, walletAddress, (approved)=>{
                if(approved) {
                    this.isAllowance = true;
                } else {
                    this.isAllowance = false;
                }
            });
        },
        async acceptOffer(){
            if(this.numberAmount <= 0){
                return;
            }

            if(!this.isAllowance){
                var NFTContract = this.$store.state.configFront.contract1155;
                var targetAddress = this.$store.state.configFront.contractJoin;
                var walletAddress = this.$store.state.walletAddress;
                this.setApprovalForAll(NFTContract, targetAddress, walletAddress, null,
                    (result)=>{result; this.isAllowance = true; this.acceptOffer()},
                    (err)=>{console.error(err)}
                );
                return;
            }

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                offerIdx: this.initAcceptOfferInfo.makeOfferIdx,
                amount: this.numberAmount
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/nft/offer/accept`, param, this.headers).then(async (res) => {
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.reqAcceptOffer(res.data.data);
                }
                else {
                    this.$emit('error', res.data.returnCode);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        // acceptOfferIdx: 4
        // amount: 10
        // aodAmount: "0x000000000000000000000000000000000000000000000000000000000000000a"
        // aodExpired: "0x0000000000000000000000000000000000000000000000000000000062ce95a8"
        // aodId: "0x0000000000000000000000000000000000000000000db654011756d9ac70076e"
        // aodMintAmount: "0x0000000000000000000000000000000000000000000000000000000000000000"
        // aodSeller: "0x0f50fbf6ad053058c1f5bc77d1ccbe255c9eb0ce"
        // aodSigId: "0x0000000000000000000000000000000000000000000db64fee73f5e7f4e462f3"
        // createDate: "2022-07-13 18:46:37"
        // expireDate: "2022-07-13 18:51:36"
        // makeOfferIdx: 30
        // memberIdx: 38
        // mintAmount: 0
        // signResult: null
        // state: 0
        // tradeDate: null
        reqAcceptOffer(acceptOfferData){
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractAddress = this.$store.state.configFront.contractJoin;
                var contractInstance = caver.contract.create(this.offerContract.abi, contractAddress);
                // var data = contractInstance.methods.placeBid(caver.utils.toBN(this.initBuyInstance.auctionId), caver.utils.toPeb(this.numberBidPrice.toString(), 'KLAY')).encodeABI();

                var data = contractInstance.methods.acceptOffer(
                    acceptOfferData.aodId, //id
                    acceptOfferData.aodAmount, //amount
                    acceptOfferData.aodMintAmount, //mint_amount
                    acceptOfferData.aodSigId, //sigID
                    acceptOfferData.aodExpired, //expired
                    acceptOfferData.signResult //signature
                ).encodeABI();
                
                // let price = "0x0000000000000000000000000000000000000000000000000000000000000000";
                // if(offerData.osdToken === "0x0000000000000000000000000000000000000000"){
                //     price = offerData.osdPrice;
                // }
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: contractAddress,
                    // value: price,
                    gas: '400000',
                    data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    this.$emit("completeAcceptOffer");
                    // console.log(receipt);
                })
                .on('error', (error) =>{
                    console.error('error : ' + error);
                });
            }
        },
        commaAmount() {
            this.isShowErrorNoCoin = false;

            var x = this.amount.toString();
            var workablePiece = this.initProductDetail.productInfo.itemCountMine - this.initProductDetail.productInfo.itemCountOnSaleMine - this.initProductDetail.productInfo.itemCountReservedMine;
            var max = workablePiece > this.initAcceptOfferInfo.remainAmount ? this.initAcceptOfferInfo.remainAmount : workablePiece; // 수락 가능 조각과 판매 가능 조각 중 큰 수
            x = this.numberOnlyWithMax(x, max, true);
            
            this.numberAmount = Number(x);
            this.amount = this.setComma(x.toString());
            this.totalAmount = (Math.floor((this.initAcceptOfferInfo.offerPricePerOne * 10000) * this.numberAmount) - ( ((this.initAcceptOfferInfo.offerPricePerOne * 10000) * this.numberAmount) * ( (Number(this.$store.state.configFront.platformFee) + Number(this.initProductDetail.royalty)) / 100) )) / 10000;

            var myBalance = this.$store.getters.getMyBalance(this.initAcceptOfferInfo.offerSymbol);

            if(myBalance < this.totalAmount){
                this.isShowErrorNoCoin = true;
            }
        },
        closePopup() {
            this.$emit("close");
        },
    },
};
</script>
