<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box padding unlock">
            <div class="top">
                <p class="popup-title">{{ $t('unlock.title') }}</p>
            </div>
            <div class="bottom">
                <div class="contents">
                    <p v-html="initUnlockContent"></p>
                </div>
                <button @click="closePopup">{{ $t('cancel.btn1') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupUnlock",
        data() {
            return {
                
            };
        },
        props: {
            initIsShowPopup: {
                type: Boolean,
                default: false,
            },
            initUnlockContent: {
                type: String,
                default: "",
            },
        },
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {
            closePopup() {
                this.$emit("close");
            },
        },
    };
</script>
