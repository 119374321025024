<template>
    <div>
        <Header :initHeaderType="initHeaderType" @completeSetUserInfo="completeSetUserInfo" />
        <div class="account-setting">
            <div class="account-setting-left" :class="{off : accountSettingLeft}">
                <ul class="account-setting-left-box">
                    <li class="left-box-mywallet" :class="{'on' : selectedTab === 0}" @click="selectedTab = 0">
                        <img class="account-setting-left-img" src="@/assets/images/icon/icon_mywallet_black.svg" alt="">
                        <p>{{ $t('accountSetting.menu1') }}</p>
                        <div class="balhang-df">
                            <p class="wallet-address">{{ $store.state.walletAddress.toString().substr(0, 6) }}...{{ $store.state.walletAddress.toString().substr(-4) }}</p>
                            <button type="button" @click="doCopy"><img src="@/assets/images/icon/icon_copy_btn.svg" alt=""></button>
                        </div>
                    </li>
                    <li class="left-box-general" :class="{'on' : selectedTab === 1}" @click="selectedTab = 1">
                        <img class="account-setting-left-img" src="@/assets/images/icon/icon_general_black.svg" alt="">
                        <p>{{ $t('accountSetting.menu2') }}</p>
                    </li>
                    <li class="left-box-notification" :class="{'on' : selectedTab === 2}" @click="selectedTab = 2">
                        <img class="account-setting-left-img" src="@/assets/images/icon/icon_notification_black.svg" alt="">
                        <p>{{ $t('accountSetting.menu3') }}</p>
                    </li>
                </ul>
            </div>
            <!-- /마이월렛/ -->
            <div class="account-setting-mid container" v-if="selectedTab === 0" :class="{'on' : selectedTab === 0}">
                <h2>{{ $t('accountSetting.menu1') }}</h2>
                <div class="account-setting-box mid-box-mywallet">
                    <h3>{{ $t('accountSetting.ad') }}</h3>
                    <div class="mid-box-mywallet-box">
                        <input type="text" class="" v-model="$store.state.walletAddress" disabled="disabled" readonly="readonly" />
                        <button class="" type="button" @click="doCopy">{{ $t('accountSetting.copy') }}</button>
                    </div>
                    <p class="logout" @click="logout()">{{ $t('accountSetting.logout') }}</p>
                </div>
            </div>
            <!-- /제너럴 셋팅/ -->
            <div class="account-setting-mid container" v-else-if="selectedTab === 1">
                <h2>{{ $t('accountSetting.menu2') }}</h2>
                <div class="account-setting-box mid-box-general">
                    <div>
                        <h3>{{ $t('accountSetting.menu2Title1') }}</h3>
                        <input type="text" v-model="$store.state.userName" disabled="disabled" readonly="readonly" />
                    </div>
                    <div>
                        <h3>{{ $t('accountSetting.menu2Title2') }}</h3>
                        <textarea class="about" v-model="userDesc"></textarea>
                    </div>
                    <div class="image-upload">
                        <h3>{{ $t('accountSetting.menu2Title4') }}</h3>
                        <p>{{ $t('accountSetting.menu2sub') }}</p>
                        <div>
                            
                            <label class="profile" for="idUploadProfile" @click="resetUploadFile('profile')">
                                <div class="dashed">
                                    <div class="photo" v-if="uploadProfile === null && $store.state.profile === null">
                                        <div>
                                            <img src="@/assets/images/icon/photo.svg" alt="profile">
                                            <p v-html="$t('accountSetting.profilePhoto')"></p>
                                        </div>
                                    </div>
                                    <img :src="uploadProfile.path" v-else-if="uploadProfile !== null"/>
                                    <img :src="$store.state.profile" v-else/>
                                    <input class="display-none" id="idUploadProfile" name="fileProfile" ref="fileProfile" @change="handleFileUpload('profile')" :accept="accept" type="file">
                                </div>
                            </label>

                            <label class="collection-banner" for="idUploadBanner" @click="resetUploadFile('banner')">
                                <div class="dashed">
                                    <div class="photo">
                                        <div class="no-photo" v-if="uploadBanner === null && $store.state.banner === null">
                                            <img src="@/assets/images/icon/photo.svg" alt="banner">
                                            <p v-html="$t('accountSetting.profileBanner')"></p>
                                        </div>
                                        <img :src="uploadBanner.path" class="my-pick" v-else-if="uploadBanner !== null"/>
                                        <img :src="$store.state.banner" class="my-pick" v-else/>
                                    </div>
                                    <input class="display-none" id="idUploadBanner" name="fileBanner" ref="fileBanner" @change="handleFileUpload('banner')" :accept="accept" type="file">
                                </div>
                            </label>
                        </div>
                    </div>
                    <div>
                        <h3>{{ $t('accountSetting.menu2Title3') }}</h3>
                        <input type="text" v-model="$store.state.email" disabled="disabled" readonly="readonly"/>
                    </div>
                    <div class="save-btn">
                        <button type="button" @click="updateProfile()">{{ $t('accountSetting.save') }}</button>
                    </div>
                </div>
            </div>
            <!-- /노티피케이션 셋팅/ -->
            <div class="account-setting-mid container notification" v-else-if="selectedTab === 2">
                <h2>{{ $t('accountSetting.menu3') }}</h2>
                <h4>{{ $t('accountSetting.menu3Sub') }}</h4>
                <div class="account-setting-box mid-box-notification">
                    <h4 class="mobile">{{ $t('accountSetting.menu3Sub') }}</h4>
                    <div class="balhang-inner-toggle notification1 mobile">
                        <h3>{{ $t('accountSetting.menu3Title1') }}</h3>
                        <div class="flex">
                            <p>{{ $t('accountSetting.menu3cont1') }}</p>
                            <div class="switch-cover"><input type="checkbox" id="switch1" v-model="isCheckedAlramItemSell" /><label for="switch1">Toggle</label></div>
                        </div>
                    </div>
                    <div class="balhang-inner-toggle notification2">
                        <h3>{{ $t('accountSetting.menu3Title2') }}</h3>
                        <div class="flex">
                            <p>{{ $t('accountSetting.menu3cont2') }}</p>
                            <div class="switch-cover"><input type="checkbox" id="switch2" v-model="isCheckedAlramBid" /><label for="switch2">Toggle</label></div>
                        </div>
                    </div>
                    <div class="balhang-inner-toggle notification3">
                        <h3>{{ $t('accountSetting.menu3Title3') }}</h3>
                        <div class="flex">
                            <p>{{ $t('accountSetting.menu3cont3') }}</p>
                            <div class="switch-cover"><input type="checkbox" id="switch3" v-model="isCheckedAlramAcceptOffer" /><label for="switch3">Toggle</label></div>
                        </div>
                    </div>            
                    <div class="balhang-inner-toggle notification4">
                        <h3>{{ $t('accountSetting.menu3Title4') }}</h3>
                        <div class="flex">
                            <p>{{ $t('accountSetting.menu3cont4') }}</p>
                            <div class="switch-cover"><input type="checkbox" id="switch4" v-model="isCheckedAlramExpiredSell" /><label for="switch4">Toggle</label></div>
                        </div>
                    </div>                
                    <div class="balhang-inner-toggle notification5">
                        <h3>{{ $t('accountSetting.menu3Title5') }}</h3>
                        <div class="flex">
                            <p>{{ $t('accountSetting.menu3cont5') }}</p>
                            <div class="switch-cover"><input type="checkbox" id="switch5" v-model="isCheckedAlramOverOffer" /><label for="switch5">Toggle</label></div>
                        </div>
                    </div>
                    <!-- <div class="balhang-inner-toggle notification6">
                            <h3>{{ $t('accountSetting.menu3Title6') }}</h3>
                        <div class="flex">
                            <p>{{ $t('accountSetting.menu3cont6') }}</p>
                            <div class="switch-cover"><input type="checkbox" id="switch6" v-model="isCheckedAlramMinOffer" /><label for="switch6">Toggle</label></div>
                        </div>
                    </div> -->
                    <div class="balhang-inner-toggle notification-end">
                        <h3>{{ $t('accountSetting.menu3Title7') }}</h3>
                        <div class="flex">
                            <p v-html="$t('accountSetting.menu3cont7')"></p>
                            <div class="switch-cover last"><input type="checkbox" id="switch7" v-model="isCheckedAlramMinOffer" /><label for="switch7">Toggle</label></div>
                        </div>
                        <div class="box" v-if="isCheckedAlramMinOffer">
                            <div class="left">
                                <div class="inline">
                                    <img src="@/assets/images/icon/icon_symbol_klaytn.svg" />
                                    <span>KLAY</span>
                                </div>
                                <input type="text" v-model="alramBidKlay" @input="commaAmount('minKlay')" />
                            </div>
                            <span>(${{$store.getters.getTokenUsdPrice('KLAY', numberAlramBidKlay, 4)}})</span>
                        </div>
                        <div class="box end" v-if="isCheckedAlramMinOffer">
                            <div class="left">
                                <div class="inline">
                                    <img src="@/assets/images/icon/icon_symbol_artube.png" />
                                    <span>ATT</span>
                                </div>
                                <input type="text" v-model="alramBidAtt" @input="commaAmount('minAtt')" />
                            </div>
                            <span>(${{$store.getters.getTokenUsdPrice('ATT', numberAlramBidAtt, 4)}})</span>
                        </div>
                    </div>
                    <div class="button-cover">
                        <button type="button" @click="updateAlram()">{{ $t('accountSetting.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name:'AccountSetting',
    krName: '계정 설정',
    components: {
        Header,
        PopupOK,
        },
    data(){
        return {
            initHeaderType: 'type2',

            userDesc: '',

            uploadProfile: null,
            uploadBanner: null,
            accept: '.gif, .jpg, .png',
            
            selectedTab:0,

            accountSettingLeft: false,
            tabName: '',

            intervalCheckUserInfo: null,
            userInfoCheckCount: 0,

            isCheckedAlramAcceptOffer: false,
            isCheckedAlramBid: false,
            isCheckedAlramEmail: false,
            isCheckedAlramExpiredSell: false,
            isCheckedAlramItemSell: false,
            isCheckedAlramMinOffer: false,
            isCheckedAlramOverOffer: false,

            alramBidKlay: '',
            numberAlramBidKlay: 0,
            alramBidAtt: '',
            numberAlramBidAtt: 0,

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }

    },
    created() {
        if(this.$store.state.id === "" || this.$store.state.ticket === ""){
            this.$router.push('/');
        }
    },
    mounted(){
        this.checkUserInfo();
        this.userDesc = this.$store.state.aboutMe;
    },
    beforeDestroy(){
        clearInterval(this.intervalCheckUserInfo);
    },
    methods:{
        changeProfileImg(){
            this.$refs.profileFiles.click();
        },
        changeBannerImg(){
            this.$refs.bannerFiles.click();
        },
        checkUserInfo(){
            var that = this;
            this.intervalCheckUserInfo = setInterval(()=>{
                if(that.userInfoCheckCount >= 10){
                    clearInterval(that.intervalCheckUserInfo);
                }
                if(that.$store.state.erc20Address !== ''){
                    that.userDesc = that.$store.state.aboutMe;
                    clearInterval(that.intervalCheckUserInfo);
                }
                that.userInfoCheckCount++;
            },  1000);
        },
        doCopy(){
            this.$copyText(this.$store.state.walletAddress);
            this.initPopupOkMessage = "지갑 주소를 복사했습니다."
            this.isShowPopupOk = true;
        },
        resetUploadFile(type){
            if(type === 'profile'){
                this.uploadProfile = null;
            } else if(type === 'banner'){
                this.uploadBanner = null;
            }
        },
        handleFileUpload(type){
            // console.log(e);
            // let files = e.target.files || e.dataTransfer.files;
            // console.log(files);
            var fileMaxSize = 2 * 1024 * 1024;
            let uploadFile;
            if(type === 'profile'){
                uploadFile = this.$refs.fileProfile.files;
            } else if(type === 'banner'){
                uploadFile = this.$refs.fileBanner.files;
            }
             
            if(uploadFile.length <= 0){
                return;
            }
            let file = uploadFile[0];
            if(this.checkImageFileExt(file.name)){
                this.initPopupOkMessage = '지원하지 않는 파일 형식입니다.';
                this.isShowPopupOk = true;
                return;
            }
            if(file.size > fileMaxSize){
                // alert("파일사이즈 : "+ file.size +", 최대파일사이즈 : 1MB");
                this.initPopupOkMessage = '파일 크기는 최대 2MB까지만 가능합니다.';
                this.isShowPopupOk = true;
                return;
            }

            this.showImage(file, type);

            // this.reqUploadResource(file, 208, 140);  //이미지 가로/세로 사이즈 체크 필요 시
            // if (document.getElementById(this.idUploadFile)) {
            //     document.getElementById(this.idUploadFile).value = '';
            // }
        },
        showImage (file, type){ //,limit_width, limit_height) {
            var that = this;
            let reader = new FileReader();
            reader.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    if(type === 'profile'){
                        that.uploadProfile = {name: file.name, path: image.src};
                    } else if(type === 'banner'){
                        that.uploadBanner = {name: file.name, path: image.src};
                    }
                    
                    // var height = this.height;
                    // var width = this.width;
                    // if (height !== limit_height || width !== limit_width) {
                    //     that.initPopupOkMessage = '썸네일 이미지 크기는 208*140px 로 제한됩니다.'
                    //     that.isShowPopupOk = true;
                    // } else {
                    //  that.thumbnail = {name: file.name, path: image.src};
                    // }
                };
            }
            reader.readAsDataURL(file);
        },
        async reqUploadProfile(){
            let userUploadFile = this.$refs.fileProfile.files;
            let file = userUploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            await this.$Axios.post(`${this.host}/user/upload/profile/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--user upload banner--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data;
                } else {
                    this.error(res.data.returnCode);
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return null;
            });
        },
        async reqUploadBanner(){
            let userUploadFile = this.$refs.fileBanner.files;
            let file = userUploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            await this.$Axios.post(`${this.host}/user/upload/banner/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--user upload profile--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data;
                } else {
                    this.error(res.data.returnCode);
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return null;
            });
        },
        async updateProfile(){
            var uploadProfileInfo = null;
            var uploadBannerInfo = null;

            // if(this.aboutMe.length > 0 && this.getByte(this.aboutMe) > 128){
            //     this.initPopupOkMessage = '내 소개는 최대 128Byte까지 입력 가능합니다.';
            //     this.isShowPopupOk = true;
            //     return;
            // }
            if(this.userDesc.length > 140){
                this.isShowErrorName = true;
                this.errorMsgName = '내 소개는 최대 140자까지만 등록할 수 있습니다.';
                return;
            }

            if(this.uploadProfile !== null){
                uploadProfileInfo = await this.reqUploadProfile();
                if(uploadProfileInfo === null) return;
            }
            if(this.uploadBanner !== null){
                uploadBannerInfo = await this.reqUploadBanner();
                if(uploadBannerInfo === null) return;
            }

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                aboutMe: this.userDesc
            }
            this.$Axios.post(`${this.host}/user/update`, param, this.headers).then(async (res) => {
                // console.log('------ user update --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.$store.commit("setUserInfo", this.host);
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = "프로필 정보 수정 완료"
                    this.isShowPopupOk = true;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        async updateAlram(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                flagItemSell: this.isCheckedAlramItemSell ? 1 : 0,
                flagBid: this.isCheckedAlramBid ? 1 : 0,
                flagAcceptOffer: this.isCheckedAlramAcceptOffer ? 1 : 0,
                flagExpiredSell: this.isCheckedAlramExpiredSell ? 1 : 0,
                flagOverOffer: this.isCheckedAlramOverOffer ? 1 : 0,
                flagMinOffer: this.isCheckedAlramMinOffer ? 1 : 0,
                minOfferPriceKlay: this.numberAlramBidKlay,
                minOfferPriceAtt: this.numberAlramBidAtt
            }
            this.$Axios.post(`${this.host}/user/alarm/update`, param, this.headers).then(async (res) => {
                // console.log('------ user update --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.$store.commit("setUserInfo", this.host);
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = "알림 설정 저장 완료 "
                    this.isShowPopupOk = true;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        completeSetUserInfo(){
            
            this.isCheckedAlramAcceptOffer = this.$store.state.flagAcceptOffer === 1 ? true : false;
            this.isCheckedAlramBid = this.$store.state.flagBid === 1 ? true : false;
            this.isCheckedAlramEmail = this.$store.state.flagEmail === 1 ? true : false;
            this.isCheckedAlramExpiredSell = this.$store.state.flagExpiredSell === 1 ? true : false;
            this.isCheckedAlramItemSell = this.$store.state.flagItemSell === 1 ? true : false;
            this.isCheckedAlramMinOffer = this.$store.state.flagMinOffer === 1 ? true : false;
            this.isCheckedAlramOverOffer = this.$store.state.flagOverOffer === 1 ? true : false;
            this.alramBidKlay = this.$store.state.minOfferPriceKlay;
            this.alramBidAtt = this.$store.state.minOfferPriceAtt;
            
            this.commaAmount('minKlay');
            this.commaAmount('minAtt');
        },
        commaAmount(type) {
            var x = "";
            switch(type){
                case 'minKlay':
                    x = this.alramBidKlay.toString();
                    x = this.numberOnly(x);
                    this.numberAlramBidKlay = x;
                    this.alramBidKlay = this.setComma(x.toString());
                    break;
                case 'minAtt':
                    x = this.alramBidAtt.toString();
                    x = this.numberOnly(x);
                    this.numberAlramBidAtt = x;
                    this.alramBidAtt = this.setComma(x.toString());
                    break;
            }
        },
        logout(){
            this.$store.commit("disconnectKaikas");
            this.$store.commit("logout");
            this.$router.push('/');
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'Report':
                    this.initIsReport = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    }
}
</script>

<style>

</style>