<template>
    <div class="popup popup-two-button" v-if="initIsShowPopup">
        <div class="popup-box popup-two-button-box">
            <p v-html="PopupTitle" class="popup-title"></p>
            <p v-html="PopupMessage" class="popup-cont"></p>
            <div class="buttons">
                <a class="left" @click="clickLeftBtn" v-html="ButtonLeft"></a>
                <a class="right" @click="clickRightBtn" v-html="ButtonRight"></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupTwoButton",
        data() {
            return {};
        },
        props: {
            initIsShowPopup: {
                type: Boolean,
                default: false,
            },
            PopupTitle: {
                type: String,
                default: "",
            },
            PopupMessage: {
                type: String,
                default: "",
            },
            ButtonLeft: {
                type: String,
                default: "",
            },
            ButtonRight: {
                type: String,
                default: "",
            },
        },
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {
            clickLeftBtn() {
                this.$emit("clickLeftBtn");
            },
            clickRightBtn(){
                this.$emit("clickRightBtn");
            },
            closePopup() {
                this.$emit("close");
            },
        },
    };
</script>
