<template>
    <div class="popup" v-if="initIsShowPopup && initCancelInstance !== null">
        <div class="popup-box cancel padding">
            <div class="top">
                <p class="title" v-if="initCancelInstance.saleType === 1">{{ $t('cancel.title') }}</p>
                <p class="title" v-else-if="initCancelInstance.saleType === 2">{{ $t('cancel.title2') }}</p>
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
            </div>
            <div class="bottom">
                <p class="cont" v-if="initCancelInstance.saleType === 1">{{ $t('cancel.cont') }}</p>
                <p class="cont" v-else-if="initCancelInstance.saleType === 2">{{ $t('cancel.cont2') }}</p>
                <div class="buttons">
                    <a class="left" @click="closePopup">{{ $t('cancel.btn1') }}</a>
                    <a class="right" @click="reqCancelSell" v-if="initCancelInstance.saleType === 1">{{ $t('cancel.btn2') }}</a>
                    <a class="right" @click="reqCancelAuction" v-else-if="initCancelInstance.saleType === 2">{{ $t('cancel.btn3') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Caver from "caver-js";
import AuctionContract from "@/assets/json/NftAuction.json";

export default {
    name: "PopupCancel",
    data() {
        return {
            auctionContract: AuctionContract
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initCancelInstance: {
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        closePopup() {
            this.$emit("close");
        },
        reqCancelSell(){
            // console.log(this.initCancelInstance);
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                instanceId: this.initCancelInstance.instanceId
            }
            this.$Axios.post(`${this.host}/nft/cancel/sale`, param, this.headers).then(async (res) => {
                // console.log('---nft cancel sale----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.$emit("completeCancelSale")
                } else {
                    this.$emit("failedCancelSale")
                    // this.$emit('error', res.data.returnCode);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        reqCancelAuction(){
            if(typeof window.klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var auctionContractAddress = this.$store.state.configFront.contractJoin;
                var contractInstance = caver.contract.create(this.auctionContract.abi, auctionContractAddress);
                // console.log(this.auctionContract.abi);
                // console.log(auctionContractAddress);
                // console.log(this.initCancelInstance.auctionId);
            
                var data = contractInstance.methods.cancelAuction(this.initCancelInstance.auctionId).encodeABI();
                // console.log(data);

                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: auctionContractAddress,
                    gas: '300000',
                    data: data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    // console.log(receipt);
                    this.$emit('completeAuctionCancel')
                })
                .on('error', (error) =>{
                    console.log('error : ' + error);
                });
            }
        }
    },
};
</script>
