<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box report padding">
            <div class="top">
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
                <p class="popup-title">{{ $t('report.title') }}</p>
            </div>
            <div class="bottom">
                <div class="category">
                    <p class="title">{{ $t('report.bottomTitle1') }}</p>
                    <div class="input-group dropdown">
                        <b-dropdown :text="getSelectedReportText()">
                            <b-dropdown-item v-for="(item) in reportCategory" :key="item.value" @click="changeReportType(item.value)">{{item.text}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="detail">
                    <p class="title">{{ $t('report.bottomTitle2') }}</p>
                    <b-textarea placeholder="신고하려는 이유를 구체적으로 기술해 주십시오." v-model="message"></b-textarea>
                </div>
                <a class="ok" :class="{'enable' : selectedCategoryIdx !== null && message.length > 0}" @click="reqReport()">{{ $t('report.button') }}</a>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "PopupReport",
    components: {
        PopupOK,
    },
    data() {
        return {
            selectedCategoryIdx: null,
            reportCategory: [
                {value: null, text: '신고 사유를 선택해 주세요'},
            ],
            message: '',

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initReportType:{
            type: Number,
            default:0
        },
        initTargetIdx: {
            type: Number,
            default: 0
        }
    },
    created() {
        this.getReportCategory();
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        getSelectedReportText(){
            var item = this.reportCategory.find((item) => item.value === this.selectedCategoryIdx);
            return item.text;
        },
        changeReportType(value){
            if(this.selectedCategoryIdx === value) return;
            this.selectedCategoryIdx = value;
        },
        getReportCategory(){
            var param = 
            {
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/support/report/category`, param, this.headers).then(async (res) => {
                // console.log('------- report category list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    res.data.list.forEach((item)=>{
                        var categoryItem = {
                            value: item.categoryIdx,
                            text: item.name
                        }
                        this.reportCategory.push(categoryItem);

                    })
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        reqReport(){
            if(this.selectedCategoryIdx === null){
                this.initPopupOkMessage = '카테고리를 선택해 주세요';
                this.isShowPopupOk = true;
                return;
            }
            if(this.message.length <= 0){
                this.initPopupOkMessage = '신고 내용을 입력해 주세요.';
                this.isShowPopupOk = true;
                return;
            } else if(this.message.length > 1000){
                this.initPopupOkMessage = '신고 내용은 최대 1000자까지만 등록할 수 있습니다.';
                this.isShowPopupOk = true;
                return;
            }

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                reportCategoryIdx: this.selectedCategoryIdx,
                type: this.initReportType,
                targetIdx: this.initTargetIdx,
                message: this.message,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/support/report/register`, param, this.headers).then(async (res) => {
                // console.log('------- report register --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.initPopupOkMessage = '신고 내용이 정상적으로 등록되었습니다.';
                    this.PopupOkCallbakPage = 'close'
                    this.isShowPopupOk = true;
                    return;
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'close'){
                        this.closePopup();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.isShowPopupOk = false;
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        closePopup() {
            this.$emit("close");
            this.selectedCategoryIdx = null;
        },
    },
};
</script>
