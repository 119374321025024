<template>
    <div >
        <Header :initHeaderType="initHeaderType" />
        <b-container class="balhang-box create-item">
            <div class="balhang-inner">
                <div class="balhang-inner-top">
                    <p>{{ $t('balhang.mainTitle') }}</p>
                </div>
                <div class="balhang-inner-upload">
                    <h3 class="must-input">{{ $t('balhang.title1') }}</h3>
                    <div class="balhang-inner-upload-box">
                        <label class="choose-file" :class="{black : uploadNft !== null && !isImageNft}" :for="idUploadNft" @click="resetUploadFile('nft')">
                            <img :src="uploadNft.path" v-if="uploadNft !== null && isImageNft" />
                            <video id="videoNft" ref="videoNft" autoplay="" controls="" controlslist="nodownload" loop="" preload="auto" muted playsinline :src="uploadNft.path" v-else-if="uploadNft !== null && !isImageNft"></video>
                            <div v-else>
                                <p>{{ $t('balhang.box-text') }}</p>
                                <p class="btn-choose-file">{{ $t('balhang.file') }}</p>
                            </div>
                            <input class="display-none" :id="idUploadNft" name="fileNft" ref="fileNft" @change="handleFileUpload('nft')" :accept="nft_accept" type="file">
                        </label>
                    </div>
                    <b-button :for="idUploadNft" v-if="uploadNft !== null && !isImageNft" @click="changeUploadFile">{{ $t('balhang.changeBtn') }}</b-button>
                </div>
                <div class="balhang-inner-thumbnail balhang-inner-upload" v-if="uploadNft !== null && !isImageNft">
                    <h3 class="must-input">{{ $t('balhang.title6') }}</h3>
                    <p class="cont">{{ $t('balhang.title6Sub') }}</p>
                    <div class="balhang-inner-upload-box">
                        <label class="choose-file" :for="idUploadThumbnail" @click="resetUploadFile('thumbnail')">
                            <img :src="uploadThumbnail.path" v-if="uploadThumbnail !== null" />
                            <div v-else>
                                <div class="noImg">
                                    <img src="@/assets/images/image.svg" />
                                    <p v-html="$t('balhang.thumbnail')"></p>
                                </div>
                            </div>
                            <input class="display-none" :id="idUploadThumbnail" name="fileThumbnail" ref="fileThumbnail" @change="handleFileUpload('thumbnail')" :accept="img_accept" type="file">
                        </label>
                    </div>
                </div>
                <div class="balhang-inner-name">
                    <h3 class="must-input">{{ $t('balhang.title3') }}</h3>
                    <textarea name="" id="" cols="30" rows="10" v-model="itemName" placeholder="예) 분위기 있는 야경 사진 모음"></textarea>
                    <!-- <div class="red"><p>{{ $t('balhang.red') }}</p></div> -->
                </div>
                <div class="balhang-inner-descript">
                    <h3>{{ $t('balhang.title4') }}</h3>
                    <p class="cont">{{ $t('balhang.descriptionCont') }}</p>
                    <div>
                        <textarea name="" id="" cols="30" rows="10" v-model="itemDescription" @input="checkRows" class="balhang-textarea-scroll" placeholder="예시 : 별자리를 중심으로 밤하늘을 촬영하고 도시의 야경을 촬영했습니다." ></textarea>
                    </div>
                </div>
                <div class="category-box">
                    <h3 class="must-input">{{ $t('newCollection.mini-title5') }}</h3>
                    <p class="cont">{{ $t('newCollection.category-cont') }}</p>
                    <div class="filter-box" v-if="$store.state.configCategory !== null">
                        <li v-for="(item, index) in $store.state.categoryList" :key="index" class="button" :class="{on : selectedCategory === item.categoryIdx}" @click="selectedCategory = item.categoryIdx">
                            <p>{{ item.name }}</p>
                        </li>
                    </div>
                </div>
                <div class="collection-box">
                    <h3 class="must-input">{{ $t('balhang.chocie') }}</h3>
                    <p class="cont">{{ $t('balhang.myItem') }}</p>
                    <!-- <b-form-select v-model="selectedCollectionIdx" :options="myCollectionList"></b-form-select> -->
                    <b-dropdown :text="getSelectedCollectionText()">
                        <b-dropdown-item v-for="(item) in myCollectionList" :key="item.value" @click="changeCollectionList(item.value)">{{item.text}}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="balhang-inner-numberissued">
                    <h3 class="must-input">{{ $t('balhang.title5') }}</h3>
                    <p class="cont">{{ $t('balhang.issuedCont') }}</p>
                    <textarea class="balhang-textarea-scroll" name="" id="" cols="30" rows="10" v-model="amount" @input="commaAmount"></textarea>
                </div>
                <div class="balhang-inner-unlocked">
                    <div>
                        <h3>{{ $t('balhang.unlockTitle') }}</h3>
                    </div>
                    <div class="balhang-inner-toggle">
                        <p>{{ $t('balhang.unlockCont') }}</p>
                        <div class="switch-cover"><input type="checkbox" id="switch" v-model="isCheckedUnlockedContent" /><label for="switch">Toggle</label></div>
                    </div>
                    <textarea class="balhang-textarea-scroll" name="" id="" cols="30" rows="10" v-model="unlockedContent" placeholder="교환할 코드나, 링크, 엑세스 키, 연락처 등을 아이템 소유자에게만 공개합니다." v-if="isCheckedUnlockedContent"></textarea>
                </div>
                <div class="btn-cover">
                    <a class="create-item-btn" @click="reqRegisterItem()">{{ $t('balhang.button') }}</a>
                </div>
            </div>
        </b-container>
        <PopupProgress :initIsShowPopup="isWorkingRegist" />
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue';
import PopupProgress from '@/components/popup/PopupProgress.vue';

export default {
    name:'NftPublish',
    krName: '아이템 등록',
    components: {
        Header,
        PopupOK,
        PopupProgress
    },
    data(){
        return{
            initHeaderType: 'type2',

            isImageNft: true,
            uploadNft: null,
            uploadThumbnail: null,

            idUploadNft: 'updateNft',
            idUploadThumbnail: 'uploadThumbnail',
            nft_accept: '.gif, .jpg, .png, .svg, .mp4, .webm, .mp3, .wav, .ogg',
            img_accept: '.gif, .jpg, .png, .svg',

            itemName: '',
            itemDescription: '',
            isCheckedUnlockedContent: false,
            unlockedContent: '',
            amount: '1',
            numberAmount: 1,

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
            
            selectedCategory: -1,

            isWorkingRegist: false,

            selectedCollectionIdx: null,
            myCollectionList: [
                {value: null, text: '컬렉션을 선택해주세요.'}
            ],
        }
    },
    created(){
        this.getMyCollectionList();
    },
    methods:{
        getSelectedCollectionText(){
            var item = this.myCollectionList.find((item) => item.value === this.selectedCollectionIdx);
            return item.text;
        },
        changeCollectionList(value){
            if(this.selectedCollectionIdx === value) return;
            this.selectedCollectionIdx = value;
        },
        resetUploadFile(type){
            if(type === 'nft'){
                document.getElementById(this.idUploadNft).value = '';
                this.uploadNft = null;
                this.isImageNft = true;
            } else if(type === 'thumbnail'){
                document.getElementById(this.idUploadThumbnail).value = '';
                this.uploadThumbnail = null;
            }
        },
        changeUploadFile(e){
            e.preventDefault();
            this.resetUploadFile('nft');
            document.getElementById(this.idUploadNft).click();
        },
        handleFileUpload(type){
            // console.log(e);
            // let files = e.target.files || e.dataTransfer.files;
            // console.log(files);
            var nftFileMaxSize = 100 * 1024 * 1024;
            var thumbnailMaxSize = 2 * 1024 * 1024;
            let uploadFile;

            if(type === 'nft'){
                uploadFile = this.$refs.fileNft.files;
            } else if(type === 'thumbnail'){
                uploadFile = this.$refs.fileThumbnail.files;
            }

            if(uploadFile.length <= 0){
                return;
            }
            let file = uploadFile[0];

            if(type === 'nft'){
                if(this.checkNftFileExt(file.name)){
                    this.initPopupOkMessage = '지원하지 않는 파일 형식입니다.';
                    this.isShowPopupOk = true;
                    return;
                }
                if(file.size > nftFileMaxSize){
                    // alert("파일사이즈 : "+ file.size +", 최대파일사이즈 : 1MB");
                    this.initPopupOkMessage = '파일 크기는 최대 40MB까지만 가능합니다.';
                    this.isShowPopupOk = true;
                    return;
                }
                this.isImageNft = !this.checkImageFileExt(file.name);    //NFT 가 이미지 인지 체크
                if(this.isImageNft){
                    this.showImage(file, type);
                } else {
                    this.showVideo(file, type);
                }
            } else if(type === "thumbnail"){
                if(this.checkImageFileExt(file.name)){
                    this.initPopupOkMessage = '지원하지 않는 파일 형식입니다.';
                    this.isShowPopupOk = true;
                    return;
                }
                if(file.size > thumbnailMaxSize){
                    // alert("파일사이즈 : "+ file.size +", 최대파일사이즈 : 1MB");
                    this.initPopupOkMessage = '파일 크기는 최대 2MB까지만 가능합니다.';
                    this.isShowPopupOk = true;
                    return;
                }
                this.showImage(file, type);
            }
            // this.reqUploadResource(file, 208, 140);  //이미지 가로/세로 사이즈 체크 필요 시
            // if (document.getElementById(this.idUploadFile)) {
            //     document.getElementById(this.idUploadFile).value = '';
            // }
        },
        showImage (file, type){ //,limit_width, limit_height) {
            var that = this;
            let reader = new FileReader();
            reader.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    if(type === 'nft'){
                        that.uploadNft = {name: file.name, path: image.src};
                    } else if(type === 'thumbnail'){
                        that.uploadThumbnail = {name: file.name, path: image.src};
                    }
                    
                    // var height = this.height;
                    // var width = this.width;
                    // if (height !== limit_height || width !== limit_width) {
                    //     that.initPopupOkMessage = '썸네일 이미지 크기는 208*140px 로 제한됩니다.'
                    //     that.isShowPopupOk = true;
                    // } else {
                    //  that.thumbnail = {name: file.name, path: image.src};
                    // }
                };
            }
            reader.readAsDataURL(file);
        },
        showVideo(file, type){
            if(type === 'nft'){
                this.uploadNft = {name: file.name, path: URL.createObjectURL(file)};
            }
            // console.log(URL.createObjectURL(file));
            // var that = this;
            // let reader = new FileReader();
            // reader.onload = () => {
                
            // }
            // reader.readAsDataURL(file);
        },
        getMyCollectionList(){   //내가 등록한 모든 아이템 조회
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: 1,
                pageSize: 9999,
                order: '1',
                // orderColumn: '',
                // apiStartDate: '',
                // apiEndDate: '',
                searchText: '',
                lang: this.$i18n.locale,
                // state: 0,
                // stateList: '',
            }
            this.$Axios.post(`${this.host}/nft/collection/list/mine`, param, this.headers).then(async (res) => {
                // console.log('---get collection list mine----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    res.data.list.forEach((item) => {
                        var collectionItem = {
                            value: item.collectionIdx,
                            text: item.collectionName
                        }
                        this.myCollectionList.push(collectionItem)
                    });
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        async uploadItemResource(){
            let userUploadFile = this.$refs.fileNft.files;
            let file = userUploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            return await this.$Axios.post(`${this.host}/nft/upload/resource/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--nft upload resource--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data;
                } else {
                    this.error(res.data.returnCode);
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return false;
            });

        },
        async uploadThumbnailImage(){
            if(this.isImageNft) return null;

            let userUploadFile = this.$refs.fileThumbnail.files;
            let file = userUploadFile[0];
            let formData = new FormData();
            formData.append('file', file);
            return await this.$Axios.post(`${this.host}/nft/upload/thumbnail/${this.$store.state.id}/${this.$store.state.ticket}`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log('--nft upload thumbnail--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data;
                } else {
                    this.error(res.data.returnCode);
                    return null;
                }
            })
            .catch((error) => {
                this.error(error);
                return false;
            });

        },
        async reqRegisterItem(){
            if(this.isWorkingRegist){
                this.initPopupOkMessage = '아이템 생성 중입니다. 잠시만 기다려 주세요.';
                this.isShowPopupOk = true;
                return;
            }

            if(this.uploadNft === null){
                this.initPopupOkMessage = '아이템 리소스를 추가해 주세요';
                this.isShowPopupOk = true;
                return;
            } else {
                if(!this.isImageNft && this.uploadThumbnail === null){
                    this.initPopupOkMessage = '썸네일 이미지를 추가해 주세요';
                    this.isShowPopupOk = true;
                    return;
                }
            }

            if(this.itemName.length <= 0){
                this.initPopupOkMessage = '아이템 이름을 입력해 주세요.';
                this.isShowPopupOk = true;
                return;
            } else if(this.itemName.length > 45){
                this.initPopupOkMessage = '아이템 이름은 최대 45자까지만 등록할 수 있습니다.';
                this.isShowPopupOk = true;
                return;
            }

            if(this.itemDescription.length > 0 && this.itemDescription.length > 1000){
                this.initPopupOkMessage = '아이템 설명은 최대 1000자까지만 등록할 수 있습니다.';
                this.isShowPopupOk = true;
                return;
            }
            if(this.selectedCategory === -1){
                this.initPopupOkMessage = '카테고리를 선택해 주세요';
                this.isShowPopupOk = true;
                return;
            }
            if(this.selectedCategory === -1){
                this.initPopupOkMessage = '카테고리를 선택해 주세요';
                this.isShowPopupOk = true;
                return;
            }
            if(this.selectedCollectionIdx === null){
                this.initPopupOkMessage = '컬렉션을 선택해 주세요';
                this.isShowPopupOk = true;
                return;
            }
            if(this.numberAmount <= 0){
                this.initPopupOkMessage = '발행할 수량을 1개이상 입력해 주세요';
                this.isShowPopupOk = true;
                return;
            }

            this.isWorkingRegist = true;
            
            var uploadResourceInfo = await this.uploadItemResource();
            if(uploadResourceInfo === null){
                this.isWorkingRegist = false;
                return;
            }

            var uploadThumbnailInfo = await this.uploadThumbnailImage();
            if(!this.isImageNft && uploadThumbnailInfo === null){
                this.isWorkingRegist = false;
                return;
            }

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                fileName: uploadResourceInfo.fileName,
                thumbnailFileName: this.isImageNft ? null : uploadThumbnailInfo.fileName,
                category: this.selectedCategory,
                productName: this.itemName,
                productDesc: this.itemDescription,
                isExistUnlockedContent: this.isCheckedUnlockedContent,
                unlockedContent: this.unlockedContent,
                totalSupply: this.numberAmount,
                flagFreezeMetadata: 0,
                collectionIdx: this.selectedCollectionIdx
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/nft/register`, param, this.headers).then(async(res) => {
                // console.log('--nft register--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isWorkingRegist = false;
                    this.initPopupOkMessage = '아이템 등록 성공';
                    this.PopupOkCallbakPage = "/MyCollection";
                    this.isShowPopupOk = true;
                } else {
                    this.error(res.data.returnCode);
                    this.isWorkingRegist = false;
                }
            })
            .catch((error) => {
                this.isWorkingRegist = false;
                this.error(error);
            });
        },
        changeCategory(categoryNo){
            this.selectedCategory = categoryNo;
        },
        checkRows(){
             var rows = this.itemDescription.split('\n').length;
             if(rows > 5){
                this.initPopupOkMessage = '아이템 소개는 5줄까지만 입력이 가능합니다.';
                this.isShowPopupOk = true;
                var modifiedText = this.itemDescription.split("\n").slice(0, 5);
                this.itemDescription = modifiedText.join("\n");
             }
        },
        commaAmount() {
            var x = "";
            x = this.amount.toString();
            x = this.numberOnly(x, true);
            
            this.numberAmount = Number(x);
            this.amount = this.setComma(x);
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    }
}
</script>