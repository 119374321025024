<template>
    <div class="item-info">
        <Header :initHeaderType="initHeaderType" @refreshConfigInfo="refreshConfigInfo" />
        <b-container>
            <div class="my-item" v-if="productDetail !== null">
                <router-link tag="button" class="btn-modify" :to="'/NftModify/' + productId" v-if="productDetail.productInfo.mbName === $store.state.userName && productDetail.productInfo.ownerCount === 1 && productDetail.productInfo.lastPriceSymbol === null && productDetail.itemCountOnSaleMine === 0 && productDetail.itemCountReservedMine === 0">{{ $t('iteminfo.edit') }}</router-link>
                <router-link tag="button" class="btn-sell" :to="'/NftSell/' + productId" v-if="productDetail.itemCountMine - productDetail.itemCountOnSaleMine - productDetail.itemCountReservedMine > 0">{{ $t('iteminfo.sell') }}</router-link>
            </div>
            <div class="height-box" v-else></div>
            <div class="product-info" v-if="productDetail !== null">
                <div class="info mobile">
                    <div class="who">
                        <div class="icon-box">
                            <img src="@/assets/images/reload.svg" @click="refreshInfo()" />
                            <img src="@/assets/images/siren.png" @click="initIsReport = !initIsReport" />
                            <div class="bluetooth" v-click-outside="closeShareMobile">
                                <img src="@/assets/images/bluetooth-icon.svg" @click="toggleShareMobile()"/>
                                <b-row v-if="isShareMobile" >
                                    <b-col class="twitter" @click="snsShare('twitter')">
                                        <img src="@/assets/images/icon/twitter-b.png"/>
                                        <p>{{ $t('collectionPage.share1') }}</p>
                                    </b-col>
                                    <b-col @click="snsShare('telegram')">
                                        <img src="@/assets/images/icon/telegram-b.png" class="telegram" />
                                        <p>{{ $t('collectionPage.share2') }}</p>
                                    </b-col>
                                    <b-col @click="snsShare('facebook')">
                                        <img src="@/assets/images/icon/facebook-b.png" class="facebook" />
                                        <p>{{ $t('collectionPage.share3') }}</p>
                                    </b-col>
                                    <b-col class="link" @click="snsShare('copy')">
                                        <img src="@/assets/images/icon/link-b.png" />
                                        <p>{{ $t('collectionPage.share4') }}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <router-link tag="p" :to="'/Collection/' + productDetail.productInfo.collectionIdx">{{productDetail.productInfo.collectionName}}</router-link>
                    </div>
                    <p class="name">{{productDetail.productInfo.name}}</p>
                    <div class="piece" v-if="productDetail.productInfo.itemType === 1">
                        <b-row class="bottom">
                            <b-col class="owner" @click="showListPopup('owner')">
                                <p class="count">{{productDetail.productInfo.ownerCount | comma}}</p>
                                <p>{{ $t('iteminfo.owner') }}</p>
                            </b-col>
                            <b-col class="fragment">
                                <p class="count">{{getStr(productDetail.productInfo.totalItemCount)}}</p>
                                <p>{{ $t('iteminfo.amount') }}</p>
                            </b-col>
                            <b-col class="mine" v-if="productDetail.productInfo.itemCountMine !== null">
                                <p class="count">{{getStr(productDetail.productInfo.itemCountMine)}}</p>
                                <p>{{ $t('iteminfo.fragment') }}</p>
                            </b-col>
                            <b-col class="views">
                                <p class="count">{{productDetail.productInfo.countView | comma}}</p>
                                <p>{{ $t('iteminfo.views') }}</p>
                            </b-col>
                            <b-col class="likes" @click="showListPopup('like')">
                                <p class="count">{{productDetail.productInfo.countHeart | comma}}</p>
                                <p>{{ $t('iteminfo.likes') }}</p>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="basic" v-else-if="productDetail.productInfo.itemType === 2">
                        <p class="count">{{ $t('iteminfo.views') }}<span>{{productDetail.productInfo.countView | comma}}</span></p>
                        <div class="like" @click="showListPopup('like')">
                            <p>
                                <span>♥</span> 
                                {{productDetail.productInfo.countHeart | comma}} {{ $t('iteminfo.like') }}
                            </p>
                        </div>
                        <p class="writer" v-if="productDetail.productInfo.ownerName !== null">{{ $t('iteminfo.owner') }}<router-link tag="span" :to="'/Profile/' + productDetail.productInfo.ownerName">{{productDetail.productInfo.ownerName}}</router-link></p>
                    </div>
                </div>
                <div class="product">
                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + productDetail.productInfo.filePath" v-if="$store.state.configFront !== null && productDetail.productInfo.resourceType === 1"/>
                    <video id="videoNft" ref="videoNft" autoplay="autoplay" controls controlslist="nodownload" loop preload="metadata" playsinline 
                        v-else-if="$store.state.configFront !== null && productDetail.productInfo.resourceType === 2 && (productDetail.productInfo.fileFormat === 'mp4' || productDetail.productInfo.fileFormat === 'webm')">
                        <source data-testid="AssetVideo"  :src="$store.state.configFront.cdnUrl + 'resource/' + productDetail.productInfo.filePath" type="video/mp4"  v-if="productDetail.productInfo.fileFormat === 'mp4'">
                        <source data-testid="AssetVideo" :src="$store.state.configFront.cdnUrl + 'resource/' + productDetail.productInfo.filePath" type="video/webm" v-else-if="productDetail.productInfo.fileFormat === 'webm'">
                    </video>
                    <audio id="audioNft" ref="audioNft" autoplay="autoplay" controls controlslist="nodownload" data-testid="AssetAudio" loop preload="none" 
                        v-else-if="$store.state.configFront !== null && productDetail.productInfo.resourceType === 2 && (productDetail.productInfo.fileFormat === 'mp3' || productDetail.productInfo.fileFormat === 'wav' || productDetail.productInfo.fileFormat === 'ogg')"
                        :src="$store.state.configFront.cdnUrl + 'resource/' + productDetail.productInfo.filePath">
                    </audio>

                    <div class="top">
                        <div class="like" @click="reqLike()">
                            <p>
                                <span v-if="productDetail.productInfo.likeState === null || productDetail.productInfo.likeState === 0">♡</span>
                                <span v-else>♥</span> 
                                {{productDetail.productInfo.countHeart | comma}} {{ $t('iteminfo.like') }}
                            </p>
                        </div>
                    </div>
                    <div class="description pc">
                        <div class="title-box">
                            <p class="creator">{{ $t('iteminfo.creator') }}</p>
                            <router-link :to="'/Profile/' + productDetail.productInfo.mbName">
                                <img src="@/assets/images/my-wallet.svg" alt="profile" class="photo" v-if="$store.state.configFront === null || productDetail.productInfo.memberFileName === null">
                                <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + productDetail.productInfo.memberFileName" class="photo" v-else />
                                <p>{{productDetail.productInfo.mbName}}</p>
                            </router-link>
                        </div>
                        <p class="title">{{ $t('iteminfo.title1') }}</p>
                        <p class="item-introduce">{{ productDetail.desc }}</p>
                    </div>
                </div>
                <div class="info">
                    <div class="who pc">
                        <router-link tag="p" :to="'/Collection/' + productDetail.productInfo.collectionIdx">{{productDetail.productInfo.collectionName}}</router-link>
                        <div class="icon-box">
                            <img src="@/assets/images/reload.svg" @click="refreshInfo()" />
                            <img src="@/assets/images/siren.png" @click="initIsReport = !initIsReport" />
                            <div class="bluetooth" v-click-outside="closeShare">
                                <img src="@/assets/images/bluetooth-icon.svg" @click="toggleShare()"/>
                                <b-row v-if="isShare" >
                                    <b-col class="twitter" @click="snsShare('twitter')">
                                        <img src="@/assets/images/icon/twitter-b.png"/>
                                        <p>{{ $t('collectionPage.share1') }}</p>
                                    </b-col>
                                    <b-col @click="snsShare('telegram')">
                                        <img src="@/assets/images/icon/telegram-b.png" class="telegram" />
                                        <p>{{ $t('collectionPage.share2') }}</p>
                                    </b-col>
                                    <b-col @click="snsShare('facebook')">
                                        <img src="@/assets/images/icon/facebook-b.png" class="facebook" />
                                        <p>{{ $t('collectionPage.share3') }}</p>
                                    </b-col>
                                    <b-col class="link" @click="snsShare('copy')">
                                        <img src="@/assets/images/icon/link-b.png" />
                                        <p>{{ $t('collectionPage.share4') }}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                    <p class="name pc">{{productDetail.productInfo.name}}</p>
                    <div class="piece pc" v-if="productDetail.productInfo.itemType === 1">
                        <b-row class="bottom">
                            <b-col class="owner" @click="showListPopup('owner')">
                                <p class="count">{{productDetail.productInfo.ownerCount | comma}}</p>
                                <p>{{ $t('iteminfo.owner') }}</p>
                            </b-col>
                            <b-col class="fragment">
                                <p class="count">{{getStr(productDetail.productInfo.totalItemCount)}}</p>
                                <p>{{ $t('iteminfo.amount') }}</p>
                            </b-col>
                            <b-col class="mine" v-if="productDetail.productInfo.itemCountMine !== null">
                                <p class="count">{{getStr(productDetail.productInfo.itemCountMine)}}</p>
                                <p>{{ $t('iteminfo.fragment') }}</p>
                            </b-col>
                            <b-col class="views">
                                <p class="count">{{productDetail.productInfo.countView | comma}}</p>
                                <p>{{ $t('iteminfo.views') }}</p>
                            </b-col>
                            <b-col class="likes" @click="showListPopup('like')">
                                <p class="count">{{productDetail.productInfo.countHeart | comma}}</p>
                                <p>{{ $t('iteminfo.likes') }}</p>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="basic pc" v-else-if="productDetail.productInfo.itemType === 2">
                        <p class="count">{{ $t('iteminfo.views') }}<span>{{productDetail.productInfo.countView | comma}}</span></p>
                        <div class="like" @click="showListPopup('like')">
                            <p>
                                <span>♥</span> 
                                {{productDetail.productInfo.countHeart | comma}} {{ $t('iteminfo.like') }}
                            </p>
                        </div>
                        <p class="writer" v-if="productDetail.productInfo.ownerName !== null">{{ $t('iteminfo.owner') }}<router-link tag="span" :to="'/Profile/' + productDetail.productInfo.ownerName">{{productDetail.productInfo.ownerName}}</router-link></p>
                    </div>
                    <div class="lock pc" @click="showPopupUnlockContents()" v-if="productDetail.productInfo.isExistUnlockedContent">
                        <img src="@/assets/images/icon/lock.svg" />
                        <p>{{ $t('iteminfo.include') }}</p>
                    </div>
                    <button class="priceOffer" @click="showOfferPopup()" v-if="minPriceInstance === null">가격 제안하기</button>
                    
                    <div class="bid-box" v-if="minPriceInstance !== null && minPriceInstance.saleType === 1">
                        <div class="piece-box" v-if="minPriceInstance.itemType === 1">
                            <img src="@/assets/images/icon/piece.svg" class="piece-img" />
                            <p>X {{minPriceInstance.remainCount | comma}}</p>
                        </div>
                        <div class="curr">
                            <p class="title">{{ $t('iteminfo.current') }}</p>
                            <p class="cont">{{minPriceInstance.priceAmount | comma}} <span>{{minPriceInstance.priceSymbol}}</span></p>
                            <p class="dollar">$ {{$store.getters.getTokenUsdPrice(minPriceInstance.priceSymbol, minPriceInstance.priceAmount, 4) | comma}}</p>
                        </div>
                        <div class="end">
                            <p class="title">{{ $t('iteminfo.end') }}</p>
                            <p class="cont">{{displayCountdown}}</p>
                        </div>
                        <!-- <a class="button buy2" @click="showBuyPopup(minPriceInstance)">{{ $t('iteminfo.bid') }}</a> -->
                        <div class="buttons">
                            <a class="button buy" @click="showBuyPopup(minPriceInstance)">{{ $t('iteminfo.bid') }}</a>
                            <a class="button" @click="showOfferPopup()">{{$t('iteminfo.title5')}}</a>
                        </div>
                    </div>
                    
                    <div class="bid-box" v-else-if="minPriceInstance !== null && minPriceInstance.saleType === 2">
                        <div class="curr" v-if="minPriceInstance.bidMaxPrice === null">
                            <p class="title">{{ $t('iteminfo.price3') }}</p>
                            <p class="cont">{{minPriceInstance.priceAmount | comma}} <span>{{minPriceInstance.priceSymbol}}</span></p>
                            <p class="dollar">$ {{$store.getters.getTokenUsdPrice(minPriceInstance.priceSymbol, minPriceInstance.priceAmount, 4) | comma}}</p>
                        </div>
                        <div class="curr" v-else>
                            <p class="title">{{ $t('iteminfo.best') }}</p>
                            <p class="cont">{{minPriceInstance.bidMaxPrice | comma}} <span>{{minPriceInstance.priceSymbol}}</span></p>
                            <p class="dollar">$ {{$store.getters.getTokenUsdPrice(minPriceInstance.priceSymbol, minPriceInstance.bidMaxPrice, 4) | comma}}</p>
                        </div>
                        <div class="end">
                            <p class="title">{{ $t('iteminfo.end') }}</p>
                            <p class="cont">{{displayCountdown}}</p>
                        </div>
                        <a class="button buy2" @click="showBuyPopup(minPriceInstance)">{{ $t('iteminfo.bid2') }}</a>
                        <!-- <a class="button" @click="showOfferPopup()">{{$t('iteminfo.title5')}}</a> -->
                    </div>
                    <div class="description mobile">
                        <div class="title-box">
                            <p class="creator">{{ $t('iteminfo.creator') }}</p>
                            <router-link :to="'/Profile/' + productDetail.productInfo.mbName">
                                <img src="@/assets/images/my-wallet.svg" alt="profile" class="photo" v-if="$store.state.configFront === null || productDetail.productInfo.memberFileName === null">
                                <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + productDetail.productInfo.memberFileName" class="photo" v-else />
                                <p>{{productDetail.productInfo.mbName}}</p>
                            </router-link>
                        </div>
                        <p class="title">{{ $t('iteminfo.title1') }}</p>
                        <p class="item-introduce">{{ productDetail.desc }}</p>
                    </div>
                    <div class="history">
                        <div class="price">
                            <p class="title">{{ $t('iteminfo.title2') }}</p>
                            <div class="graph" v-if="isExistChartData">
                                <apexCharts ref="chartPrice" type="line" :options="chartOptions" :series="chartData" />
                            </div>
                            <div class="no-graph" v-else>
                                <img src="@/assets/images/nograph.svg" />
                                <p>{{ $t('iteminfo.noActive') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="status">
                <div class="top">
                    <div class="sales">
                        <p class="title">{{ $t('iteminfo.title4') }}</p>
                        <div class="list-box">
                            <div class="lr-space">
                                <b-row class="title-box">
                                    <b-col class="price" cols="6" sm="6" lg="6">{{ $t('iteminfo.salesCol1') }}</b-col>
                                    <b-col class="quantity" cols="2" sm="2" lg="2">{{ $t('iteminfo.salesCol2') }}</b-col>
                                    <b-col class="expire" cols="2" sm="2" lg="2">{{ $t('iteminfo.salesCol3') }}</b-col>
                                    <b-col class="seller" cols="2" sm="2" lg="2">{{ $t('iteminfo.salesCol4') }}</b-col>
                                </b-row>
                            </div>
                            <div class="scroll">
                                <b-row v-for="(item,idx) in instanceList" :key="idx" class="eth-list">
                                    <b-col class="eth" cols="4" sm="4" lg="4">
                                        <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.priceSymbol === 'ATT'" />
                                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.priceSymbol === 'KLAY'" />
                                        {{item.priceAmount | comma}} {{item.priceSymbol}}
                                    </b-col>
                                    <b-col class="purchase" cols="2" sm="2" lg="2" @click="showCancelPopup(item)" v-if="item.sellerName === $store.state.userName"><p>{{ $t('iteminfo.cancle') }}</p></b-col>
                                    <b-col class="purchase" cols="2" sm="2" lg="2" @click="showBuyPopup(item)" v-else-if="item.saleType === 1"><p>{{$t('iteminfo.buy')}}</p></b-col>
                                    <b-col class="purchase" cols="2" sm="2" lg="2" v-else></b-col>
                                    <b-col class="quantity" cols="2" sm="2" lg="2">{{item.remainCount | comma}}</b-col>
                                    <b-col class="expire" cols="2" sm="2" lg="2">{{$store.getters.getRemainDate(item.expireDate)}}</b-col>
                                    <b-col class="seller" cols="2" sm="2" lg="2"><router-link tag="a" :to="'/Profile/' + item.sellerName">{{item.sellerName}}</router-link></b-col>
                                </b-row>
                                <div class="no-sell" v-if="instanceList.length <= 0">
                                    <img src="@/assets/images/icon/label.svg" />
                                    <p>{{ $t('iteminfo.noSale') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bid" v-if="minPriceInstance !== null && minPriceInstance.saleType === 2">
                        <p class="title">{{ $t('iteminfo.title6') }}</p>
                        <div class="list-box">
                            <div class="lr-space">
                                <b-row class="title-box">
                                    <b-col class="price" cols="6" sm="6" lg="6">{{ $t('iteminfo.status1') }}</b-col>
                                    <b-col class="buyer" cols="4" sm="4" lg="4">{{ $t('iteminfo.status4') }}</b-col>
                                    <b-col class="purchase" cols="2" sm="2" lg="2">{{ $t('iteminfo.status2') }}</b-col>
                                </b-row>
                            </div>
                            <div class="scroll">
                                <b-row v-for="(item,idx) in bidList" :key="idx" class="eth-list">
                                    <b-col class="eth" cols="6" sm="6" lg="6">
                                        <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.symbol === 'ATT'" />
                                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.symbol === 'KLAY'" />
                                        {{item.price | comma}} {{item.symbol}}
                                    </b-col>
                                    <b-col class="seller" cols="4" sm="4" lg="4"><router-link tag="a" :to="'/Profile/' + item.memberName">{{item.memberName}}</router-link></b-col>
                                    <b-col class="purchase cancel" cols="2" sm="2" lg="2" @click="showBidCancelPopup(item)" v-if="item.state === 0 && item.memberAddress === $store.state.walletAddress"><p>{{ $t('iteminfo.cancle') }}</p></b-col>
                                    <b-col class="purchase" cols="2" sm="2" lg="2" v-else>
                                        <p v-if="item.state === 0"></p>
                                        <p v-if="item.state === 1">{{ $t('iteminfo.canceled') }}</p>
                                        <p v-if="item.state === 2">{{ $t('iteminfo.directCancel') }}</p>
                                    </b-col>
                                </b-row>
                                <div class="no-offer" v-if="bidList.length <= 0">
                                    <img src="@/assets/images/icon/icon_no_bid.svg" />
                                    <p>{{ $t('iteminfo.noBidInfo') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="offer" v-else>
                        <p class="title">{{ $t('iteminfo.title5') }}</p>
                        <div class="list-box">
                            <div class="lr-space">
                                <b-row class="title-box">
                                    <b-col class="price" cols="5">{{ $t('iteminfo.status1') }}</b-col>
                                    <!-- <b-col class="quantity" cols="2>{{ $t('iteminfo.salesCol2') }}</b-col> -->
                                    <b-col class="buyer" cols="5">{{ $t('iteminfo.status4') }}</b-col>
                                    <b-col class="buy" cols="2"></b-col>
                                </b-row>
                            </div>
                            <div class="scroll">
                                <b-row v-for="(item,idx) in offerList" :key="idx" class="eth-list">
                                    <b-col class="eth" cols="5">
                                        <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.offerSymbol === 'ATT'" />
                                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.offerSymbol === 'KLAY'" />
                                        {{item.offerPricePerOne | comma}} {{item.offerSymbol}} ($ {{$store.getters.getTokenUsdPrice(item.offerSymbol, item.offerPricePerOne, 4) | comma}})
                                    </b-col>
                                    <!-- <b-col class="quantity" cols="2" sm="2" lg="2">{{item.remainCount | comma}}</b-col> -->
                                    <b-col class="seller" cols="4"><router-link tag="a" :to="'/Profile/' + item.memberName">{{item.memberName}}</router-link></b-col>
                                    
                                    <b-col class="purchase" cols="3" @click="showOfferCancelPopup(item)" v-if="item.memberName === $store.state.userName"><p>{{ $t('iteminfo.cancle') }}</p></b-col>
                                    <b-col class="purchase" cols="3" v-else-if="productDetail.productInfo.itemCountMine > 0" @click="showOfferAcceptPopup(item)" ><p>수락</p></b-col>
                                </b-row>
                                <div class="no-offer" v-if="offerList.length <= 0">
                                    <img src="@/assets/images/icon/icon_no_bid.svg" />
                                    <p>{{ $t('iteminfo.noOffer') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="buttons">
                        <a class="transaction" :class="{'on' : selectedInfoTab === 1}" @click="selectedInfoTab=1">{{ $t('iteminfo.history-button') }}</a>
                        <a class="chain" :class="{'on' : selectedInfoTab === 2}" @click="selectedInfoTab=2">{{ $t('iteminfo.information-button') }}</a>
                    </div>
                    <div class="t-history" v-if="selectedInfoTab === 1">
                        <div class="lr-space">
                            <b-row class="title-box">
                                <b-col cols="1" sm="1" lg="1">{{ $t('iteminfo.status-history1') }}</b-col>
                                <b-col cols="2" sm="2" lg="2" class="price-t" >{{ $t('iteminfo.status-history2') }}</b-col>
                                <b-col cols="3" sm="3" lg="3" class="from" >{{ $t('iteminfo.status-history3') }}</b-col>
                                <b-col cols="3" sm="3" lg="3" class="to" >{{ $t('iteminfo.status-history4') }}</b-col>
                                <b-col cols="2" sm="2" lg="2" class="date-t" >{{ $t('iteminfo.status-history5') }}</b-col>
                                <b-col cols="1" sm="1" lg="1" class="tx"  >{{ $t('iteminfo.status-history6') }}</b-col>
                            </b-row>
                        </div>
                        <div class="scroll">
                            <b-row v-for="(item,idx) in transactionList" :key="idx">
                                <!-- <b-col cols="1" sm="1" lg="1" class="event" v-if="item.type === 1"><img src="@/assets/images/icon/sell.svg" />{{ $t('iteminfo.mint') }}</b-col>
                                <b-col cols="1" sm="1" lg="1" class="event" v-else-if="item.type === 2"><img src="@/assets/images/icon/sell.svg" />{{ $t('collectionPage.sell') }}</b-col>
                                <b-col cols="1" sm="1" lg="1" class="event" v-else-if="item.type === 3"><img src="@/assets/images/icon/send.svg" />{{ $t('collectionPage.transfer') }}</b-col>
                                <b-col cols="1" sm="1" lg="1" class="event" v-else-if="item.type === 4"><img src="@/assets/images/icon/bid.svg" />{{ $t('collectionPage.buy') }}</b-col> 
                                <b-col cols="1" sm="1" lg="1" class="event" v-else>&nbsp;</b-col> -->

                                <b-col cols="1" sm="1" lg="1" class="event">
                                    <img src="@/assets/images/transaction/type1.svg" v-show="item.type === 2 || item.type === 15" />
                                    <img src="@/assets/images/transaction/type2.svg" v-show="item.type === 11" />
                                    <img src="@/assets/images/transaction/type3.svg" v-show="item.type === 9" />
                                    <img src="@/assets/images/transaction/type4.svg" v-show="item.type === 10" />
                                    <img src="@/assets/images/transaction/type5.svg" v-show="item.type === 12 || item.type === 28" />
                                    <img src="@/assets/images/transaction/type6.svg" v-show="item.type === 7 || item.type === 21 || item.type === 25" />
                                    <!-- <img src="@/assets/images/transaction/type7.svg" v-show="item.type === 1" /> -->
                                    <img src="@/assets/images/transaction/type8.svg" v-show="item.type === 20" />
                                    <img src="@/assets/images/transaction/type9.svg" v-show="item.type === 19" />
                                    <img src="@/assets/images/transaction/type10.svg" v-show="item.type === 0" />
                                    <img src="@/assets/images/transaction/type11.svg" v-show="item.type === 3 || item.type === 16" />
                                    <img src="@/assets/images/transaction/type12.svg" v-show="item.type === 4 || item.type === 17" />
                                    <p>{{ $t('transactional.event_type_' + item.type) }}</p>
                                </b-col>
                                
                                <!-- <b-col lg="1" class="event" v-if="item.type === 4">
                                    <img src="@/assets/images/icon/send.svg" />
                                    <p>전송</p>
                                </b-col> -->
                                <!-- <b-col lg="1" class="event" v-else-if="item.type === 2">
                                    <img src="@/assets/images/icon/sell.svg" />
                                    <p>판매</p>
                                </b-col> -->
                                <!-- <b-col lg="1" class="event" v-else-if="item.type === 3">
                                    <img src="@/assets/images/icon/sell-upload.svg" />
                                    <p>판매등록</p>
                                </b-col> -->
                                <!-- <b-col lg="1" class="event" v-else-if="item.type === 1">
                                    <img src="@/assets/images/icon/create-item.svg" />
                                    <p>아이템생성</p>
                                </b-col> -->

                                <b-col cols="2" sm="2" lg="2" class="price" v-if="item.price === null">-</b-col>
                                <b-col cols="2" sm="2" lg="2" class="price" v-else>
                                    <img src="@/assets/images/icon/icon_symbol_artube.png" class="symbol" v-if="item.symbol === 'ATT'" />
                                    <img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="symbol" v-else-if="item.symbol === 'KLAY'" />
                                    <p>{{item.price | comma}}</p>
                                </b-col>

                                <b-col cols="3" sm="3" lg="3" class="profile" v-if="item.type === 1 || (item.fromId === null && item.from ===  null)">-</b-col>
                                <b-col cols="3" sm="3" lg="3" class="profile" v-else>
                                    <img src="@/assets/images/icon/wallet.svg" class="img-wallet" v-if="item.fromId === null" />
                                    <img src="@/assets/images/my-wallet.svg" alt="profile" class="photo" v-else-if="$store.state.configFront === null || item.fromFileName === null">
                                    <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.fromFileName" class="photo" v-else />
                                    
                                    <router-link tag="p" :to="'/Profile/' + item.fromId" class="skip" v-if="item.fromId !== null">{{item.fromId}}</router-link>
                                    <p class="skip" v-else>{{item.from}}</p>
                                </b-col>

                                <b-col cols="3" sm="3" lg="3" class="profile">
                                    <img src="@/assets/images/icon/wallet.svg" class="img-wallet" v-if="item.toId === null" />
                                    <img src="@/assets/images/my-wallet.svg" alt="profile" class="photo" v-else-if="$store.state.configFront === null || item.toFileName === null">
                                    <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.toFileName" class="photo" v-else />
                                    
                                    <router-link tag="p" :to="'/Profile/' + item.toId" class="skip" v-if="item.toId !== null">{{item.toId}}</router-link>
                                    <p class="skip" v-else>{{item.to}}</p>
                                </b-col>
                                
                                <b-col cols="2" sm="2" lg="2" class="date">{{$store.getters.getLeftDate(item.date)}}</b-col>
                                <b-col cols="1" sm="1" lg="1" class="tx"><a :href="$store.getters.getCurrentUrlForTx(item.txConfirm)" target="_blank" v-if="item.txConfirm !== null"><img src="@/assets/images/icon_link_off.svg" class="link"></a></b-col>
                            </b-row>
                        </div>
                    </div>
                    <div class="information" v-if="selectedInfoTab === 2">
                        <div class="lr-space">
                            <b-row class="title-box">
                                <b-col cols="4" sm="4" lg="4" class="contract">{{ $t('iteminfo.status-information1') }}</b-col>
                                <b-col cols="2" sm="2" lg="2">{{ $t('iteminfo.status-information2') }}</b-col>
                                <b-col cols="2" sm="2" lg="2">{{ $t('iteminfo.status-information3') }}</b-col>
                                <b-col cols="2" sm="2" lg="2">{{ $t('iteminfo.status-information4') }}</b-col>
                                <b-col cols="2" sm="2" lg="2">{{ $t('iteminfo.status-information5') }}</b-col>
                                <!-- <b-col cols="1" sm="1" lg="1">{{ $t('iteminfo.status-information6') }}</b-col> -->
                            </b-row>
                        </div>
                        <div class="scroll">
                            <b-row>
                                <b-col cols="4" sm="4" lg="4" class="contract"><a :href="$store.getters.getCurrentUrlForNft(chainInfo.contractAddress)" target="_blank" v-if="$store.state.configFront !== null">{{chainInfo.contractAddress}}</a></b-col>
                                <b-col cols="2" sm="2" lg="2">{{chainInfo.tokenId}}</b-col>
                                <b-col cols="2" sm="2" lg="2">{{chainInfo.tokenType === 1 ? 'ERC-1155' : chainInfo.tokenType === 2 ? 'ERC-721' : ''}}</b-col>
                                <b-col cols="2" sm="2" lg="2">{{chainInfo.blockChain}}</b-col>
                                <b-col cols="2" sm="2" lg="2">{{chainInfo.royalty}}%</b-col>
                                <!-- <b-col cols="1" sm="1" lg="1">{{chainInfo.metaData}}</b-col> -->
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
        <b-container class="slider">
            <div>
                <div class="title">
                    <p>{{ $t('iteminfo.title7') }}</p>
                    <router-link tag="a" :to="'/Collection/' + productDetail.productInfo.collectionIdx" v-if="productDetail !== null">{{ $t('iteminfo.seeCollection') }}</router-link>
                </div>
                <Slide :slideItem="collectionOtherItems" :swiperNextButton="'info-slide-next'" :swiperOptionNext="'.info-slide-next'" :swiperPrevButton="'info-slide-prev'" :swiperOptionPrev="'.info-slide-prev'" />
            </div>
        </b-container>
        
        <PopupBuy :initIsShowPopup="initIsBuyPopup" :initBuyInstance="initBuyInstance" @close="close('Buy')" @error="error" @completeBuy="completeBuy" @completePlaceBid="completePlaceBid" />
        <PopupOffer :initIsShowPopup="initIsOfferPopup" :initProductDetail="productDetail" :initBuyInstance="minPriceInstance" @close="close('Offer')" @error="error" @completeMakeOffer="completeMakeOffer"/>
        <PopupOfferAccept :initIsShowPopup="initIsOfferAcceptPopup" :initProductDetail="productDetail" :initAcceptOfferInfo="initAcceptOfferInfo" @close="close('OfferAccept')" @error="error" @completeAcceptOffer="completeAcceptOffer"/>
        <PopupCancel :initIsShowPopup="initIsCancelPopup" :initCancelInstance="initCancelInstance" @close="close('CancelListing')" @error="error" @completeCancelSale="completeCancelSale" @completeAuctionCancel="completeAuctionCancel" @failedCancelSale="failedCancelSale" />
        <PopupBidCancel :initIsShowPopup="initIsBidCancelPopup" :initSaleInstance="minPriceInstance" :initBidInfo="initCancelBidInfo" @close="close('BidCancel')" @completeBidCancel="completeBidCancel" />
        <PopupOfferCancel :initIsShowPopup="initIsOfferCancelPopup" :initProductDetail="productDetail" :initCancelOfferInfo="initCancelOfferInfo" @close="close('OfferCancel')" @completeOfferCancel="completeOfferCancel" />
        <PopupList :initIsShowPopup="initIsListPopup" :initPopupTitle="initPopupTitle" :initListType="initListType" :initList="initList" @close="close('List')" />
        <PopupReport :initIsShowPopup="initIsReport" :initTargetIdx="productId" :initReportType="initReportType" @close="close('Report')" />
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupUnlock :initIsShowPopup="initIsUnlockPopup" :initUnlockContent="initUnlockContent" @close="close('Unlock')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Slide from '@/components/Slide.vue'
import PopupCancel from '@/components/market/PopupCancel.vue'
import PopupBuy from '@/components/market/PopupBuy.vue'
import PopupOffer from '@/components/market/PopupOffer.vue'
import PopupOfferAccept from '@/components/market/PopupOfferAccept.vue'
import PopupBidCancel from '@/components/market/PopupBidCancel.vue'
import PopupOfferCancel from '@/components/market/PopupOfferCancel.vue'
import PopupList from '@/components/market/PopupList.vue'
import PopupReport from '@/components/market/PopupReport.vue'
import PopupUnlock from '@/components/market/PopupUnlock.vue'
import PopupOK from '@/components/popup/PopupOK.vue'

import vClickOutside from 'v-click-outside'
import VueApexCharts from "vue-apexcharts"
import dayjs from"dayjs"

export default {
    name: "NftInfo",
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        Header,
        Slide,
        PopupCancel,
        PopupBuy,
        PopupOffer,
        PopupOfferAccept,
        PopupList,
        PopupReport,
        PopupOK,
        PopupBidCancel,
        PopupOfferCancel,
        PopupUnlock,
        apexCharts: VueApexCharts,
    },
    data() {
        return {
            initHeaderType: 'type1',

            productId: 0,
            productDetail: null,
            minPriceInstance: null,
            ownerList: [],
            likeMemberList: [],
            instanceList: [],
            offerList: [],
            bidList: [],
            transactionList: [],
            chainInfo: null,
            collectionOtherItems: [],

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 999,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수

            selectedInfoTab: 1,

            listCount: 0,
            pageCount: 0,
            pageNo: 0,
            pageSize: 0,
            totalRecordSize: 0,

            initListType: '',
            initList: [],

            initIsCancelPopup: false,
            initCancelInstance: null,

            initIsBidCancelPopup: false,
            initCancelBidInfo: null,

            initIsOfferCancelPopup: false,
            initCancelOfferInfo: null,

            initIsOfferAcceptPopup: false,
            initAcceptOfferInfo: null,

            initIsUnlockPopup: false,
            initUnlockContent: '',

            initIsOfferPopup: false,
            initIsBuyPopup: false,
            initBuyInstance: null,
            
            initIsListPopup: false,
            initPopupTitle: '',
            initOwner: false,
            initIsReport: false,
            initReportType: 0,  //type 0:아이템 1: 컬렉션 2: 멤버 신고

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            selected: null,

            displayCountdown: "00:00:00",
            countdownDistance: 0,
            intervalCountdown: null,

            isShare: false,
            isShareMobile: false,
            options:[
                { value: null, text: '전체' }
            ],

            isExistChartData: false,
            chartData: [],
            chartOptions: {}
        };
    },
    props: {
    },
    created() {
        if(this.$route.params.productId){
            this.productId = Number(this.$route.params.productId);
            if(isNaN(this.productId)){
                this.$router.push('/');
            }
            this.getNftDetail();
            this.getNftInstanceList();
            this.getTradingList();
            this.getNftTransactionList();
            this.getNftChainInfo();
            this.getCollectionOtherItem();
        } else {
            this.$router.push("/");
        }
    },
    mounted() {
    },
    beforeDestroy() {
        clearInterval(this.intervalCountdown);
    },
    computed: {
    },
    methods: {
        toggleShare(){
            this.isShare = !this.isShare;
        },
        closeShare(){
            this.isShare = false;
        },
        toggleShareMobile(){
            this.isShareMobile = !this.isShareMobile;
        },
        closeShareMobile(){
            this.isShareMobile = false;
        },
        snsShare(shareType){
            this.isShare = false;
            this.isShareMobile = false;

            var currUrl = window.location.href;
            if(shareType === 'twitter'){
                window.open("https://twitter.com/intent/tweet?url=" + currUrl);
                //window.open("https://twitter.com/intent/tweet?text=" + sendText + "&url=" + sendUrl);
            } else if (shareType === 'telegram'){
                window.open("https://telegram.me/share/url?url=" + currUrl);
                //window.open("https://telegram.me/share/url?url=" + url + "&text=" + text);
            } else if (shareType === 'facebook'){
                window.open("http://www.facebook.com/sharer/sharer.php?u=" + currUrl);
            } else if (shareType === 'copy'){
                this.$copyText(currUrl);
                this.initPopupOkMessage = "링크가 복사되었습니다."
                this.isShowPopupOk = true;
            }
        },
        productCountDownTimer() {
            var that = this;
            // var currDate = Date.now();
            // var serverDate = Date.parse(this.$store.state.configFront.dateServer.replace(/-/g, "/"));
            var serverDate = Date.parse(this.productDetail.currentDate.replace(/-/g, "/"));
            var expireDate = Date.parse(this.minPriceInstance.expireDate.replace(/-/g, "/"));

            if (serverDate > expireDate) {
                return;
            }
            this.countdownDistance = expireDate - serverDate;
            this.showRemaining();
            clearInterval(this.intervalCountdown);
            this.intervalCountdown = setInterval(() => {
                that.showRemaining();
            }, 1000);
        },
        showRemaining() {
            var _second = 1000;
            var _minute = _second * 60;
            var _hour = _minute * 60;
            var _day = _hour * 24;

            if (this.countdownDistance < 0) {
                if (this.intervalCountdown) {
                    clearInterval(this.intervalCountdown);
                    this.getNftDetail();
                    this.getNftInstanceList();
                    this.getNftTransactionList();
                }
                return;
            }
            var days = Math.floor(this.countdownDistance / _day);
            var hours = Math.floor((this.countdownDistance % _day) / _hour);
            var minutes = Math.floor((this.countdownDistance % _hour) / _minute);
            var seconds = Math.floor((this.countdownDistance % _minute) / _second);
            
            this.displayCountdown =
                (days > 0 ? days.toString() + 'd ' : '' ) 
                +
                (hours < 10 ? "0" + hours.toString() : hours.toString()) +
                ":" +
                (minutes < 10 ? "0" + minutes.toString() : minutes.toString()) +
                ":" +
                (seconds < 10 ? "0" + seconds.toString() : seconds.toString());

            this.countdownDistance = this.countdownDistance - _second;
        },
        getNftDetail(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: this.productId
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/nft/detail`, param, this.headers).then(async (res) => {
                // console.log('------- get item detail --------')
                // console.log(res.data.data)
                if(res.data.returnCode === 0){
                    if(res.data.data.productInfo.deleteState === 1){    //0 삭제안됨 1 삭제됨
                        this.initPopupOkMessage = '삭제된 아이템 입니다.';
                        this.PopupOkCallbakPage = '/'
                        this.isShowPopupOk = true;
                        return;
                    }
                    this.productDetail = res.data.data;
                    this.collectionIdx = this.productDetail.productInfo.collectionIdx;
                    this.getNftMinPriceInstance();
                    this.getLikeMemberList();

                    if(this.productDetail.productInfo.itemType === 1){
                        this.getOwnerList();
                    }

                    if(this.productDetail.productInfo.saleType !== 2){
                        this.getNftOfferList();
                    }
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftMinPriceInstance(){
            var param = 
            {
                productId: this.productId
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/instance/list`, param, this.headers).then(async (res) => {
                // console.log('------- get min price instance --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    this.minPriceInstance = null;
                    if(res.data.list.length > 0) {
                        this.minPriceInstance = res.data.list[0];
                        if(this.minPriceInstance.expireDate !== null){
                            this.productCountDownTimer();
                        }
                        if(this.minPriceInstance.saleType === 2){   //경매일 경우 입찰 리스트 조회
                            this.getNftInstanceBidList();
                        }
                    }
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getOwnerList(){
            var param = 
            {
                productIdx: this.productId
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/inventory/list/owner`, param, this.headers).then(async (res) => {
                // console.log('------- get owner list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    this.ownerList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getLikeMemberList(){
            var param = 
            {
                productIdx: this.productId
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/like/member/list`, param, this.headers).then(async (res) => {
                // console.log('------- get like member list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    this.likeMemberList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftInstanceList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                // order: '1',
                // orderColumn: '',
                // apiStartDate: '',
                // apiEndDate: '',
                // searchText: '',
                // lang: this.$i18n.locale,
                // state: 0,
                // stateList: '',
                productId: this.productId,
                orderType: 4,
                isDuplicate: true,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/instance/list`, param, this.headers).then(async (res) => {
                // console.log('------- get instance list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    res.data.list.forEach((item,index)=>{
                        res.data.list[index].currencyPrice = this.$store.getters.getTokenUsdPrice(item.priceSymbol, item.priceAmount);
                    });
                    res.data.list.sort((a, b)=>{ return a.currencyPrice - b.currencyPrice; });  //시세로 계산된 총 금액으로
                    this.instanceList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftInstanceBidList(){
            var param = 
            {
                // id: this.$store.state.id,
                // ticketId: this.$store.state.ticket,
                pageNo: 1,
                pageSize: 9999,
                instanceIdx: this.minPriceInstance.instanceId,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/instance/bid/list`, param, this.headers).then(async (res) => {
                // console.log('------- get instance bid list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    this.bidList = res.data.list;
                //     res.data.list.forEach((item,index)=>{
                //         res.data.list[index].currencyPrice = this.$store.getters.getTokenUsdPrice(item.priceSymbol, item.priceAmount);
                //     });
                //     res.data.list.sort((a, b)=>{ return a.currencyPrice - b.currencyPrice; });  //시세로 계산된 총 금액으로
                //     this.instanceList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getTradingList(){
            var param = 
            {
                productId: this.productId,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/trading/list`, param, this.headers).then(async (res) => {
                // console.log('------- get trading list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    if(res.data.list.length > 0){
                        // var categoriesData = [];
                        var seriesData = [
                            {name : '평균가', data: []},
                            {name : '최고가', data: []},
                            {name : '최저가', data: []},
                        ];
                        var isManyDays = res.data.list.length > 7;
                        var maxValue = Math.max(
                            Math.max(...res.data.list.map(o=>o.maxPrice)),
                            Math.max(...res.data.list.map(o=>o.minPrice)),
                            Math.max(...res.data.list.map(o=>o.avgPrice))
                        );
                        var minValue = Math.min(
                            Math.min(...res.data.list.map(o=>o.maxPrice)),
                            Math.min(...res.data.list.map(o=>o.minPrice)),
                            Math.min(...res.data.list.map(o=>o.avgPrice))
                        );  

                        maxValue = maxValue + (maxValue * 0.1);
                        // minValue = minValue - (minValue * 0.5);
                        minValue = 0;

                        res.data.list.forEach((item) => {
                            seriesData[0].data.push({x: item.tradeDate, y:item.avgPrice });
                            seriesData[1].data.push({x: item.tradeDate, y:item.maxPrice });
                            seriesData[2].data.push({x: item.tradeDate, y:item.minPrice });
                        });

                        this.chartOptions = {
                            chart: {
                                width: "100%",
                                // height: 250,
                                zoom: { enabled: false },
                                toolbar: {show: false },
                                // stacked: false,
                                animations:{
                                    enabled: false
                                },
                                // offsetX: 5
                            },
                            // dataLabels: {
                            //     enabled: false
                            // },
                            stroke: { 
                                width:1, 
                                curve: 'smooth',
                                lineCap: 'round'
                            },
                            grid: {
                                row: {
                                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                                opacity: 0.5
                                }
                            },
                            theme:{
                                mode: 'light', 
                                palette: 'palette1'
                            },
                            legend:{
                                position: 'top',
                                horizontalAlign: 'right'
                            },
                            yaxis: {
                                tickAmount: 4,
                                // showAlways: true,
                                min: minValue,
                                max: maxValue,
                                labels: {
                                    formatter: function (value) { 
                                        if(value === 0) return '';
                                        value = (Math.floor(value * 10000)) / 10000;
                                        return "$" + value; 
                                    }
                                },
                                axisBorder: {
                                    show: true,
                                    color: '#f0f0f0',
                                    offsetX: 0,
                                }
                            },
                            xaxis: {
                                // tickAmount: 1,
                                type: isManyDays ? 'datetime' : '',
                                // tickPlacement: 'between',
                                labels: {
                                    formatter: function (value) {
                                        return dayjs(value).format('MM/DD');
                                    },
                                },
                                tooltip: {
                                    enabled: false
                                }
                                // axisTicks: {
                                //     show: true,
                                //     borderType: 'solid',
                                //     height: 6,
                                //     offsetX: 0,
                                // },
                            },
                            // tooltip: {
                            //     enabled: true,
                            //     onDatasetHover: { highlightDataSeries: true, },
                            // },
                            // responsive: [
                            //     {
                            //         breakpoint: 1280,
                            //         options: {
                            //             legend: {
                            //                 position: "bottom",
                            //                 horizontalAlign: 'center'
                            //             }
                            //         }
                            //     }
                            // ]
                        }
                        // this.chartOptions.xaxis.categories = categoriesData;
                        this.chartData = seriesData;
                        this.isExistChartData = true;
                    }
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftOfferList(){
            var param = 
            {
                // id: this.$store.state.id,
                // ticketId: this.$store.state.ticket,
                pageNo: 1,
                pageSize: 9999,
                productIdx: this.productId,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/offer/product/list`, param, this.headers).then(async (res) => {
                // console.log('------- get offer list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    this.offerList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftTransactionList(){
            var param = 
            {
                productIdx: this.productId
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/info/transaction`, param, this.headers).then(async (res) => {
                // console.log('------- get transaction list --------')
                // console.log(res.data.list)
                if(res.data.returnCode === 0){
                    this.transactionList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftChainInfo(){
            var param = 
            {
                productId: this.productId
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/info/chain`, param, this.headers).then(async (res) => {
                // console.log('------- get chain info --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.chainInfo = res.data.data;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getCollectionOtherItem(){
            var param = 
            {
                productIdx: this.productId
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/info/collection/otherItem`, param, this.headers).then(async (res) => {
                // console.log('------- get collection other item --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.collectionOtherItems = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        reqLike(){
            if(this.$store.state.id === '' || this.$store.state.ticket === ''){
                return;
            }
            var apiHost = this.host + '/nft/';
            if(this.productDetail.productInfo.likeState === 0){
                apiHost += 'like';
            } else {
                apiHost += 'cancelLike';
            }
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: this.productId
            }
            this.$Axios.post(apiHost, param, this.headers).then(async (res) => {
                // console.log('------- req like --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.getNftDetail();
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        refreshInfo(){
            this.getNftDetail();
            this.getNftInstanceList();
            this.getNftTransactionList();
        },
        showListPopup(type){
            if(type === 'owner'){
                this.initPopupTitle = '소유자 목록';
                this.initList = this.ownerList;
            } else if (type === 'like'){
                this.initPopupTitle = '관심 목록';
                this.initList = this.likeMemberList;
            }
            this.initListType = type;
            this.initIsListPopup = true;
        },
        showPopupUnlockContents(){
            if(this.productDetail.productInfo.itemCountMine <= 0){
                this.initPopupOkMessage = '이 콘텐츠는 아이템 소유자만 확인이 가능합니다.';
                this.isShowPopupOk = true;
                
            } else {
                var param = 
                {
                    id: this.$store.state.id,
                    ticketId: this.$store.state.ticket,
                    productId: this.productId
                }
                this.$Axios.post(`${this.host}/nft/unlock`, param, this.headers).then(async (res) => {
                    // console.log('------unlock content---------');
                    // console.log(res.data)
                    if(res.data.returnCode === 0){
                        this.initUnlockContent = res.data.unlocked;
                        this.initIsUnlockPopup = true;
                    } else {
                        this.error(res.data.returnCode);
                    }
                })
                .catch((error) => {
                    this.error(error);
                });
            }
        },
        showOfferPopup(){
            if(this.$store.state.walletAddress === ''){
                this.initPopupOkMessage = '지갑 연결 후 구매가 가능합니다';
                this.isShowPopupOk = true;
                return;
            }

            if(this.productDetail.productInfo.totalItemCount - this.productDetail.productInfo.itemCountMine > 0){
                this.initIsOfferPopup = true;
            } else {
                this.initPopupOkMessage = '소유 중인 아이템은 제안을 요청할 수 없습니다.';
                this.isShowPopupOk = true;
            }
        },
        showBuyPopup(instanceInfo){
            if(this.$store.state.walletAddress){
                if(this.$store.state.walletAddress === instanceInfo.sellerAddress){
                    this.initPopupOkMessage = '본인이 등록한 아이템은 구매할 수 없습니다';
                    this.isShowPopupOk = true;
                    return;
                }
                this.initBuyInstance = instanceInfo;
                this.initIsBuyPopup = true;
                // console.log(instanceInfo);
            } else {
                this.initPopupOkMessage = '지갑 연결 후 구매가 가능합니다';
                this.isShowPopupOk = true;
            }
        },
        showCancelPopup(item){
            this.initCancelInstance = item;
            this.initIsCancelPopup = true;
        },
        showBidCancelPopup(item){
            this.initCancelBidInfo = item;
            this.initIsBidCancelPopup = true;
        },
        showOfferCancelPopup(item){
            this.initCancelOfferInfo = item;
            this.initIsOfferCancelPopup = true;
        },
        showOfferAcceptPopup(item){
            if(this.productDetail.productInfo.itemCountMine  - this.productDetail.productInfo.itemCountOnSaleMine - this.productDetail.productInfo.itemCountReservedMine <= 0){
                this.initPopupOkMessage = '수락을 할려면 판매 등록을 취소 후 시도하세요';
                this.isShowPopupOk = true;
                return;
            }
            this.initAcceptOfferInfo = item;
            this.initIsOfferAcceptPopup = true;
        },
        completeBuy(){
            this.refreshInfo();
            this.close('Buy')
        },
        completeCancelSale(){
            this.initCancelInstance = null;
            this.initIsCancelPopup = false;
            this.initPopupOkMessage = '판매 취소 완료';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        failedCancelSale(){
            this.initCancelInstance = null;
            this.initIsCancelPopup = false;
            this.initPopupOkMessage = '판매 취소 실패';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        refreshConfigInfo(){
            this.refreshInfo();
        },
        completePlaceBid(){
            this.initIsBuyPopup = false;
            this.initPopupOkMessage = '입찰 요청이 완료되었습니다.';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        completeBidCancel(){
            this.initIsBidCancelPopup = false;
            this.initPopupOkMessage = '입찰 취소가 완료되었습니다.';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        completeOfferCancel(){
            this.initIsOfferCancelPopup = false;
            this.initPopupOkMessage = '제안 취소가 완료되었습니다.';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        completeAuctionCancel(){
            this.initIsCancelPopup = false;
            this.initPopupOkMessage = '경매 취소가 완료되었습니다.';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        completeMakeOffer(){
            this.initIsOfferPopup = false;
            this.initPopupOkMessage = '제안 요청이 완료되었습니다.';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        completeAcceptOffer(){
            this.initIsOfferAcceptPopup = false;
            this.initPopupOkMessage = '제안 수락이 완료되었습니다.';
            this.PopupOkCallbakPage = 'resetNFT'
            this.isShowPopupOk = true;
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage === 'resetNFT'){
                        this.refreshInfo();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'CancelListing':
                    this.initIsCancelPopup = false;
                    break;
                case 'Buy':
                    this.initIsBuyPopup = false;
                    break;
                case 'List':
                    this.initIsListPopup = false;
                    this.initPopupTitle = '';
                    this.initOwner = false;
                    break;
                case 'Report':
                    this.initIsReport = false;
                    break;
                case 'BidCancel':
                    this.initIsBidCancelPopup = false;
                    break;
                case 'OfferCancel':
                    this.initIsOfferCancelPopup = false;
                    break;
                case 'OfferAccept':
                    this.initIsOfferAcceptPopup = false;
                    break;
                case 'Offer':
                    this.initIsOfferPopup = false;
                    break;
                case 'Unlock':
                    this.initIsUnlockPopup = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
