<template>
    <div class="emailverify">
        <div class="head">
            <router-link tag="a" class="logo" to="/"></router-link>
            <p v-html="$t('emailverify.header')"></p>
        </div>
        <b-container class="complete">
            <!-- <a v-if="$store.state.walletAddress" @click="connectWallet()">지갑 연결</a> -->
            <p class="title">{{ $t('emailverify.title') }}</p>
            <p class="text">{{ $t('emailverify.text') }}</p>
            <a @click="checkEmailVerify()">{{ $t('emailverify.button') }}</a>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import PopupOK from '@/components/popup/PopupOK.vue'
import Caver from "caver-js";

export default {
    name: "EmailVerify",
    krName: '이메일 인증',
    components: {
        PopupOK,
    },
    data() {
        return {
            walletAddress: '',  //현재 지갑 주소

            address: '',
            email: '',
            cert: '',

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        };
    },
    async created() {
    },
    mounted() {
        //https://artube-nft.numbers21.com/EmailVerify/%7Bghdcjs7@naver.com%7D/%7B255236%7D/%7B0xdb8e15b366ef63cc35a8153cfefec2b1cb5b935d%7D    
        if(this.$route.params.address && this.$route.params.email && this.$route.params.cert){
            this.address = this.$route.params.address;
            this.email = this.$route.params.email;
            this.cert = this.$route.params.cert;
            // console.log(this.address);
            // console.log(this.email);
            // console.log(this.cert);
        } else {
            this.$router.push("/");
        }
    },
    beforeDestroy() {},
    methods: {
        async checkEmailVerify() {
            // console.log(this.address);
            // console.log(this.email);
            // console.log(this.cert);
            var klaytn = await window.klaytn;
            if(typeof klaytn !== 'undefined'){
                await klaytn.enable().then(accounts => {
                    this.walletAddress = accounts[0].toLowerCase();
                    // console.log(this.walletAddress);
                    // console.log(this.address);
                    if(this.walletAddress !== this.address){
                        this.initPopupOkMessage = 'Kaikas에 연결된 지갑 주소와 다릅니다.';
                        this.isShowPopupOk = true;
                        return;
                    }
                    var caver = new Caver(klaytn);
                    var message = 'ArtubeNFT Email Verify(' + Date.now().toString() + ')';
                    caver.klay.sign(message, this.walletAddress).then(signature => {
                        var param = {
                            authType: 2,    //1: 메타마스크, 2: 카이카스
                            address: this.walletAddress,
                            message: message,
                            signature: signature,
                            email: this.email,
                            cert: this.cert,
                        };
                        // console.log(param);
                        this.$Axios.post(`${this.host}/emailVerify/register/check`, param, this.headers).then(async (res) => {
                            // console.log(res.data);
                            if (res.data.returnCode === 0) {
                                //회원가입된 지갑
                                this.$router.push("/SignUpComplete");
                            }  else {
                                this.error(res.data.returnCode);
                            }
                        })
                        .catch((error) => {
                            this.error(error);
                        });
                    }).catch(err => {
                        console.error(err);
                    })
                });
            }
            else{
                this.initPopupOkMessage = 'Kaikas 미설치 또는 잠금 상태입니다.<br/>설치된 상태면 실행하여 로그인 해주세요.<br/><a href="https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi" target="_blank" class="metamask_download">Kaikas 설치하기</a>';
                this.isShowPopupOk = true;
            }
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
