<template>
    <div class="item-slider" v-if="slideItem.length > 0">
        <swiper :options="swiperOption" class="mySwiper" v-if="slideType === 'curation'">
            <swiper-slide v-for="(item,idx) in slideItem" :key="idx">
                <CurationItem :itemInfo="item"/>
            </swiper-slide>
        </swiper>
        <swiper :options="swiperOption" class="mySwiper" v-else-if="slideType === 'marketplace'">
            
            <swiper-slide v-for="(item,idx) in slideItem" :key="idx" >
                <MatketplaceItem :itemInfo="item" :initItemDropDown="initDropDown && $store.state.userName === item.sellerName"/>
            </swiper-slide>
        </swiper>
        <swiper :options="swiperOption" class="mySwiper" v-else-if="slideType === 'inventory'">
            <swiper-slide v-for="(item,idx) in slideItem" :key="idx" >
                <Item :itemInfo="item.productInfo" :initItemDropDown="initDropDown && $store.state.userName === item.productInfo.mbName" @completeHide="completeHide" @completeHideCancel="completeHideCancel"/>
            </swiper-slide>
        </swiper>
        <swiper :options="swiperOption" class="mySwiper" v-else>
            <swiper-slide v-for="(item,idx) in slideItem" :key="idx" >
                <Item :itemInfo="item" :initItemDropDown="initDropDown && $store.state.userName === item.mbName" @completeHide="completeHide" @completeHideCancel="completeHideCancel"/>
            </swiper-slide>
        </swiper>
        <div :class="swiperNextButton" class="swiper-button-next" slot="button-next"></div>
        <div :class="swiperPrevButton" class="swiper-button-prev" slot="button-prev"></div>
    </div>
    <!-- <div v-else>
        아이템 없음
    </div> -->
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Item from '@/components/Item.vue'
import CurationItem from '@/components/CurationItem.vue'
import MatketplaceItem from '@/components/MatketplaceItem.vue'

export default {
    name: "Slide",
    components: {
        swiper,
        swiperSlide,
        Item,
        CurationItem,
        MatketplaceItem,
    },
    data() {
        return {
            swiperOption: {
                initialSlide: 0, //시작 index
                slidesPerView: 'auto', //한 화면에 보여질 슬라이드 수
                spaceBetween: 16, //슬라이드 간 간격
                navigation: {
                    nextEl: this.swiperOptionNext,
                    prevEl: this.swiperOptionPrev
                },
                loop: false,
                speed: 500,
                centeredSlides: false, //active된 슬라이드 가운데 위치
            },
        };
    },
    props: {
        initDropDown: {
            type: Boolean,
            default: false
        },
        slideType: {
            type: String,
            default: ''
        },
        slideItem: {
            type: Array
        },
        swiperNextButton: {
            type: String,
            default: ''
        },
        swiperOptionNext: {
            type: String,
            default: ''
        },
        swiperPrevButton: {
            type: String,
            default: ''
        },
        swiperOptionPrev: {
            type: String,
            default: ''
        }
    },
    created() {},
    mounted(){
    },
    beforeDestroy() {},
    methods: {
        completeHide(){
            this.$emit('completeHide')
        },
        completeHideCancel(){
            this.$emit('completeHideCancel')
        },
    },
};
</script>
