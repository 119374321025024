<template>    
    <div class="notice-info">
        <Header :initHeaderType="initHeaderType" />
        <b-container class="paddingLR20" v-if="noticeInfo !== null">
            <div class="support-box">
                <p class="title">{{ $t('notice.title') }}</p>
                <div class="name-box">
                    <p class="name">{{noticeInfo.title}}</p>
                    <div class="info">
                        <!-- <p class="kinds" :class="{blue : noticeInfo.kinds === '공지'}">{{info.kinds}}</p> -->
                        <p class="kinds blue">{{ $t('notice.note') }}</p>
                        <p class="day">{{noticeInfo.createDate}}</p>
                    </div>
                </div>
                <div class="contents">
                    <p v-html="noticeInfo.message"></p>
                </div>
            </div>
            <div class="button">
                <router-link tag="a" to="/Notice">{{ $t('notice.list') }}</router-link>
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "NoticeInfo",
    krName: '공지사항',
    components: {
        Header,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type3',

            currNoticeIdx: 0,
            noticeInfo: null,

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이
        };
    },
    props: {
    },
    created() {
        if(this.$route.params.idx){
            this.currNoticeIdx = this.$route.params.idx;
            this.getNoticeInfo();
        } else {
            this.$router.push("/Notice");
        }
    },
    mounted() {
        
    },
    beforeDestroy() {},
    methods: {
        getNoticeInfo(){
            var param = 
            {
                noticeIdx: this.currNoticeIdx
            }
            this.$Axios.post(`${this.host}/support/notice/info`, param, this.headers).then(async (res) => {
                // console.log('------notice info---------');
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.noticeInfo = res.data.data;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
