<template>
    <div class="filter" :class="{show : showFilter, noshow : !showFilter}">
        <div class="filter-inner" v-if="showFilter" :class="{follow : initFollowFilter}">
            <div class="filter-header flex">
                <img src="@/assets/images/filter.svg" />
                <p class="title">{{ $t('selectFilter.filter') }}</p>
                <img src="@/assets/images/filter-close.svg" class="filter-close" @click="showFilter=!showFilter"/>
            </div>
            <div id="status" class="status" :class="{'hide' : !showStatus }" v-if="initShowStatus">
                <div class="marginB-header flex">
                    <p class="title">{{ $t('selectFilter.status') }}</p>
                    <img src="@/assets/images/filter-unfold.svg" class="unfold-arrow" @click="showStatus=!showStatus" />
                </div>
                <div class="button-box filter-item">
                    <button :class="{'on' : selectedStatus === 1}" @click="changeStatus(1)" class="buy">{{initIsTransaction ? $t('selectFilter.sell') : $t('selectFilter.buy') }}</button>
                    <button :class="{'on' : selectedStatus === 2}" @click="changeStatus(2)" class="auction">{{initIsTransaction ? $t('selectFilter.sell2') : $t('selectFilter.auction') }}</button>
                    <button :class="{'on' : selectedStatus === 3}" @click="changeStatus(3)" class="newest">{{initIsTransaction ? $t('selectFilter.deal') : $t('selectFilter.new') }}</button>
                    <button :class="{'on' : selectedStatus === 4}" @click="changeStatus(4)" class="offers">{{initIsTransaction ? $t('selectFilter.send') : $t('selectFilter.offers') }}</button>
                </div>
            </div>
            <div id="price" class="price" :class="{'hide' : !showPrice }" v-if="initShowPrice">
                <div class="marginB-header flex">
                    <p class="title">{{ $t('selectFilter.price') }}</p>
                    <img src="@/assets/images/filter-unfold.svg" class="unfold-arrow" @click="showPrice=!showPrice" />
                </div>
                <div class="filter-item">
                    <div class="input-group dropdown">
                        <b-dropdown>
                            <template v-slot:button-content v-if="selectedPrice === 2">ATT</template>
                            <template v-slot:button-content v-else-if="selectedPrice === 3">KLAY</template>
                            <b-dropdown-item @click="changePriceType(2)">ATT</b-dropdown-item>
                            <b-dropdown-item @click="changePriceType(3)">KLAY</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="flex typing-price">
                        <div class="relative">
                            <b-input placeholder="0.00" v-model="minPrice"></b-input>
                        </div>
                        <p class="wave">~</p>
                        <div class="relative">
                            <b-input placeholder="0.00" v-model="maxPrice"></b-input>
                        </div>
                    </div>
                    <button class="apply click" @click="applyPrice">{{ $t('selectFilter.apply') }}</button>
                </div>
            </div>
            <div id="collections" class="collections" :class="{'hide' : !showCollections }" v-if="initShowCollection">
                <div class="marginB-header flex">
                    <p class="title">{{ $t('selectFilter.collections') }}</p>
                    <img src="@/assets/images/filter-unfold.svg" class="unfold-arrow" @click="showCollections=!showCollections" />
                </div>
                <div class="filter-item">
                    <div class="search relative">
                        <b-input placeholder="검색" v-model="collectionSearchText" @keyup.enter="searchCollection()"></b-input>
                    </div>
                    <div class="collection-list">
                        <div class="flex" v-for="(item,idx) in collectionList" :key="idx" @click="changeCollection(item)" :class="{'on' : selectedCollectionIdx === item.collectionIdx}">
                            <img :src="$store.state.configFront.cdnUrl + 'collection/logo/' + item.logo" v-if="$store.state.configFront !== null && item.logo !== null && item.logo !== ''"/>
                            <p>{{item.collectionName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="chain" class="chain" :class="{'hide' : !showChain}">
                <div class="marginB-header flex">
                    <p class="title">{{ $t('selectFilter.chain') }}</p>
                    <img src="@/assets/images/filter-unfold.svg" class="unfold-arrow" @click="showChain=!showChain" />
                </div>
                <div class="filter-item">
                    <b-form-group label="Individual radios" v-slot="{ ariaDescribedby }">
                        <b-form-radio :aria-describedby="ariaDescribedby" v-model="selectedChain" name="some-chainradios" value="Klaytn" @change="changeSalesChain()"><img src="@/assets/images/icon_klaytn.svg">Klaytn</b-form-radio>
                    </b-form-group>
                </div>
            </div>
            <div id="sales" class="sales" :class="{'hide' : !showSalesCoin }" v-if="initShowSymbol">
                <div class="marginB-header flex">
                    <p class="title">{{ $t('selectFilter.sales') }}</p>
                    <img src="@/assets/images/filter-unfold.svg" class="unfold-arrow" @click="showSalesCoin=!showSalesCoin" />
                </div>
                <div class="filter-item">
                    <b-form-group label="Individual radios" v-slot="{ ariaDescribedby }">
                        <b-form-radio :aria-describedby="ariaDescribedby" v-model="selectedSalesCoin" name="some-radios" value="KLAY" @change="changeSalesCoin()">KLAY</b-form-radio>
                        <b-form-radio :aria-describedby="ariaDescribedby" v-model="selectedSalesCoin" name="some-radios" value="ATT" @change="changeSalesCoin()">ATT</b-form-radio>
                    </b-form-group>
                </div>
            </div>
        </div>
        <div class="filter-hide" v-else :class="{follow : initFollowFilter}">
            <div class="filter-open" @click="showFilter=!showFilter">
                <img src="@/assets/images/filter-noshow.png" class="pc" />
                <img src="@/assets/images/icon/filter-white.svg" class="mb" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectFilter",
    data() {
        return {
            showFilter: true,
            showStatus: true,
            showPrice: true,
            showCollections: true,
            showChain: true,
            showSalesCoin: true,
            showEvent: true,

            resizeMobile: false,
            resizeTimer: null,

            selectedStatus: null,
            selectedSalesCoin: null,
            minPrice: 0,
            maxPrice: 0,
            selectedChain: 'Klaytn',
            selectedCollectionIdx: null,
            collectionList: [],

            selectedPrice: 2,
            collectionSearchText: '',
        };
    },
    props: {
        initIsTransaction: {
            type: Boolean,
            default: false,
        },
        initFlagSetCollection: {
            type:Boolean,
            default: false,
        },
        initPreloadCollectionInfo: {
            type: Object,
            default: null,
        },
        initFollowFilter: {
            type: Boolean,
            default: false
        },
        initShowStatus: {
            type: Boolean,
            default: true
        },
        initShowPrice: {
            type: Boolean,
            default: true
        },
        initShowCollection: {
            type: Boolean,
            default: true
        },
        initShowSymbol: {
            type: Boolean,
            default: true
        },
        initFlagResetFilter: {
            type:Boolean,
            default: false
        },
        initRemoveFilter:{
            type:String,
            default: null
        },
    },
    watch: {
        initFlagSetCollection(){
            if(this.initFlagSetCollection){
                if(this.initShowCollection) {
                    this.getRecommendCollectionList();
                    if(this.initPreloadCollectionInfo !== null && this.selectedCollectionIdx === this.initPreloadCollectionInfo.collectionIdx){
                        this.sendFilterOption({type:'collection', collectionIdx: this.initPreloadCollectionInfo.collectionIdx, collectionName: this.initPreloadCollectionInfo.collectionName}); 
                        return;   
                    }
                }
                this.sendFilterOption({type:'setCollection'});
            }
        },
        initPreloadCollectionInfo(){
            if(this.initPreloadCollectionInfo){
                this.selectedCollectionIdx = this.initPreloadCollectionInfo.collectionIdx;
                this.collectionSearchText = this.initPreloadCollectionInfo.collectionName;
            }
        },
        initFlagResetFilter(){
            if(this.initFlagResetFilter){
                this.resetFilter();
            }
        },
        initRemoveFilter(){
            if(this.initRemoveFilter !== null){
                this.removeFilter(this.initRemoveFilter);
            }
        }
    },
    created() {
        // this.getRecommendCollectionList();
    },
    mounted() {
        if(window.innerWidth <= 1404){
                this.showFilter = false
        }
        window.addEventListener("resize", this.resizeEnded);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeEnded);
    },
    methods: {
        resize(){
            if(window.innerWidth <= 1404 && this.resizeMobile === false){
                this.showFilter = false
                this.resizeMobile = true
            } else if(window.innerWidth <= 1404 && this.resizeMobile === true && this.showFilter === true){
                this.showFilter = true
                this.resizeMobile = true
            } else if(window.innerWidth > 1404){
                this.resizeMobile = false
            }
        },
        resizeEnded(){
            var that = this;
            if(this.resizeTimer){
                clearTimeout(this.resizeTimer);
            }
            this.resizeTimer = setTimeout(()=>{
                that.resize()
            }, 300);
        },
        searchCollection(){
            this.getRecommendCollectionList();
        },
        getRecommendCollectionList(){
            var param = 
            {
                // pageNo: 1,
                // pageSize: 100,
                searchText: this.collectionSearchText,
            }
            this.$Axios.post(`${this.host}/nft/collection/marketplace/list`, param, this.headers).then(async (res) => {
                // console.log('------ recommend collection list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.collectionList = res.data.list;
                } 
                // else {
                //     this.$emit('error', res.data.returnCode);
                // }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        changeStatus(idx){
            if(this.initFlagResetFilter){
                return;
            }
            if(this.selectedStatus === idx){
                return;
            }

            this.selectedStatus = idx;
            this.sendFilterOption({type:'status', value:idx})
        },
        changePriceType(symbol){
            this.selectedPrice = symbol;
        },
        applyPrice(){
            if(this.initFlagResetFilter){
                return;
            }
            this.sendFilterOption({type:'price', priceType: this.selectedPrice, minPrice: this.minPrice, maxPrice: this.maxPrice})
        },
        changeSalesChain(){

        },
        changeCollection(item){
            if(this.initFlagResetFilter){
                return;
            }
            if(this.selectedCollectionIdx === item.collectionIdx){
                return;
            }

            this.selectedCollectionIdx = item.collectionIdx;
            this.sendFilterOption({type:'collection', collectionIdx: item.collectionIdx, collectionName: item.collectionName})
        },
        changeSalesCoin(){
            if(this.initFlagResetFilter){
                return;
            }
            this.sendFilterOption({type:'coin', value:this.selectedSalesCoin})
        },
        removeFilter(type){
            switch(type){
                case 'status':
                    this.selectedStatus = null;
                    this.sendFilterOption({type:'status', value:null, isRemove: true})
                    break;
                case 'price':
                    // this.selectedPrice = null;
                    this.minPrice = 0;
                    this.maxPrice = 0;
                    this.sendFilterOption({type:'price', priceType: null, minPrice: null, maxPrice: null, isRemove: true})
                    break;
                case 'collection':
                    this.selectedCollectionIdx = null;
                    this.sendFilterOption({type:'collection', collectionIdx: null, collectionName: null, isRemove: true})
                    break;
                case 'coin':
                    this.selectedSalesCoin = null;
                    this.sendFilterOption({type:'coin', value: null, isRemove: true})
                    break;
            }
        },
        resetFilter(){
            this.selectedStatus = null;
            this.selectedSalesCoin = null;
            // this.selectedPrice = null;
            this.minPrice = 0;
            this.maxPrice = 0;
            this.selectedCollectionIdx = null;
            this.collectionSearchText = '';
            this.getRecommendCollectionList();
            this.sendFilterOption({type:'reset'})
        },
        sendFilterOption(value){
            this.$emit('changeFilterOptions', value)
        },
    },
};
</script>
