<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box signup">
            <p class="popup-title">{{ $t('popupsignup.title') }}</p>
            <!-- <div class="photo">
                <p class="title">{{ $t('popupsignup.photo') }}</p>
                <div class="photo-inner">
                    <img src="@/assets/images/camera.svg" />
                    <b-input placeholder="You can register photos up to 200MB or less."></b-input>
                    <button>{{ $t('popupsignup.registration') }}</button>
                </div>
            </div> -->
            <div class="name">
                <p class="title">{{ $t('popupsignup.name') }}</p>
                <b-input placeholder="5 ~ 45자, 한글, 영문 대소문자, 특수 문자 ( - , _ ) 만 사용 가능" v-model="userName" @input="validateName"></b-input>
                <div class="status" >
                    <!-- <p class="success">{{ $t('popupsignup.success1') }}</p> -->
                    <p class="failed" v-if="isShowErrorName">{{ errorMsgName }}</p>
                </div>
            </div>
            <!-- <div class="desc">
                <p class="title">User description</p>
                <textarea v-model="userDesc" placeholder="User Description"></textarea>
            </div> -->
            <div class="address">
                <p class="title">{{ $t('popupsignup.address') }}</p>
                <b-input v-model="$store.state.walletAddress" readonly="readonly"></b-input>
                <div class="status">
                    <!-- <p class="success">{{ $t('popupsignup.success1') }}</p> -->
                    <p class="failed" v-if="isShowErrorAddress">{{ errorMsgAddress }}</p>
                </div>
            </div>
            <div class="email">
                <p class="title">{{ $t('popupsignup.email') }}</p>
                <b-input placeholder="이메일 주소를 입력해주세요." v-model="email" @input="validateEmail"></b-input>
                <div class="status">
                    <!-- <p class="success">{{ $t('popupsignup.success2') }}</p> -->
                    <p class="failed" v-if="isShowErrorEmail">{{ errorMsgEmail }}</p>
                </div>
            </div>
            <div class="checkboxs">
                <b-form-checkbox v-model="isCheckAgree1"><p v-html="$t('popupsignup.checkbox1')"></p></b-form-checkbox>
                <b-form-checkbox v-model="isCheckAgree2" class="subscribe"><p v-html="$t('popupsignup.checkbox2')"></p></b-form-checkbox>
                <b-form-checkbox v-model="isCheckAgree3" class="agreement"><p v-html="$t('popupsignup.checkbox3')"></p></b-form-checkbox>
            </div>
            <div class="buttons">
                <a class="left" @click="closePopup">{{ $t('popupsignup.cancel') }}</a>
                <a class="right" @click="signup">{{ $t('popupsignup.signup') }}</a>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />    
    </div>
</template>

<script>
import PopupOK from '@/components/popup/PopupOK.vue'

import Caver from "caver-js";

export default {
    name: "PopupSignUp",
    components:{
        PopupOK,
    },
    data() {
        return {
            userName: '',
            userDesc: '',
            email: '',
            isCheckAgree1: false,
            isCheckAgree2: false,
            isCheckAgree3: false,

            errorMsgName: '',
            isShowErrorName: false,

            errorMsgAddress: '',
            isShowErrorAddress : false,

            errorMsgEmail: '',
            isShowErrorEmail: false,
            isValidEmail: false,

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        async checkUserState(){

            var isUserVerified = await this.checkUserVerified();
            if(isUserVerified){
                //회원가입은 되지 않았지만 이메일 인증은 완료된 경우
            } else {
                //이메일 인증이 되지 않은 경우
                this.reqCertEmail();
            }
        },
        async signup() {

            if(this.userName.length <= 0){
                this.isShowErrorName = true;
                this.errorMsgName = '사용자 이름을 입력해 주세요.';
                return;
            } else if(this.userName.length < 5 || this.userName.length > 45){
                this.isShowErrorName = true;
                this.errorMsgName = '사용자 이름은 5 ~ 45자까지만 등록할 수 있습니다.';
                return;
            } else if(this.email.length <= 0){
                this.isShowErrorEmail = true;
                this.errorMsgEmail = '이메일을 입력해 주세요.';
                return;
            } else if(!this.isValidEmail){
                this.isShowErrorEmail = true;
                this.errorMsgEmail = '올바른 형식의 이메일 주소가 아닙니다.';
                return;
            } else if(!this.isCheckAgree1 || !this.isCheckAgree2 || !this.isCheckAgree3){
                this.initPopupOkMessage = "약관에 동의해 주세요.";
                this.isShowPopupOk = true;
                return;
            }

            var returnSignature = await this.signature(); //싸이닝 값 받아옴
            if (!returnSignature.valid) {
                //싸이닝 실패 했을 경우 종료
                return;
            } else {
                //싸이닝 완료한 경우 이메일인증 요청(가입 요청)
                var param = {
                    authType: 2, //1: 메타마스크, 2: 카이카스
                    address: returnSignature.account,
                    message: returnSignature.nonce,
                    signature: returnSignature.signature,
                    email: this.email,
                    id: this.userName,
                    lang: this.$i18n.locale,
                    aboutMe: this.userDesc
                };
                // console.log(param);
                await this.$Axios
                    .post(`${this.host}/cert/email/register/request`, param, this.headers)
                    .then(async (res) => {
                        // console.log(res.data);
                        // 6, "Required fields missing."           : 필수 데이터 누락
                        // 3, "Bad request"                        : 시그니쳐 안맞음 혹은 잘못된 요청
                        // 42, "Account already exist"             : 이미 등록된 아이디
                        // 223, "Email info is registered already" : 이미 등록된 이메일
                        // 226,"address already request"           : 이미 등록된 주소
                        // 111, "Too many certification request"   : 최대 요청횟수 초과, 만료일 기준으로 삭제되면 추가 요청 가능

                        if (res.data.returnCode === 0) {
                            this.signupComplete();
                        } else if(res.data.returnCode === 42){
                            this.isShowErrorName = true;
                            this.errorMsgName = '이미 존재하는 아이디 입니다.';
                        } else if(res.data.returnCode === 223){
                            this.isShowErrorEmail = true;
                            this.errorMsgEmail = '이미 존재하는 이메일 입니다.';
                        } else if(res.data.returnCode === 226){
                            this.isShowErrorAddress = true;
                            this.errorMsgAddress = '이미 존재하는 지갑주소 입니다.';
                        } else if(res.data.returnCode === 111){
                            this.initPopupOkMessage = "최대 요청 횟수를 초과 했습니다.";
                            this.isShowPopupOk = true;
                        } else {
                            this.initPopupOkMessage = this.getErrorMsg(res.data.returnCode);
                            this.isShowPopupOk = true;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        async signature(){  //카이카스 사이닝
            var caver = new Caver(window.klaytn);
            var message = 'ArtubeNFT Signature(' + Date.now().toString() + ')';

            return new Promise((resolve, reject)=> 
                caver.klay.sign(message, this.$store.state.walletAddress).then(signature => {
                    var returnValue = {
                        valid: true,
                        account: this.$store.state.walletAddress,
                        signature: signature,
                        nonce: message,
                    };
                    return resolve(returnValue);
                }).catch(err => {
                    reject({ valid: false, err: err });
                })
            );
        },
        validateEmail() {
            this.isShowErrorEmail = false;
            this.isValidEmail = false;
            
            // let filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            var regExp = /^[0-9a-zA-Z]([-_.+]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

            if(this.email === ''){
                this.isShowErrorEmail = false;
                this.isValidEmail = false;
            }
            // else if (!filter.test(this.email)) {
            else if (this.email.match(regExp) === null) {
                this.isShowErrorEmail = true;
                this.isValidEmail = false;
                this.errorMsgEmail = '잘못된 이메일 형식 입니다.';
            }
            else {
                this.isShowErrorEmail = false;
                this.isValidEmail = true;
            }
        },
        validateName() {
            this.isShowErrorName = false;
        },
        signupComplete(){
            this.$emit("signupComplete");
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.isShowPopupOk = false;
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        closePopup() {
            this.$emit("close");
        },
    },
};
</script>
