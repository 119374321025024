<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box calendar padding">
            <div class="top">
                <p class="title">{{ $t('calendar.title') }}</p>
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
            </div>
            <div class="bottom">
                <div class="buttons">
                    <b-button @click="plusOneDay">{{ $t('calendar.button1') }}</b-button>
                    <b-button @click="plusThreeDay">{{ $t('calendar.button2') }}</b-button>
                    <b-button @click="plusOneMonth">{{ $t('calendar.button3') }}</b-button>
                    <b-button @click="plusThreeMonth">{{ $t('calendar.button4') }}</b-button>
                    <b-button @click="plusSixMonth">{{ $t('calendar.button5') }}</b-button>
                </div>
                <div class="date-text">
                    <div>
                        <img src="@/assets/images/icon/calendar.svg" />
                        <p>{{ getLocalizedDayString(startDate) }}</p>
                    </div>
                    <p class="wave">~</p>
                    <div>
                        <img src="@/assets/images/icon/calendar.svg" />
                        <p>{{ getLocalizedDayString(endDate) }}</p>
                    </div>
                </div>
                <div class="datepicker-cover">
                    <datepicker :format="getLocalizedDayString" :value="startDate" v-model="startDate" :minimumView="'day'" :maximumView="'day'" :inline="true" :language="ko" :disabled-dates="disabledStartDates" class="nowday" ></datepicker>
                    <datepicker :format="getLocalizedDayString" :value="endDate"   v-model="endDate"   :minimumView="'day'" :maximumView="'day'" :inline="true" :language="ko" :disabled-dates="disabledEndDates" ></datepicker>
                </div>
            </div>
            <div class="balhang-right-btn">
                <p @click="applyDate()">{{ $t('calendar.button6') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {ko} from 'vuejs-datepicker/dist/locale'

    export default {
        components: {
            Datepicker
        },
        name: "PopupCalnedar",
        data() {
            return {
                ko: ko,
                
                nowDate: null,
                startDate: null,
                endDate: null,

                afterDateDay1: null,
                afterDateDay3: null,
                afterDateMonth1: null,
                afterDateMonth3: null,
                afterDateMonth6: null,
                

                disabledStartDates: {},
                disabledEndDates: {}
            };
        },
        props: {
            initIsShowPopup: {
                type: Boolean,
                default: false,
            },
        },
        created() {
            this.afterDateDay1 = new Date();
            this.afterDateDay3 = new Date();
            this.afterDateMonth1 = new Date();
            this.afterDateMonth3 = new Date();
            this.afterDateMonth6 = new Date();

            this.afterDateDay1.setDate(this.afterDateDay1.getDate() + 1);
            this.afterDateDay3.setDate(this.afterDateDay3.getDate() + 3);
            this.afterDateMonth1.setMonth(this.afterDateMonth1.getMonth() + 1);
            this.afterDateMonth3.setMonth(this.afterDateMonth3.getMonth() + 3);
            this.afterDateMonth6.setMonth(this.afterDateMonth6.getMonth() + 6);

            this.startDate = new Date();
            this.endDate = this.afterDateDay1;

            this.disabledStartDates.to = new Date(Date.now() - 86400000);
            this.disabledStartDates.from = new Date(Date.now());

            this.disabledEndDates.to = this.startDate;
            this.disabledEndDates.from = this.afterDateMonth6;
            
            // this.disabledEndDates.from = (new Date()).setMonth((new Date()).getMonth() + 6);
        },
        mounted() {
        },
        beforeDestroy() {},
        methods: {
            plusOneDay(){
                this.endDate = this.afterDateDay1;
            },
            plusThreeDay(){
                this.endDate = this.afterDateDay3;
            },
            plusOneMonth(){
                this.endDate = this.afterDateMonth1;
            },
            plusThreeMonth(){
                this.endDate = this.afterDateMonth3;
            },
            plusSixMonth(){
                this.endDate = this.afterDateMonth6;
            },
            closePopup() {
                this.$emit("close");
            },
            applyDate(){
                this.$emit("applyDate", this.startDate, this.endDate);
            }
        },
    };
</script>
