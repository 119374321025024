<template>
    <div class="popup" v-if="initIsShowPopup && initProductDetail !== null">
        <div class="popup-box padding buy">
            <div class="top">
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
                <p class="popup-title">{{ $t('offer.title') }}</p>
            </div>
            <div class="bottom">
                <div class="title-box">
                    <p class="title">{{ $t('buy.item') }}</p>
                    <p class="title subtotal">{{ $t('buy.subtotal') }}</p>
                </div>
                <div class="product">
                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + initProductDetail.productInfo.filePath" v-if="$store.state.configFront !== null && initProductDetail.productInfo.resourceType === 1"/>
                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + initProductDetail.productInfo.thumbnailFileName" v-if="$store.state.configFront !== null && initProductDetail.productInfo.resourceType === 2"/>

                    <div class="basic-info">
                        <p class="amount" v-if="initProductDetail.productInfo.itemType === 1">{{ $t('buy.amount') }} x {{initProductDetail.productInfo.totalItemCount}}</p>
                        <router-link tag="p" :to="'/Collection/' + initProductDetail.productInfo.collectionIdx" class="writer">{{initProductDetail.productInfo.collectionName}}</router-link>
                        <p class="name">{{initProductDetail.productInfo.name}}</p>
                    </div>
                    <div class="price" v-if="initBuyInstance !== null && initBuyInstance.saleType === 1">
                        <p v-if="initBuyInstance.itemType === 1" class="top-text">{{ $t('buy.per-piece') }}</p>
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBuyInstance.priceSymbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBuyInstance.priceSymbol === 'KLAY'" />
                            <p>{{initBuyInstance.priceAmount | comma}}</p>
                            <span>(${{$store.getters.getTokenUsdPrice(initBuyInstance.priceSymbol, initBuyInstance.priceAmount, 4)}})</span>
                        </div>
                    </div>
                </div>
                <div class="buy-piece" v-if="initProductDetail.productInfo.itemType === 1">
                    <p class="title">{{ $t('buy.offerPiece') }}</p>
                    <input class="form-control" v-model="quantity" @input="commaAmount" />
                </div>
                <div class="saleType2">
                    <div class="flex">
                        <div class="unit">
                            <div class="input-group">
                                <b-dropdown>
                                    <template v-slot:button-content v-if="selectedSymbol === 'ATT'"><img src="@/assets/images/icon/icon_symbol_artube.png" /> ATT</template>
                                    <template v-slot:button-content v-else-if="selectedSymbol === 'KLAY'"><img src="@/assets/images/icon/icon_symbol_klaytn.svg" /> KLAY</template>
                                    <b-dropdown-item @click="changeSymbol('KLAY')"><img src="@/assets/images/icon/icon_symbol_klaytn.svg" class="klay" /> KLAY</b-dropdown-item>
                                    <b-dropdown-item @click="changeSymbol('ATT')"><img src="@/assets/images/icon/icon_symbol_artube.png" class="att" /> ATT</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                        <input class="form-control" v-model="offerPrice" @input="commaOfferPrice" />
                    </div>
                    <img src="@/assets/images/equal.svg" class="equal" />
                    <div class="flex usd">
                        <div class="unit">
                            <img src="@/assets/images/icon/dollar.svg" />
                            <span>USD</span>
                        </div>
                        <input class="form-control" v-model="usdPrice" @input="commaUsdPrice" />
                    </div>
                    <p class="text">{{ $t('buy.offerPieceText') }}</p>
                </div>
                <div class="total">
                    <div>
                        <p class="title">{{ $t('buy.total') }}</p>
                    </div>
                    <p class="price">
                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="selectedSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="selectedSymbol === 'KLAY'" />
                        {{totalPrice | comma}}
                        <span>(${{$store.getters.getTokenUsdPrice(selectedSymbol, totalPrice, 4) | comma}})</span>
                    </p>
                </div>
                <p class="red" v-if="isShowErrorNoCoin">{{ $t('buy.warnNoOfferCoin') }}</p>
                <div class="check" :class="{nopadding : isAllowance}">
                    <b-form-checkbox class="agree" v-model="checkedAgree2"><p v-html="$t('buy.checkbox2')"></p></b-form-checkbox>
                </div>
                <a class="ok" @click="checkApprove">{{$t('buy.button2')}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import Caver from "caver-js";
import OfferContract from "@/assets/json/NftOffer.json";
import MarketContract from "@/assets/json/NftMarket.json";

export default {
    name: "PopupBuy",
    data() {
        return {
            isAllowance: false,
            quantity: '',
            numberQuantity: 0,
            totalPrice: 0,
            offerPrice: '',
            numberOfferPrice: 0,

            usdPrice: '',
            numberUsdPrice: 0,

            selectedSymbol: 'KLAY',

            checkedAgree1: true,
            checkedAgree2: false,

            offerContract: OfferContract,
            marketContract: MarketContract,

            isShowErrorNoCoin: false,
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initProductDetail: {
            type: Object,
            default: null
        },
        initBuyInstance: {
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    watch:{
        initIsShowPopup(){
            this.resetData();
            if(this.initIsShowPopup && this.initProductDetail !== null){
                // console.log(this.initProductDetail);
                // console.log(this.initBuyInstance);
                // this.checkApprove();
                if(this.initProductDetail.productInfo.itemType === 2){    //단일 아이템의 경우 수량은 1개로 고정
                    this.quantity = "1";
                    this.numberQuantity = 1;
                    // this.commaAmount();
                }
            }
        }
    },
    methods: {
        resetData(){
            this.isAllowance = false;
            this.quantity = ''
            this.numberQuantity = 0;
            this.totalPrice = 0;
            this.offerPrice = '';
            this.numberOfferPrice= 0;
            this.checkedAgree2 = false;
        },
        async checkApprove(){
            if(this.isShowErrorNoCoin){
                return;
            }
            if(this.numberQuantity <= 0 || this.numberOfferPrice <= 0 || this.numberUsdPrice <= 0 || !this.checkedAgree2){
                return;
            }

            if(this.selectedSymbol === 'KLAY'){
                this.offer();
            } else {
                var currTokenInfo = this.$store.state.configTokenInfo.find((config) => config.symbol === this.selectedSymbol);
                var tokenContract = currTokenInfo.address;
                var targetAddress = this.$store.state.configFront.contractJoin;
                var walletAddress = this.$store.state.walletAddress;

                this.checkAllowance(tokenContract, targetAddress, walletAddress, (allowance)=>{
                    // console.log(allowance);
                    if(Number(allowance) > 0){
                        // console.log(allowance);
                        this.offer();
                    } else {
                        this.ApproveToken(tokenContract, targetAddress, walletAddress, null,
                            (result)=>{result; this.offer()},
                            (err)=>{console.error(err); return;}
                        );
                    }
                })
            }
        },
        async offer(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: this.initProductDetail.productId,
                pricePerOne: this.numberOfferPrice,
                amount: this.numberQuantity,
                symbol: this.selectedSymbol
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/nft/offer`, param, this.headers).then(async (res) => {
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.reqOffer(res.data.data);
                }
                else {
                    this.$emit('error', res.data.returnCode);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        // contractIdx: 2
        // createDate: "2022-07-12 15:35:45"
        // expireDate: "2022-07-12 15:40:45"
        // memberIdx: 38
        // offerAmount: 1
        // offerPricePerOne: 1
        // offerSymbol: "ATT"
        // osdAmount: "0x0000000000000000000000000000000000000000000000000000000000000001"
        // osdAuthor: null
        // osdAuthorFee: null
        // osdBuyer: null
        // osdContractAddress: null
        // osdContractType: null
        // osdExpired: "0x0000000000000000000000000000000000000000000000000000000062cd176d"
        // osdId: "0x0000000000000000000000000000000000000000000000000de0b6b3a7640000"
        // osdItemId: null
        // osdPricePerOne: null
        // osdToken: null
        // productIdx: 99
        // signResult: "0x0a3e7fe70f564aa2d1533fbaa3924f181833ab8cc3b3bd50d924713c3fe8f1082d2aaedf579d07b27e24cf87e5097a3295b795b4624dfbdc4ec321edda63baa91c"
        // state: 0
        // tradeDate: null
        reqOffer(offerData){
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractAddress = this.$store.state.configFront.contractJoin;
                var contractInstance = caver.contract.create(this.offerContract.abi, contractAddress);
                // var data = contractInstance.methods.placeBid(caver.utils.toBN(this.initBuyInstance.auctionId), caver.utils.toPeb(this.numberBidPrice.toString(), 'KLAY')).encodeABI();

                var data = contractInstance.methods.makeOffer(
                    offerData.osdId, //id
                    offerData.osdContractAddress, //contractAddress
                    offerData.osdContractType, //contractType
                    offerData.osdBuyer, //buyer
                    offerData.osdItemId, //itemID
                    offerData.osdAmount, //amount
                    offerData.osdAuthor, //author
                    offerData.osdAuthorFee, //authorFee
                    offerData.osdToken, //token
                    offerData.osdPricePerOne, //pricePerOne
                    offerData.osdExpired, //expired
                    offerData.signResult, //signature
                ).encodeABI();
                
                let price = "0x0000000000000000000000000000000000000000000000000000000000000000";
                if(offerData.osdToken === "0x0000000000000000000000000000000000000000"){
                    price = offerData.osdPrice;
                }
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: contractAddress,
                    value: price,
                    gas: '400000',
                    data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    this.$emit("completeMakeOffer");
                    // console.log(receipt);
                })
                .on('error', (error) =>{
                    console.error('error : ' + error);
                });
            }
        },
        reqPlaceBid(){

            if(this.numberBidPrice < this.minBidPrice || !this.checkedAgree1 || !this.checkedAgree2){
                return;
            }

            var auctionAddress = this.$store.state.configFront.contractJoin;
            var walletAddress = this.$store.state.walletAddress;

            if(!this.isAllowance){
                var currTokenInfo = this.$store.state.configTokenInfo.find((config) => config.symbol === this.initBuyInstance.priceSymbol);
                var tokenContract = currTokenInfo.address;

                this.ApproveToken(tokenContract, auctionAddress, walletAddress, null,
                    (result)=>{result; this.isAllowance = true; this.reqPlaceBid()},
                    (err)=>{console.error(err)}
                );
                return;
            }

            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractInstance = caver.contract.create(this.auctionContract.abi, auctionAddress);
                var data = contractInstance.methods.placeBid(caver.utils.toBN(this.initBuyInstance.auctionId), caver.utils.toPeb(this.numberBidPrice.toString(), 'KLAY')).encodeABI();
                let price = "0x0000000000000000000000000000000000000000000000000000000000000000";
                if(this.initBuyInstance.priceSymbol === 'KLAY'){
                    price = caver.utils.toPeb(this.numberBidPrice.toString(), 'KLAY')
                }
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: walletAddress,
                    to: auctionAddress,
                    value: price,
                    gas: '300000',
                    data: data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    this.$emit("completePlaceBid");
                    // console.log(receipt);
                })
                .on('error', (error) =>{
                    console.error('error : ' + error);
                });
            }
        },
        changeSymbol(symbol){
            this.selectedSymbol = symbol;
            this.offerPrice = '';
            this.numberOfferPrice = 0;
            this.usdPrice = '';
            this.numberUsdPrice = 0;
            this.totalPrice = 0;
        },
        commaAmount() {
            this.isShowErrorNoCoin = false;

            var x = "";
            var max;
            x = this.quantity.toString();
            max = this.initProductDetail.productInfo.totalItemCount - this.initProductDetail.productInfo.itemCountMine - this.initProductDetail.productInfo.itemCountReservedMine;

            x = this.numberOnlyWithMax(x, max, true);
            
            this.numberQuantity = Number(x);
            this.quantity = this.setComma(x.toString());
            this.totalPrice = (Math.floor(this.numberQuantity * (this.numberOfferPrice * 10000))) / 10000;

            var myBalance = this.$store.getters.getMyBalance(this.selectedSymbol);

            if(myBalance < this.totalPrice){
                this.isShowErrorNoCoin = true;
            }
        },
        commaOfferPrice() {
            this.isShowErrorNoCoin = false;

            var x = "";
            x = this.offerPrice.toString();
            x = this.numberOnly(x);
            
            this.numberOfferPrice = Number(x);
            this.offerPrice = this.setComma(x.toString());

            this.numberUsdPrice = this.$store.getters.getTokenUsdPrice(this.selectedSymbol, this.numberOfferPrice, 4);
            this.usdPrice = this.setComma(this.numberUsdPrice.toString());
            this.totalPrice =  (Math.floor(this.numberQuantity * (this.numberOfferPrice * 10000))) / 10000;

            var myBalance = this.$store.getters.getMyBalance(this.selectedSymbol);

            if(myBalance < this.totalPrice) {
                this.isShowErrorNoCoin = true;
            }
        },
        commaUsdPrice(){
            this.isShowErrorNoCoin = false;

            var x = "";
            x = this.usdPrice.toString();
            x = this.numberOnly(x);
            
            this.numberUsdPrice = Number(x);
            this.usdPrice = this.setComma(x.toString());

            this.numberOfferPrice = this.$store.getters.getTokenAmount(this.selectedSymbol, this.numberUsdPrice, 4);
            this.offerPrice = this.setComma(this.numberOfferPrice.toString());
            this.totalPrice =  (Math.floor(this.numberQuantity * (this.numberOfferPrice * 10000))) / 10000;

            var myBalance = this.$store.getters.getMyBalance(this.selectedSymbol);

            if(myBalance < this.totalPrice) {
                this.isShowErrorNoCoin = true;
            }
        },
        closePopup() {
            this.$emit("close");
        },
    },
};
</script>
