<template>
    <div class="marketplace">
        <Header :initHeaderType="initHeaderType" />
        <div class="item-list-box">
            <SelectFilter 
                :initFlagSetCollection="initFlagSetCollection" 
                :initFollowFilter="initFollowFilter" 
                :initFlagResetFilter="flagResetFilter" 
                :initRemoveFilter="initRemoveFilter" 
                @changeFilterOptions="changeFilterOptions"
            />
            <div class="right">
                <div class="top">
                    <p class="total">{{ $t('marketplace.total') }} {{totalRecordSize | comma}}</p>
                    <div class="dropdowns">
                        <!-- <div class="dropdown">
                            <b-form-select v-model="selected" :options="options" class="btn"></b-form-select>
                        </div> -->
                        <!-- <b-form-select v-model="selectedCategoryIdx" :options="categoryOptions" @change="resetList()" class="btn"></b-form-select> -->
                        <b-dropdown class="mb-category" :text="getSelectedCategoryText()">
                            <b-dropdown-item v-for="(item) in categoryOptions" :key="item.value" @click="changeCategory(item.value)">{{item.text}}</b-dropdown-item>
                        </b-dropdown>
                        <!-- <b-form-select v-model="selectedOrder" :options="orderOptions" class="btn" @change="changeOrder()"></b-form-select> -->
                        <b-dropdown class="mb-block" :text="getSelectedOrderText()">
                            <b-dropdown-item v-for="(item) in orderOptions" :key="item.value" @click="changeOrderType(item.value)">{{item.text}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="mid">
                    <b-row class="category">
                        <div class="col" v-for="(item, index) in categoryOptions" :key="index" :class="{'selec' : selectedCategoryIdx === item.value}" @click="changeCategory(item.value)">
                            <p>{{ item.text }}</p>
                        </div>
                    </b-row>
                </div>
                <div class="mid">
                    <div class="filter-list" v-if="getIsShowFilterList()">
                        <div class="reset" @click="resetFilter()">
                            <p>{{ $t('marketplace.reset') }}</p>
                        </div>
                        <div v-if="isSearch">
                            <p>{{ paramSearchText }}</p>
                        </div>
                        <div v-if="selectedStatus !== null">
                            <p v-if="selectedStatus === 1">{{ $t('selectFilter.buy') }}</p>
                            <p v-else-if="selectedStatus === 2">{{ $t('selectFilter.auction') }}</p>
                            <p v-else-if="selectedStatus === 3">{{ $t('selectFilter.new') }}</p>
                            <p v-else-if="selectedStatus === 4">{{ $t('selectFilter.offers') }}</p>
                            <i class="btn-close" @click="removeFilter('status')" />
                        </div>
                        <div v-if="selectedPrice !== null">
                            <p>
                                {{minPrice}}~{{maxPrice}}
                                <span v-if="selectedPrice === 1">USD</span> 
                                <span v-else-if="selectedPrice === 2">ATT</span> 
                                <span v-else-if="selectedPrice === 3">KLAY</span> 
                            </p>
                            <i class="btn-close" @click="removeFilter('price')" />
                        </div>
                        <div v-if="selectedCollectionIdx !== null">
                            <p>{{selectedCollectionName}}</p>
                            <i class="btn-close" @click="removeFilter('collection')" />
                        </div>
                        <div v-if="selectedSalesCoin !== null">
                            <p>{{selectedSalesCoin}}</p>
                            <i class="btn-close" @click="removeFilter('coin')" />
                        </div>
                    </div>
                </div>
                <div class="bottom" id="item-list">
                    <!-- <div>
                        <p class="title">Selected Filter</p>
                    </div> -->
                    <b-row v-if="itemList !== null">
                        <b-col class="product" v-for="(item,idx) in itemList" :key="idx">
                            <Item :itemInfo="item" v-if="isSearch"/>
                            <MatketplaceItem :itemInfo="item" v-else/>
                        </b-col>
                    </b-row>
                    <div class="no-tem" v-else>
                        <img src="@/assets/images/icon/market-search.svg" />
                        <p>{{ $t('marketplace.search') }}</p>
                        <router-link tag="a" to="/">{{ $t('marketplace.back') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import SelectFilter from '@/components/market/SelectFilter.vue';
import Item from '@/components/Item.vue'
import MatketplaceItem from '@/components/MatketplaceItem.vue'
import PopupOK from '@/components/popup/PopupOK.vue'

export default {
    name: "MarketPlace",
    components: {
        Header,
        SelectFilter,
        Item,
        MatketplaceItem,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type1',

            isSearch: false,
            paramSearchText: '',
            paramCategoryName: '',
            selectedCategoryIdx: null,
            categoryOptions:[
                { value: null, text: '모든 NFT' }
            ],

            //정렬
            selectedOrder: 1,
            orderOptions:[
                { value: 1, text:'최신 순',},
                { value: 2, text:'인기 순',},
                { value: 3, text:'마감 임박 순',},
                { value: 4, text:'조회 순',},
                { value: 5, text:'오래된 순',},
                { value: 6, text:'입찰 많은 순',},
                { value: 7, text:'입찰 적은 순',},
            ],

            //필터
            initFollowFilter: false,
            initFlagSetCollection: false,
            initCollectionType: 0,  // 0 내가 소유한 컬렉션, 1 내가 제안한 컬렉션, 2 내가 즐겨찾기한 컬렉션
            initPreloadCollectionInfo: null,

            flagResetFilter: false,
            initRemoveFilter: null,

            selectedStatus: null,
            selectedPrice: null,
            minPrice: null,
            maxPrice: null,
            selectedSalesCoin: null,
            selectedCollectionIdx: null, 
            selectedCollectionName: null,

            checkItemListTimer: null,
            itemList: [],

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 20,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수

            listCount: 0,
            pageCount: 0,
            pageNo: 0,
            pageSize: 0,
            totalRecordSize: 0,

            pageList:[],

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    created() {
        if(this.$route.params.category){
            this.paramCategoryName = this.$route.params.category;
        }

        if(this.$route.params.searchText){
            this.isSearch = true;
            this.paramSearchText = this.$route.params.searchText;
            this.orderOptions = [
                { value: 1, text:'최신 순',},
                { value: 2, text:'인기 순',},
                { value: 3, text:'조회 순',},
                { value: 4, text:'오래된 순',},
            ]
        }

        this.setCategoryOptions();
    },
    mounted() {
        window.addEventListener('scroll', this.changeBrowserStatusEnded);
        window.addEventListener('resize', this.changeBrowserStatusEnded);
    },
    beforeDestroy() {
        clearInterval(this.intervalSetCategory);
        window.removeEventListener('scroll', this.changeBrowserStatusEnded);
        window.removeEventListener('resize', this.changeBrowserStatusEnded);
    },
    methods: {
        changeBrowserStatusEnded(){
            var that = this;
            if(this.checkItemListTimer){
                clearTimeout(this.checkItemListTimer);
            }
            this.checkItemListTimer = setTimeout(()=>{
                that.handlerCheckListBottom()
            }, 500);
        },
        handlerCheckListBottom(){
            try{
                var browserHeight = window.innerHeight; //화면 높이
                var listItemPosition = document.getElementById('item-list').getBoundingClientRect();
                var scrollBottom = listItemPosition.height - browserHeight - document.documentElement.scrollTop;

                // console.log(this.itemList.length);
                // console.log(this.totalRecordSize);
                // console.log(scrollBottom);

                if(this.itemList.length < this.totalRecordSize && scrollBottom < 0){
                    this.currentPageNo++;
                    this.getInstanceList();
                }
            } catch(e){
                return;
            }
        },
        setCategoryOptions(){
            var that = this;
            this.intervalSetCategory = setInterval(()=>{
                if(that.$store.state.categoryList.length > 0){
                    that.$store.state.categoryList.forEach((item)=>{
                        if(that.paramCategoryName === item.name){
                            that.selectedCategoryIdx = item.categoryIdx;
                        }
                        var categoryItem = {
                            value: item.categoryIdx,
                            text: item.name
                        }
                        that.categoryOptions.push(categoryItem);
                        // console.log(that.categoryOptions);
                    });
                    clearInterval(that.intervalSetCategory);
                    that.initFlagSetCollection = true;
                    // that.getInstanceList();
                }
            }, 100);
        },
        getInstanceList(){
            if(this.isSearch){
                this.getSearchList();
                return;
            }
            // NFT 리스트 검색 요청 파라미터
            // order: // 1: latest // 2: popularity // 3: close to finish // 4: low price // 5: high price // 6: small bids // 7: a lot of bids // 8: most seen // 9: oldest 
            // itemType: // 1: 조각 아이템 // 2: 단일 아이템
            // state // 1: 숨김 // 2: 판매종료(expired) // 3: 판매완료(sold out)  // 4: 판매중
            // FilterCategory 1: art 2: music 3: virtual world 4: trading cards 5: collectibles 6: sports 7: utility 8: etc 
            // FilterPrice 1:usd 2:att 3:klay 
            // -startPrice : 최소 가격
            // -endPrice : 최대 가격
            // FilterCoin 심볼 쓰시면됩니다. ATT, KLAY
            // orderType 1:최신순 2:인기순 3:마감 임박순 4:낮은 가격순 5:높은 가격순 6:조회순 7:오래된순
            var that = this;
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                // searchText: this.paramSearchText,
                filterCategory: this.selectedCategoryIdx,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
                filterItemType: null,
                orderType: this.selectedOrder
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/instance/list`, param, this.headers).then(async (res) => {
                // console.log('------ instance list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.itemList = this.itemList.concat(res.data.list);
                    this.totalRecordSize = res.data.page.totalRecordSize;
                    
                    setTimeout(()=> {
                        that.handlerCheckListBottom();
                    }, 500)
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getSearchList(){
            // NFT 리스트 검색 요청 파라미터
            // order: // 1: latest // 2: popularity // 3: close to finish // 4: low price // 5: high price // 6: small bids // 7: a lot of bids // 8: most seen // 9: oldest 
            // itemType: // 1: 조각 아이템 // 2: 단일 아이템
            // state // 1: 숨김 // 2: 판매종료(expired) // 3: 판매완료(sold out)  // 4: 판매중
            // FilterCategory 1: art 2: music 3: virtual world 4: trading cards 5: collectibles 6: sports 7: utility 8: etc 
            // FilterPrice 1:usd 2:att 3:klay 
            // -startPrice : 최소 가격
            // -endPrice : 최대 가격
            // FilterCoin 심볼 쓰시면됩니다. ATT, KLAY
            // orderType 1:최신순 2:인기순 3:마감 임박순 4:낮은 가격순 5:높은 가격순 6:조회순 7:오래된순
            var that = this;
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                searchText: this.paramSearchText,
                filterCategory: this.selectedCategoryIdx,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCollection: this.selectedCollectionIdx,
                filterCoin: this.selectedSalesCoin,
                filterItemType: null,
                orderType: this.selectedOrder
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/info/searchList`, param, this.headers).then(async (res) => {
                // console.log('------ search list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.itemList = this.itemList.concat(res.data.list);
                    this.totalRecordSize = res.data.page.totalRecordSize;
                    
                    setTimeout(()=> {
                        that.handlerCheckListBottom();
                    }, 500)
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        
        resetList(){
            this.currentPageNo = 1;
            this.totalRecordSize =  0;
            this.itemList = [];

            this.getInstanceList();
        },
        getSelectedOrderText(){
            var item = this.orderOptions.find((item) => item.value === this.selectedOrder);
            return item.text;
        },
        changeOrderType(value){
            if(this.selectedOrder === value) return;
            this.selectedOrder = value;
            this.resetList();
        },
        getSelectedCategoryText(){
            var item = this.categoryOptions.find((item) => item.value === this.selectedCategoryIdx);
            return item.text;
        },
        changeCategory(idx){
            if(this.selectedCategoryIdx === idx) return;
            this.selectedCategoryIdx = idx;
            this.resetList();
        },
        changeFilterOptions(option){
            var that = this;
            switch(option.type){
                case 'setCollection':
                    setTimeout(()=>{that.initFlagSetCollection = false}, 100);
                    break;
                case 'status':
                    this.selectedStatus = option.value;
                    break;
                case 'price':
                    this.selectedPrice = option.priceType;
                    this.minPrice = option.minPrice;
                    this.maxPrice = option.maxPrice;
                    break;
                case 'collection':
                    this.selectedCollectionIdx = option.collectionIdx;
                    this.selectedCollectionName = option.collectionName;
                    break;
                case 'coin':
                    this.selectedSalesCoin = option.value;
                    break;
                case 'reset':
                    this.flagResetFilter = false;
                    break;
            }
            if(option.isRemove){
                this.initRemoveFilter = null;
            }
            this.resetList();
        },
        removeFilter(type){
            this.initRemoveFilter = type;
        },
        resetFilter(){
            if(this.isSearch){
                this.$router.push('/Marketplace' + (this.selectedCategoryIdx !== null ? '/' + this.getSelectedCategoryText() : ''));
                return;
            }

            this.selectedStatus = null;
            this.selectedPrice = null;
            this.minPrice = null;
            this.maxPrice = null;
            this.selectedSalesCoin = null;
            this.selectedCollectionIdx = null;
            this.selectedCollectionName = null;
            
            this.flagResetFilter = true;
        },
        getIsShowFilterList(){
            if(this.paramSearchText !== '' || this.selectedStatus !== null || this.selectedPrice !== null || this.selectedCollectionIdx !== null || this.selectedSalesCoin !== null){
                return true;
            } else {
                return false;
            }
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
