<template>
    <div class="collection-detail collection-page">
        <Header :initHeaderType="initHeaderType" @completeSetUserInfo="completeSetUserInfo" />
        <div class="collection-banner">
            <!-- <img src="@/assets/images/banner.png"/> -->
            <img :src="$store.state.configFront.cdnUrl + 'collection/banner/' + collectionInfo.banner"  v-if="collectionInfo !== null && $store.state.configFront !== null && collectionInfo.banner !== null"/>
            <img src="@/assets/images/profileBanner.png" alt="background" v-else />
        </div>
        <div class="links" v-if="collectionInfo !== null">
            <router-link tag="a" :to="'/Transactional/' + collectionIdx"><img src="@/assets/images/transaction-history.png" /></router-link>
            <router-link tag="a" :to="'/CollectionModify/' + collectionIdx" v-if="collectionInfo !== null && $store.state.userName === collectionInfo.memberId"><img src="@/assets/images/icon/fix.svg" /></router-link>
            <router-link tag="a" :to="'/MyProfile/Loyalty/' + collectionIdx" v-if="collectionInfo !== null && collectionInfo.memberId === $store.state.userName"><img src="@/assets/images/loyalty-history.svg" /></router-link>
            <img src="@/assets/images/siren.png" @click="initIsReport = !initIsReport"/>
        </div>
        <b-container class="mb-padding">
            <div class="profile" v-if="collectionInfo !== null">
                <!-- <img src="/assets/images/blackman.png" /> -->
                <img :src="$store.state.configFront.cdnUrl + 'collection/logo/' + collectionInfo.logo" v-if="collectionInfo !== null && $store.state.configFront !== null"/>
            </div>
            <div class="links mb">
                <router-link tag="a" class="noL" :to="'/Transactional/' + collectionIdx"><img src="@/assets/images/transaction-history.png" /></router-link>
                <router-link tag="a" :to="'/CollectionModify/' + collectionIdx" v-if="collectionInfo !== null && $store.state.userName === collectionInfo.memberId"><img src="@/assets/images/icon/fix.svg" /></router-link>
                <router-link tag="a" :to="'/MyProfile/Loyalty/' + collectionIdx" v-if="collectionInfo !== null && collectionInfo.memberId === $store.state.userName"><img src="@/assets/images/loyalty-history.svg" /></router-link>
                <img src="@/assets/images/siren.png" @click="initIsReport = !initIsReport"/>
            </div>
            <p class="title" v-if="collectionInfo !== null">{{collectionInfo.collectionName}}</p>
            <b-row class="status" v-if="collectionInfo !== null">
                <b-col cols="3" sm="3" lg="3">
                    <p class="number">{{collectionInfo.itemCount | comma}}</p>
                    <p class="title">{{ $t('myCollection.items') }}</p>
                </b-col>
                <b-col cols="3" sm="3" lg="3">
                    <p class="number">{{collectionInfo.ownerCount | comma}}</p>
                    <p class="title">{{ $t('myCollection.owners') }}</p>
                </b-col>
                <b-col cols="3" sm="3" lg="3">
                    <p class="number">{{collectionInfo.floorPrice | comma}}</p>
                    <p class="title">{{ $t('myCollection.avg-price') }}</p>
                    <!-- <p class="dollar">${{collectionInfo.floorPrice}}</p> -->
                </b-col>
                <b-col cols="3" sm="3" lg="3" class="last">
                    <p class="number"><span>$</span> {{collectionInfo.volumeTrade.toFixed(4) | comma}}</p>
                    <p class="title">{{ $t('myCollection.volume-traded') }}</p>
                    <!-- <p class="dollar">${{collection.volumeTradedSub}}</p> -->
                </b-col>
            </b-row>
            <p class="description" v-if="collectionInfo !== null" v-html="collectionInfo.description"></p>
            <router-link tag="div" class="mb publish" to="/NftPublish" v-if="collectionInfo !== null && $store.state.userName === collectionInfo.memberId">
                <p>{{ $t('myCollection.plus') }}</p>
                <p class="plus"></p>
            </router-link>
        </b-container>
        <b-row class="profile-menu">
            <div class="underline">
                <b-col class="menu1" :class="{on : selectedTabNo === 1}" @click="$router.push('/Collection/' + collectionIdx)">{{ $t('collectionDetail.home') }}</b-col>
                <b-col class="menu2" :class="{on : selectedTabNo === 2}" @click="$router.push('/Collection/AllItem/' + collectionIdx)">{{ $t('collectionDetail.all') }}</b-col>
                <b-col class="menu3" :class="{on : selectedTabNo === 3}" @click="$router.push('/Collection/Hide/' + collectionIdx)" v-if="collectionInfo !== null && $store.state.userName === collectionInfo.memberId">{{ $t('collectionDetail.hide') }}</b-col>
                <router-link tag="div" class="publish" to="/NftPublish" v-if="collectionInfo !== null && $store.state.userName === collectionInfo.memberId">
                    <p>{{ $t('myCollection.plus') }}</p>
                    <p class="plus"></p>
                </router-link>
            </div>
        </b-row>
        <div class="home" v-if="selectedTabNo === 1">
            <div class="recently">
                <div class="title-box">
                    <p class="title">{{ $t('myCollection.slideTitle1') }}</p>
                    <router-link tag="p" class="link" :to="'/Collection/AllItem/' + collectionIdx">{{ $t('myCollection.see') }}</router-link>
                </div>
                <Slide :slideItem="nftInfoRecentlyList" :swiperNextButton="'recently-slide-next'" :swiperOptionNext="'.recently-slide-next'" :swiperPrevButton="'recently-slide-prev'" :swiperOptionPrev="'.recently-slide-prev'" :initDropDown="isMineCollection"  @completeHide="completeHide" @completeHideCancel="completeHideCancel"/>
            </div>
            <div class="sale">
                <div class="title-box">
                    <p class="title">{{ $t('myCollection.slideTitle2') }}</p>
                    <router-link tag="p" class="link" :to="'/Collection/AllItem/' + collectionIdx">{{ $t('myCollection.see') }}</router-link>
                </div>
                <Slide :slideType="'marketplace'" :slideItem="nftInfoSaleList" :swiperNextButton="'sale-slide-next'" :swiperOptionNext="'.sale-slide-next'" :swiperPrevButton="'sale-slide-prev'" :swiperOptionPrev="'.sale-slide-prev'" :initDropDown="isMineCollection" />
            </div>
        </div>
        <div class="profile-page" v-else>
            <div class="cont-box" id="cont-box">
                <SelectFilter 
                    :initFlagSetCollection="initFlagSetCollection" 
                    :initFollowFilter="initFollowFilter" 
                    :initShowStatus="initShowStatus" 
                    :initShowCollection="initShowCollection" 
                    :initShowSymbol="initShowSymbol" 
                    :initFlagResetFilter="flagResetFilter" 
                    :initRemoveFilter="initRemoveFilter" 
                    @changeFilterOptions="changeFilterOptions"
                    v-if="selectedTabNo !== null"
                />
                <div class="cont-area">
                    <div class="item-list-box" v-if="selectedTabNo !== 1">
                        <div class="right">
                            <div class="top util">
                                <div class="search">
                                    <b-input placeholder="아이템을 검색하세요" v-model="searchText" @keyup.enter="searchItem()"></b-input>
                                </div>
                                <div class="input-group dropdown">
                                    <b-dropdown :text="getSelectedOrderText()">
                                        <b-dropdown-item v-for="(item) in orderOptions" :key="item.value" @click="changeOrderType(item.value)">{{item.text}}</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <div class="filter-list" v-if="getIsShowFilterList()">
                                <div class="reset" @click="resetFilter()">
                                    <p>{{ $t('marketplace.reset') }}</p>
                                </div>
                                <div v-if="searchedText !== ''">
                                    <p>{{searchedText}}</p>
                                    <i class="btn-close" @click="removeSearchText()" />
                                </div>
                                <div v-if="selectedStatus !== null">
                                    <p v-if="selectedStatus === 1">{{ $t('selectFilter.buy') }}</p>
                                    <p v-else-if="selectedStatus === 2">{{ $t('selectFilter.auction') }}</p>
                                    <p v-else-if="selectedStatus === 3">{{ $t('selectFilter.new') }}</p>
                                    <p v-else-if="selectedStatus === 4">{{ $t('selectFilter.offers') }}</p>
                                    <i class="btn-close" @click="removeFilter('status')" />
                                </div>
                                <div v-if="selectedPrice !== null">
                                    <p>
                                        {{minPrice}}~{{maxPrice}}
                                        <span v-if="selectedPrice === 1">USD</span> 
                                        <span v-else-if="selectedPrice === 2">ATT</span> 
                                        <span v-else-if="selectedPrice === 3">KLAY</span> 
                                    </p>
                                    <i class="btn-close" @click="removeFilter('price')" />
                                </div>
                                <div v-if="selectedCollectionIdx !== null">
                                    <p>{{selectedCollectionName}}</p>
                                    <i class="btn-close" @click="removeFilter('collection')" />
                                </div>
                                <div v-if="selectedSalesCoin !== null">
                                    <p>{{selectedSalesCoin}}</p>
                                    <i class="btn-close" @click="removeFilter('coin')" />
                                </div>
                            </div>
                            <div class="bottom cont" v-if="selectedTabNo === 2">
                                <b-row>
                                    <b-col class="product" v-for="(item,idx) in collectionAllList" :key="idx">
                                        <Item :itemInfo="item" :initItemDropDown="isMineCollection" :initIsMine="isMineCollection" @completeHide="completeHide" @completeTransfer="completeTransfer"/>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="bottom" v-if="isMineCollection && selectedTabNo === 3">
                                <b-row>
                                    <b-col class="product" v-for="(item,idx) in collectionHideList" :key="idx">
                                        <Item :itemInfo="item" :initItemDropDown="isMineCollection" :initIsMine="isMineCollection" @completeHideCancel="completeHideCancel"  @completeTransfer="completeTransfer"/>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PopupReport :initIsShowPopup="initIsReport" :initTargetIdx="collectionIdx" :initReportType="initReportType" @close="close('Report')" />
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Slide from '@/components/Slide.vue'
import SelectFilter from '@/components/market/SelectFilter.vue';
import Item from '@/components/Item.vue';
import PopupReport from '@/components/market/PopupReport.vue'
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "CollectionDetail",
    components: {
        Header,
        Slide,
        PopupReport,
        PopupOK,
        SelectFilter,
        Item
    },
    data() {
        return {
            initHeaderType: 'type1',
            tabName: 'Home',
            selectedTabNo: 1,
            isMineCollection: false,

            collectionIdx: 0,
            collectionInfo: null,

            nftInfoRecentlyList: [],
            nftInfoSaleList: [],
            nftInventoryList: [],
            collectionAllList: [],
            collectionHideList: [],

            initIsReport: false,
            initReportType: 1,  //type 0:아이템 1: 컬렉션 2: 멤버 신고

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이

            //정렬
            selectedOrder: 1,
            orderOptions:[
                { value: 1, text:'최신 순',},
                { value: 2, text:'인기 순',},
                { value: 3, text:'조회 순',},
                { value: 4, text:'오래된 순',},
            ],

            //필터
            initFollowFilter: false,
            initFlagSetCollection: false,
            initCollectionType: 0,  // 0 내가 소유한 컬렉션, 1 내가 제안한 컬렉션, 2 내가 즐겨찾기한 컬렉션
            initPreloadCollectionInfo: null,

            flagResetFilter: false,
            initRemoveFilter: null,
            initIsTransaction: true,

            initShowStatus: true,
            initShowPrice: true,
            initShowCollection: true,
            initShowSymbol: true,

            searchText: '',
            searchedText: '',
            selectedStatus: null,
            selectedPrice: null,
            minPrice: null,
            maxPrice: null,
            selectedSalesCoin: null,
            selectedCollectionIdx: null, 
            selectedCollectionName: null,

            intervalcheckMineCollection: null,
        }
    },
    created() {
        if(this.$route.params.tabName){
            this.tabName = this.$route.params.tabName;
        }

        if(this.$route.params.idx){
            this.collectionIdx = Number(this.$route.params.idx);

            if(this.tabName === 'Home'){
                this.selectedTabNo = 1;
            } else if(this.tabName === 'AllItem'){
                this.selectedTabNo = 2;
                this.initShowCollection = false;
            } 
            else if(this.tabName === 'Hide'){
                if(this.$store.state.id === '' || this.$store.state.ticket === ''){
                    this.$router.push('/Collection/' + this.collectionIdx);
                    return; 
                }
                this.selectedTabNo = 3;
                this.initShowStatus = false;
                this.initShowCollection = false;
                this.initShowSymbol = false;
            }
            this.getCollectionInfo();
        } else {
            this.$router.push("/");
        }
    },
    mounted() {
    },
    beforeDestroy() {
        clearInterval(this.intervalcheckMineCollection);
    },
    methods: {
        resetList(){
            if(this.tabName === 'Home'){
                this.getNftInfoRecentlyList();
                this.getNftInfoSaleList();
            } else if(this.tabName === 'AllItem'){
                this.collectionAllList = [];
                this.getCollectionAllItem();
            } 
            else if(this.tabName === 'Hide'){
                this.collectionHideList = [];
                if(this.isMineCollection){
                    this.getCollectionHideItem();
                }
            }
        },
        getSelectedOrderText(){
            var item = this.orderOptions.find((item) => item.value === this.selectedOrder);
            return item.text;
        },
        changeOrderType(value){
            if(this.selectedOrder === value) return;
            this.selectedOrder = value;
            this.resetList();
        },
        getCollectionInfo(){
            var param = 
            {
                // id: this.$store.state.id,
                // ticketId: this.$store.state.ticket,
                collectionIdx: this.collectionIdx
            }
            this.$Axios.post(`${this.host}/nft/collection/info`, param, this.headers).then(async (res) => {
                // console.log('---get collection info----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.collectionInfo = res.data.data;
                    this.resetList();
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftInfoRecentlyList(){   //내가 등록한 모든 아이템 조회
            // NFT 리스트 검색 요청 파라미터
            // order: // 1: latest // 2: popularity // 3: close to finish // 4: low price // 5: high price // 6: small bids // 7: a lot of bids // 8: most seen // 9: oldest
            // category: // 1: art // 2: music // 3: virtual world // 4: trading cards // 5: collectibles // 6: sports // 7: utility // 8: etc 
            // contractType: // 1: erc721 // 2: erc1155   <-- 이거만 사용예정 
            // itemType: // 1: 조각 아이템 // 2: 단일 아이템
            // resourceType: // 1: JPG // 2: PNG // 3: GIF // 4: SVG // 11: MP4 // 12: MP3 // 13: WEBM // 14: WAV // 15: OGG
            // state // 1: 숨김 // 2: 판매종료(expired) // 3: 판매완료(sold out)  // 4: 판매중

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: 1,
                pageSize: 100,
                collectionIdx: this.collectionIdx,
                orderType: 1
            }
            this.$Axios.post(`${this.host}/nft/info/list`, param, this.headers).then(async (res) => {
                // console.log('---recently item list----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.nftInfoRecentlyList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getNftInfoSaleList(){ 
            var param = 
            {
                filterCategory: null,
                filterStatus: null,
                filterPrice: null,
                startPrice: null,
                endPrice: null,
                filterCollection: this.collectionIdx,
                filterCoin: null,
                filterItemType: null,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/instance/list`, param, this.headers).then(async (res) => {
                // console.log('---sale item list----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.nftInfoSaleList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getCollectionAllItem(){
            var param = 
            {
                collectionIdx: this.collectionIdx,
                searchText: this.searchedText,
                // filterCategory: null,
                filterStatus: this.selectedStatus,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                filterCoin: this.selectedSalesCoin,
                orderType: this.selectedOrder,
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/nft/info/collection/Item`, param, this.headers).then(async (res) => {
                // console.log('------ get collection all item --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.collectionAllList = res.data.list;
                    // this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getCollectionHideItem(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                collectionIdx: this.collectionIdx,
                searchText: this.searchedText,
                // filterCategory: null,
                filterStatus: null,
                filterPrice: this.selectedPrice,
                startPrice: this.minPrice,
                endPrice: this.maxPrice,
                orderType: this.selectedOrder,
            }
            this.$Axios.post(`${this.host}/nft/info/collection/hideItem`, param, this.headers).then(async (res) => {
                // console.log('------ get collection hide item --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.collectionHideList = res.data.list;
                    // this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        changeOrder(){
            this.resetList();
        },
        changeCategory(idx){
            this.selectedCategoryIdx = idx;
            this.resetList();
        },
        searchItem(){
            if(this.searchedText === this.searchText) return;
            this.searchedText = this.searchText;
            this.resetList();
        },
        changeFilterOptions(option){
            var that = this;
            switch(option.type){
                case 'setCollection':
                    setTimeout(()=>{that.initFlagSetCollection = false}, 100);
                    break;
                case 'status':
                    this.selectedStatus = option.value;
                    break;
                case 'price':
                    this.selectedPrice = option.priceType;
                    this.minPrice = option.minPrice;
                    this.maxPrice = option.maxPrice;
                    break;
                case 'collection':
                    this.selectedCollectionIdx = option.collectionIdx;
                    this.selectedCollectionName = option.collectionName;
                    break;
                case 'coin':
                    this.selectedSalesCoin = option.value;
                    break;
                case 'reset':
                    this.flagResetFilter = false;
                    break;
            }
            if(option.isRemove){
                this.initRemoveFilter = null;
            }
            this.resetList();
        },
        removeFilter(type){
            this.initRemoveFilter = type;
        },
        removeSearchText(){
            this.searchedText = '';
            this.resetList();
        },
        resetFilter(){
            this.searchedText = '';
            this.selectedStatus = null;
            this.selectedPrice = null;
            this.minPrice = null;
            this.maxPrice = null;
            this.selectedSalesCoin = null;
            this.selectedCollectionIdx = null;
            this.selectedCollectionName = null;

            this.initPreloadCollectionInfo = null;
            
            this.flagResetFilter = true;
        },
        getIsShowFilterList(){
            if(this.searchedText !== '' || this.selectedStatus !== null || this.selectedPrice !== null || this.selectedCollectionIdx !== null || this.selectedSalesCoin !== null){
                return true;
            } else {
                return false;
            }
        },
        completeSetUserInfo(){
            var that = this;
            var checkedIsMine = this.checkIsMineCollection();
            if(checkedIsMine) return;

            this.intervalcheckMineCollection = setInterval(()=>{
                if(that.collectionInfo !== null && that.$store.state.userName !== ''){
                    clearInterval(that.intervalcheckMineCollection);
                    that.checkIsMineCollection();
                }
            }, 100);
        },
        checkIsMineCollection(){
            if(this.collectionInfo !== null && this.$store.state.userName !== ''){
                if(this.collectionInfo.memberId === this.$store.state.userName){
                    this.isMineCollection = true;
                    this.resetList();
                } else if(this.selectedTabNo === 3){
                    this.$router.push('/Collection/' + this.collectionIdx)
                }
                return true;
            } 
            else false;
        },
        completeHide(){
            this.initPopupOkMessage = '숨김 처리가 완료되었습니다.';
            this.isShowPopupOk = true;
            this.resetList();
        },
        completeHideCancel(){
            this.initPopupOkMessage = '숨김 해제 처리가 완료되었습니다.';
            this.isShowPopupOk = true;
            this.resetList();
        },
        completeTransfer(){
            this.initPopupOkMessage = '양도 요청이 완료되었습니다.';
            this.isShowPopupOk = true;
            this.resetList();
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'Report':
                    this.initIsReport = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
