<template>
    <div class="floating" :class="[$store.state.isShowFloating ? 'floating-show' : 'floating-hidden']" v-if="$store.state.floatingData !== null">
        <div class="top">
            <p><span></span>{{ $t('floating.title') }}</p>
            <img src="@/assets/images/floating-close.svg" @click="closeFloating" />
        </div>
        <p v-if="$store.state.floatingData.type === 1">{{ $t('floating.type1') }}</p>
        <p v-else-if="$store.state.floatingData.type === 2">{{ $t('floating.type2') }}</p>
        <p v-else-if="$store.state.floatingData.type === 3">{{ $t('floating.type3') }}</p>
        <p v-else-if="$store.state.floatingData.type === 4">{{ $t('floating.type4') }}</p>
        <p v-else-if="$store.state.floatingData.type === 5">{{ $t('floating.type5') }}</p>
        <p v-else-if="$store.state.floatingData.type === 6">{{ $t('floating.type6') }}</p>
        <a @click ="moveNftInfo()">{{ $t('floating.type7') }}</a>
    </div>
</template>

<script>
export default {
    name: "FloatingAlram",
    components:{
    },
    data() {
        return {
        };
    },
    props: {
        initConnectWebSocket:{
            type: Boolean,
            default: false
        },
    },
    watch:{
        initConnectWebSocket(){
            if(this.initConnectWebSocket){
                this.connectWebSocket();
            } else {
                this.disconnectWebSocket();
                this.closeFloating();
            }
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {
    },
    methods: {
        connectWebSocket(){
            try {
                // 1, 접속
                let that = this;
                if(this.$store.state.wsAlram === null){
                    this.$store.state.wsAlram = new WebSocket(this.ws_host);
                    if(this.$store.state.wsAlram === undefined || this.$store.state.wsAlram === null) { 
                        console.log('failed to connect websocket'); 
                        return; 
                    }
                    this.$store.state.wsAlram.onopen = function () {
                        let channel = { channel: that.$store.state.walletAddress }
                        that.$store.state.wsAlram.send(JSON.stringify(channel));
                        if(that.$store.state.intervalCheckWebSocketState !== null){
                            clearInterval(that.$store.state.intervalCheckWebSocketState);
                        }
                        that.$store.state.intervalCheckWebSocketState = setInterval(()=>{ that.checkWebSocketState();}, 120000);
                    };
                    this.$store.state.wsAlram.onmessage = function (event) {
                        // 2, 웹소켓 응답
                        try {
                            // console.log(event.data);
                            let jsonObject = JSON.parse(event.data);
                            // console.log(jsonObject);
                            if(jsonObject){
                                if(jsonObject.type){
                                    console.log(jsonObject);
                                    that.$store.state.floatingData = jsonObject;
                                    that.$store.state.isShowFloating = true;
                                }
                                if(jsonObject.status){
                                    if(jsonObject.status === 0 || jsonObject.status === -3){
                                        that.$store.state.wsCheckState = 2;
                                    } else {
                                        that.disconnectWebSocket();
                                    }
                                }
                            }
                        }
                        catch(e) {
                            console.error(event.data);
                            // console.error(e);
                        }
                    };
                    this.$store.state.wsAlram.onclose = function (event) { event;
                        clearInterval(that.$store.state.intervalCheckWebSocketState);
                        that.$store.commit("disconnectWebSocket");
                    };
                } else {
                    var state = this.$store.state.wsAlram.readyState;
                    if(state !== 1){
                        this.$store.commit("disconnectWebSocket")
                        this.connectWebSocket();
                    }
                }
            }
            catch(e){
                this.closeFloating();
                console.error(e);
            }
        },
        disconnectWebSocket(){
            try{
                clearInterval(this.$store.state.intervalCheckWebSocketState);

                if(this.$store.state.wsAlram !== null){
                    var state = this.$store.state.wsAlram.readyState;
                    if(state === 1){
                        this.$store.state.wsAlram.close();
                    }
                }
                this.$store.commit("disconnectWebSocket");
            }
            catch(e){
                this.$store.commit("disconnectWebSocket");
                return;
            }
        },
        checkWebSocketState(){
            if(this.initConnectWebSocket){
                if(this.$store.state.wsAlram !== null){
                    if(this.$store.state.wsCheckState === 1){
                        this.disconnectWebSocket();
                        this.connectWebSocket();
                    } else {
                        this.$store.state.wsCheckState = 1;
                        this.$store.state.wsAlram.send(" ");
                    }
                } else {
                    this.connectWebSocket();
                }
            } else {
                this.disconnectWebSocket();
                this.closeFloating();
            }
        },
        moveNftInfo(){
            this.$router.push('/NftInfo/' + this.$store.state.floatingData.data.productIdx);
            this.$store.commit("resetFloating");
        },
        closeFloating() {
            var that = this;
            this.$store.state.isShowFloating = false;
            if(this.$store.state.floatingData !== null){
                setTimeout(() => {
                    that.$store.commit("resetFloating");
                }, 1000);
            } else {
                this.$store.commit("resetFloating");
            }
        },
    },
};
</script>
