<template>    
    <div class="item" v-if="itemInfo !== null && $store.state.configFront !== null">
        <VueLoadImage>
            <div slot="preloader" class="img-preload"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div class="bg" slot="image" @click="moveProductDetil()" :src="$store.state.configFront.cdnUrl + 'resource/' + (Number(itemInfo.resourceType) === 1 ? itemInfo.itemFileType + '/' + itemInfo.itemFileName : 'thumbnail/' + itemInfo.thumbnailFileName) "> 
                <img :src="$store.state.configFront.cdnUrl + 'resource/' + itemInfo.itemFileType + '/' + itemInfo.itemFileName" class="work" v-if="Number(itemInfo.resourceType) === 1" />
                <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + itemInfo.thumbnailFileName"  class="work" v-else/>

                <img src="@/assets/images/icon/icon_PlaySymbol.svg" class="play-btn" v-if="Number(itemInfo.resourceType) === 2"/>
                <div class="info">
                    <div class="like">
                        <div class="heart">
                            <img src="@/assets/images/slideimage/full-heart.svg" class="star" />
                            <p>{{itemInfo.likeCount}}</p>
                        </div>
                    </div>
                    <p class="writer">{{itemInfo.name}}</p>
                    <p class="wname">{{itemInfo.collectionName}}</p>
                    <p class="history" v-if="itemInfo.itemType === 1">x{{itemInfo.totalItemCount | comma}}</p>
                </div>
                <div class="price">
                    <div class="time">
                        <p v-if="itemInfo.offerMaxPrice > 0">제안</p>
                        <p v-else-if="itemInfo.lastPrice > 0">{{ $t('item.before') }}</p>

                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="itemInfo.offerMaxPrice > 0 && itemInfo.offerSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/klaytn-white.svg" v-else-if="itemInfo.offerMaxPrice > 0  && itemInfo.offerSymbol === 'KLAY'" />
                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-else-if="itemInfo.lastPrice > 0 && itemInfo.lastSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/klaytn-white.svg" v-else-if="itemInfo.lastPrice > 0 && itemInfo.lastSymbol === 'KLAY'" />

                        <p v-if="itemInfo.offerMaxPrice > 0">{{itemInfo.offerMaxPrice | comma}}</p>
                        <p v-else-if="itemInfo.lastPrice > 0">{{itemInfo.lastPrice | comma}}</p>
                        <p v-else>&nbsp;</p>
                        
                        <p class="max" v-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice === null">{{ $t('item.min') }}</p>
                        <p class="max" v-else-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice !== null">{{ $t('item.max') }}</p>
                    </div>
                    <div class="bottom">
                        <p>{{ $store.getters.getRemainDate(itemInfo.expireDate)}}</p>
                        
                        <p class="ausp" v-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice !== null">{{itemInfo.bidMaxPrice | comma}} {{itemInfo.bidSymbol}}</p>
                        <!-- <p class="ausp" v-else-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice === null">{{itemInfo.minPriceToken | comma}} {{itemInfo.minSymbol}}</p> -->
                        <p class="ausp" v-else-if="itemInfo.minPriceToken > 0">{{itemInfo.minPriceToken | comma}} {{itemInfo.minSymbol}}</p>
                    </div>
                </div>
            </div>
            <div class="bg" slot="error">
                {{ $t('main.error') }}
            </div>
        </VueLoadImage>
    </div>
</template>

<script>
import VueLoadImage from 'vue-load-image'

export default {
    name: "CurationItem",
    components: {
        VueLoadImage,
    },
    data() {
        return {
        };
    },
    props: {
        itemInfo: {
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        moveProductDetil(){
            this.$router.push('/NftInfo/' + this.itemInfo.productIdx)
        }
    },
};
</script>
