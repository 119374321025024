<template>    
    <div class="item" v-if="itemInfo !== null && $store.state.configFront !== null">
        <div class="dropdown-box" v-if="initItemDropDown">
            <b-dropdown id="dropdown-1">
                <b-dropdown-item @click="moveSell()" v-if="itemInfo.itemCountMine - itemInfo.itemCountOnSaleMine - itemInfo.itemCountReservedMine > 0"><img src="@/assets/images/icon/bag.svg" /> 판매하기</b-dropdown-item>
                <b-dropdown-item @click="snsShare('copy')"><img src="@/assets/images/icon/clip.svg" /> 링크복사</b-dropdown-item>
                <b-dropdown-item @click="showTransfer()" v-if="itemInfo.itemCountMine - itemInfo.itemCountOnSaleMine > 0"><img src="@/assets/images/icon/share.svg" /> 양도하기</b-dropdown-item>
                <b-dropdown-item @click="moveModify()" v-if="itemInfo.ownerCount === 1 && itemInfo.lastPriceSymbol === null && itemInfo.itemCountOnSaleMine === 0 && itemInfo.itemCountReservedMine === 0"><img src="@/assets/images/icon/pencil.svg" /> 수정하기</b-dropdown-item>
                <b-dropdown-item @click="hideCancelItem()" v-if="itemInfo.hideState === 1"><img src="@/assets/images/icon/see.svg" /> {{ $t('item.unhide') }}</b-dropdown-item>
                <b-dropdown-item @click="hideItem()" v-else><img src="@/assets/images/icon/no-see.svg" /> {{ $t('item.hide') }}</b-dropdown-item>
            </b-dropdown>
        </div>
        <VueLoadImage>
            <div slot="preloader" class="img-preload"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div class="bg" slot="image" @click="moveProductDetil()" :src="$store.state.configFront.cdnUrl + 'resource/' + (itemInfo.resourceType === 1 ? itemInfo.filePath : 'thumbnail/' + itemInfo.thumbnailFileName)">
                <img :src="$store.state.configFront.cdnUrl + 'resource/' + itemInfo.filePath" class="work"  v-if="itemInfo.resourceType === 1"/>
                <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + itemInfo.thumbnailFileName" class="work" v-else/>
                <img src="@/assets/images/icon/icon_PlaySymbol.svg" class="play-btn" v-if="itemInfo.resourceType === 2"/>
                <div class="info">
                    <div class="like">
                        <div class="heart">
                            <img src="@/assets/images/slideimage/full-heart.svg" />
                            <p>{{itemInfo.countHeart}}</p>
                        </div>
                        <!-- <img src="@/assets/images/slideimage/empty-star.svg" class="star" /> -->
                    </div>
                    <p class="writer">{{itemInfo.collectionName}}</p>
                    <p class="wname">{{itemInfo.name}}</p>
                    <p class="history" v-if="itemInfo.itemType === 1">x{{initIsMine ? itemInfo.itemCountMine : itemInfo.totalItemCount | comma}}</p>
                </div>
                <div class="price">
                    <div class="time">
                        <p v-if="itemInfo.offerMaxPrice > 0">제안</p>
                        <p v-else-if="itemInfo.lastPriceAmount > 0">{{ $t('item.before') }}</p>

                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="itemInfo.offerMaxPrice > 0 && itemInfo.offerSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/klaytn-white.svg" v-else-if="itemInfo.offerMaxPrice > 0  && itemInfo.offerSymbol === 'KLAY'" />
                        <img src="@/assets/images/icon/icon_symbol_artube.png" v-else-if="itemInfo.lastPriceAmount > 0 && itemInfo.lastPriceSymbol === 'ATT'" />
                        <img src="@/assets/images/icon/klaytn-white.svg" v-else-if="itemInfo.lastPriceAmount > 0  && itemInfo.lastPriceSymbol === 'KLAY'" />

                        <p v-if="itemInfo.offerMaxPrice > 0">{{itemInfo.offerMaxPrice | comma}}</p>
                        <p v-else-if="itemInfo.lastPriceAmount > 0">{{itemInfo.lastPriceAmount | comma}}</p>
                        <p v-else>&nbsp;</p>

                        <p class="max" v-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice === null">{{ $t('item.min') }}</p>
                        <p class="max" v-else-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice !== null">{{ $t('item.max') }}</p>
                    </div>
                    <div class="bottom">
                        <p>{{ $store.getters.getRemainDate(itemInfo.expireDate)}}</p>

                        <p class="ausp" v-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice !== null">{{itemInfo.bidMaxPrice | comma}} {{itemInfo.bidSymbol}}</p>
                        <!-- <p class="ausp" v-else-if="itemInfo.saleType === 2 && itemInfo.bidMaxPrice === null">{{itemInfo.minPriceToken | comma}} {{itemInfo.minSymbol}}</p> -->
                        <p class="ausp" v-else-if="itemInfo.minPriceToken > 0">{{itemInfo.minPriceToken | comma}} {{itemInfo.minSymbol}}</p>
                    </div>
                </div>
            </div>
            <div class="bg" slot="error">
                {{ $t('main.error') }}
            </div>
        </VueLoadImage>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupTransfer :initIsShowPopup="isShowPopupTransfer" :initItemInfo="itemInfo" @close="close('PopupTransfer')" @completeTransfer="completeTransfer" />
    </div>
</template>

<script>
import PopupOK from '@/components/popup/PopupOK.vue';
import PopupTransfer  from '@/components/market/PopupTransfer.vue';

import VueLoadImage from 'vue-load-image'

export default {
    name: "Item",
    components: {
        PopupOK,
        PopupTransfer,
        VueLoadImage,
    },
    data() {
        return {
            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupTransfer: false,
        };
    },
    props: {
        itemInfo: {
            type: Object,
            default: null
        },
        initItemDropDown: {
            type: Boolean,
            default: false
        },
        initIsMine:{
            type:Boolean,
            default: false
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        moveProductDetil(){
            this.$router.push('/NftInfo/' + this.itemInfo.productId)
        },
        moveSell(){
            this.$router.push('/NftSell/' + this.itemInfo.productId)
        },
        moveModify(){
            this.$router.push("/NftModify/" + this.itemInfo.productId)
        },
        snsShare(shareType){
            var targetURL = window.location.protocol + "//" + window.location.host + "/NftInfo/" + this.itemInfo.productId;
            if(shareType === 'twitter'){
                window.open("https://twitter.com/intent/tweet?url=" + targetURL);
                //window.open("https://twitter.com/intent/tweet?text=" + sendText + "&url=" + sendUrl);
            } else if (shareType === 'telegram'){
                window.open("https://telegram.me/share/url?url=" + targetURL);
                //window.open("https://telegram.me/share/url?url=" + url + "&text=" + text);
            } else if (shareType === 'facebook'){
                window.open("http://www.facebook.com/sharer/sharer.php?u=" + targetURL);
            } else if (shareType === 'copy'){
                this.$copyText(targetURL);
                this.initPopupOkMessage = "링크가 복사되었습니다."
                this.isShowPopupOk = true;
            }
        },
        showTransfer(){
            this.isShowPopupTransfer = true;
        },
        hideItem(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: this.itemInfo.productId
            }
            this.$Axios.post(`${this.host}/nft/hide`, param, this.headers).then(async (res) => {
                // console.log('---hide item----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    // this.initPopupOkMessage = '숨김 처리 완료';
                    // this.PopupOkCallbakPage = "reload";
                    // this.isShowPopupOk = true;
                    this.$emit('completeHide')
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        hideCancelItem(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                productId: this.itemInfo.productId
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/hideCancel`, param, this.headers).then(async (res) => {
                // console.log('---hide cancel item----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    // this.initPopupOkMessage = '숨김해제 처리 완료';
                    // this.PopupOkCallbakPage = "reload";
                    // this.isShowPopupOk = true;
                    this.$emit('completeHideCancel')
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        completeTransfer(){
            this.$emit("completeTransfer");
            // this.isShowPopupTransfer = false;
            // this.initPopupOkMessage = '양도 요청이 완료되었습니다.';
            // this.PopupOkCallbakPage = "reload";
            // this.isShowPopupOk = true;
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'PopupTransfer':
                    this.isShowPopupTransfer = false;
                    break;
                
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
