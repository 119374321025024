<template>    
    <div class="rankings">
        <Header :initHeaderType="initHeaderType" />
        <b-container>
            <p class="title">{{ $t('rankings.title') }}</p>
            <p class="intro" v-html="$t('rankings.intro')"></p>
        </b-container>
        <div class="dropdowns">
            <b-dropdown class="mobile category" :text="getSelectedRankingText()">
                <b-dropdown-item v-for="(item) in orderColumnOptions" :key="item.value" @click="changeOrderMobile(item.value)">{{item.text}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="container2">
            <div class="mobile sort" @click="changeOrderType()">
                <img src="@/assets/images/icon/sort.svg" />
                <p>{{ $t('rankings.sort') }}</p>
            </div>
            <div class="list-box">
                <b-row class="list-head pc">
                    <b-col class="rank-no">{{ $t('rankings.chart-title1') }}</b-col>
                    <b-col class="collection-rank">{{ $t('rankings.chart-title2') }}</b-col>
                    <b-col class="total-volume" @click="changeOrder(1)">
                        <p>{{ $t('rankings.chart-title3') }}</p>
                        <img src="@/assets/images/icon_small_down_arrow.svg" class="arrow" :class="{'desc' : selectedOrderType}" v-if="selectedOrderColumn === 1" />
                    </b-col>
                    <b-col class="trading-volume" @click="changeOrder(2)">
                        {{ $t('rankings.chart-title4') }}
                        <img src="@/assets/images/icon_small_down_arrow.svg" class="arrow" :class="{'desc' : selectedOrderType}" v-if="selectedOrderColumn === 2" />
                    </b-col>
                    <b-col class="day-change" @click="changeOrder(3)">
                        {{ $t('rankings.chart-title5') }}
                        <img src="@/assets/images/icon_small_down_arrow.svg" class="arrow" :class="{'desc' : selectedOrderType}" v-if="selectedOrderColumn === 3" />
                    </b-col>
                    <b-col class="average-price" @click="changeOrder(4)">
                        {{ $t('rankings.chart-title6') }}
                        <img src="@/assets/images/icon_small_down_arrow.svg" class="arrow" :class="{'desc' : selectedOrderType}" v-if="selectedOrderColumn === 4" />
                    </b-col>
                    <b-col class="owner" @click="changeOrder(5)">
                        {{ $t('rankings.chart-title7') }}
                        <img src="@/assets/images/icon_small_down_arrow.svg" class="arrow" :class="{'desc' : selectedOrderType}" v-if="selectedOrderColumn === 5" />
                    </b-col>
                    <b-col class="item-number" @click="changeOrder(6)">
                        {{ $t('rankings.chart-title8') }}
                        <img src="@/assets/images/icon_small_down_arrow.svg" class="arrow" :class="{'desc' : selectedOrderType}" v-if="selectedOrderColumn === 6" />
                    </b-col>
                </b-row>
                <b-row class="list-head mobile">
                    <b-col class="rank-no">{{ $t('rankings.chart-title1') }}</b-col>
                    <b-col class="collection-rank">{{ $t('rankings.chart-title2') }}</b-col>
                    <b-col class="total-volume"><p>{{ $t('rankings.chart-title3') }}</p></b-col>
                    <b-col class="day-change">{{ $t('rankings.chart-title5') }}</b-col>
                </b-row>
                <b-row v-for="(item,idx) in rankingList" :key="idx">
                    <b-col class="rank-no"><p>{{ item.no }}</p></b-col>
                    <b-col class="collection-rank">
                        <router-link tag="a" :to="'/Collection/' + item.collectionIdx" class="link-item">
                            <img :src="$store.state.configFront.cdnUrl + 'collection/logo/' + item.collectionFileName" class="photo" v-if="$store.state.configFront !== null"/>
                            <p>{{item.collectionName}}</p>
                        </router-link>
                    </b-col>
                    <b-col class="total-volume"><p>${{numberOnly(item.totalTrade) | comma}}</p></b-col>
                    <b-col class="trading-volume"><p>${{numberOnly(item.weekTrade) | comma}}</p></b-col>
                    <b-col class="day-change"><p v-if="item.weekChange !== null" :class="[item.weekChange === 0 ? '' : item.weekChange > 0 ? 'plus' : 'minus']"><span v-if="item.weekChange > 0">+</span>{{item.weekChange | comma}}%</p><p v-else>-</p></b-col>
                    <b-col class="average-price"><p>${{numberOnly(item.averagePrice) | comma}}</p></b-col>
                    <b-col class="owner"><p>{{item.owners | comma}}</p></b-col>
                    <b-col class="item-number"><p>{{item.itemCount | comma}}</p></b-col>
                </b-row>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "Rankings",
    krName: '랭킹',
    components: {
        Header,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type1',
            
            selectedOrderColumn: 1,
            selectedOrderType: false,    //true : 오름차순, false: 내림차순
            orderColumnOptions: [
                {value: null, text: '랭킹 목록 선택'},
                {value: 1, text: '총 거래량'},
                {value: 3, text: '7일 변화량'},
            ],

            rankingList: [],

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 100,    //한번에 불러올 게시물 수
            // maxPageCount:10,        //한 화면에 보여질 페이지 수

            // listCount: 0,
            // pageCount: 0,
            // pageNo: 0,
            // pageSize: 0,
            // totalRecordSize: 0,

            // pageList:[],

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이

            
        };
    },
    props: {
    },
    created() {
    },
    mounted() {
        this.getRankingList();
    },
    beforeDestroy() {},
    methods: {
        getSelectedRankingText(){
            var item = this.orderColumnOptions.find((item) => item.value === this.selectedOrderColumn);
            if(item === undefined){
                return this.orderColumnOptions[0].text;
            }
            return item.text;
        },
        changeOrder(orderColumn){
            if(this.selectedOrderColumn === orderColumn){
                this.selectedOrderType = !this.selectedOrderType;
            } else {
                this.selectedOrderColumn = orderColumn;
                this.selectedOrderType = false;
            }
            this.getRankingList();
        },
        changeOrderMobile(orderColumn){
            if(this.selectedOrderColumn === orderColumn) return;
            this.selectedOrderColumn = orderColumn;

            if(this.selectedOrderColumn !== null){
                this.getRankingList();
            }
        },
        changeOrderType(){
            this.selectedOrderType = !this.selectedOrderType;
            this.getRankingList();
        },
        getRankingList(){
            // RankOrderColumn 1: 총거래량 2:7일거래량 3:7일변화량 4:평균가 5:소유자 6:아이템 수 구요  
            // OrderType 1:ASC 2:DESC
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                // order: "1",
                // orderColumn: "",
                // apiStartDate: "",
                // apiEndDate: "",
                // searchText: "",
                // lang: this.$i18n.locale,
                // state: 0,
                // stateList: "",
                rankOrderColumn: this.selectedOrderColumn,
                rankOrderType: this.selectedOrderType ? 1 : 2,

            }
            // console.log(param);
            this.$Axios.post(`${this.host}/nft/ranking/list`, param, this.headers).then(async (res) => {
                // console.log('------ ranking list --------')
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.rankingList = res.data.list;
                    // this.listCount       = res.data.page.listCount;
                    // this.pageCount       = res.data.page.pageCount;
                    // this.pageNo          = res.data.page.pageNo;
                    // this.pageSize        = res.data.page.pageSize;
                    // this.totalRecordSize = res.data.page.totalRecordSize;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
