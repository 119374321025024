<template>
    <div class="qa wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container class="paddingLR20">
                <div class="support-box">
                    <div class="title">
                        <h1 class="title">{{ $t('faq.title') }}</h1>
                        <h2 class="sub-title">{{ $t('faq.subTitle') }}</h2>
                    </div>
                    <div class="flex">
                        <div class="input-group dropdown common">
                            <b-dropdown>
                                <template v-slot:button-content v-if="selectedSearchType === 1">{{ $t('notice.name') }}</template>
                                <template v-slot:button-content v-else-if="selectedSearchType === 2">{{ $t('inquiry.writeTitle2') }}</template>
                                <b-dropdown-item @click="changeSearchType(1)">{{ $t('notice.name') }}</b-dropdown-item>
                                <b-dropdown-item @click="changeSearchType(2)">{{ $t('inquiry.writeTitle2') }}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="search common">
                            <img src="@/assets/images/search.svg" @click="reqSearch()"/>
                            <input type="text" v-model="searchText" @keyup.enter="reqSearch()" />
                        </div>
                    </div>
                    <div class="menu-cover">
                        <div class="menu">
                            <p :class="{'on' : selectedFaqType === 0}" @click="changeCategory(0)">{{ $t('faq.all') }}</p>
                            <p :class="{'on' : selectedFaqType === item.faqCategoryIdx}" @click="changeCategory(item.faqCategoryIdx)" v-for="(item, index) in faqCategoryList" :key="index">{{item.name}}</p>
                        </div>
                    </div>
                    <div class="accordion np_tab" role="tablist">
                        <div v-for="(item, index) in faqList" :key="index" class="tab-cover">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle="'accordion-' + item.categoryIdx + '-' + index" variant="info">
                                        <b-row>
                                            <b-col class="kinds"><span>{{item.categoryName}}</span></b-col>
                                            <b-col class="question">{{item.title}}</b-col>
                                            <b-col class="arrow"><img src="@/assets/images/icon/icon_down_arrow.svg" alt="화살표"></b-col>
                                        </b-row>
                                    </b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + item.categoryIdx + '-' + index" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-row>
                                            <b-col>
                                                <b-card-text v-html="item.message"></b-card-text>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
            </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: 'FAQ',
    krName: '자주하는 질문',
    components: {
        Header,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type3',

            selectedFaqType: 0,
            faqCategoryList: [],
            faqList:[],

            selectedSearchType: 1,
            searchText: '',
            beforeSearchText: '',

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이
        }
    },
    created(){
        this.getFaqCategoryList();
        this.getFaqList();
    },
    mounted() {
    },
    beforeDestroy() {},
    methods: {
        changeCategory(categoryIdx){
            this.selectedFaqType = categoryIdx;
            this.faqList = [];
            this.getFaqList();
        },
        getFaqCategoryList(){
            var param = 
            {
            }
            this.$Axios.post(`${this.host}/support/faq/category`, param, this.headers).then(async (res) => {
                // console.log('------faq category list---------');
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.faqCategoryList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        getFaqList(){
            var param = 
            {
                // faqIdx: this.selectedFaqType,
                filterCategoryIdx: this.selectedFaqType === 0 ? null : this.selectedFaqType,
                searchType: this.selectedSearchType,
                searchText: this.beforeSearchText,
            }
            this.$Axios.post(`${this.host}/support/faq/list`, param, this.headers).then(async (res) => {
                // console.log('------faq list---------');
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.faqList = res.data.list;
                    this.searchText = this.beforeSearchText;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        reqSearch(){
            if(this.beforeSearchText === this.searchText) return;
            this.beforeSearchText = this.searchText;
            this.currentPageNo = 1;
            this.getFaqList();
        },
        changeSearchType(type){
            if(this.selectedSearchType === type) return;
            this.selectedSearchType = type;
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
}
</script>