<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box padding transfer">
            <div class="top">
                <p class="popup-title">{{ $t('transferPopup.title') }}</p>
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
            </div>
            <div class="middle">
                <div class="flex">
                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + initItemInfo.filePath" v-if="$store.state.configFront !== null && initItemInfo.resourceType === 1"/>
                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + initItemInfo.thumbnailFileName" v-if="$store.state.configFront !== null && initItemInfo.resourceType === 2"/>

                    <div class="right">
                        <router-link tag="p" :to="'/Collection/' + initItemInfo.collectionIdx" class="col-name">{{initItemInfo.collectionName}}</router-link>
                        <p class="product-name">{{initItemInfo.name}}</p>
                        <div class="product-intro"><p>{{initItemInfo.desc}}</p></div>
                    </div>
                </div>
                <div class="product-intro mb"><p>{{initItemInfo.desc}}</p></div>
            </div>
            
            <div class="bottom">
                <div class="buy-piece" v-if="initItemInfo.itemType === 1">
                    <h3>{{ $t('transferPopup.give') }}</h3>
                    <input class="form-control" v-model="quantity" @input="commaAmount" />
                    <p class="selling">{{ $t('transferPopup.have') }} : {{initItemInfo.itemCountMine - initItemInfo.itemCountOnSaleMine}}</p>
                </div>
                <div class="to-address">
                    <h3>{{ $t('transferPopup.bottomTitle') }}</h3>
                    <p>• {{ $t('transferPopup.bottomText1') }}</p>
                    <p>• {{ $t('transferPopup.bottomText2') }}</p>
                    <b-input placeholder="예시) 0xc1a8cf204708498de3aa891ad18979881936fc79" v-model="targetAddress" @input="checkAddress"></b-input>
                    <div class="warn">
                        <p v-if="isAddressWarning">{{ $t('transferPopup.warn') }}</p>
                    </div>
                </div>
                <b-button @click="reqNftTransfer()">{{ $t('transferPopup.button') }}</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import Caver from "caver-js";
import NFTContract from "@/assets/json/ArtubeNft.json";
import MarketContract from "@/assets/json/NftMarket.json";

export default {
    name: "PopupTransfer",
    data() {
        return {
            nftContract: NFTContract,
            marketContract: MarketContract,

            quantity: '',
            numberQuantity: 0,
            targetAddress: '',
            isAddressWarning: false,

            isAllowance: false,
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initItemInfo: {
            type: Object,
            default: null,
        },
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    watch:{
        initIsShowPopup(){
            this.targetAddress = '';
            this.isAllowance = false;

            if(this.initIsShowPopup && this.initItemInfo !== null){
                // console.log(this.initItemInfo);
                this.checkApprove();
                if(this.initItemInfo.itemType === 2){    //단일 아이템의 경우 수량은 1개로 고정
                    this.quantity = "1";
                    this.commaAmount();
                }
            }
        }  
    },
    methods: {
        async checkApprove(){
            // console.log(this.initBuyInstance.saleType);
            var NftContract = this.$store.state.configFront.contract1155;
            var targetAddress = this.$store.state.configFront.contractJoin;
            var walletAddress = this.$store.state.walletAddress;

            this.isApprovedForAll(NftContract, targetAddress, walletAddress, (approved)=>{
                if(approved) {
                    this.isAllowance = true;
                } else {
                    this.isAllowance = false;
                }
            });
        },
        checkAddress() {
            var address = this.targetAddress.toLowerCase()

            if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
                this.isAddressWarning = true;
            } else {
                this.isAddressWarning = false;
            }
        },
        async reqNftTransfer(){

            if(this.targetAddress === '' ||this.isAddressWarning) return;

            if(this.initItemInfo.flagMint === 0){
                if(!this.isAllowance){
                    var NftContract = this.$store.state.configFront.contract1155;
                    var targetAddress = this.$store.state.configFront.contractJoin;
                    var walletAddress = this.$store.state.walletAddress;
                    this.setApprovalForAll(NftContract, targetAddress, walletAddress, null,
                        (result)=>{result; this.isAllowance = true; this.reqNftTransfer()},
                        (err)=>{console.error(err)}
                    );
                    return;
                }
                
                var param = 
                {
                    id: this.$store.state.id,
                    ticketId: this.$store.state.ticket,
                    productIdx: this.initItemInfo.productId,
                    amount: this.numberQuantity,
                    toAddress: this.targetAddress
                }
                // console.log(param);
                this.$Axios.post(`${this.host}/nft/transfer`, param, this.headers).then(async (res) => {
                    // console.log(res.data)
                    if(res.data.returnCode === 0){
                        this.reqTransferWithMint(res.data.data);
                    }
                    else {
                        this.$emit('error', res.data.returnCode);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            }
            else if (this.initItemInfo.flagMint === 1){
                this.reqSafeTransferFrom();
            }
        },
        reqTransferWithMint(transferData){
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractAddress = this.$store.state.configFront.contractJoin;

                const abi = this.marketContract.abi.find((contract) => contract.name === 'transferWithMint');
                const data = caver.klay.abi.encodeFunctionCall(abi,
                    [
                        caver.utils.toBN(transferData.tsdId),
                        transferData.tsdContractAddress,
                        transferData.tsdContractType,
                        transferData.tsdItemId,
                        transferData.tsdAmount,
                        this.targetAddress,
                        transferData.tsdAuthor,
                        transferData.tsdMintAmount,
                        caver.utils.toBN(transferData.tsdExpired),
                        transferData.signResult,
                    ]
                )
                // console.log(data);
                
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: contractAddress,
                    // value: price,
                    gas: '300000',
                    data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    // this.isCompleteBuy = true;
                    // this.txReceipt = receipt.transactionHash;
                    // console.log('receipt');
                    // console.log(receipt);
                    this.$emit("completeTransfer")
                })
                .on('error', (error) =>{
                    console.error('error : ' + error);
                });
            }
        },
        reqSafeTransferFrom(){
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var contractAddress = this.$store.state.configFront.contract1155;

                const abi = this.nftContract.abi.find((contract) => contract.name === 'safeTransferFrom');
                const data = caver.klay.abi.encodeFunctionCall(abi,
                    [
                        this.$store.state.walletAddress,
                        this.targetAddress,
                        caver.utils.toBN(this.initItemInfo.productId),
                        caver.utils.toBN(this.numberQuantity),
                        "0x00"
                    ]
                )
                // console.log(data);
                
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: contractAddress,
                    // value: price,
                    gas: '300000',
                    data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    // console.log('receipt');
                    // console.log(receipt);
                    this.$emit("completeTransfer")
                })
                .on('error', (error) =>{
                    console.error('error : ' + error);
                });
            }
        },
        commaAmount() {
            var x = "";
            var max;
            x = this.quantity.toString();
            max = this.initItemInfo.itemCountMine - this.initItemInfo.itemCountOnSaleMine;

            x = this.numberOnlyWithMax(x, max);
            
            this.numberQuantity = Number(x);
            this.quantity = this.setComma(x.toString());
        },
        closePopup() {
            this.$emit("close");
        },
    },
};
</script>
