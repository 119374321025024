<template>    
    <div class="inquiry-write">
        <Header :initHeaderType="initHeaderType" />
        <b-container class="paddingLR20">
            <div class="support-box">
                <p class="title">{{ $t('inquiry.title') }}</p>
                <b-row class="typing-title">
                    <b-col class="first">{{ $t('inquiry.writeTitle1') }}</b-col>
                    <b-col>
                        <b-input placeholder="문의 제목을 입력해주세요" v-model="title"></b-input>
                    </b-col>
                </b-row>
                <b-row class="typing-contents">
                    <b-col class="first">{{ $t('inquiry.writeTitle2') }}</b-col>
                    <b-col>
                        <b-textarea placeholder="문의 내용을 입력해주세요" v-model="message"></b-textarea>
                    </b-col>
                </b-row>
            </div>
            <p class="precautions">{{ $t('inquiry.writePrecautions') }}</p>
            <div class="buttons">
                <button @click="$router.go(-1)" class="cancel">{{ $t('inquiry.writeButton1') }}</button>
                <button @click="reqRegisterInquiry">{{ $t('inquiry.writeButton2') }}</button>
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "InquiryWrite",
    krName: '1:1 문의하기',
    components: {
        Header,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type3',
            title: '',
            message: '',

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        };
    },
    props: {
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        async reqRegisterInquiry(){

            if(this.title.length <= 0){
                this.initPopupOkMessage = '제목을 입력해 주세요.';
                this.isShowPopupOk = true;
                return;
            } else if(this.title.length > 100){
                this.initPopupOkMessage = '제목은 최대 100자까지만 등록할 수 있습니다.';
                this.isShowPopupOk = true;
                return;
            }

            if(this.message.length <= 0){
                this.initPopupOkMessage = '문의 내용을 입력해 주세요.';
                this.isShowPopupOk = true;
                return;
            } else if(this.message.length > 1000){
                this.initPopupOkMessage = '문의 내용은 최대 1000자까지만 등록할 수 있습니다.';
                this.isShowPopupOk = true;
                return;
            }

            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                title: this.title,
                message: this.message
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/support/inquiry/register`, param, this.headers).then(async(res) => {
                // console.log('--register inquiry--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '1:1문의 등록 성공';
                    this.PopupOkCallbakPage = "/Inquiry";
                    this.isShowPopupOk = true;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
                return false;
            });
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        },
    },
};
</script>
