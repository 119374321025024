import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import BigNumber from "bignumber.js";
import VueClipboard from "vue-clipboard2";
import i18n from "./i18n";
import ServerErrorList from "./assets/js/ServerErrorList.js"
import Caver from "caver-js"
import dayjs from"dayjs"
import VueApexCharts from 'vue-apexcharts'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'swiper/dist/css/swiper.css'

import "./assets/css/reset.css";
import "./assets/css/common.css";
import "./assets/css/popup.css";

import "./assets/css/index.css";
import "./assets/css/market.css";
import "./assets/css/profile.css";
import "./assets/css/balhang.css"
import "./assets/css/accountsetting.css"
import"./assets/css/explorer.css"
import"./assets/css/support.css"
import"./assets/css/signup.css"

import "./assets/css/components.css";

Vue.prototype.$Axios = axios;
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueClipboard);
Vue.use(VueApexCharts)

Vue.mixin({
    data() {
        return {
            // host: "https://devapi.numbers21.com:9443/attnftmarket/api/v1",  //test
            // ws_host: 'wss://artube-nft.numbers21.com:10443',    //test

            host: "https://market.artube.video:30443/attnftmarket/api/v1",  //live
            ws_host: 'wss://market.artube.video:10443',     //live

            headers: {
                headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            },
        };
    },
    created() {
        BigNumber.config({ EXPONENTIAL_AT: [-10, 100] });
    },
    beforeDestroy() {},
    methods: {
        isPc() {
            var ua = window.navigator.userAgent.toString().toLowerCase() || '';
            if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
                return true;
            }
            return false;
        },
        isTablet() {
            var ua = window.navigator.userAgent.toString().toLowerCase() || '';
            if (ua.match(/ipad/) || (ua.match(/android/) && !ua.match(/mobi|mini|fennec/))) {
                return true;
            }
            return false;
        },
        isMobile() {
            var ua = window.navigator.userAgent.toString().toLowerCase() || '';
            if (ua.match(/ip(hone|od)|android.+mobile|windows (ce|phone)|blackberry|bb10|symbian|webos|firefox.+fennec|opera m(ob|in)i|polaris|iemobile|lgtelecom|nokia|sonyericsson|dolfin|uzard|natebrowser|ktf;|skt;/)) {
                return true;
            } else {
                return false;
            }
        },
        getErrorMsg: (returnCode) => {
            if(isNaN(returnCode)) return returnCode;
            
            var localeMessage = ServerErrorList.find((localeMessage) => localeMessage.locale === i18n.locale).message_list;
            var errorMessage = localeMessage.find((message) => message.id === returnCode);
            if(errorMessage) return errorMessage.message;
            else return localeMessage.find((message) => message.id === 999).message;
        },
        getAddDateString(addValue, addFormat, getStringFormat){
            return dayjs().add(addValue, addFormat).format(getStringFormat);
        },
        getLocalizedDateString(date) {
            return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        },
        getLocalizedDayString(date) {
            return dayjs(date).format("YYYY-MM-DD");
        },
        getLocalizedFormatString(date, format) {
            return dayjs(date).format(format);
        },
        DateParseForISO(date) {
            return dayjs(date).unix() * 1000;
        },
        getBigNumber(param) {
            return BigNumber(param)
                .times(BigNumber(10 ** 18))
                .toString();
        },
        getStr(value) {
            if (value === undefined || value === null) return "0";
            var numValue = Number(value);
            var divNum = 0;
            var commaNum = "";

            if (numValue < 10000) {
                return this.setComma(numValue.toString());
            } else if (numValue / 1000 < 1000) {
                divNum = (numValue / 1000).toFixed(0).toString();
                commaNum = this.setComma(divNum);
                return commaNum + "K";
            } else {
                divNum = (numValue / 1000 / 1000).toFixed(0).toString();
                commaNum = this.setComma(divNum);
                return commaNum + "M";
            }
        },
        getByteLength(decimal) {
            const LINE_FEED = 10; // '\n'
            return (decimal >> 7) || (LINE_FEED === decimal) ? 2 : 1
        },
        getByte(str) {
            var byteLength = str.split('').map((s) => s.charCodeAt(0)).reduce((prev, unicodeDecimalValue) => prev + this.getByteLength(unicodeDecimalValue), 0);
            return byteLength;
        },
        getLimitedByteText(inputText, maxByte) {
            const characters = inputText.split('')
            let validText = ''
            let totalByte = 0

            for (let i = 0; i < characters.length; i += 1) {
                const character = characters[i]
                const decimal = character.charCodeAt(0)
                const byte = this.getByteLength(decimal) // 글자 한 개가 몇 바이트 길이인지 구해주기

                // 현재까지의 바이트 길이와 더해 최대 바이트 길이를 넘지 않으면 
                if (totalByte + byte <= maxByte) { 
                totalByte += byte      // 바이트 길이 값을 더해 현재까지의 총 바이트 길이 값을 구함
                validText += character // 글자를 더해 현재까지의 총 문자열 값을 구함
                } else {                 // 최대 바이트 길이를 넘으면
                break                  // for 루프 종료
                }
            }

            return validText
        },
        // getStrByteLength(str) {
        //     //정규식으로 Byte계산
        //     // var byteLength = str.replace(/[\0-\x7f]:([0-\u07ff]:(.))/g,"$&amp;$1$2").length;
        //     // console.log(byteLength);
        //     // return byteLength

        //     //속도개선 문자열 Byte 계산
        //     var byteLength = (function(s, b,i,c){
        //         for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
        //         return b
        //     })(str);
        //     console.log(byteLength +" Bytes");
        //     return byteLength;
        // },
        checkNftFileExt(fileName, accept = ['jpg', 'gif', 'png', 'svg', 'mp4', 'webm', 'mp3', 'wav', 'ogg']){
            let fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length).toLowerCase();
            var objExt = accept.find((ext)=> ext == fileExt);
            // console.log(objExt);
            if(objExt === undefined) return true;
            return false;
        },
        checkImageFileExt(fileName, accept = ['jpg', 'gif', 'png', 'svg']){
            let fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length).toLowerCase();
            var objExt = accept.find((ext)=> ext == fileExt);
            // console.log(objExt);
            if(objExt === undefined) return true;
            return false;
        },
        setComma(obj) {
            var regx = new RegExp(/(-?\d+)(\d{3})/);
            var bExists = obj.indexOf(".", 0); //0번째부터 .을 찾는다.
            var strArr = obj.split(".");
            while (regx.test(strArr[0])) {
                //문자열에 정규식 특수문자가 포함되어 있는지 체크
                //정수 부분에만 콤마 달기
                strArr[0] = strArr[0].replace(regx, "$1,$2"); //콤마추가하기
            }
            if (bExists > -1) {
                //. 소수점 문자열이 발견되지 않을 경우 -1 반환
                obj = strArr[0] + "." + strArr[1];
            } else {
                //정수만 있을경우 //소수점 문자열 존재하면 양수 반환
                obj = strArr[0];
            }
            return obj; //문자열 반환
        },
        numberOnly(numStr, isInt = false, decimalPlace = 4) { //isInt : 정수여부, decimalPlace : 소수점 표시 자리
            if(numStr === null) numStr = '';
            else if (isInt) numStr = numStr.replace(/[^0-9]/g, "");
            else {
                if(typeof numStr !== 'string') numStr = numStr.toString();
                numStr = numStr.replace(/[^.0-9]/g, "");
                var decimalNumber = "";
                if (numStr.indexOf(".", 0) >= 0) {
                    decimalNumber = numStr.substring(numStr.indexOf(".", 0));
                } else {
                    numStr = Number(numStr).toString();
                }

                if (decimalNumber.length > 1) {
                    if (Number(decimalNumber.substring(1)) > 0) {
                        numStr = Number(numStr.substring(0, numStr.indexOf("."))).toString();
                        numStr += decimalNumber.substring(0, (decimalPlace + 1)); // . 이 포함되어 있어서 +1 해줌
                        numStr = Number(numStr);
                    }
                }
            }
            return numStr;
        },
        numberOnlyWithMax(numStr, max, isInt = false) {
            numStr = this.numberOnly(numStr, isInt);
            if (max <= Number(numStr)) {
                numStr = max.toString();
            }
            return numStr;
        },
        async isApprovedForAll(NFTContract, targetAddress, walletAddress, isApprovedCallback = null) {
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(klaytn);
                var objKip37 = await new caver.kct.kip37(NFTContract);

                await objKip37.isApprovedForAll(walletAddress, targetAddress).then(approved =>{
                    // console.log('isApproved result : ' + approved);
                    isApprovedCallback(approved);
                })
            }
        },
        async setApprovalForAll(NFTContract, targetAddress, walletAddress, transactionHashCallback = null, receiptCallback = null, errCallback = null){
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                const data = caver.klay.abi.encodeFunctionCall(
                    {
                        name: 'setApprovalForAll',
                        type: 'function',
                        inputs: [
                        {
                            name: 'operator',
                            type: 'address'
                        },
                        {
                            name: 'approved',
                            type: 'bool'
                        }
                        ]
                    },
                    [targetAddress, true]
                )
                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: walletAddress,
                    to: NFTContract,
                    gas: '100000',
                    data,
                    value: '0x0'
                })
                .on('transactionHash', (hash) =>{
                    if(transactionHashCallback) transactionHashCallback(hash);
                })
                .on('receipt', (receipt)=> {
                    if(receiptCallback) receiptCallback(receipt);
                })
                .on('error', (error) =>{
                    if(errCallback) errCallback(error);
                });
            }
        },
        async checkAllowance(tokenContract, targetAddress, walletAddress, isAllowanceCallback = null) {
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(klaytn);
                var objKip7 = await new caver.kct.kip7(tokenContract);

                await objKip7.allowance(walletAddress, targetAddress).then(allowance =>{
                    // console.log('isallowance result : ' + allowance);
                    isAllowanceCallback(allowance);
                })
            }
        },
        async ApproveToken(tokenContract, targetAddress, walletAddress, transactionHashCallback = null, receiptCallback = null, errCallback = null) {
            var klaytn = window.klaytn;
            if(typeof klaytn !== 'undefined') {
                var caver = new Caver(klaytn);
                const data = caver.klay.abi.encodeFunctionCall(
                    {
                        inputs: [
                            { internalType: "address", name: "spender", type: "address" },
                            { internalType: "uint256", name: "amount", type: "uint256" },
                        ],
                        name: "approve",
                        outputs: [{ internalType: "bool", name: "", type: "bool" }],
                        stateMutability: "nonpayable",
                        type: "function",
                    },
                    [targetAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935"]
                );

                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: walletAddress,
                    to: tokenContract,
                    gas: '100000',
                    data,
                    value: '0x0'
                })
                .on('transactionHash', (hash) =>{
                    if(transactionHashCallback) transactionHashCallback(hash);
                })
                .on('receipt', (receipt)=> {
                    if(receiptCallback) receiptCallback(receipt);
                })
                .on('error', (error) =>{
                    if(errCallback) errCallback(error);
                });

            }
        },
        //로컬스토리지에 객체저장
        fn_setLocalStorage(name,obj){
            localStorage.setItem(name, obj);
        },
        //로컬스토리지에 특정 객체 삭제
        fn_removeLocalStorage(name){
            localStorage.removeItem(name);
        },
        //로컬스토리지에서 특정 객체 가져오기
        fn_getItemLocalStorage(name){
            return localStorage.getItem(name);
        },
    },
});

Vue.filter("comma", function (val) {
    var filter_str = "";
    var isMinus = false;

    if (val < 0) {
        isMinus = true;
        val = val.toString().replace("-", "");
    }

    if (val >= 0 && val !== null && val !== undefined) {
        var split_num = val.toString().split(".");
        filter_str = String(split_num[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (split_num[1]) {
            filter_str = filter_str + "." + split_num[1];
        }
    }

    if (filter_str === "" || filter_str === null || filter_str === undefined) {
        filter_str = "0";
    }

    if (isMinus) {
        filter_str = "-" + filter_str;
    }

    return filter_str;
});

Vue.component('apexchart', VueApexCharts)

new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n,
}).$mount("#app");
