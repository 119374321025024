<template>
    <div class="main">
        <Header :initHeaderType="initHeaderType" />
        <div v-for="(parentItem, curationIdx) in curationAllList" :key="curationIdx">
            <div class="featured" v-if="parentItem.curationType === 0">
                <b-container>
                    <div class="text-area">
                        <img src="@/assets/images/maintext.svg" />
                        <p>{{ $t('main.featured-title') }}</p>
                        <div class="buttons">
                            <router-link tag="a" to="/Marketplace">{{ $t('main.featured-button1') }}</router-link>
                            <a class="black" @click="moveCreateItem()">{{ $t('main.featured-button2') }}</a>
                        </div>
                    </div>
                    <div class="swiper-cover">
                        <swiper :options="featuredWwiperOption" ref="featuredSwiper" class="mySwiper" @slideChange="onChangeFeaturedSwiper" v-if="$store.state.configFront !== null">
                            <swiper-slide v-for="(item,idx) in parentItem.dataList" :key="idx" :class="'slide' + idx">
                                <div class="featured-img">
                                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + item.itemFileType + '/' + item.itemFileName" @click="moveProductDetil(item)"/>
                                </div>
                                <div class="right" @click="moveProductDetil(item)">
                                    <img :src="$store.state.configFront.cdnUrl + 'user/profile/' + item.memberFileName" v-if="item.memberFileName !== null" />
                                    <div class="info">
                                        <p class="name">{{item.name}}</p>
                                        <p class="writer">{{item.memberId}}</p>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <!-- <img src="@/assets/images/slideimage/left-arrow.svg" class="unfold" @click="isMoveLeft = !isMoveLeft" /> -->
                    <div class="swiper-button-next" slot="button-next" id="button-next"></div>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <!-- <div class="smoke"></div> -->
                </b-container>
            </div>
            <b-container class="slider" v-else-if="parentItem.curationType === 1">
                <div class="collection">
                    <p class="title">{{ parentItem.name }}</p>
                    <div class="collection-inner">
                        <swiper :options="collectionSwiperOption" class="mySwiper" v-if="$store.state.configFront !== null">
                            <swiper-slide v-for="(item,idx) in parentItem.dataList" :key="idx">
                                <VueLoadImage>
                                    <div slot="preloader" class="img-preload"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div slot="image" class="shadow" @click="moveCollectionDetil(item)" :src="$store.state.configFront.cdnUrl + 'collection/featured/' + item.featuredFileName">
                                        <div class="work-cover">
                                            <img :src="$store.state.configFront.cdnUrl + 'collection/featured/' + item.featuredFileName" class="work" />
                                        </div>
                                        <img :src="$store.state.configFront.cdnUrl + 'collection/logo/' + item.logoFileName" class="profile" />
                                        <div class="info">
                                            <p class="wname">{{item.collectionName}}</p>
                                            <p class="writer">{{item.creatorId}}</p>
                                            <p class="wcontents">{{item.collectionDescription}}</p>
                                            <p class="items">{{item.itemCount | comma}} {{ $t('main.item') }}</p>
                                        </div>
                                    </div>
                                    <div class="shadow" slot="error">
                                        {{ $t('main.error') }}
                                    </div>
                                </VueLoadImage>
                            </swiper-slide>
                        </swiper>
                        <div class="collection-button-next" slot="button-next"></div>
                        <div class="collection-button-prev" slot="button-prev"></div>
                    </div>
                </div>
            </b-container>

            <b-container class="slider" v-else-if="parentItem.curationType === 2">
                <div class="category">
                    <p class="title">{{ parentItem.name }}</p>
                    <div class="menu-box">
                        <router-link tag="li" :to="'/Marketplace/' + item.categoryName" v-for="(item, index) in parentItem.dataList" :key="index">
                            <img :src="$store.state.configFront.cdnUrl + 'category/' + item.imageFileName" v-if="$store.state.configFront !== null"/>
                            <p>{{ item.categoryName }}</p>
                        </router-link>
                    </div>
                </div>
            </b-container>

            <b-container class="slider" v-else-if="parentItem.curationType === 3">
                <div class="trend">
                    <p class="title">{{ parentItem.name }}</p>
                    <Slide :slideType="'curation'" :slideItem="parentItem.dataList" :swiperNextButton="'trend-slide-next'" :swiperOptionNext="'.trend-slide-next'" :swiperPrevButton="'trend-slide-prev'" :swiperOptionPrev="'.trend-slide-prev'" />
                </div>
            </b-container>

            <b-container class="slider" v-else-if="parentItem.curationType === 4">
                <div class="new">
                    <p class="title">{{ parentItem.name }}</p>
                    <Slide :slideType="'curation'" :slideItem="parentItem.dataList" :swiperNextButton="'new-slide-next'" :swiperOptionNext="'.new-slide-next'" :swiperPrevButton="'new-slide-prev'" :swiperOptionPrev="'.new-slide-prev'" />
                </div>
            </b-container>

            <b-container class="slider" v-else-if="parentItem.curationType === 6">
                <div class="new">
                    <p class="title">{{ parentItem.name }}</p>
                    <Slide :slideType="'curation'" :slideItem="parentItem.dataList" :swiperNextButton="'auction-slide-next'" :swiperOptionNext="'.auction-slide-next'" :swiperPrevButton="'auction-slide-prev'" :swiperOptionPrev="'.auction-slide-prev'" />
                </div>
            </b-container>

            <b-container class="slider" v-else-if="parentItem.curationType === 5">
                <div class="tips">
                    <p class="title">{{ parentItem.name }}</p>
                    <div class="tips-box" v-if="$store.state.configFront !== null">
                        <a class="tips-item" v-for="(item,idx) in parentItem.dataList" :key="idx" :href="item.connectUrl" target="_blank">
                            <div class="tips-item-inner">
                                <img :src="$store.state.configFront.cdnUrl + 'curationTip/' + item.fileName" />
                                <div class="text-area">
                                    <p class="title">{{item.title}}</p>
                                    <p class="cont">{{item.detail}}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </b-container>
        </div>
        <b-container class="make-nft">
            <div class="title-box">
                <p class="title">{{ $t('main.makeNFT') }}</p>
                <router-link tag="a" to="/FAQ">{{$t('main.more-guide')}}</router-link>
                <!-- <img src="@/assets/images/text.png" @click="$router.push('/FAQ')" /> -->
            </div>
            <b-row>
                <b-col sm="3">
                    <img src="@/assets/images/make-wallet.svg" />
                    <p class="title">{{ $t('main.makeTitle1') }}</p>
                    <p class="cont">{{ $t('main.makeCont1') }}</p>
                </b-col>
                <b-col sm="3">
                    <img src="@/assets/images/make-collection.svg" />
                    <p class="title">{{ $t('main.makeTitle2') }}</p>
                    <p class="cont">{{ $t('main.makeCont2') }}</p>
                </b-col>
                <b-col sm="3">
                    <img src="@/assets/images/make-img.svg" />
                    <p class="title">{{ $t('main.makeTitle3') }}</p>
                    <p class="cont">{{ $t('main.makeCont3') }}</p>
                </b-col>
                <b-col sm="3">
                    <img src="@/assets/images/make-sell.svg" />
                    <p class="title">{{ $t('main.makeTitle4') }}</p>
                    <p class="cont">{{ $t('main.makeCont4') }}</p>
                </b-col>
            </b-row>
        </b-container>

        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupTwoButton :initIsShowPopup="isShowPopupTB" :PopupTitle="iniPopupTBTitle" :PopupMessage="initPopupTBMessage" :ButtonLeft="'취소'" :ButtonRight="'확인'" @clickLeftBtn="close('PopupTB')" @clickRightBtn="$router.push('/CollectionRegist')" @close="close('PopupTB')" />
        <PopupNotice />
        <Footer />
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Header from '@/components/common/Header.vue'
import Slide from '@/components/Slide.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/popup/PopupOK.vue'
import PopupNotice from '@/components/popup/PopupNotice.vue'
import PopupTwoButton from '@/components/popup/PopupTwoButton.vue'
import VueLoadImage from 'vue-load-image'

export default {
    name: 'Index',
    components: {
        swiper,
        swiperSlide,
        Header,
        Slide,
        Footer,
        PopupOK,
        PopupNotice,
        PopupTwoButton,
        VueLoadImage,
    },
    data() {
        return {
            initHeaderType: 'type1',
            isMoveLeft: false,

            curationAllList: [],
            featuredList: [],
            trendingCollectionList: [],
            marketCategoryList: [],
            trendingNftItemList: [],
            newNftItemList: [],
            tipList: [],

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupTB: false,
            iniPopupTBTitle: '',
            initPopupTBMessage: '',     //TB : TwoButton
            
            featuredWwiperOption: {
                initialSlide: 0, //시작 index
                slidesPerView: 'auto', //한 화면에 보여질 슬라이드 수
                spaceBetween: 0, //슬라이드 간 간격
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                touchRatio: 0,
                autoplay: {
                    delay: 4000
                },
                loop: false,
                speed: 500,
                centeredSlides: false, //active된 슬라이드 가운데 위치
            },
            collectionSwiperOption: {
                initialSlide: 0, //시작 index
                slidesPerView: 'auto', //한 화면에 보여질 슬라이드 수
                spaceBetween: 0, //슬라이드 간 간격
                navigation: {
                    nextEl: '.collection-button-next',
                    prevEl: '.collection-button-prev'
                },
                loop: false,
                speed: 500,
                centeredSlides: false, //active된 슬라이드 가운데 위치
            },
        }
    },
    created(){
        this.setCurationList();
    },
    mounted(){
    },
    beforeDestroy(){
    },
    methods: {
        setCurationList(){
            var param = 
            {
            }
            this.$Axios.post(`${this.host}/nft/curation/all`, param, this.headers).then(async (res) => {
                // console.log('---set Curation List----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.curationAllList = res.data.list;
                    res.data.list.forEach((item)=>{
                        if(item.curationType === 0) this.featuredList = item.dataList;
                        else if(item.curationType === 1) this.trendingCollectionList = item.dataList;
                        else if(item.curationType === 2) this.marketCategoryList = item.dataList;
                        else if(item.curationType === 3) this.trendingNftItemList = item.dataList;
                        else if(item.curationType === 4) this.newNftItemList = item.dataList;
                        else if(item.curationType === 5) this.tipList = item.dataList;
                    });
                    // console.log(this.featuredList);
                    // console.log(this.trendingCollectionList);
                    // console.log(this.marketCategoryList);
                    // console.log(this.trendingNftItemList);
                    // console.log(this.newNftItemList);
                    // console.log(this.tipList);
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        moveProductDetil(item){
            this.$router.push('/NftInfo/' + item.productIdx)
        },
        moveCollectionDetil(item){
            // console.log(item);
            this.$router.push('/Collection/' + item.collectionIdx)
        },
        onChangeFeaturedSwiper(){
            var lastSlide = document.getElementsByClassName('swiper-slide')[this.featuredList.length-1].className;
            var lastClass = 'slide' + (this.featuredList.length-1);

            if(lastSlide === 'swiper-slide ' + lastClass + ' swiper-slide-next'){
                this.addClass('button-next','swiper-button-disabled');
            } else{
                this.delClass('button-next','swiper-button-disabled')
            }
        },
        moveCreateItem(){
            if(this.$store.state.collectionCount > 0){
                this.$router.push('/NftPublish')
            } else {
                this.initPopupTBMessage = 'NFT를 생성하기 위해선 최소 1개의 컬렉션이 필요합니다.<br/>컬렉션 생성 페이지로 이동 하겠습니까 ? ';
                this.isShowPopupTB = true;
            }
        },
        addClass(id, className) {
            try{
                var element = document.getElementById(id);
                var classNames = element.className.split(' ');
                var getIndex = classNames.indexOf(className);

                if (getIndex === -1) {
                    classNames.push(className);
                    element.className = classNames.join(' ');
                }
            }catch(err){err;}
		},
		delClass(id, className) {
            try{
                var element = document.getElementById(id);
                var classNames = element.className.split(' ');
                var getIndex = classNames.indexOf(className);

                if (getIndex > -1) {
                    classNames.splice(getIndex, 1);
                }
                element.className = classNames.join(' ');
            }catch(err){err;}
		},
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
                case 'PopupTB':
                    this.isShowPopupTB = false;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    }
}
</script>
