<template>
    <div class="popup bid-cancel" v-if="initIsShowPopup && initSaleInstance !== null && initBidInfo !== null">
        <div class="popup-box padding buy">
            <div class="top">
                <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
                <p class="popup-title">{{ $t('bidCancel.title') }}</p>
            </div>
            <div class="bottom">
                <div class="title-box">
                    <p class="title">{{ $t('buy.item') }}</p>
                    <p class="title subtotal">{{ $t('buy.subtotal') }}</p>
                </div>
                <div class="product">
                    <img :src="$store.state.configFront.cdnUrl + 'resource/' + initSaleInstance.fileType + '/' + initSaleInstance.fileName" v-if="$store.state.configFront !== null && initSaleInstance.productInfoVO.pdResourceType === 1"/>
                    <img :src="$store.state.configFront.cdnUrl + 'resource/thumbnail/' + initSaleInstance.productInfoVO.pdThumbnailFileName" v-if="$store.state.configFront !== null && initSaleInstance.productInfoVO.pdResourceType === 2"/>
                    <div>
                        <router-link tag="p" :to="'/Collection/' + initSaleInstance.collectionIdx" class="writer">{{initSaleInstance.collectionName}}</router-link>
                        <p class="name">{{initSaleInstance.productInfoVO.pdName}}</p>
                    </div>
                    <div class="price">
                        <div>
                            <img src="@/assets/images/icon/icon_symbol_artube.png" v-if="initBidInfo.symbol === 'ATT'" />
                            <img src="@/assets/images/icon/icon_symbol_klaytn.svg" v-else-if="initBidInfo.symbol === 'KLAY'" />
                            <p>{{initBidInfo.price | comma}}</p>
                            <span>(${{$store.getters.getTokenUsdPrice(initBidInfo.symbol, initBidInfo.price, 4)}})</span>
                        </div>
                    </div>
                </div>
                <div class="tips">
                    <p>{{ $t('bidCancel.tip1') }}</p>
                    <p>{{ $t('bidCancel.tip2') }}</p>
                </div>
                <a class="ok" @click="reqCancelBid">{{ $t('buy.button2') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import Caver from "caver-js";
import AuctionContract from "@/assets/json/NftAuction.json";

export default {
    name: "PopupBuy",
    data() {
        return {
            auctionContract: AuctionContract,
            auctionAvailableRate: 0,
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
        initSaleInstance: {
            type: Object,
            default: null
        },
        initBidInfo:{
            type: Object,
            default: null
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    // watch:{
    //     initIsShowPopup(){
    //         if(this.initIsShowPopup && this.initSaleInstance !== null){
    //             console.log(this.initSaleInstance);
    //         }
    //     }
    // },
    methods: {
        reqCancelBid(){
            if(typeof window.klaytn !== 'undefined') {
                var caver = new Caver(window.klaytn);
                var auctionContractAddress = this.$store.state.configFront.contractJoin;
                var contractInstance = caver.contract.create(this.auctionContract.abi, auctionContractAddress);
                // console.log(this.auctionContract.abi);
                // console.log(auctionContractAddress);
            
                var data = contractInstance.methods.cancelBid(this.initSaleInstance.auctionId).encodeABI();
                // console.log(data);

                caver.klay.sendTransaction({
                    type: 'SMART_CONTRACT_EXECUTION',
                    from: this.$store.state.walletAddress,
                    to: auctionContractAddress,
                    gas: '300000',
                    data: data
                })
                .on('transactionHash', (hash) =>{
                    hash;
                    // console.log('hash : ' + hash);
                })
                .on('receipt', (receipt)=> {
                    receipt;
                    // console.log(receipt);
                    this.$emit('completeBidCancel')
                })
                .on('error', (error) =>{
                    console.log('error : ' + error);
                });
            }
        },
        closePopup() {
            this.$emit("close");
        },
    },
};
</script>
