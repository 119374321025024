<template>
    <div class="my-wallet" :class="{dark : changeAccount}" v-if="initIsShowPopup">
        <div class="unconnect"  v-if="$store.state.erc20Address === ''">
            <div class="title-box">
                <img src="@/assets/images/my-wallet.svg" />
                <p class="title">{{ $t('myWallet.title') }}</p>
            </div>
            <div class="wallet" @click="connectKaikas">
                <img src="@/assets/images/icon_klaytn.svg" />
                <p>Kaikas</p>
            </div>
            <p class="ps">{{ $t('myWallet.ps') }}</p>
            <div class="guide">
                <router-link tag="button" to="/Notice">{{ $t('myWallet.button') }}</router-link>
            </div>
        </div>
        <div class="wallet-connect" v-else>
            <div class="title-box">
                <div class="change-account" :class="{on : changeAccount}"  v-click-outside="closeChangeAccount">
                    <div @click="changeAccount = !changeAccount" class="first">
                        <img src="@/assets/images/profile-menu/no-profile.png" v-if="$store.state.profile === null" alt="profile" class="profile-photo">
                        <img class="profile-photo" :src="$store.state.profile" v-else />
                        <p>{{ $t('header.m-wallet') }}</p>
                        <img class="arrow" src="@/assets/images/icon/icon_down_arrow.svg" />
                    </div>
                    <div v-if="changeAccount" class="kaikas">
                        <img src="@/assets/images/icon_klaytn.svg" />
                        <p>{{ $t('header.kaikas') }}</p>
                    </div>
                    <div v-if="changeAccount" @click="logout()" class="last">
                        <img src="@/assets/images/icon/logout.svg" />
                        <p>{{ $t('myWallet.logout') }}</p>
                    </div>
                </div>
                <div class="wallet-ad">
                    <img src="@/assets/images/icon_klaytn.svg" />
                    <p>{{$store.state.walletAddress}}</p>
                    <img src="@/assets/images/icon/copy.svg" class="btn-copy" v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler"/>
                </div>
            </div>
            <!-- <div class="change">
                <div class="wallet select">
                    <img src="@/assets/images/icon_klaytn.svg" />
                    <p>Kaikas</p>
                </div>
            </div> -->
            <div class="amount">
                <img src="@/assets/images/icon/recycle.svg" @click="refreshBalance()" class="refresh" />
                <div class="top">
                    <p>{{ $t('myWallet.amount') }}</p>
                </div>
                <p class="money">$ {{getTotalPrice() | comma}}</p>
                <!-- <button>{{ $t('myWallet.charge') }}</button> -->
            </div>
            <div class="coins">
                <draggable v-model="$store.state.balanceList">
                    <transition-group>
                        <div class="flex" v-for="(item, index) in $store.state.balanceList" :key="index">
                            <div class="left">
                                <img :src="item.iconImg" />
                                <p>{{item.name}}</p>
                            </div>
                            <div class="right">
                                <p>{{item.balance | comma}}</p>
                                <p class="usd">${{item.usd | comma}} USD</p>
                            </div>
                            <img src="@/assets/images/icon/three-dot.svg" />
                        </div>
                    </transition-group>
                </draggable>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import PopupOK from '@/components/popup/PopupOK.vue';
import vClickOutside from 'v-click-outside'

export default {
    name: "MyWallet",
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        PopupOK,
        draggable,
    },
    data() {
        return {
            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            changeAccount: false,
        };
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false,
        },
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        closeChangeAccount(){
            this.changeAccount = false;
        },
        clipboardSuccessHandler ({ value, event }) {
            value; event;
            this.initPopupOkMessage = "지갑 주소를 복사했습니다."
            this.isShowPopupOk = true;
        },
        connectKaikas(){
            this.$emit("connectKaikas");
        },
        refreshBalance(){
            this.$emit("getTokenInfo");
        },
        getTotalPrice(){
            var total = 0;
            this.$store.state.balanceList.forEach((item)=>{
                total += item.usd;
            })
            total = Math.floor(total * 10000) / 10000;
            return total;
        },
        logout(){
            this.$store.commit("disconnectKaikas");
            this.$store.commit("logout");
            this.$router.push('/');
        },
        closePopup() {
            this.$emit("close");
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
    },
};
</script>
