<template>
    <div class="notice">
        <Header :initHeaderType="initHeaderType" />
        <b-container class="paddingLR20">
            <div class="support-box">
                <p class="title">{{ $t('notice.title') }}</p>
                <p class="sub-title">{{ $t('notice.subTitle') }}</p>
                <div class="flex">
                    <div class="inline-text" v-if="initPagingData !== null">
                        <p>{{ $t('notice.all') }}</p>
                        <p class="count all">{{initPagingData.totalRecordSize}}</p>
                        <p>{{ $t('notice.have') }}</p>
                        <p>&nbsp;(<span class="count">{{initPagingData.pageNo}}</span>/{{initPagingData.pageCount}})</p>
                    </div>
                    <div class="input-group dropdown common">
                        <b-dropdown>
                            <template v-slot:button-content v-if="selectedSearchType === 1">{{ $t('notice.name') }}</template>
                            <template v-slot:button-content v-else-if="selectedSearchType === 2">{{ $t('inquiry.writeTitle2') }}</template>
                            <b-dropdown-item @click="changeSearchType(1)">{{ $t('notice.name') }}</b-dropdown-item>
                            <b-dropdown-item @click="changeSearchType(2)">{{ $t('inquiry.writeTitle2') }}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="search common">
                        <img src="@/assets/images/search.svg" @click="reqSearch()"/>
                        <input type="text" v-model="searchText" @keyup.enter="reqSearch()" />
                    </div>
                </div>
                <div class="chart">
                    <b-row class="chart-header">
                        <b-col class="no">{{ $t('notice.no') }}</b-col>
                        <b-col class="name">{{ $t('notice.name') }}</b-col>
                        <b-col class="day">{{ $t('notice.day') }}</b-col>
                    </b-row>
                    <router-link tag="div" :to="'/Notice/' + item.idx" class="note" v-for="(item, index) in noticeList" :key="index">
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1">
                                <b-row block variant="info">
                                    <b-col class="no" :class="{blue : item.listType === 2}">{{item.listType === 2 ? '공지' : item.no}}</b-col>
                                    <b-col class="name" :class="{blue : item.listType === 2}">{{item.title}}</b-col>
                                    <b-col class="day">{{item.createDate.substring(0,10)}}</b-col>
                                </b-row>
                            </b-card-header>
                        </b-card>
                    </router-link>
                </div>
                <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Pagination from '@/components/common/Pagination.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "Notice",
    krName: '공지사항',
    components: {
        Header,
        Pagination,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type3',

            selectedSearchType: 1,
            searchText: '',
            beforeSearchText: '',

            noticeList: [],

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이
        };
    },
    created() {
        this.getNoticeList();
    },
    mounted() {
    },
    beforeDestroy() {},
    methods: {
        getNoticeList(){
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                searchType: this.selectedSearchType,
                searchText: this.beforeSearchText,
            }
            this.$Axios.post(`${this.host}/support/notice/list`, param, this.headers).then(async (res) => {
                // console.log('------notice list---------');
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.noticeList = res.data.list;
                    this.searchText = this.beforeSearchText;
                    this.initPagingData = res.data.page;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        reqSearch(){
            if(this.beforeSearchText === this.searchText) return;
            this.beforeSearchText = this.searchText;
            this.currentPageNo = 1;
            this.getNoticeList();
        },
        changeSearchType(type){
            if(this.selectedSearchType === type) return;
            this.selectedSearchType = type;
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getNoticeList();
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
