<template>
    <div class="footer">
        <b-container>
            <div class="top-menu">
                <div class="left">
                    <h1 class="logo">Artube</h1>
                    <p>{{ $t('footer.footer-left') }}</p>
                </div>
                <div class="right">
                    <b-row>
                        <b-col class="market">
                            <p>{{ $t('footer.market') }}</p>
                            <router-link tag="a" to="/Marketplace">{{ $t('footer.market1') }}</router-link>
                            <router-link tag="a" :to="'/Marketplace/' + item.name" v-for="(item, index) in $store.state.categoryList" :key="index">
                                {{ item.name }}
                            </router-link>
                        </b-col>
                        <b-col class="account" v-if="$store.state.erc20Address !== ''">
                            <p>{{ $t('footer.account') }}</p>
                            <router-link tag="a" to="/MyProfile">{{ $t('footer.account1') }}</router-link>
                            <!-- <router-link tag="a" to="/MyCollection">{{ $t('footer.account2') }}</router-link> -->
                            <router-link tag="a" to="/MyProfile/Liked">{{ $t('footer.account3') }}</router-link>
                            <router-link tag="a" to="/AccountSetting">{{ $t('footer.account4') }}</router-link>
                        </b-col>
                        <b-col class="explore">
                            <p>{{ $t('footer.explore') }}</p>
                            <router-link tag="a" to="/Transactional">{{ $t('footer.explore2') }}</router-link>
                            <a @click="moveRanking()">{{ $t('footer.explore1') }}</a>
                            <!-- <router-link tag="a" to="/Ranking">{{ $t('footer.explore1') }}</router-link> -->
                        </b-col>
                        <b-col class="support">
                            <p>{{ $t('footer.support') }}</p>
                            <router-link tag="a" to="/Notice">{{ $t('footer.support1') }}</router-link>
                            <router-link tag="a" to="/FAQ">{{ $t('footer.support2') }}</router-link>
                            <router-link tag="a" to="/Inquiry" v-if="$store.state.erc20Address !== ''">{{ $t('footer.support3') }}</router-link>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="bottom-menu">
                <div class="popup-menu">
                    <a href="/PrivacyPolicy" target="_blank" class="policy">{{ $t('footer.policy') }}</a>
                    <a href="/UserTerms" target="_blank" class="service">{{ $t('footer.service') }}</a>
                </div>
            </div>
            <div class="footer-end">
                <p class="copyright">{{ $t('footer.copyright') }}</p>
                <a href="https://www.artube.video/" target="_blank" class="webtoon">{{ $t('footer.link1') }}</a>
                <a href="https://governance.artube.video/" target="_blank" class="governance">{{ $t('footer.link2') }}</a>
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import PopupOK from '@/components/popup/PopupOK.vue'

export default {
    name: "Footer",
    components:{
        PopupOK,
    },
    data() {
        return {
            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        };
    },
    props: {
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        moveRanking(){
            this.initPopupOkMessage = "정식 서비스에 오픈됩니다."
            this.isShowPopupOk = true;
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
    },
};
</script>
