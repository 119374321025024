<template>    
    <div class="collection-cpt" v-if="collectionInfo !== null && $store.state.configFront !== null">
        <div class="shadow" v-if="$store.state.configFront.cdnUrl !== null" @click="moveCollectionDetil()">
            <div class="work-cover">
                <img :src="$store.state.configFront.cdnUrl + 'collection/featured/' + collectionInfo.featured" class="work" v-if="collectionInfo.featured !== null" />
                <img :src="$store.state.configFront.cdnUrl + 'collection/logo/' + collectionInfo.logo" class="work" v-else-if="collectionInfo.logo !== null" />
                <div class="featured-blank" v-else></div>
            </div>
            <img :src="$store.state.configFront.cdnUrl + 'collection/logo/' + collectionInfo.logo" class="profile" />
            <div class="info">
                <p class="wname">{{collectionInfo.collectionName}}</p>
                <p class="writer">{{collectionInfo.memberId}}</p>
                <p class="wcontents">{{collectionInfo.description}}</p>
                <p class="wcount">{{collectionInfo.itemCount | comma}} ITEM</p>
            </div>
        </div>
        <b-dropdown id="dropdown-dropup" dropup text="" class="m-2 dots-menu" v-if="$store.state.userName === collectionInfo.memberId">
            <router-link tag="b-dropdown-item" :to="'/CollectionModify/' + collectionInfo.collectionIdx">{{ $t('collection.remake') }}</router-link>
            <router-link tag="b-dropdown-item" :to="'/MyProfile/TransactionHistory/' + collectionInfo.collectionIdx">거래 내역</router-link>
            <router-link tag="b-dropdown-item" :to="'/MyProfile/Loyalty/' + collectionInfo.collectionIdx">로열티 내역</router-link>
            <router-link tag="b-dropdown-item" :to="'/Collection/AllItem/' + collectionInfo.collectionIdx">전체보기</router-link>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: "collection",
        data() {
            return {
            };
        },
        props: {
            collectionInfo: {
                type: Object,
                default: null
            }
        },
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {
            moveCollectionDetil(){
                this.$router.push('/Collection/' + this.collectionInfo.collectionIdx)
            }
        },
    };
</script>
