<template>
    <div class="inquiry">
        <Header :initHeaderType="initHeaderType" />
        <b-container class="paddingLR20">
            <div class="support-box">
                <p class="title">{{ $t('inquiry.title') }}</p>
                <p class="sub-title">{{ $t('inquiry.contact') }}</p>
                <div class="inline-text">
                    <p>{{ $t('inquiry.precautions1') }} <span>{{ $t('inquiry.precautions2') }}</span></p>
                    <img src="@/assets/images/icon/siren.svg" />
                    <p class="pc">{{ $t('inquiry.precautions2') }}</p>
                </div>
                <div class="chart">
                    <b-row class="chart-header">
                        <b-col class="question">{{ $t('inquiry.chartTitle') }}</b-col>
                        <b-col class="status">{{ $t('inquiry.chartStatus') }}</b-col>
                        <b-col class="arrow"></b-col>
                    </b-row>
                    <div class="accordion" role="tablist" v-if="inquiryList.length > 0">
                        <b-card no-body class="mb-1" v-for="(item,idx) in inquiryList" :key="idx">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-row block v-b-toggle="'accordion-'+idx" variant="info">
                                    <b-col class="question">Q. <p class="message" v-html="item.title"></p></b-col>
                                    <b-col class="status wait" v-if="item.state === 0">{{ $t('inquiry.wait') }}</b-col>
                                    <b-col class="status end" v-else-if="item.state === 1">{{ $t('inquiry.end') }}</b-col>
                                    <b-col class="arrow"><img src="@/assets/images/icon/icon_down_arrow.svg" /></b-col>
                                </b-row>
                            </b-card-header>
                            <b-collapse :id="'accordion-' + idx" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text><p v-html="item.message"></p></b-card-text>
                                    <b-card-text class="answer" v-if="item.reply !== null">{{ $t('inquiry.answer') }}<p class="message" v-html="item.reply"></p></b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                    <p class="nope" v-else>{{ $t('inquiry.Nope') }}</p>
                </div>
                <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
            </div>
            <div class="write">
                <router-link tag="a" to="/Inquiry/Write">{{ $t('inquiry.write') }}</router-link>
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Pagination from '@/components/common/Pagination.vue';
import PopupOK from '@/components/popup/PopupOK.vue';

export default {
    name: "Inquiry",
    krName: '1:1 문의하기',
    components: {
        Header,
        Pagination,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type3',
            
            inquiryList: [],

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 7,    //한번에 불러올 게시물 수
            maxPageCount:5,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이
            
        };
    },
    created() {
        this.getQnaList();
    },
    mounted() {
    },
    beforeDestroy() {},
    methods: {
        getQnaList(){
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                // order: '1',
                // orderColumn: '',
                // apiStartDate: '',
                // apiEndDate: '',
                // searchText: '',
                // lang: this.$i18n.locale,
                // inquiryIdx: 0
            }
            this.$Axios.post(`${this.host}/support/inquiry/list`, param, this.headers).then(async (res) => {
                // console.log('------inquiry list---------');
                // console.log(res.data)
                if(res.data.returnCode === 0){
                    this.inquiryList = res.data.list;
                    this.initPagingData = res.data.page;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.inquiryList= [];
            this.getQnaList();
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
