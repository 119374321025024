<template>
    <div class="my-collection">
        <Header :initHeaderType="initHeaderType" />
        <b-container>
            <div class="add">
                <!-- <p class="title">{{ $t('publish-home.add') }}</p> -->
                <p class="title">{{ $t('publish-home.menu1') }}</p>
                <div class="text-box">
                    <p class="nft">{{ $t('publish-home.nft') }}</p>
                    <p>{{ $t('publish-home.collection') }}</p>
                </div>
                <div class="buttons">
                    <router-link tag="button" to="/CollectionRegist" class="on"><p class="plus"></p><p>{{ $t('publish-home.new') }}</p></router-link>
                    <!-- <router-link tag="button" to="/load-contract" class="load">{{ $t('publish-home.load') }}</router-link> -->
                </div>
            </div>
            <div class="my">
                <b-row>
                    <b-col class="my-collection-list" v-for="(item,idx) in myCollectionList" :key="idx">
                        <Collection :collectionInfo="item" />
                    </b-col>
                </b-row>
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOk" :initPopupTitle="iniPopupOkTitle" :initPopupMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Collection from '@/components/Collection.vue'
import PopupOK from '@/components/popup/PopupOK.vue'

export default {
    name: "MyCollection",
    components: {
        Header,
        Collection,
        PopupOK,
    },
    data() {
        return {
            initHeaderType: 'type1',

            myCollectionList: [],

            isShowPopupOk: false,
            iniPopupOkTitle: '',
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    created() {
        if(this.$store.state.id === "" || this.$store.state.ticket === ""){
            this.$router.push('/');
        }
    },
    mounted() {
        this.getMyCollectionList();
    },
    beforeDestroy() {},
    methods: {
        getMyCollectionList(){   //내가 등록한 모든 아이템 조회
            var param = 
            {
                id: this.$store.state.id,
                ticketId: this.$store.state.ticket,
                pageNo: 1,
                pageSize: 100,
                order: '1',
                // orderColumn: '',
                // apiStartDate: '',
                // apiEndDate: '',
                searchText: '',
                lang: this.$i18n.locale,
                // state: 0,
                // stateList: '',
            }
            this.$Axios.post(`${this.host}/nft/collection/list/mine`, param, this.headers).then(async (res) => {
                // console.log('---get collection list mine----');
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.myCollectionList = res.data.list;
                } else {
                    this.error(res.data.returnCode);
                }
            })
            .catch((error) => {
                this.error(error);
            });
        },
        moveCollectionDetil(item){
            this.$router.push('/Collection/' + item.collectionIdx)
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.iniPopupOkTitle = '';
                    this.initPopupOkMessage = '';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOk = false;
                    break;
            }
        },
        error(returnCode){
            this.iniPopupOkTitle = 'Error';
            this.initPopupOkMessage = this.getErrorMsg(returnCode);
            this.isShowPopupOk = true;
        }
    },
};
</script>
