<template>
    <div class="popup" v-if="initIsShowPopup">
        <div class="popup-box mailsent">
            <img src="@/assets/images/icon_close.png" @click="closePopup" class="close" />
            <p class="popup-title">{{ $t('mailsent.title') }}</p>
            <p class="popup-cont">{{ $t('mailsent.text1') }}</p>
            <p class="mail">{{ initBeforeSendEmail }}</p>
            <!-- <div class="resend-email">
                <input type="text" v-model="resendEmail" />
            </div> -->
            <!-- <p class="popup-cont2">{{ $t('mailsent.text2') }}</p> -->
            <div class="notice">
                <p class="title">{{ $t('mailsent.notice1') }}</p>
                <div class="flex">
                    <img src="@/assets/images/check-notice.svg" />
                    <p>{{ $t('mailsent.notice2') }}</p>
                </div>
                <div class="flex">
                    <img src="@/assets/images/check-notice.svg" />
                    <p>{{ $t('mailsent.notice3') }}</p>
                </div>
            </div>
            <a class="ok" @click="closePopup">{{ $t('mailsent.ok') }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupOk",
        data() {
            return {
                resendEmail: '',
            };
        },
        props: {
            initIsShowPopup: {
                type: Boolean,
                default: false
            },
            initBeforeSendEmail:{
                type: String,
                default: ''
            }
        },
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {
            closePopup() {
                this.$emit("close");
            },
        },
    };
</script>
